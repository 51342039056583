import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import useInfiniteScroll from "../../common/useInfiniteScroll.js";
import debounce from "lodash/debounce";
import { capitalize } from "@material-ui/core";
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useSelector, useDispatch } from 'react-redux';
import { useAuthHook } from "../../common/useAuthHook";
// Components
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "../../components/Typography/Typography";
import Card from "../../components/Card/Card";
import Menu from "../../components/Menu/Menu";
import AddNewCustomProfileModal from "./AddNewCustomProfileModal/AddNewCustomProfileModal";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
// Assets
import { ReactComponent as KeyboardArrowDownIcon } from "../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as SearchPlaceholderIcon } from "../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/IconMore.svg";
// Redux
import { getSubscriptionsRequest, deleteSubscriptionRequest } from "../../store/subscriptions/requests";
import { deleteUserRequest } from "../../store/userprofiles/requests";
import { getSubscriptionsSelector, getPaginationSelector, deleteSubscriptionStatusSelector } from "../../store/subscriptions/selectors";
import { deleteUserStatusSelector } from "../../store/userprofiles/selectors";
import { actions as subscriptionsActions } from "../../store/subscriptions/slice";
// Constants
import {
    REQUEST_SUCCESS
} from "../../constants/statuses";

const Row = props => {
    const classes = useStyles();
    const { row, key } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={key}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.row} onClick={() => setOpen(!open)}>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="secondaryDark">{t("users.name")}</Typography></TableCell>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="secondaryDark">{t("users.email")}</Typography></TableCell>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="secondaryDark"><Tag value="super_admin"/></Typography></TableCell>
                <TableCell className={classes.cell} align="right">...</TableCell>
            </TableRow>
        </React.Fragment>
    )
}

const Tag = ({ value }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let className;
    switch (value) {
        case "free trial":
            className = classes.freeTrial;
            break;
        case "pay as you go":
            className = classes.pro;
            break;
        default:
            className = classes[value];
    }
    return (
        <div className={classnames([classes.tag, className])}><Typography variant="bodySmall" className={classes.tagText}>{value ? capitalize(value) : ""}</Typography></div>
    );
}

const cards = [
    {
        tag: "free_trial",
        company_name: "Company name",
        email: "email@address.com"
    },
    {
        tag: "free",
        company_name: "Company name",
        email: "email@address.com"
    },
    {
        tag: "single",
        company_name: "Company name",
        email: "email@address.com"
    },
    {
        tag: "pro",
        company_name: "Company name",
        email: "email@address.com"
    },
    {
        tag: "teams",
        company_name: "Company name",
        email: "email@address.com"
    },
    {
        tag: "custom",
        company_name: "Company name",
        email: "email@address.com"
    }
]  

const Profiles = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const subscriptions = useSelector(getSubscriptionsSelector);
    const pagination = useSelector(getPaginationSelector);
    const deleteSubscriptionStatus = useSelector(deleteSubscriptionStatusSelector);
    const deleteUserStatus = useSelector(deleteUserStatusSelector);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const { user } = useAuthHook();
    const [addNewCustomProfileModalOpened, setAddNewCustomProfileModalOpened] = useState(false);

    useEffect(() => {
        user && dispatch(getSubscriptionsRequest({ search: searchValue }));
        dispatch(subscriptionsActions.deleteSubscriptionUndefined());
    }, [dispatch, user, searchValue]);

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    };

    const deleteUserSubscription = card => {
        if (selectedSubscriptionForDeletion) {
            dispatch(deleteSubscriptionRequest({ planId: card.plan_id, planType: card.plan_type }));
        } else {
            dispatch(deleteUserRequest({ id: card.user_id, planId: card.plan_id, planType: card.plan_type }));
        }
    }

    const handleSearchValueChange = e => {
        setSearchValue(e.target.value)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(1);

    useEffect(() => {
        if (pagination) {
            setCurrentPage(pagination.current_page);
            setTotalUsers(pagination.total)
        }
    }, [pagination])

    const moreData = () => {
        dispatch(getSubscriptionsRequest({ search: searchValue, page: currentPage + 1 }));
    }

    const [isFetching, setIsFetching] = useInfiniteScroll(debounce(moreData, 400), totalUsers);

    const [areYouSureModalOpened, setAreYouSureModalOpened] = useState(false);
    const [selectedSubscriptionForDeletion, setSelectedSubscriptionForDeletion] = useState();
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState();

    useEffect(() => {
        if (deleteSubscriptionStatus === REQUEST_SUCCESS) {
            setAreYouSureModalOpened(false);
            setSelectedSubscriptionForDeletion(null);
        }
        if (deleteUserStatus === REQUEST_SUCCESS) {
            setAreYouSureModalOpened(false);
            setSelectedUserForDeletion(null);
        }
    }, [deleteSubscriptionStatus, deleteUserStatus])

    const handleDeleteSubscription = card => {
        setAreYouSureModalOpened(true);
        setSelectedSubscriptionForDeletion(card);
        setSelectedUserForDeletion(null);
    }

    const handleDeleteUser = card => {
        setAreYouSureModalOpened(true);
        setSelectedUserForDeletion(card);
        setSelectedSubscriptionForDeletion(null);
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        {t("profiles.title")}
                    </Typography>
                    <TextField testId="header-search-field" onChange={handleSearchValueChange} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput}></TextField>
                </div>
                <Button disabled={user && user.role !== "admin" && user.role !== "super_admin"} variant="secondary" onClick={() => setAddNewCustomProfileModalOpened(true)}>
                    {t("profiles.addNewProfile")}
                </Button>
            </div>
            <div className={classes.cards}>
                {subscriptions && subscriptions.map((card, i) => (
                    <div
                    cardIndex={i}
                    className={classnames([classes.card, { [classes.clickable]: card && card.plan_type !== "free" }])}
                    key={`card-${i}`}
                    onClick={() => card && card.plan_type !== "free" && openPage(`/profiles/${card.plan_id}/${card.plan_type}`)}
                    >
                        <Tag value={card.plan_name}/>
                        {user && (user.role === "super_admin" || user.role === "admin") && <Menu
                            btnClassName={classes.moreButton}
                            onChange={handleMenuClick}
                            menuItems={card && card.plan_type !== "free" ? [
                                {
                                    label: t("profiles.deleteSubscription"),
                                    onClick: () => handleDeleteSubscription(card),
                                    warningItem: true
                                },
                                {
                                    label: t("profiles.deleteUser"),
                                    onClick: () => handleDeleteUser(card),
                                    warningItem: true
                                }
                            ] : [
                                {
                                    label: t("profiles.deleteUser"),
                                    onClick: () => handleDeleteUser(card),
                                    warningItem: true
                                }
                            ]}
                        >
                            <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                        </Menu>}
                        <Typography component="div" variant="bodyXs" className={classes.cardLabel} color="textLight">{t("profiles.companyName")}</Typography>
                        <Typography component="div" variant="body" color="black">{card.team_name ? card.team_name : card.plan_owner_company || card.user_company || card.user_name}</Typography>
                        <Typography component="div" variant="bodyXs" className={classes.cardLabel}  color="textLight">{t("profiles.adminEmail")}</Typography>
                        <Typography component="div" variant="body" color="black">{card.user_email}</Typography>
                    </div>
                ))}
            </div>
            <AddNewCustomProfileModal user={user} dialogOpened={addNewCustomProfileModalOpened} onClose={() => setAddNewCustomProfileModalOpened(false)} />
            <AreYouSureModal
                dialogOpened={areYouSureModalOpened}
                titleCopy={t('accountProfile.deleteUserTitle', { name: selectedSubscriptionForDeletion ? selectedSubscriptionForDeletion.user_company : selectedUserForDeletion && selectedUserForDeletion.user_name })}
                confirmCopy={selectedSubscriptionForDeletion ? t('accountProfile.yesDeleteProfile') : t('accountProfile.yesDeleteUser')}
                onConfirm={() => deleteUserSubscription(selectedSubscriptionForDeletion ? selectedSubscriptionForDeletion : selectedUserForDeletion)}
                onChange={() => setAreYouSureModalOpened(false)}
            />
        </div>
    )
};
export default Profiles;