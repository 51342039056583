import { all, takeEvery } from "redux-saga/effects";
import { filterResultsRequest, getFilterResultsCountRequest } from "../requests";
import { filterResults, getFilterResultsCount } from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();
function* watchGetFilteredData() {
    yield takeEvery(
        convertTypeFromAction(filterResultsRequest), ({ payload: { data } }) => filterResults(data)
    )
}
function* watchGetFilterResultsCount() {
    yield takeEvery(
        convertTypeFromAction(getFilterResultsCountRequest), ({ payload: { data } }) => getFilterResultsCount(data)
    )
}
function* saga() {
    yield all([
        watchGetFilteredData(),
        watchGetFilterResultsCount()
    ]);
}
export { saga };