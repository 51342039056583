import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, typography }) => createStyles({
    block: {
        width: "calc(50% - 12px)",
        height: 298,
        borderRadius: spacing(3),
        backgroundColor: palette.common.white,
        padding: spacing(4),
        margin: `${spacing(3)}px ${spacing(3)}px 0 0`,
        "&:nth-child(even)": {
            marginRight: 0
        },
        position: "relative",
        cursor: "pointer"
    },
    titleActions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    description: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
        paddingTop: spacing(2),
        paddingBottom: spacing(2),
        borderBottom: `1px solid ${palette.secondary.backgroundLight}`,
        borderTop: `1px solid ${palette.secondary.backgroundLight}`,
    },
    tag: {
        marginRight: spacing(1),
    },
    checkMark: {
        position: "absolute",
        right: 66,
        zIndex: 3
    },
    deleteMark: {
        position: "absolute",
        right: 45,
        zIndex: 3
    },
    rootSwitch: {
        width: "42px!important",
        height: "26px!important",
        padding: "0!important",
        margin: `${spacing(1)}px!important`,
        position: "relative"
    },
    switchBase: {
        padding: 1,
        position: "relative",
        '&$checked': {
            transform: 'translateX(16px)',
            color: `${palette.common.white}!important`,
            '& + $track': {
                backgroundColor: `${palette.success.main}!important`,
                border: 'none',
                opacity: "1!important",
            },
        },
    },
    thumb: {
        width: "24px!important",
        height: "24px!important",
        position: "absolute!important",
        left: 0
    },
    track: {
        borderRadius: "13px!important",
        border: `1px solid ${palette.grey.main}`,
        backgroundColor: `${palette.grey.main}!important`,
        opacity: "0.5!important",
        transition: `transitions.create(['background-color', 'border'])!important`,
    },
    checked: {},
    focusVisible: {},
    status: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    technologyStatus: {
        marginRight: spacing(1)
    },
    lastUpdateFooter: {
        display: 'flex',
        alignItems: 'center'
    },
    anotherUserWorking: {
        cursor: "pointer"
    },
    lastUpdate: {
        marginRight: spacing(1)
    },
    moreIcon: {
        fill: palette.secondary.dark
    },
    moreIconOpened: {
        fill: palette.primary.dark
    },
    moreButton: {
        position: "absolute",
        top: spacing(4),
        right: spacing(4),
        cursor: "pointer"
    },
}));