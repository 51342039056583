import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    user: null,
    getMeStatus: REQUEST_UNDEFINED,
    postLoginStatus: REQUEST_UNDEFINED,
    postLogoutStatus: REQUEST_UNDEFINED,
    accessToken: "",
    loginMessage: "",
    resetPasswordStatus: REQUEST_UNDEFINED,
    sendResetLinkStatus: REQUEST_UNDEFINED,
    checkResetLinkStatus: REQUEST_UNDEFINED,
    getCookieStatus: REQUEST_UNDEFINED,
    resetPasswordMessage: "",
    sendResetLinkMessage: "",
    checkResetLinkMessage: "",
    getMeStatusCode: null
};

export const REDUCER_PREFIX = "auth";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getMePending: state => {
            state.getMeStatus = REQUEST_PENDING;
        },
        setUnauthenticated: state => {
            state.getMeStatusCode = 401;
        },
        getMeSuccess: (state, { payload }) => {
            state.getMeStatus = REQUEST_SUCCESS;
            state.getMeStatusCode = payload.status_code;
        },
        resetGetMeStatusCode: state => {
            state.getMeStatusCode = null;
        },
        getMeFailure: state => {
            state.getMeStatus = REQUEST_FAILURE;
        },
        getCookiePending: state => {
            state.getCookieStatus = REQUEST_PENDING;
        },
        getCookieSuccess: state => {
            state.getCookieStatus = REQUEST_SUCCESS;
        },
        getCookieFailure: state => {
            state.getCookieStatus = REQUEST_FAILURE;
        },
        resetPasswordPending: state => {
            state.resetPasswordStatus = REQUEST_PENDING;
        },
        resetPasswordSuccess: (state, { payload }) => {
            state.resetPasswordStatus = REQUEST_SUCCESS;
            state.resetPasswordMessage = payload;
        },
        resetPasswordFailure: state => {
            state.resetPasswordStatus = REQUEST_FAILURE;
            state.resetPasswordMessage = "Something went wrong. Please try again later.";
        },
        sendResetLinkPending: state => {
            state.sendResetLinkStatus = REQUEST_PENDING;
        },
        sendResetLinkSuccess: (state, { payload }) => {
            state.sendResetLinkStatus = REQUEST_SUCCESS;
            state.sendResetLinkMessage = payload;
        },
        sendResetLinkFailure: state => {
            state.sendResetLinkStatus = REQUEST_FAILURE;
            state.sendResetLinkMessage = "Something went wrong. Please try again later.";
        },
        checkResetLinkPending: state => {
            state.checkResetLinkStatus = REQUEST_PENDING;
        },
        checkResetLinkSuccess: (state, { payload }) => {
            state.checkResetLinkStatus = REQUEST_SUCCESS;
            state.checkResetLinkMessage = payload;
        },
        checkResetLinkFailure: state => {
            state.checkResetLinkStatus = REQUEST_FAILURE;
            state.checkResetLinkMessage = "Something went wrong. Please try again later.";
        },
        postLoginPending: state => {
            state.getMeStatus = REQUEST_UNDEFINED;
            state.getMeStatusCode = null;
            state.postLoginStatus = REQUEST_PENDING;
            state.loginMessage = "";
        },
        postLoginSuccess: state => {
            state.postLoginStatus = REQUEST_SUCCESS;
            state.loginMessage = "";
        },
        postLoginFailure: (state, { payload }) => {
            state.loginMessage = payload;
            state.postLoginStatus = REQUEST_FAILURE;
        },
        postLoginUndefined: state => {
            state.postLoginStatus = REQUEST_UNDEFINED;
        },
        postLogoutUndefined: state => {
            state.postLogoutStatus = REQUEST_UNDEFINED;
        },
        postLogoutPending: state => {
            state.postLogoutStatus = REQUEST_PENDING;
        },
        postLogoutSuccess: state => {
            state.postLogoutStatus = REQUEST_SUCCESS;
            state.postLoginStatus = REQUEST_UNDEFINED;
        },
        postLogoutFailure: state => {
            state.postLogoutStatus = REQUEST_FAILURE;
        },
        setUser: (state, { payload }) => {
            state.user = payload;
            state.postUpdateProfileStatus = REQUEST_UNDEFINED;
        },
        setAccessToken: (state, { payload }) => {
            state.accessToken = payload;
        },
    }
});
export { reducer, actions };