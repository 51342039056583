import { makeStyles, createStyles, Typography } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoints, typography }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    contentRoot: {
        padding: 0
    },
    dialog: {
        padding: spacing(4),
        background: palette.common.white,
        borderRadius: spacing(2),
        [breakpoints.down('md')]: {
            width: 528
        },
        [breakpoints.down('sm')]: {
            width: "100%!important",
            borderRadius: "unset",
            padding: spacing(2)
        }
    },
    dialogFilter: {
        padding: spacing(3),
        background: palette.common.white,
        borderRadius: 16,
        margin: spacing(3),
        width: "100%",
        [breakpoints.down("sm")]: {
            padding: spacing(2),
            margin: 0,
            maxHeight: "none",
            height: "100%",
            borderRadius: 0
        }
    },
    dialogScrollPaper: {
        maxWidth: 800,
        width: "100%",
        [breakpoints.down("sm")]: {
            width: 500,
        }
    },
    dialogContent: {
        padding: 0
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
        [breakpoints.down("sm")]: {
            top: 11,
            right: 15
        }
    },
    radarTitle: {
        paddingTop: spacing(2),
        color: palette.text.main
    },
    radarTitleInput: {
        color: palette.secondary.main,
        [breakpoints.down('sm')]: {
            width: "100%!important",
        },
        "& input": {
            [breakpoints.down('sm')]: {
                width: "100%!important",
            },
            width: 430
        }
    },
    radarDescription: {
        marginTop: spacing(3),
        marginBottom: spacing(1),
        color: palette.text.main
    },
    dialogTitleWrapper: {
        padding: 0
    },
    filterDialogTitle: {
        marginBottom: spacing(3),
        [breakpoints.down("sm")]: {
            paddingRight: spacing(4)
        }
    },
    cancelButton: {
        marginRight: spacing(1)
    },
    goBackBtn: {
        marginRight: spacing(3)
    },
    textarea: {
        borderColor: palette.secondary.dark,
        fontFamily: "GT Flexa, sans-serif",
        marginBottom: spacing(1),
        outline: 'none',
        padding: spacing(1),
        resize: 'none',
        borderRadius: spacing(1),
        color: palette.secondary.dark,
        width: 480,
        [breakpoints.down('md')]: {
            width: "100%"
        },
        "&:focus": {
            borderColor: palette.primary.dark,
        },
        "&::placeholder": {
            fontSize: 14,
            lineHeight: "18px",
            fontFamily: "GT Flexa, sans-serif",
            color: palette.text.lighter
        },
        minHeight: 114,
        maxHeight: 114
    },
    maximumCharactersWrap: {
        marginBottom: spacing(3),
        minHeight: 16,
    },
    maximumCharactersWarning: {
        color: palette.error.main
    },
    addModal: {
        position: 'fixed',
        left: 235,
        bottom: 0,
        right: 0,
        height: 70,
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.12)",
        background: palette.common.white,
        padding: spacing(3)
    },
    addPopup: {
        width: 234,
        height: 'auto',

        background: palette.common.white,
        boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
        borderRadius: shape.borderRadius,
        border: '1px solid',
        borderColor: palette.secondary.main,
        bottom: '55px',
        right: '0'
    },
    radarName: {
        width: "100%",
        padding: `${spacing(1)}px ${spacing(2)}px`,
        "&:hover": {
            background: palette.secondary.main,
        },
        cursor: 'pointer'
    },
    rowWrap: {
        marginTop: spacing(3),
        width: "calc(50% - 8px)",
        "&:first-child": {
            marginRight: spacing(2)
        },
        [breakpoints.down('md')]: {
            width: "100%",
            "&:first-child": {
                marginRight: 0
            },
        }
    },
    rowWrapButtons: {
        marginTop: spacing(3)
    },
    rowWrapYear: {
        marginTop: spacing(3),
        border: 0,
        margin: 0,
        display: "inline-flex",
        padding: 0,
        position: "relative",
        minWidth: 0,
        flexDirection: "column",
        verticalAlign: "top",
        width: "calc(50% - 8px)",
        "&:first-child": {
            marginRight: spacing(2)
        },
        [breakpoints.down('md')]: {
            width: "100%",
            "&:first-child": {
                marginRight: 0
            },
        }
    },
    resetFilter: {
        cursor: 'pointer',
        position: "absolute",
        right: spacing(2),
        zIndex: 2
    },
    arrowDown: {
        position: "absolute",
        right: spacing(2),
        zIndex: 2,
        cursor: "pointer"
    },
    disabledField: {
        "& $arrowDown": {
            cursor: "default"
        }
    },
    ml24: {
        marginLeft: spacing(3)
    },
    ml16: {
        marginLeft: spacing(2),
        [breakpoints.down("md")]: {
            marginLeft: 0
        }
    },
    ml8: {
        marginLeft: spacing(1)
    },
    disableSubsectors: {
        background: palette.text.backgroundMain,
        border: `1px solid ${palette.text.lighter}!important`
    },
    radarTitleError: {
        marginTop: spacing(1),
        color: palette.error.main
    },
    radarTitleErrorWrap: {
        minHeight: 13
    },
    inputClass: {
        borderRadius: spacing(1),
        width: "100%",
        padding: `${spacing(1)}px ${spacing(2)}px`,
        color: palette.secondary.dark,
        "&:focus": {
            borderColor: palette.primary.dark,
        },

        "&::placeholder": {
            fontSize: 14,
            lineHeight: "18px",
            fontFamily: "GT Flexa, sans-serif",
            color: palette.text.lighter
        }

    },
    labelClass: {
        color: palette.text.main,
        fontSize: 14,
        lineHeight: "18px"
    },
    multiselect: {
        border: `1px solid ${palette.secondary.dark}`,
        marginTop: `${spacing(3)}px !important`,
        width: "100% !important"
    },
    row: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        [breakpoints.down("xs")]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    half: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        "&:nth-child(odd)": {
            marginRight: spacing(2),
            width: "calc(50% - 16px)",
            [breakpoints.down("xs")]: {
                width: "100%",
                flexDirection: "column"
            }
        },
        [breakpoints.down("xs")]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    third: {
        width: "33%",
        display: "flex",
        flexDirection: "row",
        marginRight: spacing(2),
        width: "calc(33% - 11px)",
        "&:nth-child(3n+3)": {
            marginRight: spacing(0),
            width: "33%",
            [breakpoints.down("xs")]: {
                width: "100%",
                flexDirection: "column"
            }
        },
        [breakpoints.down("xs")]: {
            width: "100%",
            flexDirection: "column"
        }
    },
    rows: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${palette.text.lightest}`,
        flexDirection: "row",
        flexWrap: "wrap",
        paddingBottom: spacing(3)
    },
    dateCalendar: {
        position: "absolute",
        bottom: 56,
        left: 0,
        boxShadow: "0px 0px 10px rgb(0 0 0 / 20%)",
        borderRadius: spacing(2),
        border: `1px solid ${palette.text.lightest}`
    },
    dateCalendarRight: {
        position: "absolute",
        bottom: 56,
        right: 0,
        boxShadow: "0px 0px 10px rgb(0 0 0 / 20%)",
        borderRadius: spacing(2),
        border: `1px solid ${palette.text.lightest}`
    },
    multiselectYear: {
        marginTop: 6
    },
    multiselectFromTo: {
        width: "100%"
    },
    multiselectWrap: {
        width: "50%"
    },
    resetFilterAction: {
        color: palette.text.lighter,
        paddingLeft: 0
    },
    showResultsButton: {
        // background: palette.primary.dark
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    cancelAndCreate: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        [breakpoints.down('sm')]: {
            flexDirection: "column-reverse",
            alignItems: "center"
        }
    },
    subscribeConfirmationModal: {
        minWidth: 500,
        padding: spacing(7),
        background: palette.success.backgroundLight,
        borderRadius: spacing(2),
        display: "flex",
        alignItems: "center"
    },
    subscribeTitle: {
        margin: spacing(5)
    },
    subscribeDialogContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    upgradePlan: {
        fontFamily: "GT Flexa Mono, sans-serif",
        padding: "6px 16px",
        background: palette.success.backgroundDark,
        borderRadius: 8
    },
    confirmCtas: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        paddingTop: spacing(3)
    },
    price: {
        lineHeight: "22px",
        display: "flex",
        margin: `${spacing(5)}px 0 ${spacing(2)}px 0`,
        alignItems: "center"
    },
    priceAmount: {
        textTransform: "uppercase"
    },
    priceLabel: {
        marginRight: 7
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif",
        [breakpoints.down("md")]: {
            ...typography.bodyButton
        }
    },
    modalInput: {
        marginBottom: spacing(2),
        width: "100%"
    },
    eye: {
        position: "absolute",
        right: spacing(1),
        cursor: "pointer"
    }
}))