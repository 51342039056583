import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchKanbanItemsRequest = createAction(
    `${REDUCER_PREFIX}/fetchKanbanItemsRequest`
);
export const updateKanbanItemRequest = createAction(
    `${REDUCER_PREFIX}/updateKanbanItemRequest`
);
export const deleteKanbanItemRequest = createAction(
    `${REDUCER_PREFIX}/deleteKanbanItemRequest`
);
