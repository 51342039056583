import React, { useState } from "react";
// Hooks
import useStyles from "./styles";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useDispatch } from 'react-redux';
// Components
import Button from "../../components/Button/Button";
import Menu from "../../components/Menu/Menu";
// Assets
import LogoNavbar from '../../assets/icons/LogoNavbar.svg';
import NoAvatarUser from '../../assets/icons/AvatarDefault.png';
// Redux
import { postLogoutRequest } from "../../store/auth/requests";


const TopNav = ({ match }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const [logOutOpened, setLogOutOpened] = useState(false);
    const { isAuthenticated, user } = useAuthHook();
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const signOut = () => {
        dispatch(postLogoutRequest());
        setTimeout(() =>
            openPage("/")
            , 1000);
    }

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    }

    return (
        <div className={classes.topNav}>
            <div className={classes.logoTopNav} >
                <img src={LogoNavbar} alt={t('outcome.step2')} />
            </div>
            {isAuthenticated ? (
                <div className={classes.userSection}>
                    {/* {match === "profile-settings" ? <ProfileSettingsActiveIcon test-id="profile-settings" className={classes.topNavItem} onClick={() => openPage("/profile-settings")} /> : <ProfileSettingsIcon className={classes.topNavItem} onClick={() => openPage("/profile-settings")} />} */}
                    <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        noButton
                        menuItems={[
                            {
                                label: t("sidebar.logOut"),
                                onClick: () => signOut(),
                                testId: "log-out"
                            }
                        ]}
                    >
                        <div className={classes.avatarIcon}>
                            <div className={classes.avatarWrapper} test-id="avatar">{user && user.avatar ? <img onClick={() => setLogOutOpened(!logOutOpened)} src={user.avatar} className={classes.avatar} alt={t('sidebar.userNameExample')} /> : <img onClick={() => setLogOutOpened(!logOutOpened)} src={NoAvatarUser} className={classes.avatar} alt={t('sidebar.userNameExample')} />}</div>
                        </div>
                </Menu>
                </div>
            ) : (
                <div>
                    <Button variant="textSecondary" onClick={() => openPage("/")}>{t("topNav.signIn")}</Button>
                </div>
            )}
        </div>
    );
};

export default TopNav;