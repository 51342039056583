import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const postLoginRequest = createAction(
    `${REDUCER_PREFIX}/postLoginRequest`
);
export const postLogoutRequest = createAction(
    `${REDUCER_PREFIX}/postLogoutRequest`
);
export const getMeRequest = createAction(
    `${REDUCER_PREFIX}/getMeRequest`
);
export const sendResetLinkRequest = createAction(
    `${REDUCER_PREFIX}/sendResetLinkRequest`
);
export const checkResetLinkRequest = createAction(
    `${REDUCER_PREFIX}/checkResetLinkRequest`
);
export const resetPasswordRequest = createAction(
    `${REDUCER_PREFIX}/resetPasswordRequest`
);
export const fetchCookieRequest = createAction(
    `${REDUCER_PREFIX}/fetchCookieRequest`
)