import React, { useEffect, useState } from "react";

// UI components
import Typography from "../../../../../components/Typography/Typography.js";
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
//Assets
import CloseIcon from '../../../../../assets/icons/CloseIcon.svg';

const ReadMoreModal = ({ dialogOpened, onChange, title, value, goNext, isHtml }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [nextModalName, setNextModalName] = useState("");
    useEffect(() => {
        switch (title) {
            case "Business model":
                setNextModalName("Value proposition");
                break;
            case "Value proposition":
                setNextModalName("Product portfolio");
                break;
            case "Product portfolio":
                setNextModalName("Technology overview");
                break;
            case "Technology overview":
                setNextModalName("Market opportunities");
                break;
            case "Market opportunities":
                setNextModalName("Achievements and Future Plans");
                break;
            case "Achievements and Future Plans":
                setNextModalName("Business model");
                break;

        }
    }, [title]);
    const handleGoNext = () => {
        goNext(nextModalName);
    };
    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialog, scrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}
        >
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img src={CloseIcon} alt={t('scope.step2')} className={classes.stepIcon} />
            </IconButton>
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="h4" >{title}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                {
                    isHtml ? <Typography className={classes.dialogValue} variant="subtitle2" component="div">
                        <div dangerouslySetInnerHTML={{ __html: value }}></div>
                    </Typography> :
                        <Typography className={classes.dialogValue} variant="subtitle2" component="div">{value}</Typography>}
            </MuiDialogContent>

        </Dialog >
    );
};
export default ReadMoreModal;
