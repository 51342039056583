import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as subscriptionsActions } from "../../subscriptions/slice";
import {
    fetchUsers,
    postCreateUser,
    putUpdateUser,
    getSingleUser,
    deleteSingleUser
} from "./helpers";

function* getUsers(search, sortBy, sortDirection, perPage) {
    try {
        yield put(actions.getUsersPending());
        let data = new FormData();
        data.append("search", search);
        data.append("sort-by", sortBy);
        data.append("sort-direction", sortDirection);
        data.append("per-page", perPage);
        const response = yield call(fetchUsers, data);
        yield put(actions.getUsersSuccess());
        yield put(actions.setUsers(response.data));
    } catch (err) {
        yield put(actions.getUsersFailure());
    }
}

function* createUser(data) {
    yield put(actions.createUserPending());
    let response;
    
    try {
        response = yield call(postCreateUser, data);
    } catch (e) {
        response = e.response;
    }
    if (response.data.status === 'error' || response.status === "error") {
        yield put(actions.createUserFailure(Object.values(response.data.data)));
    } else {
        yield put(actions.createUserSuccess());
        yield put(subscriptionsActions.addSubscription(response.data.user_profile));
    }
}
function* updateUser(id, data) {
    try {
        yield put(actions.updateUserPending());
        const response = yield call(putUpdateUser, id, data);
        yield put(actions.updateUserSuccess());
        yield put(actions.updateUser(response.data.user));
    } catch (err) {
        yield put(actions.updateUserFailure());
    }
}
function* getUser(id) {
    try {
        yield put(actions.getUserPending());
        const response = yield call(getSingleUser, id);
        yield put(actions.getUserSuccess(response.data));
        yield put(actions.addSubscriptionUser(response.data));
    } catch (err) {
        yield put(actions.getUserFailure());
    }
}
function* deleteUser(id, planId, planType) {
    try {
        yield put(actions.deleteUserPending());
        yield call(deleteSingleUser, id);
        yield put(actions.deleteUserSuccess());
        yield put(subscriptionsActions.deleteUser(id))
    } catch (err) {
        yield put(actions.deleteUserFailure());
    }
}

export {
    getUsers,
    createUser,
    updateUser,
    getUser,
    deleteUser
};