import { call } from "redux-saga/effects";
import axios from "axios";
import { FILTERING_API } from "../../../constants/endpoints";

function* getFilterResults(filterNameValue, orderBy, orderDirection, page, sid) {
    let direction = orderBy === "match" ? orderDirection === "desc" ? "asc" : "desc" : orderDirection;
    let url = filterNameValue ? `${FILTERING_API}${filterNameValue}${filterNameValue ? "&" : ""}sid=${sid}&order-direction=${direction}&order-by=${orderBy}&page=${page}` : `${FILTERING_API}sid=${sid}&order-direction=${direction}&order-by=${orderBy}&page=${page}`
    const response = yield call(axios, {
        method: "GET",
        url: url,
        withCredentials: true
    });
    return response.data;
}
export {
    getFilterResults
}