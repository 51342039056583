import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    items: [],
    getKanbanItemsStatus: REQUEST_UNDEFINED,
    updateKanbanItemStatus: REQUEST_UNDEFINED,
    deleteKanbanItemStatus: REQUEST_UNDEFINED
};

export const REDUCER_PREFIX = "kanban";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        setKanbanItems: (state, { payload }) => {
            state.items = payload.data;
        },
        getKanbanItemsPending: state => {
            state.getKanbanItemsStatus = REQUEST_PENDING;
        },
        getKanbanItemsSuccess: state => {
            state.getKanbanItemsStatus = REQUEST_SUCCESS;
        },
        getKanbanItemsFailure: state => {
            state.getKanbanItemsStatus = REQUEST_FAILURE;
        },
        updateKanbanItemPending: state => {
            state.updateKanbanItemStatus = REQUEST_PENDING;
        },
        updateKanbanItemSuccess: state => {
            state.updateKanbanItemStatus = REQUEST_SUCCESS;
        },
        updateKanbanItemFailure: state => {
            state.updateKanbanItemStatus = REQUEST_FAILURE;
        },
        deleteKanbanItemPending: state => {
            state.deleteKanbanItemStatus = REQUEST_PENDING;
        },
        deleteKanbanItemSuccess: state => {
            state.deleteKanbanItemStatus = REQUEST_SUCCESS;
        },
        deleteKanbanItemFailure: state => {
            state.deleteKanbanItemStatus = REQUEST_FAILURE;
        }
    }
});

export { reducer, actions };
