const colors = {
    common: {
        black: "#000000",
        white: "#ffffff"
    },
    primary: {
        light: '#FEBA86',
        main: '#FD8C35',
        dark: '#FD750D',
        backgroundDark: "#FEDCC2",
        backgroundMedium: "#FFF8F3",
        backgroundLight: "#FFF1E7"
    },
    secondary: {
        light: '#8594AF',
        main: '#485E87',
        dark: '#0B295F',
        darker: '#485E87',
        backgroundDark: "#C2C9D7",
        backgroundLight: "#E7EAEF"
    },
    text: {
        light: '#666666',
        lighter: '#999999',
        lightest: '#f2f2f2',
        main: '#333333',
        dark: '#000000',
        backgroundDark: "#DBDBDA",
        backgroundMain: "#EFEFEF",
        backgroundLight: "#F9F9F9"
    },
    success: {
        light: "#80BCAE",
        main: "#409B86",
        dark: "#007A5E",
        backgroundDark: "#BFDED7",
        backgroundLight: "#E6F2EF"
    },
    error: {
        light: "#DC6F6A",
        main: "#D03F38",
        dark: "#D03F38",
        backgroundDark: "#E79F9B",
        backgroundLight: "#F3CFCD"
    },
    warning: {
        light: "#F7DE9E",
        main: "#F6D070",
        dark: "#F5C343",
        backgroundDark: "#F8ECCC",
        backgroundLight: "#F9F4E7"
    },
    grey: {
        dark: "#07152F",
        main: "#6B778C",
        light: "#F9FAFB"
    },
    info: {
        main: "#0288d1",
        light: "#03a9f4",
        dark: "#01579b",
        contrastText: "#ffffff"
    }
};

export default colors;