const getFilteringStatusSelector = state => state.filtering.getFilteringStatus;
const getFilteringStateSelector = state => state.filtering.pool;
const getFilteringTotalCountSelector = state => state.filtering.totalCount;
const getFilterResultsCountSelector = state => state.filtering.filterResultsCount;
const getFilterResultsTotalPagesSelector = state => state.filtering.totalPages;

export {
    getFilteringStatusSelector,
    getFilteringStateSelector,
    getFilteringTotalCountSelector,
    getFilterResultsCountSelector,
    getFilterResultsTotalPagesSelector
}