import { useState, useEffect } from "react";

const useInfiniteScroll = (callback, totalCount) => {
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", isScrolling);
        return () => window.removeEventListener("scroll", isScrolling);
    }, []);

    useEffect(() => {
        isFetching && callback();
    }, [isFetching]);

    const isScrolling = e => {
        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 420) && totalCount !== 0
        ) {
            setIsFetching(true);
        } else {
            setIsFetching(false);
        }
    }
    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;