import React, { useEffect, useRef } from "react";
import useStyles from "./styles";
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
// Components
import TopNav from "./TopNav";
import Sidebar from "./Sidebar";
import { UserProvider } from './UserProvider';
// Hooks
import { useLocation } from "react-router";
import { useRouterHook } from "../../common/useRouterHook";
// Redux
import { getMeStatusCodeSelector, getUserStatusSelector } from "../../store/auth/selectors";
import { getIndustriesStatusCodeSelector } from "../../store/industries/selectors";
import { getTechnologiesStatusCodeSelector } from "../../store/technologies/selectors";
import { actions as authActions } from "../../store/auth/slice";
// Constants
import {
    REQUEST_PENDING
} from "../../constants/statuses";

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

const Layout = ({ children, match, white }) => {
    const getMeStatusCode = useSelector(getMeStatusCodeSelector);
    const getUserStatus = useSelector(getUserStatusSelector);
    const getIndustriesStatusCode = useSelector(getIndustriesStatusCodeSelector);
    const getTechnologiesStatusCode = useSelector(getTechnologiesStatusCodeSelector);
    let location = useLocation();
    const dispatch = useDispatch();
    let { pathname } = location;
    const { openPage } = useRouterHook();
    const hideSidebar = pathname.includes("industries-board") || pathname.includes("technologies-board");
    const classes = useStyles({ hideSidebar });

      const prevGetMeStatusCode = usePrevious(getMeStatusCode);

    useEffect(() => {
        if (prevGetMeStatusCode !== getMeStatusCode && getUserStatus !== REQUEST_PENDING && getMeStatusCode === 401) {
            window.alert(`Your session is expired and your further activity will not be saved. If you want to continue - please click “Okay” and log in again.`)
            dispatch(authActions.resetGetMeStatusCode());
            openPage("/");
        }
    }, [getMeStatusCode]);


    useEffect(() => {
        if (getIndustriesStatusCode === 401 || getTechnologiesStatusCode === 401) {
            dispatch(authActions.setUnauthenticated());
        }
    }, [getIndustriesStatusCode, getTechnologiesStatusCode]);

    return (
        <UserProvider>
            <div className={classes.root}>
                <TopNav match={match} />
                <div>
                    {!hideSidebar && <Sidebar match={match} />}
                    <main className={classnames([classes.content, { [classes.whiteContent]: white }])}>{children}</main>
                </div>
            </div>
        </UserProvider>
    );
};

export default Layout;
