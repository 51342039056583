import useStyles from "./style.js";
import classnames from 'classnames';
import { Link as MuiLink } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const Link = ({ className, children, iconRight, dark, disabled, rotateIcon, variant, onClick, color, ...props }) => {
    const theme = useTheme();
    let iconColor, hoverColor, textColor;
    switch (color) {
        case "textPrimary":
            iconColor = theme.palette.text.primary;
            break;
        case "textSecondary":
            iconColor = theme.palette.secondary.main;
            hoverColor = theme.palette.secondary.light
            textColor = theme.palette.secondary.main;
            break;
        case "secondary":
            iconColor = theme.palette.secondary.dark;
            hoverColor = theme.palette.secondary.light
            textColor = theme.palette.secondary.dark;
            break;
        case "grey":
            iconColor = theme.palette.grey.main;
            hoverColor = theme.palette.secondary.light;
            break;
        case "error":
            iconColor = theme.palette.error.main;
            break;
        default:
            iconColor = theme.palette[color].main;
    }
    iconColor = !!dark ? theme.palette.common.white : iconColor;
    const classes = useStyles({ color, iconColor, hoverColor, iconRight, disabled, rotateIcon, textColor });
    const linkClasses = classnames(
        className,
        classes.link,
        !!dark && classes.dark
    );

    const handleClick = e => {
        if (disabled) {
            e.preventDefault();
            e.stopPropagation();
            return;
        }
        onClick && onClick();
    }

    return (
        <MuiLink
            className={linkClasses}
            {...props}
            color={color}
            variant
            onClick={handleClick}
        >
            {children}
        </MuiLink>
    )
}

Link.defaultProps = {
    color: "primary"
};

export default Link;