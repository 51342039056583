import React, { useState, useEffect } from "react";
// Hooks
import { useStyles } from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
import { useTheme } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import useFilters from "./hooks/useFilters.js";
import { useSelector, useDispatch } from 'react-redux';
//Components
import Board from "../../components/Board/Board";
import Typography from "../../components/Typography/Typography";
import Header from "./components/Header/Header";
//Redux
import {
    getFilteringStatusSelector,
    getFilteringStateSelector,
    getFilteringTotalCountSelector,
    getFilterResultsTotalPagesSelector
} from "../../store/filtering/selectors";
import { getSectorsSelector, getSubsectorsSelector } from "../../store/sectors/selectors";
import { getCountriesSelector } from "../../store/countries/selectors";
import { getSearchResultsSelector, getSearchResultsTotalCountSelector, getSearchResultsStatusSelector } from "../../store/results/selectors";
import { getTechnologyStatusSelector, getPostGetClosestStatusSelector, postSaveCompaniesDataStatusSelector, getTechnologySelector, updateTechnologyKanbanStatusSelector, releaseCompaniesStatusSelector } from "../../store/technologies/selectors";
import { getTechnologyWithCompaniesRequest } from "../../store/technologies/requests";
import { getLastReleaseInfoSelector } from "../../store/technologies/selectors";
//Constants
import {
    stageOptions,
    fundingOptionsMin,
    yearOptionsMin,
    teamSizeOptions,
    valuerScoreOptionsMin
} from "../../constants/filters";
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE
} from "../../constants/statuses";
import { objectHasEmptyValues } from "../../utils/objectHasEmptyValues";
//assets
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { Shared } from "../../utils/shared";
import ContentLoader from "../../components/ContentLoader/ContentLoader";

const TechnologiesBoard = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const theme = useTheme();
    const { isAuthenticated } = useAuthHook();
    let { id } = useParams();
    const initialColumns = [{ id: 0, name: 'Not relevant', cardIds: [] },
    { id: 1, name: 'Raw ML output', cardIds: [] },
    { id: 2, name: 'Update data', cardIds: [] },
    { id: 3, name: 'Ready for technology', cardIds: [] },];
    const dispatch = useDispatch();
    const { hasOnlySearchValue } = Shared({});

    const [columns, setColumns] = useState(initialColumns);
    const headerColors = [
        theme.palette.error.light,
        theme.palette.secondary.main,
        theme.palette.primary.main,
        theme.palette.success.main
    ];

    const [lastUpdate, setLastUpdate] = useState("");
    const [sortValue, setSortValue] = useState("match");
    const [sortDirection, setSortDirection] = useState("desc");
    // Selectors
    const allSectors = useSelector(getSectorsSelector);
    const getFilteringStatus = useSelector(getFilteringStatusSelector);
    const getFilteringState = useSelector(getFilteringStateSelector);
    const getFilteringTotalCount = useSelector(getFilteringTotalCountSelector);
    const getFilterResultsTotalPages = useSelector(getFilterResultsTotalPagesSelector);
    const allResults = useSelector(getSearchResultsSelector);
    const searchResultsTotalCount = useSelector(getSearchResultsTotalCountSelector);
    const searchResultsStatusSelector = useSelector(getSearchResultsStatusSelector);
    const getClosestStatusSelector = useSelector(getPostGetClosestStatusSelector);
    const saveCompaniesStatusSelector = useSelector(postSaveCompaniesDataStatusSelector);
    const updateCompanyKanbanStatus = useSelector(updateTechnologyKanbanStatusSelector);
    const allCountries = useSelector(getCountriesSelector);
    const allSubsectors = useSelector(getSubsectorsSelector);
    const getTechnology = useSelector(getTechnologySelector);
    const getTechnologyStatus = useSelector(getTechnologyStatusSelector);
    const getLastReleaseInfo = useSelector(getLastReleaseInfoSelector)
    const releaseCompaniesStatus = useSelector(releaseCompaniesStatusSelector)

    //State
    const [timeout, setTimeoutVal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [searchResults, setSearchResults] = useState(allResults);
    const [totalCount, setTotalCount] = useState(searchResultsTotalCount);
    const [technology, setTechnology] = useState(null);
    const [noCompanies, setNoCompanies] = useState(false);
    const [cards, setCards] = useState(technology && technology.companies);
    const [releasedInfo, setReleasedInfo] = useState(getLastReleaseInfo);

    // Filtering
    const {
        filters,
        selectedFilters,
        setDisplayedFilters,
        filterNameValue,
        resetFilters,
        handleSectorChange,
        handleFundingMinChange,
        handleYearMinChange,
        handleValuerScoreMinChange,
        resetFilter,
        setModalFilters,
        setSelectedFilters,
        displayedFilters,
        fundingOptionsMax,
        valuerScoreOptionsMax,
        yearOptionsMax,
        handleFieldChange,
        handleFilterRemove,
        handleShowResults,
        handleShowFilters,
    } = useFilters({
        allSectors,
        getFilterResultsTotalPages,
        sortValue,
        sortDirection
    });

    useEffect(() => {
        dispatch(getTechnologyWithCompaniesRequest({ id, filterString: filterNameValue, orderBy: sortValue, orderDirection: sortDirection }));
    }, [dispatch, filterNameValue, sortValue, sortDirection]);

    const handleSortChange = (sortValue, sortDirection) => {
        setSortValue(sortValue);
        setSortDirection(sortDirection);
    };
    const handleOnSearch = val => {
        setLoading(true);
        clearTimeout(timeout);
        setTimeoutVal(
            setTimeout(() => {
                setDisplayedFilters(prevDisplayedFilters => {
                    return {
                        ...prevDisplayedFilters,
                        search: val
                    }
                });
                setSelectedFilters(prevSelectedFilters => {
                    return {
                        ...prevSelectedFilters,
                        search: val
                    }
                });
                setLoading(false);
            }, 500)
        );
    };


    useEffect(() => {
        id && dispatch(getTechnologyWithCompaniesRequest({ id, orderBy: sortValue, orderDirection: sortDirection }));
    }, [dispatch, id]);
    useEffect(() => {
        (updateCompanyKanbanStatus === REQUEST_SUCCESS || releaseCompaniesStatus === REQUEST_SUCCESS) && dispatch(getTechnologyWithCompaniesRequest({ id, orderBy: sortValue, orderDirection: sortDirection }));
    }, [updateCompanyKanbanStatus, releaseCompaniesStatus])

    useEffect(() => {
        getTechnology && setTechnology(getTechnology);
        getTechnology && setColumnsData();
        getTechnology && setCards(getTechnology.companies);
        getTechnology && setNoCompanies(!getTechnology.companies || getTechnology.companies.length === 0);
        getTechnology && setLastReleaseData();
    }, [getTechnology]);

    useEffect(() => {
        getLastReleaseInfo ? setReleasedInfo(setReleasedInfo) : setLastReleaseData();
    }, [getLastReleaseInfo, columns]);

    const setColumnsData = () => {
        let cols = [...initialColumns];
        let column = { ...cols[0] };
        for (let i = 0; i < 4; i++) {
            column = { ...cols[i] };
            column.cardIds = getTechnology && getTechnology.companies && getTechnology.companies.filter(company => {
                return company.kanban_status === i;
            }).map(company => { return company.id });
            cols[i] = column;
            setColumns(cols);
        }
    };
    const setLastReleaseData = () => {
        let releasedCompanyId = columns[3] && columns[3].cardIds && columns[3].cardIds.length > 0 ? columns[3].cardIds[0] : "";
        let releaseInfo = cards && cards.filter(f => { return f.id === releasedCompanyId }).map(m => { return { date: m.published_date_human, user: m.released_by_user } })[0];
        setReleasedInfo(releaseInfo);
    };
    const handleSetColumns = (newColumns) => {
        setColumns(newColumns);
    };
    useEffect(() => {
        setLoadingData(getClosestStatusSelector === REQUEST_PENDING || saveCompaniesStatusSelector === REQUEST_PENDING || getTechnologyStatus === REQUEST_PENDING)
    }, [getClosestStatusSelector, saveCompaniesStatusSelector, getTechnologyStatus])

    return (
        <div className={classes.root}>

            <Header
                releasedInfo={releasedInfo}
                technology={technology}
                onSearch={handleOnSearch}
                onFilterSelected={handleShowFilters}
                searchResults={searchResults}
                searchResultsTotal={searchResults && searchResults.length}
                isAuthenticated={isAuthenticated}
                onSortChange={handleSortChange}
                filters={filters}
                allResults={allResults}
                total={totalCount}
                resetFilters={resetFilters}
                stageOptions={stageOptions}
                fundingOptionsMin={fundingOptionsMin}
                fundingOptionsMax={fundingOptionsMax}
                yearOptionsMin={yearOptionsMin}
                yearOptionsMax={yearOptionsMax}
                teamSizeOptions={teamSizeOptions}
                valuerScoreOptionsMin={valuerScoreOptionsMin}
                valuerScoreOptionsMax={valuerScoreOptionsMax}
                handleFieldChange={handleFieldChange}
                handleSectorChange={handleSectorChange}
                resetFilter={resetFilter}
                handleFundingMinChange={handleFundingMinChange}
                handleYearMinChange={handleYearMinChange}
                handleValuerScoreMinChange={handleValuerScoreMinChange}
                allSectors={allSectors}
                allSubsectors={allSubsectors}
                selectedFilters={selectedFilters}
                allCountries={allCountries}
                onShowResults={handleShowResults}
                displayedFilters={displayedFilters}
                setSelectedFilters={setSelectedFilters}
                setDisplayedFilters={setDisplayedFilters}
                setModalFilters={setModalFilters}
            />
            {loadingData ? <ContentLoader /> : <>
                {/* Displayed filters */}
                {displayedFilters && !objectHasEmptyValues(displayedFilters) && !hasOnlySearchValue(displayedFilters) && <div className={classes.appliedFiltersWrap} >
                    <Typography variant="body2">{t("board.appliedFilters")}</Typography>
                    <div className={classes.appliedFilters}>
                        {
                            Object.keys(displayedFilters).map((filter, i) => {
                                return displayedFilters[filter] && filter !== "search" && <div className={classes.filterTag} key={`filter-${i}`}><Typography variant="body2" test-id={`filter-${i}`}>{displayedFilters[filter]}<CloseIcon onClick={() => handleFilterRemove(filter)} className={classes.closeIcon} /></Typography></div>
                            })
                        }
                    </div >

                </div >}
                <Board cards={cards} columns={columns} setColumns={handleSetColumns} headerColors={headerColors} />
            </>}
        </div>
    )
};
export default TechnologiesBoard;