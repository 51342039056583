import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoint }) =>
    createStyles({
        root: {
            marginTop: spacing(5)
        },
        block: {
            padding: `0`,
            background: palette.common.white,
            width: '100%',
            borderRadius: spacing(1),
            marginBottom: spacing(2),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
            // [breakpoint.down("sm")]: {
            //     padding: "24px 24px 16px 24px",
            //     marginLeft: spacing(2)
            // }
        },
        imgWrap: {
            width: 148,
            height: 148,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: palette.success.backgroundLight,
            borderRadius: '50%',
            marginTop: spacing(5),
            marginBottom: spacing(4)
        },
        blockUnlocked: {
            padding: `0`,
            background: palette.common.white,
            width: 'calc(50% - 12px)',
            borderRadius: spacing(1),
            marginBottom: spacing(3),
            marginRight: spacing(3),
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            '&:nth-child(2n)': {
                marginRight: 0
            },
            // [breakpoint.down("md")]: {
            //     width: "100%",
            //     margin: 24
            // },
            // [breakpoint.down("sm")]: {
            //     margin: 16
            // },
            '& $imgWrap': {
                width: 48,
                height: 48,
                marginBottom: spacing(2),
                marginTop: spacing(3)
            },
            '& $titleWrap': {
                position: 'relative'
            },
            '& $title': {
                marginBottom: spacing(2),
                marginTop: 4
            },
            '& $name': {
                marginBottom: spacing(1)
                // [breakpoint.down("sm")]: {
                //     order: 0
                // }
            },
            '& $link': {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            },
            '& $seeMore': {
                marginTop: 0,
                paddingBottom: 0,
                textDecoration: 'underline'
            },
            '& $seeMoreIcon': {
                width: 28,
                marginLeft: spacing(2),
                display: 'block'
            },
            '& $noAvatarIcon': {
                maxWidth: 48
            }
        },
        seeMoreIcon: {},
        info: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        infoLocked: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '&:nth-child(3n+3)': {
                marginRight: 0
            }
            // [breakpoint.down("sm")]: {
            //     width: "100%",
            //     padding: "0",
            //     margin: 0,
            //     "&:nth-child(3n+3)": {
            //         marginRight: 0
            //     },
            //     "&:nth-child(2n)": {
            //         marginRight: 0
            //     }
            // }
        },
        infoLockedContent: {
            // [breakpoint.down("lg")]: {
            //     display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "center",
            //     alignItems: "center",
            //     width: "100%"
            // }
        },
        email: {
            color: palette.text.lighter
        },
        framedLockIcon: {
            margin: '40px 0 24px 0'
        },
        titleWrap: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: `1px solid ${palette.text.backgroundMain}`
        },
        title: {
            textTransform: 'uppercase'
        },
        seeMore: {
            paddingBottom: spacing(7),
            marginTop: spacing(3)
        },
        closeButton: {
            position: 'absolute !important',
            right: spacing(4),
            top: spacing(3),
            color: palette.grey[500]
        },
        descriptionImgWrap: {
            width: 54,
            height: 54,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: palette.primary.backgroundLight,
            borderRadius: '50%',
            marginTop: spacing(1)
        },
        description: {
            minHeight: 352,
            padding: spacing(1)
        },
        descriptionLocked: {
            minHeight: 400,
            padding: spacing(4)
        },
        profileLockInfo: {
            marginTop: spacing(3)
        },
        ring: {
            position: 'absolute',
            width: 47,
            height: 47,
            borderRadius: '50%',
            border: '2px solid',
            borderColor: palette.common.white,
            top: 3.4,
            left: 3.4
        },
        execTeamTitle: {
            padding: spacing(1)
        },
        noAvatarIcon: {
            maxWidth: 95
        },
        noAvatarSmall: {
            maxWidth: 48
        },
        memberName: {
            marginLeft: spacing(2),
            marginTop: spacing(1)
        },
        blockDescription: {
            padding: '0 24px',
            background: palette.common.white,
            flex: 'calc(50% - 12px)',
            maxWidth: 'calc(50% - 12px)',
            borderRadius: spacing(3),
            marginBottom: spacing(2),
            marginRight: spacing(3),
            '&:nth-child(2n)': {
                marginRight: 0
            },
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // [breakpoint.down("md")]: {
            //     width: "100%",
            //     flex: "100%",
            //     maxWidth: "none",
            //     margin: 24
            // },
            // [breakpoint.down("sm")]: {
            //     margin: 16
            // },
            '& $description': {
                minHeight: 200
            }
        },
        profileInfo: {
            color: palette.text.main,
            margin: `${spacing(2)}px 0 ${spacing(2)}px 0`,
            maxHeight: 250,
            overflow: 'auto'
        },
        avatar: {
            maxWidth: 48,
            borderRadius: '50%'
        },
        avatarSmall: {
            maxWidth: spacing(6),
            borderRadius: '50%'
        },
        shTitle: {
            marginTop: spacing(2),
            maxWidth: 727
        },
        shSubtitle: {
            marginTop: spacing(2)
        },
        shButton: {
            marginTop: spacing(3),
            background: palette.primary.dark
        },
        freeUserRoot: {
            background: palette.common.white,
            borderRadius: spacing(3),
            padding: '64px 0 78px 0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        flexWrap: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        flexCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: spacing(1),
            marginBottom: spacing(2),
            marginLeft: spacing(1)
        },
        openDialogSection: {
            marginTop: spacing(3),
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            height: 66,
            justifyContent: 'center',
            width: '100%',
            borderTop: `1px solid ${palette.text.backgroundMain}`,
            '&:hover $mono': {
                color: palette.secondary.dark
            }
            // [breakpoint.down("sm")]: {
            //     order: 3,
            //     padding: `16px ${spacing(4)}px 0 0`,
            //     marginTop: spacing(2),
            //     height: "auto"
            // }
        },
        mono: {
            fontFamily: 'GT Flexa Mono, sans-serif'
        },
        questionMark: {
            marginRight: spacing(2)
            // [breakpoint.down("sm")]: {
            //     order: 2,
            //     marginLeft: spacing(1)
            // }
        },
        name: {
            // [breakpoint.down("sm")]: {
            //     order: 2
            // }
        },
        lockIcon: {
            // [breakpoint.down("sm")]: {
            //     order: 1,
            //     marginRight: spacing(2)
            // }
        },
        flex: {
            display: 'flex'
        },
        benefitsCardsWrapper: {
            background: palette.common.white,
            borderRadius: spacing(3),
            marginBottom: spacing(6),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        benefitsCardsWrapperTitle: {
            height: '56px',
            background: palette.primary.backgroundLight,
            width: '100%',
            borderRadius: `${spacing(3)}px ${spacing(3)}px 0 0`,
            textAlign: 'center',
            lineHeight: '56px'
        },
        benefitsCard: {
            width: '100%',
            borderBottom: `1px solid ${palette.text.lightest}`,
            maxHeight: 'none',
            paddingTop: spacing(1),
            paddingLeft: spacing(2),
            paddingBottom: spacing(2)
            // [breakpoint.down("md")]: {
            //     paddingTop: spacing(4)
            // }
        },
        descriptionBox: {
            padding: spacing(2)
        },
        blockTitle: {
            fontSize: '20px',
            width: '100%',
            marginTop: spacing(3),
            paddingLeft: spacing(2)
            // [breakpoint.down("sm")]: {
            //     margin: 0,
            // }
        }
    })
);
