import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    getIndustries,
    getIndustry,
    postIndustry,
    deleteIndustry,
    putIndustry,
    postSubindustry,
    deleteSubindustry,
    putSubindustry,
    postGetClosestIndustry,
    postCompaniesData,
    getSubIndustryWithCompanies,
    updateMLCompanyKanban,
    releaseCompanies
} from "./helpers";
import { getCookie } from "../../auth/saga/helpers";
function* fetchIndustries() {
    try {
        yield put(actions.getIndustriesPending());
        const response = yield call(getIndustries);
        yield put(actions.getIndustriesSuccess());
        yield put(actions.setIndustries(response.data));
    } catch (err) {
        yield put(actions.getIndustriesFailure(err.response.status));
    }
}

function* fetchIndustry(id) {
    try {
        yield put(actions.createIndustryUndefined());
        yield put(actions.updateIndustryUndefined());
        yield put(actions.deleteIndustryUndefined());
        yield put(actions.getIndustryPending());
        const response = yield call(getIndustry, id);
        yield put(actions.getIndustrySuccess());
        yield put(actions.setIndustry(response.data));
    } catch (err) {
        yield put(actions.getIndustryFailure(err.response.status));
    }
}

function* createIndustry(data) {
    try {
        yield put(actions.createIndustryPending());
        const response = yield call(postIndustry, data);
        yield put(actions.createIndustrySuccess());
        yield put(actions.setIndustry(response.data));
    } catch (err) {
        yield put(actions.createIndustryFailure(err.response.status));
    }
}

function* addSubindustry(data) {
    try {
        yield put(actions.addSubindustryPending());
        const response = yield call(postSubindustry, data);
        yield put(actions.addSubindustrySuccess(response));
    } catch (err) {
        yield put(actions.addSubindustryFailure(err.response.status));
    }
}

function* removeIndustry(id) {
    try {
        yield put(actions.deleteIndustryPending());
        const response = yield call(deleteIndustry, id);
        yield put(actions.deleteIndustrySuccess(response));
    } catch (err) {
        yield put(actions.deleteIndustryFailure(err.response.status));
    }
}

function* removeSubindustry(id) {
    try {
        yield put(actions.deleteSubindustryPending());
        const response = yield call(deleteSubindustry, id);
        yield put(actions.deleteSubindustrySuccess(response));
    } catch (err) {
        yield put(actions.deleteSubindustryFailure(err.response.status));
    }
}

function* updateIndustry(data) {
    try {
        yield put(actions.updateIndustryPending());
        const response = yield call(putIndustry, data);
        yield put(actions.updateIndustrySuccess(response));
    } catch (err) {
        yield put(actions.updateIndustryFailure(err && err.response && err.response.status));
    }
}

function* updateSubindustry(data) {
    try {
        yield put(actions.updateSubindustryPending());
        const response = yield call(putSubindustry, data);
        yield put(actions.updateSubindustrySuccess(response));
    } catch (err) {
        yield put(actions.updateSubindustryFailure(err && err.response && err.response.status));
    }
}

function* submitGetClosestIndustry(id, subIndustry) {
    try {
        yield call(getCookie);
        yield put(actions.postGetClosestPending());

        const response = yield call(postGetClosestIndustry, subIndustry);
        yield put(actions.postGetClosestSuccess(response));
        let mlCompanies = response.data;
        const companies = JSON.stringify(mlCompanies);
        let postResultsData = new FormData();
        postResultsData.append("companies", companies);
        yield put(actions.postSaveCompaniesDataPending());
        const postResponse = yield call(postCompaniesData, postResultsData, id);
        yield put(actions.setIndustry(postResponse));
        yield put(actions.postSaveCompaniesDataSuccess(postResponse));

    } catch (err) {
        yield put(actions.postSaveCompaniesDataFailure(err.response.status))
        if (err.response.status === 442) {
            yield put(actions.postGetClosestFailure(err.response.status));
        } else if (err.response.status >= 500) {
            yield put(actions.postGetClosestTimeout());
        } else {
            yield put(actions.postGetClosestFailure(err && err.response && err.response.status));
        }
    }
}

function* submitGetSubIndustryWithCompanies(id) {
    try {
        yield put(actions.getSubIndustryWithCompaniesPending());
        const response = yield call(getSubIndustryWithCompanies, id);
        yield put(actions.setSubindustry(response));
        yield put(actions.getSubIndustryWithCompaniesSuccess(response));
    } catch (err) {
        yield put(actions.getSubIndustryWithCompaniesFailure(err && err.response && err.response.status));
    }
}
function* submitUpdateMLCompanySubIndustry(companies, isBoard) {
    try {
        isBoard ? yield put(actions.updateMlCompanyKanbanStatusPending()) : yield put(actions.updateMlCompanyPending());
        const response = yield call(updateMLCompanyKanban, companies);
        isBoard ? yield put(actions.updateMlCompanyKanbanStatusSuccess(response)) : yield put(actions.updateMlCompanySuccess(response));
    } catch (err) {
        isBoard ? yield put(actions.updateMlCompanyKanbanStatusFailure(err && err.response && err.response.status)) : yield put(actions.updateMlCompanyFailure(err && err.response && err.response.status));
    }
}

function* submitUpdateCompanyIndustryKanban(companies) {
    try {
        yield put(actions.updateMlCompanyPending());
        const response = yield call(updateMLCompanyKanban, companies);
        yield put(actions.updateMlCompanySuccess(response));
    } catch (err) {
        yield put(actions.updateMlCompanyFailure(err && err.response && err.response.status));
    }
}
function* submitReleaseSubIndustryCompanies(id, companyIds) {
    try {
        yield put(actions.releaseCompaniesPending());
        const response = yield call(releaseCompanies, id, companyIds);
        yield put(actions.processRelease(response));
        yield put(actions.releaseCompaniesSuccess(response));
    } catch (err) {
        yield put(actions.releaseCompaniesFailure(err && err.response && err.response.status));
    }
}

export {
    fetchIndustries,
    createIndustry,
    removeIndustry,
    fetchIndustry,
    updateIndustry,
    addSubindustry,
    removeSubindustry,
    updateSubindustry,
    submitGetClosestIndustry,
    submitGetSubIndustryWithCompanies,
    submitUpdateMLCompanySubIndustry,
    submitUpdateCompanyIndustryKanban,
    submitReleaseSubIndustryCompanies
};
