import { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from 'react-redux';
import debounce from "lodash/debounce";
import useInfiniteScroll from "./useInfiniteScroll.js";
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_PENDING
} from "../../../../../constants/statuses";
// import { subscribeRequest } from "../../../../store/auth/requests";
import { getTechnologyProfileRequest } from "../../../../../store/technologies/requests";
import {
    getTechnologyProfileStatus
} from "../../../../../store/technologies/selectors";


const useResults = ({
    technology,
    totalCount,
    searchResults,
    isAuthenticated,
    getMeStatus,
    page,
    totalPages,
    filterNameValue,
    setPage,
    sortValue,
    sortDirection,
    isSearching,
    getFilteringState
}) => {
    const [noResults, setNoResults] = useState(false);
    const [isSubscribeDialogOpened, setIsSubscribeDialogOpened] = useState(false);
    const dispatch = useDispatch();
    const [pageLoading, setPageLoading] = useState(false);
    const getTechnologyStatus = useSelector(getTechnologyProfileStatus);

    useEffect(() => {
        setNoResults(totalCount && totalCount === 0 && (getTechnologyStatus === REQUEST_SUCCESS))
    }, [totalCount, searchResults, getTechnologyStatus]);

    const moreData = () => {
        if (totalCount !== 0) {
            if (totalPages && page && page < totalPages && !isSearching) {
                const data = { filterNameValue: `${filterNameValue}page=${page + 1}`, page: page + 1, orderBy: sortValue, orderDirection: sortDirection };
                dispatch(getTechnologyProfileRequest({ id: technology.id, data: data }));
                setPage(page + 1);
            }
            setIsFetching(false);
        }
    }
    const [isFetching, setIsFetching] = useInfiniteScroll(debounce(moreData, 300), totalCount);


    // useEffect(() => {
    //     dispatch(mixpanelTrackRequest({ eventName: 'Results' }));
    // }, [dispatch]);

    useEffect(() => {
        setPageLoading(getTechnologyStatus === REQUEST_PENDING)
    }, [getTechnologyStatus]);


    return {
        isFetching,
        noResults,
        pageLoading,
    };
}

export default useResults;