import React, { useState, useEffect } from 'react';
// Hooks
import { useStyles } from './style';
import { useSelector, useDispatch } from 'react-redux';
// Components
import Board from '../../components/Board/Board';
import ContentLoader from '../../components/ContentLoader/ContentLoader';
import TextField from '../../components/TextField/TextField';
// Assets
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as SearchPlaceholderIcon } from '../../assets/icons/SearchPlaceholderIcon.svg';
// Redux
import Loader from '../../components/Loader/Loader';
import { fetchKanbanItemsRequest } from '../../store/kanban/requests';
import { getKanbanItemsStateSelector } from '../../store/kanban/selectors';

const Kanban = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const kanban = useSelector(getKanbanItemsStateSelector);
    const initialColumns = [
        { id: 0, name: 'Requests', cardIds: [] },
        { id: 1, name: 'In progress', cardIds: [] },
        { id: 2, name: 'Done', cardIds: [] },
        { id: 3, name: 'Notify client', cardIds: [] }
    ];
    const headerColors = [
        theme.palette.secondary.backgroundLight,
        theme.palette.secondary.backgroundLight,
        theme.palette.secondary.backgroundLight,
        theme.palette.success.main
    ];

    const [items, setItems] = useState(null);
    const [cards, setCards] = useState(null);
    const [columns, setColumns] = useState(initialColumns);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        dispatch(fetchKanbanItemsRequest());
    }, [dispatch]);
    useEffect(() => {
        setItems(kanban);
    }, [kanban]);

    useEffect(() => {
        items && setColumnsData(items);
        items && setCards(items);
    }, [items]);

    const convertKanbanStatus = (oldStatus) => {
        let newStatus;

        switch (oldStatus) {
            case 1:
                newStatus = 1;
                break;
            case 2:
                newStatus = 1;
                break;
            case 3:
                newStatus = 2;
                break;
            case 4:
                newStatus = 2;
                break;
            case 5:
                newStatus = 2;
                break;
            case 6:
                newStatus = 3;
                break;
            case 7:
                newStatus = 3;
                break;
            case 8:
                newStatus = 4;
                break;
            default:
                break;
        }

        return newStatus;
    };

    const setColumnsData = (items) => {
        let cols = [...initialColumns];
        let column = { ...cols[0] };

        for (let i = 0; i < 4; i++) {
            column = { ...cols[i] };
            column.cardIds = items
                .filter((item) => convertKanbanStatus(item.kanban_status) === i + 1)
                .map((item) => item.id);
            cols[i] = column;

            setColumns(cols);
        }
    };
    const handleSetColumns = (newColumns) => setColumns(newColumns);
    const handleSearch = (e) =>
        setColumnsData(
            items.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()))
        );

    return (
        <div className={classes.root}>
            <h1>Kanban 2.1</h1>
            <div className={classes.refineAndSearch}>
                <TextField
                    placeholder="Search by name"
                    testId="kanban-search"
                    endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />}
                    className={classes.headerInput}
                    onChange={handleSearch}
                />
            </div>
            {loadingData ? (
                <ContentLoader />
            ) : (
                <>
                    <Board
                        isKanban={true}
                        cards={cards}
                        columns={columns}
                        setColumns={handleSetColumns}
                        headerColors={headerColors}
                    />
                </>
            )}
        </div>
    );
};
export default Kanban;
