import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialog: {
        padding: spacing(4),
        background: palette.common.white,
        borderRadius: spacing(2)
    },
    dialogFilter: {
        padding: spacing(4),
        background: palette.common.white,
        borderRadius: 16
    },
    dialogScrollPaper: {
        maxWidth: 800,
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
    },
    radarTitle: {
        paddingTop: spacing(2),
        color: palette.text.main
    },
    radarTitleInput: {
        color: palette.secondary.main,
        "& input": {
            width: 430
        }
    },
    radarDescription: {
        marginTop: spacing(3),
        marginBottom: spacing(1),
        color: palette.text.main
    },
    dialogTitleWrapper: {
        padding: 0,
        paddingLeft: spacing(3)
    },
    filterDialogTitle: {
        marginBottom: spacing(3)
    },
    cancelButton: {
        background: palette.text.backgroundMain,
        color: palette.text.lighter,
        border: "none",
        marginRight: spacing(1),
        height: spacing(5)
    },
    textarea: {
        borderColor: palette.secondary.dark,
        fontFamily: "GT Flexa, sans-serif",
        marginBottom: spacing(1),
        outline: 'none',
        padding: spacing(1),
        resize: 'none',
        borderRadius: spacing(1),
        color: palette.secondary.dark,
        width: 480,
        "&:focus": {
            borderColor: palette.primary.dark,
        },
        "&::placeholder": {
            fontSize: 14,
            lineHeight: "18px",
            fontFamily: "GT Flexa, sans-serif",
            color: palette.text.lighter
        }
    },
    maximumCharactersWrap: {
        marginBottom: spacing(3),
        minHeight: 16,
    },
    maximumCharactersWarning: {
        color: palette.error.main
    },
    addModal: {
        position: 'fixed',
        left: 235,
        bottom: 0,
        right: 0,
        height: 70,
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.12)",
        background: palette.common.white,
        padding: spacing(3)
    },
    addPopup: {
        width: 234,
        height: 'auto',

        background: palette.common.white,
        boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
        borderRadius: shape.borderRadius,
        border: '1px solid',
        borderColor: palette.secondary.main,
        bottom: '55px',
        right: '0'
    },
    radarName: {
        width: "100%",
        padding: `${spacing(1)}px ${spacing(2)}px`,
        "&:hover": {
            background: palette.secondary.main,
        },
        cursor: 'pointer'
    },
    rowWrap: {
        marginTop: spacing(3)
    },
    resetFilter: {
        cursor: 'pointer',
        position: "absolute",
        right: spacing(2),
        zIndex: 2
    },
    arrowDown: {
        position: "absolute",
        right: spacing(2),
        cursor: 'pointer',
        zIndex: 1
    },
    ml24: {
        marginLeft: spacing(3)
    },
    ml16: {
        marginLeft: spacing(2)
    },
    ml8: {
        marginLeft: spacing(1)
    },
    disableSubsectors: {
        background: palette.text.backgroundMain,
        border: `1px solid ${palette.text.lighter}!important`
    },
    radarTitleError: {
        marginTop: spacing(1),
        color: palette.error.main
    },
    radarTitleErrorWrap: {
        minHeight: 13
    },
    inputClass: {
        borderRadius: spacing(1),
        width: "100%",
        padding: `${spacing(1)}px ${spacing(2)}px`,
        color: palette.secondary.dark,
        "&:focus": {
            borderColor: palette.primary.dark,
        },

        "&::placeholder": {
            fontSize: 14,
            lineHeight: "18px",
            fontFamily: "GT Flexa, sans-serif",
            color: palette.text.lighter
        }

    },
    labelClass: {
        color: palette.text.main,
        fontSize: 14,
        lineHeight: "18px"
    },
    multiselect: {
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: spacing(1)
    },
    resetFilterAction: {
        color: palette.text.lighter,
        paddingLeft: 0
    },
    showResultsButton: {
        background: palette.primary.dark
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    cancelAndCreate: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    }

}))