import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../../../../../components/Typography/Typography";
import IconButton from '@material-ui/core/IconButton';
import Button from "../../../../../components/Button/Button";
import Grow from '@material-ui/core/Grow';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Multiselect from '../../../../../components/Multiselect/Multiselect';

//Assets
import CloseIcon from '../../../../../assets/icons/CloseIcon.svg';
import { ReactComponent as ResetIcon } from "../../../../../assets/icons/ResetFilter.svg";
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/ArrowDownGrey.svg";

//Redux
import { fetchSectorsRequest } from "../../../../../store/sectors/requests";
import { getCountriesRequest } from "../../../../../store/countries/requests";
import {
    getFilterResultsCountSelector
} from "../../../../../store/filtering/selectors";
import _ from "lodash";
import classnames from "classnames";

const FiltersModal = ({
    dialogOpened,
    onClose,
    onShowResults,
    onShowFilters,
    resetFilters,
    stageOptions,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamSizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    handleSectorChange,
    handleFieldChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleValuerScoreMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    allCountries,
    displayedFilters
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const getFilterResultsCount = useSelector(getFilterResultsCountSelector)
    useEffect(() => {
        dialogOpened && dispatch(fetchSectorsRequest());
        dialogOpened && dispatch(getCountriesRequest());
    }, [dispatch, dialogOpened]);

    const showResults = () => {
        onShowResults(1);
        onShowFilters(selectedFilters);
        onClose();
    };

    const isShowResultsButtonDisabled = () => {
        for (const key in selectedFilters) {
            if (selectedFilters[key] !== displayedFilters[key]) {
                return false;
            }
        }
        return true;
    };

    const handleClose = () => {
        resetFilters();
        onClose();
    }

    return (
        <Dialog
            onClose={handleClose}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialogFilter, scrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    handleClose();
                }
            }}
            open={dialogOpened}>
            <IconButton className={classes.closeButton} onClick={handleClose}>
                <img src={CloseIcon} alt={t('filterModal.closeX')} className={classes.stepIcon} />
            </IconButton>

            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="h6" component="div" className={classes.filterDialogTitle}>{t('filterModal.filtersTitle')}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>

                <Box display="flex" justifyContent="space-between">
                    <Box>
                        <Multiselect testId="multiselect-sector" placeholder="Select industry" customClass={classes.multiselect} labelClass={classes.labelClass} width="278px" iconArrow={selectedFilters.sector ? <ResetIcon test-id="reset-sector" className={classes.resetFilter} onClick={() => resetFilter('sector')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.sectorFilter')} options={allSectors} value={selectedFilters.sector} onChange={handleSectorChange}></Multiselect>

                        <Multiselect testId="multiselect-stage" placeholder="Select stage" customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="278px" iconArrow={selectedFilters.stage ? <ResetIcon test-id="reset-stage" className={classes.resetFilter} onClick={() => resetFilter('stage')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.stageFilter')} options={stageOptions} value={selectedFilters.stage} onChange={(e) => handleFieldChange(e, "stage")}></Multiselect>

                        <Multiselect testId="multiselect-teamSize" placeholder="Select team size" customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="278px" iconArrow={selectedFilters.teamSize ? <ResetIcon test-id="reset-teamSize" className={classes.resetFilter} onClick={() => resetFilter('teamSize')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.teamFilter')} options={teamSizeOptions} value={selectedFilters.teamSize} onChange={(e) => handleFieldChange(e, "teamSize")}></Multiselect>

                        <Box className={classes.rowWrap} display="flex" alignItems="center">
                            <Multiselect testId="multiselect-yearMin" placeholder="From" customClass={classes.multiselect} labelClass={classes.labelClass} width="129px" iconArrow={selectedFilters.yearMin ? <ResetIcon test-id="reset-yearMin" className={classes.resetFilter} onClick={() => resetFilter('yearMin')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.yearFilter')} options={yearOptionsMin} value={selectedFilters.yearMin} onChange={handleYearMinChange}></Multiselect>

                            <Multiselect testId="multiselect-yearMax" placeholder="To" customClass={classes.multiselect} labelClass={classes.labelClass} width="129px" className={classes.ml8} iconArrow={selectedFilters.yearMax ? <ResetIcon test-id="reset-yearMax" className={classes.resetFilter} onClick={() => resetFilter('yearMax')} /> : <ArrowDown className={classes.arrowDown} />} options={yearOptionsMax} value={selectedFilters.yearMax} onChange={(e) => handleFieldChange(e, "yearMax")}></Multiselect>
                        </Box>
                    </Box>
                    <Box className={classes.ml16}>
                        <Multiselect testId="multiselect-subsector" placeholder="Select subsectors" customClass={classnames([classes.multiselect, { [classes.disableSubsectors]: !selectedFilters.sector }])} labelClass={classes.labelClass} disabled={!selectedFilters.sector} width="278px" iconArrow={selectedFilters.subsector ? <ResetIcon test-id="reset-subsector" className={classes.resetFilter} onClick={() => resetFilter('subsector')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.subsectorFilter')} options={allSubsectors} value={selectedFilters.subsector} onChange={(e) => handleFieldChange(e, "subsector")}></Multiselect>

                        <Box className={classes.rowWrap} display="flex" alignItems="center" justifyContent="space-between">
                            <Multiselect testId="multiselect-fundingMin" placeholder="From" customClass={classes.multiselect} labelClass={classes.labelClass} width="129px" iconArrow={selectedFilters.fundingMin ? <ResetIcon test-id="reset-fundingMin" className={classes.resetFilter} onClick={() => resetFilter('fundingMin')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.fundingFilter')} options={fundingOptionsMin} value={selectedFilters.fundingMin} onChange={handleFundingMinChange}></Multiselect>

                            <Multiselect testId="multiselect-fundingMax" placeholder="To" customClass={classes.multiselect} labelClass={classes.labelClass} width="129px" className={classes.ml8} iconArrow={selectedFilters.fundingMax ? <ResetIcon test-id="reset-fundingMax" className={classes.resetFilter} onClick={() => resetFilter('fundingMax')} /> : <ArrowDown className={classes.arrowDown} />} options={fundingOptionsMax} value={selectedFilters.fundingMax} onChange={(e) => handleFieldChange(e, "fundingMax")}></Multiselect>

                        </Box>
                        <Multiselect testId="multiselect-location" placeholder="Select location" customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="278px" iconArrow={selectedFilters.location ? <ResetIcon test-id="reset-location" className={classes.resetFilter} onClick={() => resetFilter('location')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.locationFilter')} options={allCountries} value={selectedFilters.location} onChange={(e) => handleFieldChange(e, "location")}></Multiselect>
                        <Box className={classes.rowWrap} display="flex" alignItems="center">
                            <Multiselect testId="multiselect-valuerScoreMin" placeholder="From" customClass={classes.multiselect} labelClass={classes.labelClass} width="129px" iconArrow={selectedFilters.valuerScoreMin ? <ResetIcon test-id="reset-valuerScoreMin" className={classes.resetFilter} onClick={() => resetFilter('valuerScoreMin')} /> : <ArrowDown className={classes.arrowDown} />} label={t('filterModal.valuerScoreFilter')} options={valuerScoreOptionsMin} value={selectedFilters.valuerScoreMin} onChange={handleValuerScoreMinChange}></Multiselect>

                            <Multiselect testId="multiselect-valuerScoreMax" placeholder="To" customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.ml8} width="129px" iconArrow={selectedFilters.valuerScoreMax ? <ResetIcon test-id="reset-valuerScoreMax" className={classes.resetFilter} onClick={() => resetFilter('valuerScoreMax')} /> : <ArrowDown className={classes.arrowDown} />} label="-" options={valuerScoreOptionsMax} value={selectedFilters.valuerScoreMax} onChange={(e) => handleFieldChange(e, "valuerScoreMax")}></Multiselect>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.rowWrap}>
                    <Button
                        variant="textSecondary"
                        disabled={false}
                        onClick={resetFilters}
                        className={classes.resetFilterAction}
                        test-id="reset-filters"
                    >
                        {t("filterModal.resetButton")}
                    </Button>
                    <Box display="flex" justifyContent="flex-end" alignItems="center">
                        <Button
                            className={classes.cancelButton}
                            variant="tertiary"
                            disabled={false}
                            onClick={handleClose}
                            test-id="cancel-filters"
                        >
                            {t("filterModal.cancel")}
                        </Button>
                        <Button
                            variant="primary"
                            className={classes.showResultsButton}
                            disabled={isShowResultsButtonDisabled()}
                            onClick={showResults}
                            test-id="show-results"
                        >
                            {t("filterModal.show")} {getFilterResultsCount} {t("filterModal.results")}
                        </Button>
                    </Box>
                </Box>
            </MuiDialogContent>
        </Dialog >
    )
}
export default FiltersModal;