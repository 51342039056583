import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialog: {
        padding: spacing(4),
        background: palette.common.white
    },
    dialogFilter: {
        padding: spacing(3),
        background: ({ colorSwitch }) => colorSwitch === null ? palette.common.white : colorSwitch ? palette.primary.backgroundLight : palette.success.backgroundLight,
        borderRadius: spacing(2),
        height: 458
    },
    dialogScrollPaper: {
        maxWidth: 800,
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
    },
    dialogTitle: {
        color: palette.secondary.main,
    },
    dialogSubtitle: {
        color: palette.grey.main,
        marginTop: spacing(3)
    },
    dialogContent: {
        marginTop: spacing(8)
    },
    confirmButton: {
        background: ({ colorSwitch }) => colorSwitch === null ? palette.primary.dark : palette.error.main,
        borderColor: ({ colorSwitch }) => colorSwitch === null ? palette.primary.dark : palette.error.main,
        marginRight: spacing(2),
        "&:hover": {
            background: ({ colorSwitch }) => colorSwitch === null ? palette.primary.light : palette.error.light,
            borderColor: ({ colorSwitch }) => colorSwitch === null ? palette.primary.light : palette.error.light,
        },

    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& img": {
            maxWidth: 80
        },
        "& span": {
            textAlign: "center"
        }
    },
    buttons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: spacing(10)
    },
    checkIcon: {
        marginTop: spacing(7)
    },
    saveButton: {
        marginLeft: spacing(2)
    },
    goBack: {
        border: "none",
        background: "transparent",
        "&:hover": {
            // background: palette.error.light,
            border: "none",
        },
    },
    dontSave: {
        background: "transparent",
    }
}));
