import React from 'react';
// Hooks
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useRouterHook } from '../../common/useRouterHook';
//Components
import Button from '../../components/Button/Button';
// Assets
import ProfilesSidebarIcon from '../../assets/icons/ProfilesSidebarIcon.png';
import UsersSidebarIcon from '../../assets/icons/UsersSidebarIcon.png';
import { ReactComponent as SearchHistoryIcon } from '../../assets/icons/SearchHistoryIcon.svg';
import { ReactComponent as TechnologiesIcon } from '../../assets/icons/TechnologiesIcon.svg';
import { ReactComponent as IndustriesIcon } from '../../assets/icons/IndustriesIcon.svg';
import { ReactComponent as KanbanIcon } from '../../assets/icons/Kanban.svg';
import { ReactComponent as ValuerUsers } from '../../assets/icons/ValuerUsers.svg';

const Sidebar = ({ match }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const NavigationItem = React.forwardRef((props, ref) => {
        const { children, match, disabled, slug } = props;
        const classes = useStyles();
        return (
            <div ref={ref}>
                <Button
                    variant="sidebar"
                    leftAligned
                    {...(match === slug && { active: true })}
                    className={classes.navigationItem}
                    disabled={disabled}
                    fullWidth
                    onClick={() => openPage(`/${slug}`)}
                    test-id={`${props.testId}-link-sidebar`}>
                    {children}
                </Button>
            </div>
        );
    });
    return (
        <div className={classes.sidebarRoot} test-id="sidebar">
            <div className={classes.sidebarContent}>
                <div>
                    <NavigationItem match={match} slug={'technologies'} testId="technologies">
                        <TechnologiesIcon />
                        {t('sidebar.technologies')}
                    </NavigationItem>
                    <NavigationItem match={match} slug={'industries'} testId="industries">
                        <IndustriesIcon />
                        {t('sidebar.industries')}
                    </NavigationItem>
                    {
                        <NavigationItem match={match} slug={'kanban'} testId="kanban">
                            <KanbanIcon />
                            {t('sidebar.kanban')}
                        </NavigationItem>
                    }
                </div>
                <div className={classes.sidebarBottom}>
                    <NavigationItem match={match} slug={'users'} testId="users">
                        <ValuerUsers />
                        {t('sidebar.users')}
                    </NavigationItem>
                    <NavigationItem match={match} slug={'profiles'} testId="profiles">
                        <TechnologiesIcon />
                        {t('sidebar.profiles')}
                    </NavigationItem>
                </div>
                {/* <NavigationItem
                    match={match}
                    slug="profiles"
                    testId="profiles"
                >
                    <img src={ProfilesSidebarIcon} alt={t('sidebar.profiles')} />{t('sidebar.profiles')}
                </NavigationItem>
                <NavigationItem
                    match={match}
                    slug={"users-management"}
                    testId="users-management"
                >
                    <img src={UsersSidebarIcon} alt={t('sidebar.usersManagement')} />{t('sidebar.usersManagement')}
                </NavigationItem> */}
            </div>
        </div>
    );
};

export default Sidebar;
