const getSubscriptionsSelector = state => state.subscriptions.pool;
const getPaginationSelector = state => state.subscriptions.pagination;
const getSubscriptionProfile = state => state.subscriptions.subscriptionProfile;
const updateUserStatusSelector = state => state.userprofiles.updateUserStatus;
const updateSubscriptionStatusSelector = state => state.subscriptions.updateSubscriptionStatus;
const createUserStatusSelector = state => state.userprofiles.createUserStatus;
const deleteSubscriptionStatusSelector = state => state.subscriptions.deleteSubscriptionStatus;
const resendUserInvitationStatusSelector = state => state.subscriptions.resendUserInvitationStatus;
const resendEmailVerificationStatusSelector = state => state.subscriptions.resendEmailVerificationStatus;
const createTeamMemberStatusSelector = state => state.subscriptions.createTeamMemberStatus;
const updateSubscriptionFailureMessageSelector = state => state.subscriptions.updateSubscriptionFailureMessage;
const createTeamMemberMessageFailureSelector = state => state.subscriptions.createTeamMemberMessageFailure;
const createSubscriptionMessageFailureSelector = state => state.subscriptions.createSubscriptionMessageFailure;
const addExistingUserStatusSelector = state => state.subscriptions.addExistingUserStatus;
const addExistingUserMessageFailureSelector = state => state.subscriptions.addExistingUserMessageFailure;
const removeTeamMemberStatusSelector = state => state.subscriptions.removeTeamMemberStatus;
const getTeamSelector = state => state.subscriptions.team;

export {
    getSubscriptionsSelector,
    updateUserStatusSelector,
    createUserStatusSelector,
    getSubscriptionProfile,
    updateSubscriptionStatusSelector,
    deleteSubscriptionStatusSelector,
    getTeamSelector,
    getPaginationSelector,
    resendUserInvitationStatusSelector,
    resendEmailVerificationStatusSelector,
    createTeamMemberStatusSelector,
    updateSubscriptionFailureMessageSelector,
    createTeamMemberMessageFailureSelector,
    createSubscriptionMessageFailureSelector,
    removeTeamMemberStatusSelector,
    addExistingUserMessageFailureSelector,
    addExistingUserStatusSelector
};