import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


export default makeStyles(({ palette, spacing, typography }) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    whiteContent: {
        backgroundColor: `${palette.common.white} !important`
    },
    paperAnchorLeft: {
        top: "56px !important"
    },
    wrapper: {
        padding: `${spacing(3)}px`
    },
    content: {
        flexGrow: 1,
        minHeight: "100vh",
        backgroundColor: palette.grey.light,
        position: "relative",
        marginLeft: ({ hideSidebar }) => hideSidebar ? 0 : 236,
        marginTop: spacing(6)
    },
    sidebarBottom: {
        selfAlign: "flex-end"
    },
    sidebarContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    // headerLabel: {
    //     color: palette.common.black,
    //     marginBottom: 8
    // },
    headerInput: {
        "& input": {
            width: 167
        },
    },
    filterButton: {
        background: "transparent!important",
        color: palette.secondary.dark,
        border: "none",
        padding: `${spacing(1)}px`,
        minWidth: 102,
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },

    },
    resultsTotal: {
        color: palette.text.light,
    },
    headerTitle: {
        marginBottom: `${spacing(1)}px`,
        display: "flex",
        justifyContent: "space-between",
    },
    header: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    filterWrap: {
        marginLeft: `${spacing(2)}px`,
        display: "flex",
        alignItems: "center"
        // flexDirection: "column",
        // alignItems: "flex-start"
    },
    refineAndSearch: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    sidebarRoot: {
        height: "calc(100% - 56px)",
        boxShadow: "1px 0px 1px rgba(0, 0, 0, 0.1)",
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: 256,
        backgroundColor: palette.common.white,
        position: "fixed",
        left: 0,
        top: spacing(6),
        bottom: 0
    },
    startSearchWrapper: {
        padding: `${spacing(3)}px ${spacing(2)}px`
    },
    navigationItem: {
        color: palette.text.main,
        padding: `${spacing(3)}px ${spacing(4)}px`,
        fontFamily: "GT Flexa, sans-serif",
        fontSize: 16,
        lineHeight: "22px",
        "&:disabled": {
            color: palette.text.lighter,
            cursor: "default",
            "& > svg": {
                stroke: palette.text.lighter,
                marginRight: spacing(2)
            }
        },
        "&.active": {
            color: palette.primary.dark
        },
        "&:hover": {
            "& > svg": {
                stroke: palette.primary.dark
            }
        }
    },
    topNav: {
        height: 56,
        width: "100%",
        background: palette.common.white,
        padding: `9px ${spacing(4)}px`,
        zIndex: 1202,
        boxShadow: "0px 1px 1px 0px rgb(0 0 0 / 12%)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "fixed"
    },
    avatarIcon: {
        position: "relative"
    },
    logoutMenuItem: {
        position: "absolute",
        zIndex: 1203,
        top: 37,
        right: 0,
        background: palette.common.white,
        borderRadius: spacing(1),
        border: `1px solid ${palette.text.backgroundDark}`,
        fontFamily: "GT Flexa Mono, sans-serif",
        color: palette.error.main,
        padding: `${spacing(2)}px ${spacing(3)}px`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        ...typography.bodyXs,
        "&:hover": {
            background: palette.common.white,
            textDecoration: "underline"
        }
    },
    logoTopNav: {
        paddingTop: "6px",
        height: 32
    },
    divider: {
        background: palette.text.backgroundMain,
        width: "100%",
        height: 1,
        borderRadius: 1,
        margin: `${spacing(3)}px 0`
    },
    avatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        cursor: "pointer"
    },
    topNavItem: {
        marginLeft: spacing(2),
        cursor: "pointer"
    },
    userSection: {
        padding: `${spacing(2)}px 0`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    logOut: {
        margin: `${spacing(3)}px 0`
    },
    startEndornment: {
        marginRight: `${spacing(1)}px`
    },
    arrowIcon: {
        marginRight: `${spacing(1)}px`,
        cursor: 'pointer',
        transform: "rotate(0deg) scale(1.4)"
    },
    rotateIcon: {
        transform: "rotate(180deg) scale(1.4)"
    },
    inviteIcon: {
        marginRight: spacing(1),
        cursor: "pointer"
    },
    searchImage: {
        marginRight: spacing(3)
    },
    editSearchBtn: {
        border: "none",
        background: "transparent",
        marginRight: spacing(2),
        height: spacing(5),
        "&:hover": {
            background: palette.primary.backgroundLight,
            border: "none"
        }
    },
    startSearchBtn: {
        background: palette.primary.dark
    },
    multiselectAdjust: {
        border: "none",
        background: "transparent",
        borderRadius: spacing(3),
        "&:hover": {
            background: palette.primary.backgroundLight,
            border: "none"
        }
    },
    sortedBy: {
        marginRight: spacing(1)
    },
    creditsIcon: {
        width: 17,
        height: 14
    },
    creditsIconWrapper: {
        width: 32,
        height: 32,
        background: palette.success.backgroundLight,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    creditsWrapper: {
        display: "flex",
        alignItems: "center",
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    creditsLeftText: {
        display: "flex",
        alignItems: "center",
        marginRight: spacing(3),
        cursor: "pointer"
    },
    creditsLeftTextDisabled: {
        cursor: "default"
    },
    creditsText: {
        margin: `0 ${spacing(1)}px`
    },
    arrowDown: {
        width: 12
    },
    creditsDropdown: {
        background: palette.common.white,
        border: `1px solid ${palette.text.backgroundDark}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: spacing(1),
        position: "absolute",
        top: 32,
        right: 16,
        minWidth: 200
    },
    dropdown: {
        position: "relative"
    },
    creditsReplenishText: {
        textAlign: "center",
        borderBottom: `1px solid ${palette.text.backgroundLight}`,
        padding: `${spacing(3)}px ${spacing(3)}px ${spacing(2)}px ${spacing(3)}px`
    },
    getMoreCreditsText: {
        padding: `${spacing(2)}px ${spacing(3)}px ${spacing(3)}px ${spacing(3)}px`
    },
    getMoreCreditsTextWIthoutBilling: {
        padding: `${spacing(3)}px`
    },
    getMoreCredits: {
        textDecoration: "underline",
        cursor: "pointer"
    },
    avatarWrapper: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.success.backgroundLight,
        marginLeft: spacing(2)
    },
    inviteUsers: {
        cursor: "pointer",
        marginLeft: spacing(1)
    },
    tagWrapper: {
        marginTop: -12,
        padding: "0 12px",
        background: palette.common.white

    },
    disabledNavigationItem: {
        color: palette.text.lighter,
        cursor: "default",
        padding: "24px 32px",
        width: "100%",
        height: 70,
        margin: 0,
        zIndex: 1,
        maxHeight: 70,
        lineHeight: "22px"
    },
    tooltipContent: {
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        verticalAlign: "top",
        maxHeight: 24,
        minWidth: 160
    },
    disabledNavigationItemIcon: {
        stroke: palette.text.lighter,
        marginRight: 16
    },
    tagBorder: {
        borderTop: `1px solid ${palette.text.backgroundMain}`,
        margin: "24px 12px 12px 12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    upgradeBox: {
        background: palette.primary.backgroundMedium,
        borderRadius: spacing(3),
        padding: `${spacing(4)}px ${spacing(3)}px`,
        margin: spacing(3)
    },
    upgradeCta: {
        marginTop: spacing(2),
        textDecoration: "underline",
        cursor: "pointer",
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    "@media screen and (max-height: 700px)": {
        removeOnSmallHeight: {
            display: "none"
        }
    },

}));
