import React, { createRef, useEffect, useState } from 'react';
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import { useParams } from 'react-router-dom';
import { useAuthHook } from '../../common/useAuthHook';
//Components
import Header from '../TechnologiesProfile/components/Header/Header';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { getTechnologyProfileRequest } from '../../store/technologies/requests';
import {
    getTechnologyProfileState,
    getTechnologyProfileStatus
} from '../../store/technologies/selectors';
import { REQUEST_SUCCESS } from '../../constants/statuses';
import Typography from '../../components/Typography/Typography';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, convertFromRaw } from 'draft-js';
import classnames from 'classnames';

const TechnologyPreview = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useAuthHook();
    const getTechnologyStatusSelector = useSelector(getTechnologyProfileStatus);
    const getTechnologyStateSelector = useSelector(getTechnologyProfileState);
    const [technology, setTechnology] = useState(getTechnologyStateSelector);
    const [loading, setLoading] = useState(true);
    const [descHeight, setDescHeight] = useState(0);
    const [descExpanded, setDescExpanded] = useState(false);
    const descRef = createRef(null);
    let { id } = useParams();

    const [technologyBenefits, setTechnologyBenefits] = useState({
        detailed_description: '',
        applications: '',
        benefits: '',
        maturity: '',
        market_opportunities: '',
        sustainability: ''
    });

    const JSONToHTML = (json) => {
        let dataToHtml;
        let text;
        const content = json;

        text = content && EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
        dataToHtml = text && stateToHTML(text.getCurrentContent());

        return dataToHtml;
    };

    useEffect(() => {
        setTechnology(getTechnologyStateSelector);
    }, [getTechnologyStateSelector]);

    useEffect(() => {
        getTechnologyStatusSelector === REQUEST_SUCCESS && setLoading(false);
    }, [getTechnologyStatusSelector]);

    useEffect(() => {
        dispatch(
            getTechnologyProfileRequest({
                id,
                data: { page: 1, orderBy: 'match', orderDirection: 'desc' },
                clearResults: true
            })
        );
    }, [dispatch]);

    const handleShowMore = () => {
        setDescExpanded(!descExpanded);
    };

    return (
        <div className={classes.main}>
            <Header user={user} previewPage={true} technologyId={id} />
            <div className={classes.root}>
                <div className={classes.placeholder}>
                    {/*<div className={classes.placeholderItem}><Typography color="textSecondary" variant="h5">...</Typography></div>*/}
                    {/*<div className={classes.placeholderItem}><Typography color="textSecondary" variant="h5">{t("technologyPreview.placeholderTitle")}</Typography></div>*/}
                    {/*<div className={classes.placeholderItem}><Typography color="textSecondary" variant="h5">...</Typography></div>*/}
                    <div className={classes.main} data-intercom-target="Technology summary">
                        <div className={classes.wrap}>
                            <Typography variant="subtitle1" color="secondaryDark">
                                {technology && technology.name}
                            </Typography>
                        </div>
                        {/*{((sectors && sectors.length > 0) ||*/}
                        {/*    (subsectors && subsectors.length > 0)) && (*/}
                        {/*    <div className={classes.descriptionScores}>*/}
                        {/*        <div className={classes.flexColumn}>*/}
                        {/*            <div className={classes.mr80}>*/}
                        {/*                <Typography variant="bodySmall" color="">*/}
                        {/*                    {t('technologyProfile.relevantIndustries')}*/}
                        {/*                </Typography>*/}
                        {/*                <div className={classes.flexWrap}>*/}
                        {/*                    <SeeSectorsModal sectors={sectors} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div>*/}
                        {/*                <Typography variant="bodySmall" color="">*/}
                        {/*                    {t('technologyProfile.relevantSubindustries')}*/}
                        {/*                </Typography>*/}
                        {/*                <div className={classes.flexWrap}>*/}
                        {/*                    <SeeSectorsModal subsectors={subsectors} />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className={classes.technologies}>
                            <div className={classes.descriptionBox} ref={descRef}>
                                <Typography
                                    // variant="body"
                                    component="div">
                                    <div
                                        className={classnames([
                                            {
                                                [classes.descriptionExpanded]: descExpanded,
                                                [classes.description]: !descExpanded
                                            }
                                        ])}
                                        dangerouslySetInnerHTML={{
                                            __html: technology && technology.ml_description
                                        }}></div>
                                </Typography>
                                {descHeight > 48 && (
                                    <div className={classnames([classes.showMore, 'laptop'])}>
                                        <Typography
                                            // variant="bodySmall"
                                            color="textLighter"
                                            onClick={handleShowMore}>
                                            {descExpanded
                                                ? t('companyProfile.showLess')
                                                : t('companyProfile.showMore')}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.benefitsCardsWrapper}>
                    <Typography
                        className={classes.benefitsCardsWrapperTitle}
                        // variant="bodyXs"
                    >
                        {t('technologyPreview.whatWillIGet')}
                    </Typography>
                    {Object.keys(technologyBenefits).map((technologyBenefitName) => (
                        <div
                            className={classes.benefitsCard}
                            key={`technology-overview-benefit-${technologyBenefitName}`}>
                            <div className={classes.imgBlock}>
                                <Typography
                                    className={classes.blockTitle}
                                    // variant="subtitle1"
                                    color="textPrimary"
                                    component="div">
                                    {t(`technologyPreview.${technologyBenefitName}.title`)}
                                </Typography>
                                <div className={classes.technologies}>
                                    <div
                                        className={classes.descriptionBox}
                                        data-name={technologyBenefitName}>
                                        <Typography
                                            // variant="body"
                                            color="textPrimary"
                                            component="div">
                                            <div
                                                className={classes.description}
                                                dangerouslySetInnerHTML={{
                                                    __html: technology
                                                        ? JSONToHTML(
                                                              technology[technologyBenefitName]
                                                          )
                                                        : 'Not edited yet.'
                                                }}
                                            />
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default TechnologyPreview;
