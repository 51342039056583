import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    totalCount: null,
    getResultsStatus: REQUEST_UNDEFINED,
};

export const REDUCER_PREFIX = "results";


const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getResultsPending: state => {
            state.getResultsStatus = REQUEST_PENDING;
        },
        getResultsSuccess: state => {
            state.getResultsStatus = REQUEST_SUCCESS;
        },
        getResultsFailure: state => {
            state.getResultsStatus = REQUEST_FAILURE;
        },
        setResults: (state, { payload }) => {
            state.pool = payload.data;
        },
        clearResults: state => {
            state.pool = [];
            state.currentPage = 1;
        },
        setSearches: (state, { payload }) => {
            state.searches = payload;
        },
    }
});
export { reducer, actions };
