import { useHistory, generatePath } from "react-router-dom";

export const useRouterHook = () => {
    let history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    const openPage = (url) => {
        history.push(generatePath(url));
    }

    return { goBack, openPage };
}
