import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    fetchAdminUsers,
    postCreateAdminUser,
    putUpdateAdminUser,
    getSingleAdminUser,
    deleteSingleAdminUser
} from "./helpers";

function* getAdminUsers(search, sortBy, sortDirection, perPage, page) {
    try {
        yield put(actions.getAdminUsersPending());
        let data = new FormData();
        data.append("search", search);
        data.append("sort-by", sortBy);
        data.append("sort-direction", sortDirection);
        data.append("per-page", perPage);
        data.append("page", page);
        const response = yield call(fetchAdminUsers, data);
        yield put(actions.getAdminUsersSuccess());
        yield put(actions.setAdminUsers(response.data));
    } catch (err) {
        yield put(actions.getAdminUsersFailure());
    }
}
function* createAdminUser(data) {
    yield put(actions.createAdminUserPending());
    let response;
    
    try {
        response = yield call(postCreateAdminUser, data);
    } catch (e) {
        response = e.response;
    }
    
    if (response.data.status === 'error' || response.status === "error") {
        yield put(actions.createAdminUserFailure(Object.values(response.data.data)));
    } else {
        yield put(actions.createAdminUserSuccess());
        yield put(actions.addAdminUser(response.data));
    }
}
function* updateAdminUser(id, data) {
    try {
        yield put(actions.updateAdminUserPending());
        const response = yield call(putUpdateAdminUser, id, data);
        yield put(actions.updateAdminUserSuccess());
        yield put(actions.updateAdminUser(response.data.user));
    } catch (err) {
        yield put(actions.updateAdminUserFailure());
    }
}
function* getAdminUser(id) {
    try {
        yield put(actions.getAdminUserPending());
        const response = yield call(getSingleAdminUser, id);
        yield put(actions.getAdminUserSuccess(response.data));
    } catch (err) {
        yield put(actions.getAdminUserFailure());
    }
}
function* deleteAdminUser(id) {
    try {
        yield put(actions.deleteAdminUserPending());
        const response = yield call(deleteSingleAdminUser, id);
        yield put(actions.deleteAdminUserSuccess());
        yield put(actions.deleteAdminUser(id));
    } catch (err) {
        yield put(actions.deleteAdminUserFailure());
    }
}

export {
    getAdminUsers,
    createAdminUser,
    updateAdminUser,
    getAdminUser,
    deleteAdminUser
};