import { all, takeEvery } from "redux-saga/effects";
import {
    fetchKanbanItemsRequest,
    updateKanbanItemRequest,
    deleteKanbanItemRequest
} from "../requests";
import {
    getKanban,
    updateKanban,
    deleteKanban
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchKanbanItems() {
    yield takeEvery(
        convertTypeFromAction(fetchKanbanItemsRequest),
        () => getKanban()
    );
}

function* watchUpdateKanbanItem() {
    yield takeEvery(
        convertTypeFromAction(updateKanbanItemRequest),
        ({ payload: {id, kanbanStatus} }) => updateKanban(id, kanbanStatus)
    );
}
function* watchDeleteKanbanItem() {
    yield takeEvery(
        convertTypeFromAction(deleteKanbanItemRequest),
        ({ payload: id }) => deleteKanban(id)
    );
}

function* saga() {
    yield all([
        watchFetchKanbanItems(),
        watchUpdateKanbanItem(),
        watchDeleteKanbanItem()
    ]);
}

export { saga };
