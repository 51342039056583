import * as React from 'react';
import Button from '@material-ui/core/Button';
import { Menu as MuiMenu } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import useStyles from "./style";
import classnames from "classnames";

const Menu = ({ children, btnClassName, onChange, menuItems, noButton, testId }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onChange();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = e => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
        setAnchorEl(null);
    };

    const handleItemClick = (e, item) => {
        e.preventDefault();
        e.stopPropagation();
        const { onClick } = item;
        onClick();
        handleClose(e);
    }

    return (
        <div>
            {noButton ? <div onClick={handleClick}>{children}</div> : <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={btnClassName}
                test-id={testId}
            >
                <>
                    {children}
                </>
            </Button>}
            <MuiMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                classes={{ paper: classes.menu }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem key={`menu-item-${index}`} test-id={item.testId} className={classnames({ [classes.warning]: item.warningItem})} onClick={(e) => handleItemClick(e, item)}>{item.label}</MenuItem>
                ))}
            </MuiMenu>
        </div >
    );
}

export default Menu;