import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    pagination: {},
    getSubscriptionsStatus: REQUEST_UNDEFINED,
    createSubscriptionStatus: REQUEST_UNDEFINED,
    updateSubscriptionStatus: REQUEST_UNDEFINED,
    getSubscriptionUserStatus: REQUEST_UNDEFINED,
    deleteSubscriptionStatus: REQUEST_UNDEFINED,
    changeTeamOwnerStatus: REQUEST_UNDEFINED,
    resendUserInvitationStatus: REQUEST_UNDEFINED,
    removeTeamMemberStatus: REQUEST_UNDEFINED,
    resendUserInvitationStatus: REQUEST_UNDEFINED,
    resendEmailVerificationStatus: REQUEST_UNDEFINED,
    createTeamMemberStatus: REQUEST_UNDEFINED,
    getTeamStatus: REQUEST_UNDEFINED,
    addExistingUserStatus: REQUEST_UNDEFINED,
    subscriptionProfile: {},
    team: [],
    createSubscriptionMessageFailure: null,
    createTeamMemberMessageFailure: null,
    addExistingUserMessageFailure: null,
    updateSubscriptionFailureMessage: null
};

export const REDUCER_PREFIX = "subscriptions";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getSubscriptionsPending: state => {
            state.getSubscriptionsStatus = REQUEST_PENDING;
        },
        getSubscriptionsSuccess: state => {
            state.getSubscriptionsStatus = REQUEST_SUCCESS;
        },
        getSubscriptionsFailure: state => {
            state.getSubscriptionsStatus = REQUEST_FAILURE;
        },
        setSubscriptions: (state, { payload }) => {
            if (payload.subscriptions.pagination.current_page === 1) {
                state.pool = payload.subscriptions.results;
                state.pagination = payload.subscriptions.pagination;
            } else {
                let stateObj = [...state.pool];
                let newArray = stateObj.concat(payload.subscriptions.results);
                state.pool = newArray;
                state.pagination = payload.subscriptions.pagination;
            }
            // state.pool = payload.subscriptions.results;
        },
        createSubscriptionPending: state => {
            state.createSubscriptionStatus = REQUEST_PENDING;
        },
        createSubscriptionSuccess: state => {
            state.createSubscriptionStatus = REQUEST_SUCCESS;
            state.createSubscriptionMessageFailure = null;
        },
        createSubscriptionFailure: (state, { payload }) => {
            state.createSubscriptionStatus = REQUEST_FAILURE;
            state.createSubscriptionMessageFailure = payload;
        },
        clearCreateSubscriptionMessageFailure: state => {
            state.createSubscriptionMessageFailure = null;
        },
        updateSubscriptionPending: state => {
            state.updateSubscriptionStatus = REQUEST_PENDING;
        },
        updateSubscriptionSuccess: state => {
            state.updateSubscriptionStatus = REQUEST_SUCCESS;
            state.updateSubscriptionFailureMessage = null;
        },
        updateSubscriptionFailure: state => {
            state.updateSubscriptionStatus = REQUEST_FAILURE;
        },
        updateSubscriptionFailureMessage: (state, { payload }) => {
            state.updateSubscriptionStatus = REQUEST_FAILURE;
            state.updateSubscriptionFailureMessage = payload;
        },
        updateSubscription: (state, { payload }) => {
            state.subscriptionProfile = payload;
            state.team = payload.team.team_members;
        },
        addSubscription: (state, { payload }) => {
            let subscription = {
                user_email: payload.user.email,
                user_company: payload.user.company,
                plan_type: payload.plan.type,
                plan_name: payload.plan.name,
                plan_id: payload.plan.id
            }
            let stateObj = [...state.pool];
            stateObj.unshift(subscription);
            state.pool = stateObj;
        },
        deleteSubscription: (state, { payload }) => {
            let stateObj = [...state.pool];
            state.pool = stateObj.filter( el => Number(el.plan_id) !== Number(payload) );
        },
        deleteUser: (state, { payload }) => {
            let stateObj = [...state.pool];
            state.pool = stateObj.filter( el => Number(el.user_id) !== Number(payload) );
        },
        getSubscriptionUserPending: state => {
            state.getSubscriptionUserStatus = REQUEST_PENDING;
        },
        getSubscriptionUserSuccess: (state, { payload }) => {
            state.getSubscriptionUserStatus = REQUEST_SUCCESS;
        },
        getSubscriptionUserFailure: state => {
            state.getSubscriptionUserStatus = REQUEST_FAILURE;
        },
        deleteSubscriptionPending: state => {
            state.deleteSubscriptionStatus = REQUEST_PENDING;
        },
        deleteSubscriptionSuccess: state => {
            state.deleteSubscriptionStatus = REQUEST_SUCCESS;
        },
        deleteSubscriptionFailure: state => {
            state.deleteSubscriptionStatus = REQUEST_FAILURE;
        },
        deleteSubscriptionUndefined: state => {
            state.deleteSubscriptionStatus = REQUEST_UNDEFINED;
        },
        addSubscriptionProfile: (state, { payload }) => {
            state.subscriptionProfile = payload;
            state.team = payload.team.team_members;
        },
        changeTeamOwnerPending: state => {
            state.changeTeamOwnerStatus = REQUEST_PENDING;
        },
        changeTeamOwnerSuccess: state => {
            state.changeTeamOwnerStatus = REQUEST_SUCCESS;
        },
        changeTeamOwnerFailure: state => {
            state.changeTeamOwnerStatus = REQUEST_FAILURE;
        },
        changeTeamOwner: (state, { payload }) => {
            state.team = payload.team.team_members;
        },
        resendUserInvitationPending: state => {
            state.resendUserInvitationStatus = REQUEST_PENDING;
        },
        resendUserInvitationSuccess: state => {
            state.resendUserInvitationStatus = REQUEST_SUCCESS;
        },
        resendUserInvitationFailure: state => {
            state.resendUserInvitationStatus = REQUEST_FAILURE;
        },
        removeTeamMemberPending: state => {
            state.removeTeamMemberStatus = REQUEST_PENDING;
        },
        removeTeamMemberSuccess: state => {
            state.removeTeamMemberStatus = REQUEST_SUCCESS;
        },
        removeTeamMemberFailure: state => {
            state.removeTeamMemberStatus = REQUEST_FAILURE;
        },
        removeTeamMember: (state, { payload }) => {
            let stateObj = [...state.team];
            state.team = stateObj.filter( el => Number(el.id) !== Number(payload) );
        },
        resendUserInvitationPending: state => {
            state.resendUserInvitationStatus = REQUEST_PENDING;
        },
        resendUserInvitationSuccess: state => {
            state.resendUserInvitationStatus = REQUEST_SUCCESS;
        },
        resendUserInvitationFailure: state => {
            state.resendUserInvitationStatus = REQUEST_FAILURE;
        },
        resendEmailVerificationPending: state => {
            state.resendEmailVerificationStatus = REQUEST_PENDING;
        },
        resendEmailVerificationSuccess: state => {
            state.resendEmailVerificationStatus = REQUEST_SUCCESS;
        },
        resendEmailVerificationFailure: state => {
            state.resendEmailVerificationStatus = REQUEST_FAILURE;
        },
        createTeamMemberPending: state => {
            state.createTeamMemberStatus = REQUEST_PENDING;
        },
        createTeamMemberSuccess: state => {
            state.createTeamMemberStatus = REQUEST_SUCCESS;
            state.createTeamMemberMessageFailure = null;
        },
        createTeamMemberFailure: (state, { payload }) => {
            state.createTeamMemberStatus = REQUEST_FAILURE;
            state.createTeamMemberMessageFailure = payload;
        },
        addExistingUserPending: state => {
            state.addExistingUserStatus = REQUEST_PENDING;
        },
        addExistingUserSuccess: state => {
            state.addExistingUserStatus = REQUEST_SUCCESS;
            state.addExistingUserMessageFailure = null;
        },
        addExistingUserFailure: (state, { payload }) => {
            state.addExistingUserStatus = REQUEST_FAILURE;
            state.addExistingUserMessageFailure = payload;
        },
        clearAddExistingUserMessageFailure: state => {
            state.addExistingUserMessageFailure = null;
        },
        clearCreateTeamMemberMessageFailure: state => {
            state.createTeamMemberMessageFailure = null;
        },
        addTeamMember: (state, { payload }) => {
            let stateObj = [...state.team];
            stateObj.unshift(payload.user);
            state.team = stateObj;
        },
        getTeamPending: state => {
            state.getTeamStatus = REQUEST_PENDING;
        },
        getTeamSuccess: state => {
            state.getTeamStatus = REQUEST_SUCCESS;
        },
        getTeamFailure: state => {
            state.getTeamStatus = REQUEST_FAILURE;
        },
        setTeam: (state, { payload }) => {
            state.team = payload.users.results;
        },
    }
});
export { reducer, actions };