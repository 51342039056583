import {put, call, select} from "redux-saga/effects";
import {actions} from "../slice";
import {
    getKanbanItems,
    updateKanbanItem,
    deleteKanbanItem
} from "./helpers";

function* getKanban() {
    try {
        yield put(actions.getKanbanItemsPending());
        const response = yield call(getKanbanItems);
        yield put(actions.setKanbanItems(response));
        yield put(actions.getKanbanItemsSuccess());
    } catch (err) {
        yield put(actions.getKanbanItemsFailure());
    }
}

function* updateKanban(id, kanbanStatus) {
    try {
        yield put(actions.updateKanbanItemPending());
        const response = yield call(updateKanbanItem, {id, kanbanStatus});
        yield put(actions.updateKanbanItemSuccess());
    } catch (err) {
        yield put(actions.updateKanbanItemFailure());
    }
}

function* deleteKanban(id) {
    try {
        yield put(actions.deleteKanbanItemPending());
        const response = yield call(deleteKanbanItem, id);
        yield put(actions.deleteKanbanItemSuccess());
    } catch (err) {
        yield put(actions.deleteKanbanItemFailure());
    }
}

export {
    getKanban,
    updateKanban,
    deleteKanban
};
