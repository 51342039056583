import { DragSource, DropTarget } from 'react-dnd';
import classnames from 'classnames';
import _ from 'lodash';
//Hooks
import useStyles from "./style";
//Components
import CollapseComponent from '../CollapseComponent/CollapseComponent';
import StartupCard from '../../pages/IndustriesBoard/components/StartupCard/StartupCard';
import KanbanCard from "../../pages/Kanban/components/KanbanCard/KanbanCard";

export function Card(props) {
    const classes = useStyles();
    //NOTE: all commented code here is for multiple selection of cards in board
    // const selectCardClick = (e) => {
    //     props.onSelectionChange(props.id, e.metaKey, e.shiftKey);
    // };
    // const isCardSelected = () => {
    //     return props.selectedCardIds && props.selectedCardIds.filter(f => f === props.id).length > 0;
    // }
    return _.flowRight(props.connectDragSource, props.connectDropTarget)(
        <div
            className={
                classnames([classes.card, { [classes.cardDragging]: props.isDragging, [classes.cardSpacer]: props.isSpacer }])
            }
            test-id={`card-${props.testId}`}
            id={`card-${props.id}`}
        >
            {
                props.data && props.isKanban ?
                <>
                    <KanbanCard
                        justDropped={props.justDropped}
                        fetchMlDone={props.fetchMlDone}
                        fetchMlFailed={props.fetchMlFailed}
                        isIndustry={props.isIndustry}
                        id={props.id}
                        columnId={props.columnId}
                        item={props.data} />
                </> :
                <>
                    <CollapseComponent data={props.data}>
                        <StartupCard isIndustry={props.isIndustry} id={props.id} company={props.data} />
                    </CollapseComponent>
                </>
            }
        </div >
    );
}

export const DraggableCard = _.flowRight([
    DropTarget(
        'Card',
        {
            drop(props, monitor) {
                const { columnId, columnIndex } = props;
                const draggingItem = monitor.getItem();

                if (draggingItem && draggingItem.id !== props.id) {
                    // bug when dropping an item in a column without items
                    if (props.isSpacer) {
                        setTimeout(() => {
                            props.moveCard(draggingItem.id, columnId, columnIndex);
                        }, 0)
                    } else {
                        props.moveCard(draggingItem.id, columnId, columnIndex);
                    }
                }
            },
        },
        connect => ({
            connectDropTarget: connect.dropTarget(),
        })
    ),
    DragSource(
        'Card',
        {
            beginDrag(props) {
                return { id: props.id };
            },

            isDragging(props, monitor) {
                return props.id === monitor.getItem().id;
            },
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        })
    ),
])(Card);
