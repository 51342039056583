import React from "react";
import * as classnamesArray from 'classnames'
// UI components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
// Hooks
import { useStyles } from "./style";
import { useTranslation } from 'react-i18next';

const Modal = ({
        onClose,
        classnames,
        dialogOpened = false,
        children,
        background,
        maxWidth,
        borderRadius,
        closeButtonIcon,
        paddingSpace,
        persistent,
        maxHeight,
        minHeight,
        disableEscapeKeyDown,
        dialogContentClassName,
        dialogFilterClassName
    }) => {
    const classes = useStyles({ background, maxWidth, borderRadius, paddingSpace, maxHeight, minHeight });
    const { t } = useTranslation();
    return (
        <Dialog
            maxWidth="lg"
            disableEscapeKeyDown={disableEscapeKeyDown}
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classnamesArray([classes.dialogFilter, dialogFilterClassName]), scrollPaper: classes.dialogScrollPaper, ...classnames }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' && !persistent) {
                    onClose(event, reason);
                }
            }}
            open={dialogOpened}>
            {dialogOpened && !persistent ? (
                <>{closeButtonIcon && <IconButton className={classes.closeButton} onClick={onClose}>
                    <img src={closeButtonIcon} alt={t('outcome.step2')} className={classes.stepIcon} />
                </IconButton>}</>
            ) : null}
            <MuiDialogContent className={classnamesArray([classes.dialogContent, dialogContentClassName])}>
                {children}
            </MuiDialogContent>
        </Dialog >
    );

}
export default Modal;