import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
    root: {
        padding: spacing(4),
        background: palette.common.white,
        maxWidth: 1146,
        margin: "0 auto"
    },
    button: {
        display: "block"
    },
    cell: {
        padding: `${spacing(2)}px ${spacing(1)}px`,
        border: "none",
        lineHeight: "initial"
    },
    headRow: {
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderBottom : `1px solid ${palette.text.backgroundMain}`
    },
    row: {
        borderRadius: `${spacing(2)}px`,
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderTop: `1px solid ${palette.text.backgroundMain}`,
        "&:hover": {
            background: palette.primary.backgroundMedium,
            cursor: "pointer"
        }
    },
    avatar: {
        marginRight: spacing(6),
        width: 72,
        height: 72
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    info: {
        display: "flex",
        paddingTop: spacing(3)
    },
    sector: {
        marginRight: spacing(1)
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: spacing(4)
    }
}));