const live = [
    {
        name: 'Unspecified',
        value: null
    },
    {
        name: 'France central',
        value: 'francecentral'
    },
    {
        name: 'UK south',
        value: 'uksouth'
    }
];
const staging = [
    {
        name: 'Unspecified',
        value: null
    },
    {
        name: 'Canada east',
        value: 'canadaeast'
    },
];

export const getLlmRegions = () => {
    if (process.env.REACT_APP_ENV === 'prod') {
        return live;
    } else {
        return staging;
    }
}