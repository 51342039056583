const getKanbanItemsStateSelector = state => state.kanban.items;
const getKanbanItemsStatusSelector = state => state.kanban.getKanbanItemsStatus;
const updateKanbanItemStatusSelector = state => state.kanban.updateKanbanItemStatus;
const deleteKanbanItemStatusSelector = state => state.kanban.deleteKanbanItemStatus;

export {
    getKanbanItemsStateSelector,
    getKanbanItemsStatusSelector,
    updateKanbanItemStatusSelector,
    deleteKanbanItemStatusSelector
};
