import { all, takeEvery } from "redux-saga/effects";
import {
    getUsersRequest,
    createUserRequest,
    updateUserRequest,
    getUserRequest,
    deleteUserRequest
} from "../requests";
import {
    getUsers,
    createUser,
    updateUser,
    getUser,
    deleteUser
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetUsers() {
    yield takeEvery(
        convertTypeFromAction(getUsersRequest),
        ({ payload: { search, sortBy, sortDirection, perPage } }) => getUsers(search, sortBy, sortDirection, perPage)
    );
}

function* watchCreateUser() {
    yield takeEvery(
        convertTypeFromAction(createUserRequest),
        ({ payload: { data } }) => createUser(data)
    );
}
function* watchUpdateUser() {
    yield takeEvery(
        convertTypeFromAction(updateUserRequest),
        ({ payload: { id, data } }) => updateUser(id, data)
    );
}

function* watchGetUser() {
    yield takeEvery(
        convertTypeFromAction(getUserRequest),
        ({ payload: { id } }) => getUser(id)
    );
}

function* watchDeleteUser() {
    yield takeEvery(
        convertTypeFromAction(deleteUserRequest),
        ({ payload: { id, planId, planType } }) => deleteUser(id, planId, planType)
    );
}

function* saga() {
    yield all([
        watchGetUsers(),
        watchCreateUser(),
        watchUpdateUser(),
        watchGetUser(),
        watchDeleteUser()
    ]);
}

export { saga };