import { all, takeEvery } from "redux-saga/effects";
import {
    getSubscriptionsRequest,
    createSubscriptionRequest,
    updateSubscriptionRequest,
    getSubscriptionUserRequest,
    deleteSubscriptionRequest,
    changeTeamOwnerRequest,
    resendUserInvitationRequest,
    removeTeamMemberRequest,
    createTeamMemberRequest,
    resendEmailVerificationRequest,
    getTeamRequest,
    addExistingUserRequest
} from "../requests";
import {
    getSubscriptions,
    createSubscription,
    updateSubscription,
    getSubscriptionUser,
    deleteSubscription,
    changeTeamOwner,
    resendUserInvitation,
    removeTeamMember,
    createTeamMember,
    resendEmailVerification,
    getTeam,
    addExistingUser
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetSubscriptions() {
    yield takeEvery(
        convertTypeFromAction(getSubscriptionsRequest),
        ({ payload: { search, page, userId } }) => getSubscriptions(search, page, userId)
    );
}

function* watchCreateSubscription() {
    yield takeEvery(
        convertTypeFromAction(createSubscriptionRequest),
        ({ payload: { data } }) => createSubscription(data)
    );
}
function* watchUpdateSubscription() {
    yield takeEvery(
        convertTypeFromAction(updateSubscriptionRequest),
        ({ payload: { id, type, data } }) => updateSubscription(id, type, data)
    );
}

function* watchGetSubscriptionUser() {
    yield takeEvery(
        convertTypeFromAction(getSubscriptionUserRequest),
        ({ payload: { id, type } }) => getSubscriptionUser(id, type)
    );
}

function* watchDeleteSubscription() {
    yield takeEvery(
        convertTypeFromAction(deleteSubscriptionRequest),
        ({ payload: { planId, planType } }) => deleteSubscription(planId, planType)
    );
}

function* watchChangeTeamOwner() {
    yield takeEvery(
        convertTypeFromAction(changeTeamOwnerRequest),
        ({ payload: { teamId, ownerId, planId, planType } }) => changeTeamOwner(teamId, ownerId, planId, planType)
    );
}

function* watchResendUserInvitation() {
    yield takeEvery(
        convertTypeFromAction(resendUserInvitationRequest),
        ({ payload: { teamId, userEmail } }) => resendUserInvitation(teamId, userEmail)
    );
}

function* watchResendEmailVerification() {
    yield takeEvery(
        convertTypeFromAction(resendEmailVerificationRequest),
        ({ payload: { teamId, userEmail } }) => resendEmailVerification(teamId, userEmail)
    );
}

function* watchCreateTeamMemberRequest() {
    yield takeEvery(
        convertTypeFromAction(createTeamMemberRequest),
        ({ payload: { teamId, data } }) => createTeamMember(teamId, data)
    );
}

function* watchAddExistingUserRequest() {
    yield takeEvery(
        convertTypeFromAction(addExistingUserRequest),
        ({ payload: { teamId, email } }) => addExistingUser(teamId, email)
    );
}

function* watchRemoveTeamMember() {
    yield takeEvery(
        convertTypeFromAction(removeTeamMemberRequest),
        ({ payload: { teamId, userId, userEmail, planId, planType } }) => removeTeamMember(teamId, userId, userEmail, planId, planType)
    );
}

function* watchGetTeam() {
    yield takeEvery(
        convertTypeFromAction(getTeamRequest),
        ({ payload: { search, teamId } }) => getTeam(search, teamId)
    );
}

function* saga() {
    yield all([
        watchGetSubscriptions(),
        watchCreateSubscription(),
        watchUpdateSubscription(),
        watchGetSubscriptionUser(),
        watchDeleteSubscription(),
        watchChangeTeamOwner(),
        watchResendUserInvitation(),
        watchRemoveTeamMember(),
        watchResendEmailVerification(),
        watchCreateTeamMemberRequest(),
        watchGetTeam(),
        watchAddExistingUserRequest()
    ]);
}

export { saga };