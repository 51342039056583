import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Hooks
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../../common/useRouterHook.js";
//UI Components
import Button from "../../../../components/Button/Button"
import Link from '../../../../components/Link/Link';
//Assets
import ArrowLeft from '../../../../assets/icons/ArrowLeft.png';


const Header = ({ user, technologyId, previewPage = false }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const goBack = () => {
        openPage(previewPage ? `/technologies-profile/${technologyId}` : '/technologies');
    };

    return (
        <div>
            <div className={classes.flexSpaceCenter}>
                <Link onClick={() => goBack()} variant="body2" rotateIcon color="secondary" className={classes.goBack}>
                    {t('technologyProfile.goBack')}
                    <img src={ArrowLeft} />
                </Link>

                <div className={classes.flexRelative}>
                    {
                        !previewPage &&
                        <>
                            <Button
                                test-id="open-client-view"
                                disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}
                                onClick={() => openPage(`/technologies-profile/${technologyId}/preview`)}
                                className={classes.shareButton}
                                variant="primary">
                                {t("technologyProfile.openClientView")}
                            </Button>
                        </>
                    }
                </div>
            </div>
        </div>
    )

}
export default Header;
