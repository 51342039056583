import React from 'react';
import { useStyles } from './style.js';
import classnames from 'classnames';

const Tag = React.forwardRef(({ size, variant, children, className, onClick, clickable }, ref) => {
    const classes = useStyles();
    let clickableClass = {};
    if (clickable !== undefined) {
        if (clickable) {
            clickableClass = classes.link;
        } else {
            clickableClass = classes.notClickable;
        }
    }
    return (
        <div
            ref={ref}
            className={classnames([
                classes.root,
                classes[size],
                classes[variant],
                clickableClass,
                className
            ])}
            onClick={onClick}>
            {children}
        </div>
    );
});

Tag.defaultProps = {
    size: 'small',
    variant: 'root'
};

export default Tag;
