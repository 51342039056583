import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
    root: {
        padding: `${spacing(4)}px ${spacing(5)}px`,
        maxWidth: 1146,
        margin: "0 auto"
    },
    goBack: {
        marginBottom: spacing(4),
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontFamily: "GT Flexa Mono, sans-serif",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    card: {
        borderRadius: spacing(3),
        background: palette.common.white,
        padding: `${spacing(4)}px !important`
    },
    goBackIcon: {
        stroke: palette.secondary.dark,
        transform: "rotate(180deg)",
        marginRight: spacing(1),
        width: 20,
        height: 24
    }
}));