import React, { useState, useEffect } from 'react';
import './index.css';
import './i18n';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
//Pages
import SignIn from './pages/Authentication/SignIn/SignIn';
import Layout from './pages/Layout/Layout';
import Technologies from './pages/Technologies/Technologies';
import TechnologyPreview from './pages/TechnologyPreview/TechnologyPreview';
import CompanyPreview from './pages/CompanyPreview/CompanyPreview';
import CompanyPreview2 from './pages/CompanyPreview/CompanyPreview2';
import IndustryProfile from './pages/IndustryProfile/IndustryProfile';
import Industries from './pages/Industries/Industries';
import Users from './pages/Users/Users';
import Profiles from './pages/Profiles/Profiles';
import AccountProfile from './pages/Profiles/AccountProfile';
import AddIndustry from './pages/AddIndustry/AddIndustry';
import IndustriesBoard from './pages/IndustriesBoard/IndustriesBoard';
// Hooks
import { useCookiesHook } from './common/useCookiesHook';
// Redux
import { REQUEST_SUCCESS, REQUEST_FAILURE } from './constants/statuses';
import TechnologiesBoard from './pages/TechnologiesBoard/TechnologiesBoard';
import TechnologiesProfile from './pages/TechnologiesProfile/TechnologiesProfile';
import Kanban from './pages/Kanban/Kanban';

export default function App() {
    const { getCookieStatus } = useCookiesHook();
    const [sanctumCookieResponse, setSancutumCookieResponse] = useState(false);
    useEffect(() => {
        if (getCookieStatus === REQUEST_SUCCESS || getCookieStatus === REQUEST_FAILURE) {
            setSancutumCookieResponse(true);
        }
    }, [getCookieStatus]);
    return (
        <Router>
            {sanctumCookieResponse && (
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <React.StrictMode>
                        <Switch>
                            <Route exact path="/">
                                <SignIn />
                            </Route>
                            <Route exact path="/technologies">
                                <Layout match="technologies">
                                    <Technologies />
                                </Layout>
                            </Route>
                            <Route exact path="/industries">
                                <Layout white match="industries">
                                    <Industries />
                                </Layout>
                            </Route>
                            <Route exact path="/industries/add">
                                <Layout match="industries/add">
                                    <AddIndustry />
                                </Layout>
                            </Route>
                            <Route path="/industries/:id">
                                <Layout match="industries">
                                    <IndustryProfile />
                                </Layout>
                            </Route>
                            <Route exact path="/industries-board/:id">
                                <Layout match="industries-board">
                                    <IndustriesBoard />
                                </Layout>
                            </Route>
                            <Route exact path="/technologies-profile/:id/technologies-board">
                                <Layout match="technologies-board">
                                    <TechnologiesBoard />
                                </Layout>
                            </Route>
                            <Route exact path="/technologies-profile/:id/preview">
                                <Layout match="technologies-preview">
                                    <TechnologyPreview />
                                </Layout>
                            </Route>
                            <Route exact path="/technologies-profile/:id">
                                <Layout match="technologies-profile">
                                    <TechnologiesProfile />
                                </Layout>
                            </Route>
                            <Route exact path="/company/:slug/preview">
                                <Layout match="company-preview">
                                    <CompanyPreview2 />
                                </Layout>
                            </Route>
                            <Route exact path="/kanban">
                                <Layout white match="kanban">
                                    <Kanban />
                                </Layout>
                            </Route>
                            <Route exact path="/users">
                                <Layout white match="users">
                                    <Users />
                                </Layout>
                            </Route>
                            <Route exact path="/profiles">
                                <Layout white match="profiles">
                                    <Profiles />
                                </Layout>
                            </Route>
                            <Route path="/profiles/:id/:type">
                                <Layout match="profiles">
                                    <AccountProfile />
                                </Layout>
                            </Route>
                        </Switch>
                    </React.StrictMode>
                </ThemeProvider>
            )}
        </Router>
    );
}
