import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialog: {
        padding: spacing(4),
        background: palette.common.white,
        borderRadius: spacing(2)
    },
    dialogFilter: {
        padding: spacing(4),
        background: palette.common.white,
        borderRadius: 16
    },
    dialogScrollPaper: {
        maxWidth: 800,
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
    },

    dialogTitleWrapper: {
        padding: 0,
        paddingLeft: spacing(3)
    },
    cancelButton: {
        background: palette.text.backgroundMain,
        color: palette.text.lighter,
        border: "none",
        marginRight: spacing(2),
        height: spacing(5)
    },
    saveButton: {

    },
    cancelAndCreate: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing(5)
    },
    editorClassName: {
        minHeight: 300,
        border: `1px solid ${palette.text.lighter}`,
        padding: spacing(1),
        resize: 'none',
        borderRadius: spacing(1),

    }
    // textarea: {
    //     borderColor: palette.secondary.dark,
    //     fontFamily: "GT Flexa, sans-serif",
    //     marginBottom: spacing(1),
    //     outline: 'none',
    //     padding: spacing(1),
    //     resize: 'none',
    //     borderRadius: spacing(1),
    //     color: palette.secondary.dark,
    //     width: 480,
    //     "&:focus": {
    //         borderColor: palette.primary.dark,
    //     },
    //     "&::placeholder": {
    //         fontSize: 14,
    //         lineHeight: "18px",
    //         fontFamily: "GT Flexa, sans-serif",
    //         color: palette.text.lighter
    //     }
    // },

}))