import React, { useEffect, useState } from "react";
import classnames from "classnames";
// Hooks
import { useStyles } from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography";
import TechnologyOverview from "../TechnologyOverview/TechnologyOverview";
import CompaniesTechnology from "../CompaniesTechnology/CompaniesTechnology";
import Menu from "../../../../components/Menu/Menu";
import Tag from "../../../../components/Tag/Tag.js";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";
import AddNewTechnologyModal from "../../../Technologies/components/AddNewTechnologyModal/AddNewTechnologyModal.js";
import WarningModal from "../../../Technologies/components/WarningModal/WarningModal.js";
//Assest
import { ReactComponent as MoreIcon } from "../../../../assets/icons/icon-more.svg";
//Hooks
import { useTranslation } from "react-i18next";
import { useRouterHook } from "../../../../common/useRouterHook.js";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { deleteTechnologyRequest } from "../../../../store/technologies/requests.js";
import { deleteTechnologyStatusSelector, getTechnologyStatusSelector } from "../../../../store/technologies/selectors.js";
import { REQUEST_SUCCESS } from "../../../../constants/statuses.js";
import { updateTechnologyRequest, getTechnologyProfileRequest } from "../../../../store/technologies/requests.js";

const Profile = ({ technology, user }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('technology_overview');
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningModalType, setWarningModalType] = useState("");
    const [editData, setEditData] = useState(null);
    const { openPage } = useRouterHook();
    const deleteStatus = useSelector(deleteTechnologyStatusSelector);
    const [unsavedData, setUnsavedData] = useState(null);
    const [isInitialization, setIsInitialization] = useState(true)
    const handleTabSelected = value => {
        setActiveTab(value)
    };
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    };
    const handleClosingDialog = () => {
        setShowAddNewModal(false);
    };
    const handleOnDataEdited = () => {
        setShowAddNewModal(false);
        setShowWarningModal(true);
        setWarningModalType('edit_success');
    };
    const handleClosingWarningModal = type => {
        setShowWarningModal(false);
        if (type === "delete_success") {
            openPage("/technologies");
        } else {
            window.location.reload();
        }
    };
    const handleCancelAction = () => {
        setWarningModalType("");
        setShowWarningModal(false);
    }

    const editProfileData = () => {
        setShowAddNewModal(true);
    };
    const deleteTechnologyProfile = () => {
        setShowWarningModal(true);
        setWarningModalType('delete');
    };
    const handleConfirmAction = (type) => {
        let tech
        switch (type) {
            case "delete":
                tech = { id: technology.id }
                dispatch(deleteTechnologyRequest(tech));
                break;
            case "unsaved":
                tech = { id: unsavedData.id, name: unsavedData.title, ml_description: unsavedData.description };
                unsavedData && unsavedData.id && dispatch(updateTechnologyRequest(tech));
                setShowWarningModal(true);
                setWarningModalType('edit_success');

                break;
        };
    }
    useEffect(() => {
        deleteStatus === REQUEST_SUCCESS && setWarningModalType('delete_success');
    }, [deleteStatus]);
    // useEffect(() => {
    //     getTechnologyStatusSelector === REQUEST_SUCCESS && setIsInitialization(false);
    // }, [getTechnologyStatusSelector]);

    const handleDataChanged = data => {
        setUnsavedData(data)
        setShowWarningModal(true);
        setWarningModalType('unsaved');
    };
    const handleOnSortFilterChange = data => {
        dispatch(getTechnologyProfileRequest({ id: technology.id, data: data.data, clearResults: true }))
    };

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.main}>
                    <div className={classes.wrap}>
                        <Typography variant="subtitle1" color="secondaryDark">{technology.name}</Typography>
                        {user && user.role !== "moderator" && <Menu
                            btnClassName={classes.moreButton}
                            onChange={handleMenuClick}
                            testId={`menu-${technology.slug}`}
                            menuItems={[
                                {
                                    label: t("technologyProfile.edit"),
                                    onClick: () => editProfileData(),
                                    testId: `edit-profile-${technology.slug}`
                                },
                                {
                                    label: t("technologyProfile.editCompaniesKanban"),
                                    onClick: () => openPage(`/technologies-profile/${technology.id}/technologies-board`),
                                    testId: `edit-companies-${technology.slug}`
                                },
                                {
                                    label: t("technologyProfile.deleteTechnology"),
                                    onClick: () => deleteTechnologyProfile(),
                                    testId: `delete-technology-${technology.slug}`
                                },
                            ]}
                        >
                            <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                        </Menu>}
                    </div>
                    <div className={classes.descriptionScores}>
                        <div className={classes.descriptionBox}>
                            <Typography variant="body" className={classes.description} component="div">
                                <div dangerouslySetInnerHTML={{ __html: technology.ml_description }}></div>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.technologies}>
                        <div className={classes.flexColumn}>
                            {technology && technology.sectors.length > 0 && <div>
                                <Typography variant="bodySmall" color="">{t("technologyProfile.relevantIndustries")}</Typography>
                                <div className={classes.flexWrap}>
                                    {technology && technology.sectors.map((tag, i) => (
                                        <Tag key={`sector-${i}`} className={classes.tag}>
                                            <Typography variant="caption" component="span">
                                                {tag.name}
                                            </Typography>
                                        </Tag>
                                    ))}
                                </div>
                            </div>}
                            {technology && technology.subsectors.length > 0 && <div>
                                <Typography variant="bodySmall" color="">{t("technologyProfile.relevantSubindustries")}</Typography>
                                <div className={classes.flexWrap}>
                                    {technology && technology.subsectors.map((tag, i) => (
                                        <Tag key={`sector-${i}`} className={classes.tag} variant="sub">
                                            <Typography variant="caption" component="span">
                                                {tag.name}
                                            </Typography>
                                        </Tag>
                                    ))}
                                </div>
                            </div>}
                        </div>
                    </div>
                    <AddNewTechnologyModal data={technology} dialogOpened={showAddNewModal} onChange={handleClosingDialog} onDataEdited={handleOnDataEdited} dataChanged={handleDataChanged}></AddNewTechnologyModal>
                    <WarningModal data={technology} type={warningModalType} dialogOpened={showWarningModal} onChange={handleClosingWarningModal} onConfirmAction={handleConfirmAction} onCancelAction={handleCancelAction}></WarningModal>
                </div>
                <div className={classes.tabs}>
                    <Typography onClick={() => handleTabSelected('technology_overview')} variant="bodyXl" className={classnames([classes.tab, { [classes.activeTab]: activeTab === "technology_overview" }])}>{t("technologyProfile.overview")}</Typography>
                    <Typography onClick={() => handleTabSelected('companies_technologies')} variant="bodyXl" className={classnames([classes.tab, { [classes.activeTab]: activeTab === "companies_technologies" }])}>{t("technologyProfile.companies")}</Typography>
                </div>
                <div>
                    {activeTab === 'technology_overview' && <TechnologyOverview profile={technology} user={user} />}
                    {activeTab === 'companies_technologies' && <CompaniesTechnology user={user} technology={technology} onSortFilterChange={handleOnSortFilterChange} onFilterChange={handleOnSortFilterChange} />}
                </div>
            </div>
        </div >
    )
}
export default Profile;
