import { makeStyles, createStyles, Typography } from "@material-ui/core";

export default makeStyles(({ palette, spacing, typography }) => ({
    menu: {
        borderRadius: spacing(1),
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        "& > ul": {
            padding: `${spacing(1)}px 0`,
            "& > li": {
                fontFamily: "GT Flexa Mono, sans-serif",
                padding: `${spacing(1)}px ${spacing(3)}px`,
                ...typography.bodyXs,
                minWidth: 140,
                "&$warning": {
                    "&:hover": {
                        backgroundColor: `${palette.text.backgroundLight} !important`,
                        color: palette.error.main
                    }
                }
            }
        }
    },
    warning: {}
}));