import { makeStyles, createStyles } from '@material-ui/core/styles';

function animationFuncLoader() {
    return {
        '0%': {
            // Fix IE 11 wobbly
            transformOrigin: '50% 50%',
        },
        '100%': {
            transform: 'rotate(360deg)',
        }
    };
}

export default makeStyles(({ palette, shape }) => createStyles({
    loader: {
        animation: `$loadingAnimation 1.4s linear infinite`,
        display: "block",
        stroke: palette.common.white,
        "& > circle": {
            animation: `$animationFuncLoaderLarge 1.4s ease-in-out infinite`,
            strokeDasharray: '400px, 800px',
            strokeDashoffset: '0px'
        }
    },
    "@keyframes loadingAnimation": {
        ...animationFuncLoader()
    },
    "@keyframes animationFuncLoaderLarge": {
        '0%': {
            strokeDasharray: '1px, 800px',
            strokeDashoffset: '0px',
        },
        '50%': {
            strokeDasharray: '400px, 800px',
            strokeDashoffset: '-45px',
        },
        '100%': {
            strokeDasharray: '400px, 800px',
            strokeDashoffset: '-425px',
        }
    }
}));
