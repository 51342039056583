import React, { useState, useEffect } from "react";
// Hooks
import { useStyles } from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../../../common/useRouterHook.js";
import { useAuthHook } from "../../../../common/useAuthHook";
import useFilters from "../../../IndustriesBoard/hooks/useFilters";
import { useScrollToTop } from "../../../../common/useScrollToTop";
import useResults from "./hooks/useResults";
//Components
import Card from "../Card/Card";
import Header from "./Header/Header";
import Typography from "../../../../components/Typography/Typography";
import ContentLoader from "../../../../components/ContentLoader/ContentLoader";
//Redux
import { useSelector, useDispatch } from 'react-redux';
// import {
//     getFilteringStatusSelector,
//     getFilteringStateSelector,
//     getFilteringTotalCountSelector,
//     getFilterResultsTotalPagesSelector
// } from "../../../../store/filtering/selectors";
import { getSectorsSelector, getSubsectorsSelector } from "../../../../store/sectors/selectors";
import { getCountriesSelector } from "../../../../store/countries/selectors";
// import { getSearchResultsSelector, getSearchResultsTotalCountSelector, getSearchResultsStatusSelector } from "../../../../store/results/selectors";
import { getTechnologyCompaniesSelector, getTechnologyProfileStatus, getTechnologyCompaniesTotalPagesSelector, getTotalCountSelector } from "../../../../store/technologies/selectors"
import { getUserStatusSelector } from "../../../../store/auth/selectors"
//Constants
import {
    stageOptions,
    fundingOptionsMin,
    yearOptionsMin,
    teamSizeOptions,
    valuerScoreOptionsMin
} from "../../../../constants/filters";
import {
    REQUEST_SUCCESS,
    REQUEST_PENDING,
    REQUEST_FAILURE
} from "../../../../constants/statuses";
import { objectHasEmptyValues } from "../../../../utils/objectHasEmptyValues";
//assets
import { ReactComponent as CloseIcon } from '../../../../assets/icons/CloseIcon.svg';
import { ReactComponent as UpArrowResults } from "../../../../assets/icons/UpArrowResults.svg";
import { Shared } from "../../../../utils/shared";


const CompaniesTechnology = ({ technology, onSortFilterChange, onFilterNameValueChange, onFilterChange, user }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const [sortValue, setSortValue] = useState("match");
    const [sortDirection, setSortDirection] = useState("desc");
    const { hasOnlySearchValue } = Shared({});
    // Selectors
    const allSectors = useSelector(getSectorsSelector);
    // const getFilteringStatus = useSelector(getFilteringStatusSelector);
    // const getFilteringState = useSelector(getFilteringStateSelector);
    // const getFilteringTotalCount = useSelector(getFilteringTotalCountSelector);
    // const getFilterResultsTotalPages = useSelector(getFilterResultsTotalPagesSelector);
    // const allResults = useSelector(getSearchResultsSelector);
    const searchResultsTotalCount = useSelector(getTotalCountSelector);
    const searchResultsStatusSelector = useSelector(getTechnologyProfileStatus);
    const getCompaniesSelector = useSelector(getTechnologyCompaniesSelector);
    //State
    const [timeout, setTimeoutVal] = useState(null);
    const [loading, setLoading] = useState(false);

    const [totalCount, setTotalCount] = useState(searchResultsTotalCount);
    const allCountries = useSelector(getCountriesSelector);
    const allSubsectors = useSelector(getSubsectorsSelector);
    const { isAuthenticated } = useAuthHook();
    const [companies, setCompanies] = useState(getCompaniesSelector);
    const [searchResults, setSearchResults] = useState(companies);
    const { scrollToTop, upArrowVisible } = useScrollToTop();
    const slug = technology && technology.slug;
    const getMeStatus = useSelector(getUserStatusSelector);
    const totalPagesSelector = useSelector(getTechnologyCompaniesTotalPagesSelector)
    const [totalPages, setTotalPages] = useState(totalPagesSelector);
    const [page, setPage] = useState(1);
    const allResults = [];
    const [isSearching, setIsSearching] = useState(false);

    // Filtering
    const {
        filters,
        selectedFilters,
        setDisplayedFilters,
        filterNameValue,
        resetFilters,
        handleSectorChange,
        handleFundingMinChange,
        handleYearMinChange,
        handleValuerScoreMinChange,
        resetFilter,
        setModalFilters,
        setSelectedFilters,
        displayedFilters,
        fundingOptionsMax,
        valuerScoreOptionsMax,
        yearOptionsMax,
        handleFieldChange,
        handleFilterRemove,
        handleShowResults,
        handleShowFilters,
    } = useFilters({
        allSectors,
        sortValue,
        sortDirection,
        page,
        onFilterNameValueChange
    });
    const handleSortChange = (sortValue, sortDirection) => {
        setSortValue(sortValue);
        setSortDirection(sortDirection);
        setPage(1);
    };
    const handleOnSearch = val => {
        setLoading(true);
        clearTimeout(timeout);
        setTimeoutVal(
            setTimeout(() => {
                setDisplayedFilters(prevDisplayedFilters => {
                    return {
                        ...prevDisplayedFilters,
                        search: val
                    }
                });
                setSelectedFilters(prevSelectedFilters => {
                    return {
                        ...prevSelectedFilters,
                        search: val
                    }
                });
                setLoading(false);
            }, 500)
        );
    };
    useEffect(() => {
        getCompaniesSelector && setCompanies(getCompaniesSelector);
    }, [getCompaniesSelector]);
    useEffect(() => {
        setSearchResults(companies);
        getTotalCountSelector && setTotalCount(searchResultsTotalCount);
    }, [getTechnologyCompaniesTotalPagesSelector, companies, getTotalCountSelector]);
    useEffect(() => {
        const data = { orderBy: sortValue, orderDirection: sortDirection, page };
        setIsSearching(false);
        onSortFilterChange({ data })
    }, [sortValue, sortDirection]);
    // useEffect(() => {
    //     if (companies.length > 0 && page !== undefined) {
    //         setIsSearching(true);
    //         const data = !!filterNameValue ? { filterNameValue: filterNameValue, orderBy: sortValue, orderDirection: sortDirection } :
    //             { orderBy: sortValue, orderDirection: sortDirection, page };
    //         console.log("onFilterChange");
    //         onFilterChange({ data });
    //     }
    //     console.log("filterNameValue", filterNameValue)
    // }, [filterNameValue]);


    const {
        isFetching,
        pageLoading,
    } = useResults({
        technology,
        totalCount,
        searchResults,
        isAuthenticated,
        getMeStatus,
        page,
        totalPages,
        filterNameValue,
        setPage,
        sortValue,
        sortDirection,
        isSearching
    });
    return (
        <div>
            <Header
                profile={technology}
                onSearch={handleOnSearch}
                onFilterSelected={handleShowFilters}
                searchResults={searchResults}
                searchResultsTotal={searchResults && searchResults.length}
                isAuthenticated={isAuthenticated}
                onSortChange={handleSortChange}
                filters={filters}
                allResults={allResults}
                total={totalCount}
                resetFilters={resetFilters}
                stageOptions={stageOptions}
                fundingOptionsMin={fundingOptionsMin}
                fundingOptionsMax={fundingOptionsMax}
                yearOptionsMin={yearOptionsMin}
                yearOptionsMax={yearOptionsMax}
                teamSizeOptions={teamSizeOptions}
                valuerScoreOptionsMin={valuerScoreOptionsMin}
                valuerScoreOptionsMax={valuerScoreOptionsMax}
                handleFieldChange={handleFieldChange}
                handleSectorChange={handleSectorChange}
                resetFilter={resetFilter}
                handleFundingMinChange={handleFundingMinChange}
                handleYearMinChange={handleYearMinChange}
                handleValuerScoreMinChange={handleValuerScoreMinChange}
                allSectors={allSectors}
                allSubsectors={allSubsectors}
                selectedFilters={selectedFilters}
                allCountries={allCountries}
                onShowResults={handleShowResults}
                displayedFilters={displayedFilters}
                setSelectedFilters={setSelectedFilters}
                setDisplayedFilters={setDisplayedFilters}
                setModalFilters={setModalFilters}
                user={user}
            />
            {/* Displayed filters */}
            {displayedFilters && !objectHasEmptyValues(displayedFilters) && !hasOnlySearchValue(displayedFilters) && <div className={classes.appliedFiltersWrap} >
                <Typography variant="body2">{t("results.appliedResults")}</Typography>
                <div className={classes.appliedFilters}>
                    {
                        Object.keys(displayedFilters).map((filter, i) => {
                            return displayedFilters[filter] && filter !== "search" && <div className={classes.filterTag} key={`filter-${i}`}><Typography variant="body2" test-id={`filter-${i}`}>{displayedFilters[filter]}<CloseIcon onClick={() => handleFilterRemove(filter)} className={classes.closeIcon} /></Typography></div>
                        })
                    }
                </div >

            </div >}
            <div className={classes.resultsContent}>
                {companies && companies.map((company, i) => (
                    <Card
                        cardIndex={i}
                        classes={classes}
                        openPage={openPage}
                        result={company}
                        key={`card-${i}-${company.id}`}
                    />
                ))
                }
                {(pageLoading || loading) && <div className={classes.resultsLoader}><ContentLoader /></div>}
            </div>
            {upArrowVisible && <UpArrowResults onClick={scrollToTop} className={classes.arrowUp} />}
        </div >
    )
};
export default CompaniesTechnology;