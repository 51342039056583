import React, { useState } from 'react';
import { useStyles } from './style';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DialogActions, DialogContent, DialogTitle, Divider, FormLabel } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Loader from '../../../../../components/Loader/Loader';
import CompanyAvatar from "../../../../../assets/icons/Company.svg";
import { companySchema, companyResolver } from './CompanyFormValidator';
import {useForm} from 'react-hook-form';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import Typography from '../../../../../components/Typography/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const AddNewCompanyModal = ({toggleAddNewCompanyModal, techId}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [secondaryLoading, setSecondaryLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [mlCompanies, setMlCompanies] = useState([]);
	const [clickedCompany, setClickedCompany] = useState(null);
	
	const {
		register,
		handleSubmit,
		reset,
		formState: {
			errors
		}
	} = useForm(companyResolver);
	
	const handleGetCompany = async (data) => {
		const isValid = await companySchema.validate(data);
		
		setSearchTerm(data.name);
		
		if(isValid) {
			setLoading(true)
			//axios
			axios
				.get(
					`${
						process.env.REACT_APP_API_URL
					}/api/admin/kanban/company/ml`,
					{
						withCredentials: true,
						params: {
							name: data.name,
							techId: techId
						}
					}
				)
				.then((res) => {
					setLoading(false);
					setMlCompanies([res.data.data])
				})
				.catch(error => {
					setLoading(false);
					if( error.response.status === 404 ) {
						setErrorMsg('Company not found.')
					}
					if( error.response.status === 500 ) {
						setErrorMsg('Whoops, something went wrong. Please try again later.');
					}
				});
			
		}
	}
	
	const handleAddToBoard = (company) => {
		
		setSecondaryLoading(true);
		
		let fd = new FormData();
		fd.append("_id", company._id);
		fd.append("name", company.name);
		fd.append("slug", company.slug);
		fd.append("website", company.website);
		fd.append("description", company.description);
		fd.append("hq_city", company.hq_city);
		fd.append("hq_country", company.hq_country);
		fd.append("investment_stage", company.investment_stage);
		fd.append("number_of_employees", company.number_of_employees);
		fd.append("founded_date", company.founded_date);
		fd.append("total_funding_amount_dollars", company.total_funding_amount_dollars);
		fd.append("sectors", JSON.stringify(company.sectors));
		fd.append("subsectors", JSON.stringify(company.subsectors));
		fd.append("tech_id", techId);
		
		axios.post(
			`${ process.env.REACT_APP_API_URL }/api/admin/technologies/companies/add`, fd,
			{
				withCredentials: true
			}
		)
		.then((res) => {
			setSecondaryLoading(false);
			console.log(res);
			setErrorMsg('');
			company.exists_in_technology = true;
			setClickedCompany(company);
		})
		.catch(error => {
			setSecondaryLoading(false);
			setClickedCompany(null);
			if( error.response.status === 403 || error.response.status === 422 ) {
				console.log(error.response.data);
				setErrorMsg(error.response.data.message);
			}
			if( error.response.status === 500 ) {
				setErrorMsg('Whoops, something went wrong. Please try again later.');
			}
		});
		
	}
	
	const closeAndRefresh = () => {
		toggleAddNewCompanyModal(false);
		window.location.reload();
	}
	
	return (
		<Dialog
			open={() => toggleAddNewCompanyModal(true)}
			onClose={ () => toggleAddNewCompanyModal(false)}
			maxWidth="md"
			fullWidth={true}
			// className={classes.wrapper}
		>
			<DialogTitle onClose={ () => toggleAddNewCompanyModal(false) }>
				<Typography className={classes.title}>Search ML Database for specific company</Typography>
				<IconButton aria-label="close" className={classes.closeBtn} onClick={ () => toggleAddNewCompanyModal(false) }>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<FormControl
					className={classes.content}
					component="form"
					onSubmit={
						handleSubmit(
							(data) => {
								handleGetCompany(data);
								// reset();
							}
						)
					}
				>
					<FormLabel className={classes.inputLabel}>Company name/alias</FormLabel>
					<TextField
						{
							...register('name')
						}
						name="name"
						id="name"
						error={ !!errors.name }
						helperText={
							errors.name ? errors.name.message : null
						}
						type="text"
						variant="outlined"
						color="primary"
						className={classes.inputField} />
					<Button
						type="submit"
						color="primary"
						variant="contained"
						className={classes.submitBtn}
					>
						{
							loading ?
								<Loader /> :
								<span>GO</span>
						}
					</Button>
				</FormControl>
				
				{
					errorMsg && <h2>{ errorMsg }</h2>
				}
				
				{
					!!(mlCompanies && mlCompanies.length) &&
					<div className={classes.resultsBox}>
						
						<Typography variant="bodyXl" color="textLight">Results for "{searchTerm}"</Typography>
						
						<Divider className={classes.divider} variant="middle" />
						
						<List dense className={classes.resultsList}>
							{
								mlCompanies.map( (company) => {
									const labelId = `checkbox-list-secondary-label-${company.name}`;
									return (
										<ListItem key={company.name} button>
											<ListItemAvatar>
												<img alt="avatar" src={CompanyAvatar} />
											</ListItemAvatar>
											<ListItemText id={labelId} primary={company.name} />
											<ListItemSecondaryAction>
												<Button
													color={ company.exists_in_technology ? "success.dark" : "primary"}
													variant="contained"
													className={classes.actionBtn}
													onClick={ () => handleAddToBoard(company) }
													disabled={ company.exists_in_technology }
												>
													{
														secondaryLoading ? 'Loading...' :
															company.exists_in_technology || clickedCompany?.exists_in_technology ?
																'Added' :
																'Add to Kanban'
													}
												</Button>
											</ListItemSecondaryAction>
										</ListItem>
									)
								})
							}
						</List>
					</div>
				}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={ closeAndRefresh }
					variant="contained"
					color="primary"
					className={classes.actionBtn}
				>
					Close and Refresh
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddNewCompanyModal;