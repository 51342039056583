import React, { useState } from "react";
import { stateToHTML } from 'draft-js-export-html';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style.js";
// UI components
import Typography from "../../../../components/Typography/Typography.js";
import ReadMoreModal from "../Profile/ReadMoreModal/ReadMoreModal";
import Button from "../../../../components/Button/Button";
import AddContentModal from "../AddContentModal/AddContentModal.js";
import WarningModal from "../../../Technologies/components/WarningModal/WarningModal.js";

//Assets
import BusinessModel from "../../../../assets/images/company-profile/BusinessModel.png";
import ProductPortfolio from "../../../../assets/images/company-profile/ProductPortfolio.png";
import ValueProposition from "../../../../assets/images/company-profile/ValueProposition.png";
import TechnologyOverviewImg from "../../../../assets/images/company-profile/TechnologyOverview.png";
import MarketOpportunities from "../../../../assets/images/company-profile/MarketOpportunities.png";
import Achievements from "../../../../assets/images/company-profile/Achievements.png";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { updateTechnologyRequest, updateTechnologyEditorRequest } from "../../../../store/technologies/requests.js"

const TechnologyOverview = ({ profile, user }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { detailed_description, benefits, applications, maturity, market_opportunities, sustainability, } = profile;
    const [showReadMoreModal, setShowReadMoreModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalValue, setModalValue] = useState("");
    const [showAddContentModal, setShowAddContentModal] = useState(false);
    const [textData, setTextData] = useState("");
    const [warningModalType, setWarningModalType] = useState("");
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [editingBlockName, setEditingBlockName] = useState("");
    const [isHTMLType, setIsHTMLType] = useState(false);
    const [unsavedData, setUnsavedData] = useState(null);
    const dispatch = useDispatch();
    const closeReadMoreModal = () => {
        setShowReadMoreModal(false)
    };
    const handleClosingWarningModal = () => {
        setShowWarningModal(false);
        setShowAddContentModal(false)
    };
    const readMore = value => {
        setIsHTMLType(false);
        switch (value) {
            case "Detailed description":
                setModalTitle("Detailed description");
                setModalValue(detailed_description ? detailed_description : "");
                setShowReadMoreModal(true);
                break;
            case "Technology benefits":
                setModalTitle("Technology benefits");
                setModalValue(benefits ? benefits : "");
                setShowReadMoreModal(true);
                break;
            case "Applications":
                setModalTitle("Applications");
                setModalValue(applications ? applications : "");
                setShowReadMoreModal(true);
                break;
            case "Maturity":
                setModalTitle("Maturity");
                setModalValue(maturity ? maturity : "");
                setShowReadMoreModal(true);
                break;
            case "Market opportunities":
                setModalTitle("Market opportunities");
                setModalValue(market_opportunities ? market_opportunities : "");
                setShowReadMoreModal(true);
                break;
            case "Sustainability":
                setModalTitle("Sustainability");
                setModalValue(sustainability ? sustainability : "");
                setShowReadMoreModal(true);
                break;
        }
    };
    const openAddContentModal = value => {
        let { type, data, property } = value;
        setEditingBlockName(property);
        if (type === "edit") {
            setTextData(data);
        } else {
            setTextData("");
        }
        setShowAddContentModal(true)
    };
    const closeAddContentModal = () => {
        setShowAddContentModal(false);
    };
    const handleSavingData = data => {
        let tech = { id: profile.id };
        tech[editingBlockName] = JSON.stringify(data);
        dispatch(updateTechnologyEditorRequest(tech));
        setShowAddContentModal(false);
    };
    const handleOnPreview = data => {
        let dataToHtml = stateToHTML(data.getCurrentContent());
        setIsHTMLType(true);
        switch (editingBlockName) {
            case "detailed_description":
                setModalTitle("Detailed description");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
            case "benefits":
                setModalTitle("Technology benefits");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
            case "applications":
                setModalTitle("Applications");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
            case "maturity":
                setModalTitle("Maturity");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
            case "market_opportunities":
                setModalTitle("Market opportunities");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
            case "sustainability":
                setModalTitle("Sustainability");
                setModalValue(dataToHtml);
                setShowReadMoreModal(true);
                break;
        }
    };

    const handleDataChanged = data => {
        setUnsavedData(data)
        setShowWarningModal(true);
        setWarningModalType('unsaved');
    };
    const handleCancelAction = () => {
        setUnsavedData(null);
        setWarningModalType("");
        setShowWarningModal(false);
    };
    const handleConfirmAction = (val) => {
        let tech = {};
        switch (val) {
            case "unsaved":
                let tech = { id: profile.id };
                tech[editingBlockName] = JSON.stringify(unsavedData);
                dispatch(updateTechnologyEditorRequest(tech));
                setShowAddContentModal(false);
                setShowWarningModal(true);
                setWarningModalType('edit_success');
                break;
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.flexWrap}>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={BusinessModel} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.detailedDescription")}</Typography>
                    {!detailed_description ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "detailed_description" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: detailed_description ? detailed_description : "", property: "detailed_description" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}

                </div>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={ValueProposition} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.technologyBenefits")}</Typography>
                    {!benefits ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "benefits" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: benefits ? benefits : "", property: "benefits" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}
                </div>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={ProductPortfolio} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.applications")}</Typography>
                    {!applications ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "applications" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: applications ? applications : "", property: "applications" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}
                </div>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={TechnologyOverviewImg} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.maturity")}</Typography>
                    {!maturity ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "maturity" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: maturity ? maturity : "", property: "maturity" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}
                </div>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={MarketOpportunities} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.marketOpportunities")}</Typography>
                    {!market_opportunities ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "market_opportunities" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: market_opportunities ? market_opportunities : "", property: "market_opportunities" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}
                </div>
                <div className={classes.block}>
                    <div className={classes.imgBlock}>
                        <img src={Achievements} alt="" />
                    </div>
                    <Typography className={classes.blockTitle} variant="subtitle1" component="div">{t("technologyProfile.sustainability")}</Typography>
                    {!sustainability ? <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'add', property: "sustainability" })} variant="secondary">{t("technologyProfile.addContent")}</Button> : <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={() => openAddContentModal({ type: 'edit', data: sustainability ? sustainability : "", property: "sustainability" })} variant="tertiary">{t("technologyProfile.editContent")}</Button>}
                </div>
            </div>
            <ReadMoreModal isHtml={isHTMLType} dialogOpened={showReadMoreModal} onChange={closeReadMoreModal} title={modalTitle} value={modalValue} goNext={readMore}></ReadMoreModal>
            <AddContentModal
                dataChanged={handleDataChanged}
                data={textData}
                technologyId={profile.id}
                dialogOpened={showAddContentModal}
                onChange={closeAddContentModal}
                onDataSaved={handleSavingData}
                onPreview={handleOnPreview} />
            <WarningModal data={profile} type={warningModalType} dialogOpened={showWarningModal} onChange={handleClosingWarningModal} onConfirmAction={handleConfirmAction} onCancelAction={handleCancelAction}></WarningModal>
        </div>
    )
}
export default TechnologyOverview;
