import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { capitalize } from "@material-ui/core";
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useSelector, useDispatch } from 'react-redux';
import { useAuthHook } from "../../common/useAuthHook";
// Components
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "../../components/Typography/Typography";
import Card from "../../components/Card/Card";
import Menu from "../../components/Menu/Menu";
import Multiselect from '../../components/Multiselect/Multiselect';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ClickAwayListener from 'react-click-away-listener';
import NoAvatarUser from '../../assets/icons/AvatarDefault.png';
import ProfileLockImage from "../../assets/icons/ProfileLockImage.png";
import AddNewUserModal from "./AddNewUserModal/AddNewUserModal";
import AddExistingUserModal from "./AddExistingUserModal/AddExistingUserModal";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
// Assets
import { ReactComponent as KeyboardArrowDownIcon } from "../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as SearchPlaceholderIcon } from "../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/IconMore.svg";
import CompanyProfileIllustration from '../../assets/icons/CompanyProfileIllustration.svg';
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDownGrey.svg";
import { ReactComponent as MinusBig } from "../../assets/icons/MinusBig.svg";
import { ReactComponent as PlusBig } from "../../assets/icons/PlusBig.svg";
import { ReactComponent as Minus } from "../../assets/icons/Minus.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import ArrowLeft from "../../assets/icons/ArrowLeft.png";
// Redux
import {
    getSubscriptionUserRequest,
    updateSubscriptionRequest,
    deleteSubscriptionRequest,
    changeTeamOwnerRequest,
    removeTeamMemberRequest,
    resendEmailVerificationRequest,
    resendUserInvitationRequest,
    getTeamRequest
} from "../../store/subscriptions/requests";
import {
    getSubscriptionProfile,
    updateSubscriptionStatusSelector,
    deleteSubscriptionStatusSelector,
    getTeamSelector,
    resendUserInvitationStatusSelector,
    resendEmailVerificationStatusSelector,
    updateSubscriptionFailureMessageSelector,
    removeTeamMemberStatusSelector
} from "../../store/subscriptions/selectors";
import { REQUEST_SUCCESS, REQUEST_PENDING } from "../../constants/statuses";
import { getLlmRegions } from "../../constants/llmRegions";

const Row = ({ row, classes, t, isEditAllowed, team, plan, dispatch, handleDeleteUser, user }) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const resendUserInvitationStatus = useSelector(resendUserInvitationStatusSelector);
    const resendEmailVerificationStatus = useSelector(resendEmailVerificationStatusSelector);
    const [resendVerificationLoading, setResendVerificationLoading] = useState({
        loading: false,
        rowId: null
    })
    
    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    };

    const handleResendInvitation = () => {
        dispatch(resendUserInvitationRequest({ teamId: team.id, userEmail: row.email }));
    }

    const handleResendEmailVerification = (rowId) => {
        if (resendEmailVerificationStatus !== REQUEST_PENDING) {
            setResendVerificationLoading({
                loading: true,
                rowId
            })
            dispatch(resendEmailVerificationRequest({ teamId: team.id, userEmail: row.email }));
        }
    }
    
    useEffect(() => {
        resendEmailVerificationStatus !== REQUEST_PENDING &&
        setResendVerificationLoading({
            loading: false,
            rowId: null
        })
    }, [resendEmailVerificationStatus])
    
    return (
            <TableRow key={row.id} className={classes.tableRow}>
                <TableCell align="left" component="th" scope="row" className={classes.cellAvatarWrap}>
                    <div className={classes.teamUserAvatar}>
                        <img src={NoAvatarUser} alt={row.name} />
                    </div>
                </TableCell>
                <TableCell align="left">
                    <Typography variant="bodyButton" color="secondaryDark" component="div">
                        {row.name}
                    </Typography>
                    <Typography className={classes.email} variant="bodyButton" color="textLight" component="div">
                        {row.email}
                    </Typography>
                </TableCell>
                <TableCell align="left" className={classes.hideOnMobile}>
                    <Typography variant="bodyButton" color="secondaryDark" component="div">{row.role ? capitalize(row.role) : "User"}</Typography>
                </TableCell>
                <TableCell align="left" className={classes.hideOnMobile}>
                    {isEditAllowed && <>
                        {
                            row.pending_invitation &&
                                <Button
                                    loading={resendUserInvitationStatus === REQUEST_PENDING}
                                    onClick={handleResendInvitation}
                                    variant="success">
                                    {t('accountProfile.resendInvite')}
                                </Button>
                        }
                        {
                            !row.email_verified && row.id &&
                                <Button
                                    loading={resendVerificationLoading.loading && resendVerificationLoading.rowId === row.id}
                                    onClick={() => handleResendEmailVerification(row.id)}
                                    variant="success">
                                    {t('accountProfile.resendValidation')}
                                </Button>
                        }
                    </>}
                </TableCell>
                <TableCell align="left" className={classes.cellMoreWrap}>
                    {isEditAllowed && row.role !== "owner" && <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        menuItems={user && (user.role === "super_admin" || user.role === "admin") ? [
                            {
                                label: t("users.makeAdmin"),
                                onClick: () => dispatch(changeTeamOwnerRequest({ teamId: team.id, ownerId: row.id, planId: plan.id, planType: plan.type }))
                            },
                            {
                                label: t("users.removeTeamMember"),
                                onClick: handleDeleteUser,
                                warningItem: true
                            }
                        ] : [
                            {
                                label: t("users.makeAdmin"),
                                onClick: () => dispatch(changeTeamOwnerRequest({ teamId: team.id, ownerId: row.id, planId: plan.id, planType: plan.type }))
                            }
                        ]}
                    >
                        <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                    </Menu>}
                </TableCell>
            </TableRow>
    )
}

const AccountProfile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const { openPage } = useRouterHook();
    let { id, type } = useParams();
    const dispatch = useDispatch();
    const profile = useSelector(getSubscriptionProfile);
    const updateSubscriptionStatus = useSelector(updateSubscriptionStatusSelector);
    const deleteSubscriptionStatus = useSelector(deleteSubscriptionStatusSelector);
    const updateSubscriptionFailureMessage = useSelector(updateSubscriptionFailureMessageSelector);
    const removeTeamMemberStatus = useSelector(removeTeamMemberStatusSelector);
    const team = useSelector(getTeamSelector);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const { user } = useAuthHook();
    const [addNewCustomProfileModalOpened, setAddNewCustomProfileModalOpened] = useState(false);
    const [tabView, setTabView] = useState('profile');
    const [isEditAllowed, setIsEditAllowed] = useState(false);
    const [addNewUserModalOpened, setAddNewUserModalOpened] = useState(false);
    const [addExistingUserModalOpened, setAddExistingUserModalOpened] = useState(false);
    const [areYouSureModalOpened, setAreYouSureModalOpened] = useState(false);
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState();
    const [planOptions, setPlanOptions] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [changeName, setChangeName] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [changeCompanyName, setChangeCompanyName] = useState(false);
    const [changeCompanyCountry, setChangeCompanyCountry] = useState(false);
    const [changeCompanyCity, setChangeCompanyCity] = useState(false);
    const [changeLLlmRegion, setChangeLLlmRegion] = useState(false);
    const [changePhoneNumber, setChangePhoneNumber] = useState(false);
    const [profileName, setProfileName] = useState("");
    const [profileEmail, setProfileEmail] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState([true, true, true]);
    const [companyProfileName, setCompanyProfileName] = useState("");
    const [companyProfileCountry, setCompanyProfileCountry] = useState("");
    const [companyProfileCity, setCompanyProfileCity] = useState("");
    const [llmRegionName, setLLlmRegionName] = useState("");
    const [llmRegionValue, setLLlmRegionValue] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [availableRadars, setAvailableRadars] = useState(0);
    const [initialAvailableRadars, setInitialAvailableRadars] = useState(0);
    const [monthlyCredits, setMonthlyCredits] = useState(0);
    const [initialMonthlyCredits, setInitialMonthlyCredits] = useState(0);
    const [topUpCredits, setTopUpCredits] = useState(0);
    const [initialTopUpCredits, setInitialTopUpCredits] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);
    const [currentPlan, setCurrentPlan] = useState("");
    const [currentPlanType, setCurrentPlanType] = useState("");
    const [initialCurrentPlanType, setInitialCurrentPlanType] = useState("");
    const [initialCurrentPlan, setInitialCurrentPlan] = useState("");
    const [userSeats, setUserSeats] = useState(0);
    const [initialUserSeats, setInitialUserSeats] = useState(0);
    const [isExpirationDateCalendarShown, setIsExpirationDateCalendarShown] = useState("");
    const [expirationDateValue, setExpirationDateValue] = useState("");
    const [expirationDateInputValue, setExpirationDateInputValue] = useState("");
    const [initialExpirationDateValue, setInitialExpirationDateValue] = useState("");

    useEffect(() => {
        if (currentPlan === "single" || currentPlan === "pro" || currentPlan === "teams") {
            setPlanOptions([
                {
                    name: "Single",
                    type: "subscription"
                },
                {
                    name: "Pro",
                    type: "subscription"
                },
                {
                    name: "Teams",
                    type: "subscription"
                },
                {
                    name: "Enterprise",
                    type: "subscription"
                },
                {
                    name: "Custom",
                    type: "custom_subscription"
                },
                {
                    name: "Free trial",
                    type: "free_trial"
                },
                {
                    name: "Free",
                    type: "free"
                }
            ]);
        } else {
            setPlanOptions([
                {
                    name: "Pay as you go",
                    type: "subscription"
                },
                {
                    name: "Custom",
                    type: "custom_subscription"
                },
                {
                    name: "Free trial",
                    type: "free_trial"
                },
                {
                    name: "Free",
                    type: "free"
                }
            ]);
        }
    }, [currentPlan])

    const llmRegionOptions = getLlmRegions();

    useEffect(() => {
        if (updateSubscriptionStatus === REQUEST_SUCCESS) {
            setChangeCompanyCity(false);
            setChangeLLlmRegion(false);
            setChangeCompanyCountry(false);
            setChangeCompanyName(false);
        }
    }, [updateSubscriptionStatus])

    useEffect(() => {
        if (deleteSubscriptionStatus === REQUEST_SUCCESS) {
            openPage("/profiles");
        }
    }, [deleteSubscriptionStatus])

    useEffect(() => {
        user && dispatch(getSubscriptionUserRequest({ id, type }));
        user && setIsEditAllowed(user.role === "super_admin" || user.role === "admin")
    }, [dispatch, user]);

    useEffect(() => {
        if (removeTeamMemberStatus === REQUEST_SUCCESS) {
            setAreYouSureModalOpened(false);
            setSelectedUserForDeletion(null);
        }
    }, [removeTeamMemberStatus])

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    };

    const openTab = value => {
        setTabView(value);
    };

    const openBilling = () => {
        openTab('billing');
    };

    const handleCompanyProfileName = event => {
        setCompanyProfileName(event.target.value)
    };
    const handleCompanyProfileCountry = event => {
        setCompanyProfileCountry(event.target.value)
    };
    const handleCompanyProfileCity = event => {
        setCompanyProfileCity(event.target.value)
    };
    const handlePhoneNumber = event => {
        setPhoneNumber(event.target.value)
    };
    useEffect(() => {
        setIsMobile(window.innerWidth <= 575)
    }, []);

    useEffect(() => {
        setTotalCredits(totalCredits)
    }, [totalCredits]);

    useEffect(() => {
        if (profile && profile.user) {
            setCompanyProfileName(profile.user.company);
            setCompanyProfileCountry(profile.user.company_country);
            setCompanyProfileCity(profile.user.company_city);
            const savedLlmRegion = llmRegionOptions && llmRegionOptions.find(region => region.value === profile.user.llm_region);
            if (savedLlmRegion) {
                setLLlmRegionName(savedLlmRegion.name);
                setLLlmRegionValue(savedLlmRegion.value);
            }
        }
        if (profile && profile.plan) {
            if (profile.plan.id.toString() !== id.toString()) {
                openPage(`/profiles/${profile.plan.id}/${profile.plan.type}`)
            }
            setInitialCurrentPlanType(profile.plan.type);
            setInitialCurrentPlan(profile.plan.name);
            setAvailableRadars(profile.plan.max_active_radars || 0);
            setInitialAvailableRadars(profile.plan.max_active_radars || 0);
            setMonthlyCredits(profile.plan.monthly_credits || 0);
            setInitialMonthlyCredits(profile.plan.monthly_credits || 0);
            setTopUpCredits(profile.plan.max_credits || 0);
            setInitialTopUpCredits(profile.plan.max_credits || 0);
            setTotalCredits(profile.plan.total_credits);
            setCurrentPlan(profile.plan.name);
            setCurrentPlanType(profile.plan.type);
            setExpirationDateValue((profile.plan.expire_date_formated && new Date(profile.plan.expire_date_formated).toString() !== "Invalid Date") ? new Date(profile.plan.expire_date_formated) : "");
            setInitialExpirationDateValue((profile.plan.expire_date_formated && new Date(profile.plan.expire_date_formated).toString() !== "Invalid Date") ? new Date(profile.plan.expire_date_formated) : "")
            setInitialUserSeats(profile.plan.max_users);
            setUserSeats(profile.plan.max_users);
        }
    }, [profile]);

    const handleCancelBoxesUpdates = () => {
        setAvailableRadars(initialAvailableRadars);
        setCurrentPlan(initialCurrentPlan);
        setCurrentPlanType(initialCurrentPlanType);
        setMonthlyCredits(initialMonthlyCredits);
        setTopUpCredits(initialTopUpCredits);
        setTotalCredits(profile.plan.total_credits);
        setCurrentPlan(profile.plan.name);
        setExpirationDateValue((profile.plan.expire_date_formated && new Date(profile.plan.expire_date_formated).toString() !== "Invalid Date") ? new Date(profile.plan.expire_date_formated) : "")
        setInitialUserSeats(initialUserSeats);
    }

    const editData = value => {
        switch (value) {
            case "profileName":
                setChangeName(true);
                break;
            case "profileEmail":
                setChangeEmail(true);
                break;
            case "profilePassword":
                setChangePassword(true);
                break;
            case "companyName":
                setChangeCompanyName(true);
                break;
            case "companyCountry":
                setChangeCompanyCountry(true);
                break;
            case "companyCity":
                setChangeCompanyCity(true);
                break;
            case "llmRegion":
                setChangeLLlmRegion(true);
                break;
            case "phone":
                setChangePhoneNumber(true);
                break;
            default:
                break;
        }
    };

    const updateProfileData = (value) => {
        let data = {};
        switch (value) {
            case 'boxes':
                if (initialAvailableRadars !== availableRadars) {
                    data["max_active_radars"] = availableRadars;
                }
                if (initialMonthlyCredits !== monthlyCredits) {
                    data["monthly_credits"] = monthlyCredits;
                }
                if (initialTopUpCredits !== topUpCredits) {
                    data["max_credits"] = topUpCredits;
                }
                if (initialUserSeats !== userSeats) {
                    data["max_users"] = userSeats;
                }
                if (initialCurrentPlanType !== currentPlanType) {
                    data["plan_type"] = currentPlanType;
                }
                if (initialExpirationDateValue.toString() !== expirationDateValue.toString()) {
                    data["expire_date"] = moment(expirationDateInputValue).format('YYYY-MM-DD');
                }
                break;
            case "name":
                data.name = profileName;
                break;
            case "email":
                data.email = profileEmail;
                break;
            case "password":
                data["password"] = newPassword;
                data["password_confirmation"] = newPasswordAgain;
                break;
            case "companyName":
                data["company_name"] = companyProfileName;
                data["user_id"] = profile.user.id;
                break;
            case "companyCountry":
                data["company_country"] = companyProfileCountry;
                data["user_id"] = profile.user.id;
                break;
            case "companyCity":
                data["company_city"] = companyProfileCity;
                data["user_id"] = profile.user.id;
                break;
            case "llmRegion":
                data["llm_region"] = llmRegionValue;
                data["user_id"] = profile.user.id;
                break;
            case "phone":
                data.phone = phoneNumber;
                break;
            default:
                break;
        }
        isEditAllowed && dispatch(updateSubscriptionRequest({ id, type, data }));
    };

    const handleAddRadars = () => {
        isEditAllowed && setAvailableRadars(prevAvailableRadars => prevAvailableRadars + 1);
    }

    const handleSubtractRadars = () => {
        availableRadars > 0 && isEditAllowed && setAvailableRadars(prevAvailableRadars => prevAvailableRadars - 1);
    }

    const handleAddMonthlyCredits = () => {
        isEditAllowed && setMonthlyCredits(prevMonthlyCredits => prevMonthlyCredits + 1);
    }

    const handleSubtractMonthlyCredits = () => {
        monthlyCredits > 0 && isEditAllowed && setMonthlyCredits(prevMonthlyCredits => prevMonthlyCredits - 1);
    }

    const handleAddTopUpCredits = () => {
        isEditAllowed && setTopUpCredits(prevTopUpCredits => prevTopUpCredits + 1);
    }

    const handleSubtractTopUpCredits = () => {
        topUpCredits > 0 && isEditAllowed && setTopUpCredits(prevTopUpCredits => prevTopUpCredits - 1);
    }

    const handleAddUserSeats = () => {
        isEditAllowed && setUserSeats(prevUserSeats => prevUserSeats + 1)
    }

    const handleSubtractUserSeats = () => {
        isEditAllowed && setUserSeats(prevUserSeats => prevUserSeats - 1);
    }

    useEffect(() => {
        expirationDateValue && expirationDateValue.toString() !== "Invalid Date" && setExpirationDateInputValue(moment(expirationDateValue).format('MMM DD, YYYY'));
    }, [expirationDateValue]);

    const handleExpirationDateChange = (event) => {
        isEditAllowed && setExpirationDateValue(event || null);
        isEditAllowed && setIsExpirationDateCalendarShown(false);
    }

    const handleFieldChange = event => {
        isEditAllowed && setCurrentPlan(event.target.value);
        isEditAllowed && setCurrentPlanType(planOptions && planOptions.find(card => card.name === event.target.value).type);
    };
    const handleLLlmRegionChange = event => {
        isEditAllowed && setLLlmRegionName(event.target.value);
        isEditAllowed && setLLlmRegionValue(llmRegionOptions && llmRegionOptions.find(region => region.name === event.target.value).value);
    };

    const handleDeleteSubscription = () => {
        dispatch(deleteSubscriptionRequest({ planId: profile.plan.id, planType: profile.plan.type }));
    }

    const goBack = () => {
        openPage("/profiles");
    };

    const handleSearchValueChange = e => {
        setSearchValue(e.target.value);
    }

    useEffect(() => {
        dispatch(getTeamRequest({ search: searchValue, teamId: profile && profile.team && profile.team.id }));
    }, [searchValue]);

    const handleDeleteUser = user => {
        setAreYouSureModalOpened(true);
        setSelectedUserForDeletion(user);
    }

    return (
        <div className={classes.root}>
            <div className={classes.goBack} onClick={() => goBack()}>
                <img src={ArrowLeft} alt={t("board.goBack")} />
                <Typography color="secondaryDark" variant="bodyButton">{t("board.goBack")}</Typography>
            </div>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        {t("accountProfile.title")}
                    </Typography>
                </div>
                {isEditAllowed && <Menu
                    btnClassName={classes.moreButton}
                    onChange={handleMenuClick}
                    menuItems={[
                        {
                            label: t("accountProfile.deleteSubscription"),
                            onClick: () => handleDeleteSubscription(),
                            warningItem: true
                        }
                    ]}
                >
                    <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                </Menu>}
            </div>
            <div className={classes.links}>
                <div
                    className={classnames([classes.link, tabView === 'profile' ? classes.active : ""])}
                    onClick={() => openTab('profile')}
                >
                    {/* <div className={classes.imgWrapper}>
                        <img src={ProfileSettingsIllustration} alt={t("profileSettings.accountTitle")}/>
                    </div> */}
                    <Typography variant="bodyXs" color="primary" component="div" className={classes.subtitle}>{t("accountProfile.manageProfileSettings")}</Typography>
                    <Typography variant="bodySmall" color="black" component="div" className={classes.title}>{t("accountProfile.profileSettings")}</Typography>
                </div>
                <div
                    className={classnames([classes.link, tabView === 'billing' ? classes.active : ""])}
                    onClick={openBilling}>
                    {/* <div className={classes.imgWrapper}>
                            <img src={BillingIllustration} alt={t("profileSettings.billingTitle")}/>
                        </div> */}
                    <Typography variant="bodyXs" color="primary" component="div" className={classes.subtitle}>{t("accountProfile.manageTeam")}</Typography>
                    <Typography variant="bodySmall" color="black" component="div" className={classes.title}>{t("accountProfile.usersTeam")}</Typography>
                </div>
            </div>
            {tabView === 'profile' ? <>
                <div className={classes.profile}>
                    <div className={classes.box}>
                        <Typography variant="paragraph" color="black" component="div" align="center" gutterBottom>{t("accountProfile.currentPlan")}</Typography>
                        <Multiselect
                            testId="multiselect-subsector"
                            disabled={!isEditAllowed || (initialCurrentPlanType !== "custom_subscription" && initialCurrentPlanType !== "free_trial")}
                            className={classes.rowWrap}
                            customClass={classnames([classes.multiselect, { [classes.disableSubsectors]: !currentPlan }])}
                            labelClass={classes.labelClass}
                            iconArrow={<ArrowDown className={classes.arrowDown} />}
                            label={t('accountProfile.subscriptionType')}
                            options={planOptions}
                            value={capitalize(currentPlan)}
                            placeholder={planOptions && planOptions[0] && planOptions[0].name}
                            onChange={handleFieldChange}
                        />
                        <div className={classes.dateCalendarWrapper}>
                            <ClickAwayListener onClickAway={() => setIsExpirationDateCalendarShown(false)}>
                                <div className={classes.rowNonClickable}>
                                    <TextField disabled={!isEditAllowed} onClick={() => isEditAllowed && setIsExpirationDateCalendarShown(!isExpirationDateCalendarShown)} testId="header-expiration-date-field" value={expirationDateInputValue || ""} className={classes.modalInput} label={t("profiles.expirationDate")}></TextField>
                                    {isExpirationDateCalendarShown && <div className={classes.dateCalendar}><Calendar onChange={handleExpirationDateChange} value={expirationDateValue} /></div>}
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <Typography variant="paragraph" color="black" component="div" align="center" gutterBottom>{t("accountProfile.creditsTitle")}</Typography>
                        <div className={classes.calculatorSmallWrapper}>
                            <Typography variant="bodySmall" color="black" component="div">{t('accountProfile.monthlyCredits')}</Typography>
                            <div className={classes.calculatorSmall}>
                                <div className={classes.plusMinusIcon}><Minus className={classes.bigCalcButton} onClick={handleSubtractMonthlyCredits} /></div>
                                <Typography variant="paragraph" color="black" component="div" align="center" className={classes.creditsNumber}>
                                    <strong>{monthlyCredits}</strong>
                                    {monthlyCredits - initialMonthlyCredits !== 0 && <Typography variant="label" color="black" component="div" align="center" className={classnames([classes.creditsNumberChange, { [classes.changeMinus]: (monthlyCredits - initialMonthlyCredits < 0), [classes.changePlus]: (monthlyCredits - initialMonthlyCredits > 0)}])}><strong>{(monthlyCredits - initialMonthlyCredits > 0) && "+"}{monthlyCredits - initialMonthlyCredits}</strong></Typography>}
                                </Typography>
                                <Plus className={classes.bigCalcButton} onClick={handleAddMonthlyCredits} />
                            </div>
                        </div>
                        <div className={classes.calculatorSmallWrapper}>
                            <Typography variant="bodySmall" color="black" component="div">{t('accountProfile.topUpCredits')}</Typography>
                            <div className={classes.calculatorSmall}>
                                <div className={classes.plusMinusIcon}><Minus className={classes.bigCalcButton} onClick={handleSubtractTopUpCredits} /></div>
                                <Typography variant="paragraph" color="black" component="div" align="center" className={classes.creditsNumber}>
                                    <strong>{topUpCredits}</strong>
                                    {topUpCredits - initialTopUpCredits !== 0 && <Typography variant="label" color="black" component="div" align="center" className={classnames([classes.creditsNumberChange, { [classes.changeMinus]: (topUpCredits - initialTopUpCredits < 0), [classes.changePlus]: (topUpCredits - initialTopUpCredits > 0)}])}><strong>{(topUpCredits - initialTopUpCredits > 0) && "+"}{topUpCredits - initialTopUpCredits}</strong></Typography>}
                                </Typography>
                                <Plus className={classes.bigCalcButton} onClick={handleAddTopUpCredits} />
                            </div>
                        </div>
                        <div className={classnames([classes.calculatorSmallWrapper, classes.totalCalc])}>
                            <Typography variant="subtitle2" color="black" component="div">{t('accountProfile.totalCredits')}</Typography>
                            <div className={classes.calculatorSmall}>
                                <div className={classes.plusMinusIcon}></div>
                                <Typography variant="paragraph" color="black" component="div" align="center" className={classes.creditsNumber}><strong>{totalCredits}</strong></Typography>
                                <div className={classes.plusMinusIcon}></div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <Typography variant="paragraph" color="black" component="div" align="center" gutterBottom>{t("accountProfile.radarsTitle")}</Typography>
                        <div className={classes.calculator}>
                            <div className={classes.plusMinusIconBig}>{profile && profile.team && (availableRadars > profile.team.number_of_active_radars || (initialAvailableRadars < availableRadars && !(availableRadars < profile.team.number_of_active_radars))) && <MinusBig className={classes.bigCalcButton} onClick={handleSubtractRadars} />}</div>
                            <Typography variant="h3" color="black" component="div" align="center" className={classes.availableRadarsNumber}>
                                <strong>{availableRadars}</strong>
                                {availableRadars - initialAvailableRadars !== 0 && <Typography variant="subtitle1" color="black" component="div" align="center" className={classnames([classes.creditsNumberChangeBig, { [classes.changeMinus]: (availableRadars - initialAvailableRadars < 0), [classes.changePlus]: (availableRadars - initialAvailableRadars > 0)}])}><strong>{(availableRadars - initialAvailableRadars > 0) && "+"}{availableRadars - initialAvailableRadars}</strong></Typography>}
                            </Typography>
                            <PlusBig className={classes.bigCalcButton} onClick={handleAddRadars} />
                        </div>
                    </div>
                </div>
                {!!updateSubscriptionFailureMessage && <Typography variant="body" component="div" gutterBottom color="danger">
                        {updateSubscriptionFailureMessage}
                    </Typography>}
                {isEditAllowed && <>
                    <Button onClick={handleCancelBoxesUpdates} className={classes.cancelButton} variant="tertiary">{t("accountProfile.cancel")}</Button>
                    <Button onClick={() => updateProfileData('boxes')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                </>}
                <div className={classes.companyWrap}>
                    <Typography variant="subtitle1" component="div">
                        {t('accountProfile.companyProfileSettings')}
                    </Typography>
                    <div display="flex" className={classes.profileWrap}>
                        <div className={classnames([classes.editLogo, classes.hideImg])}>
                            <div className={classes.logoWrapper}>
                                <div className={classes.companyIllustration}><img src={CompanyProfileIllustration} alt={t('companyName')} /></div>
                            </div>
                        </div>
                        <div className={classes.flexColumn}>
                            <div className={classes.infoRow} >
                                <Typography className={classes.profileTitle} variant="bodyXs" color="textLight">{t('accountProfile.companyName')}</Typography>
                                {changeCompanyName ? <div>
                                    <TextField fullWidth={isMobile} placeholder={t("accountProfile.enterName")} value={companyProfileName} className={classes.textInput} onChange={handleCompanyProfileName}></TextField>
                                    <div className={classes.buttons}>
                                        <Button onClick={() => setChangeCompanyName(false)} variant="textSecondary">{t("accountProfile.cancel")}</Button>
                                        <Button onClick={() => updateProfileData('companyName')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                                    </div>
                                </div> : <div>
                                    <Typography className={classes.profileValue} variant="subtitle1" component="div">{companyProfileName}</Typography>
                                    {isEditAllowed && <Typography onClick={() => editData('companyName')} variant="bodyXs" color="primary" className={classes.changeLink}>{t("accountProfile.edit")}</Typography>}
                                </div>}
                            </div>
                            <div className={classes.infoRow}>
                                <Typography className={classes.profileTitle} variant="bodyXs" color="textLight">{t('accountProfile.country')}</Typography>

                                {changeCompanyCountry ? <div>
                                    <TextField fullWidth={isMobile} placeholder={t("accountProfile.enterCountry")} value={companyProfileCountry} className={classes.textInput} onChange={handleCompanyProfileCountry}></TextField>
                                    <div className={classes.buttons}>
                                        <Button onClick={() => setChangeCompanyCountry(false)} variant="textSecondary">{t("accountProfile.cancel")}</Button>
                                        <Button onClick={() => updateProfileData('companyCountry')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                                    </div>
                                </div> : <div>
                                    <Typography className={classes.profileValue} variant="subtitle1" component="div">{companyProfileCountry}</Typography>
                                    {isEditAllowed && <Typography onClick={() => editData('companyCountry')} variant="bodyXs" color="primary" className={classes.changeLink}>{t("accountProfile.edit")}</Typography>}
                                </div>}
                            </div>
                            <div className={classes.infoRow}>
                                <Typography className={classes.profileTitle} variant="bodyXs" color="textLight">{t('accountProfile.city')}</Typography>
                                {changeCompanyCity ? <div>
                                    <TextField fullWidth={isMobile} placeholder={t("accountProfile.enterCity")} value={companyProfileCity} className={classes.textInput} onChange={handleCompanyProfileCity}></TextField>
                                    <div className={classes.buttons}>
                                        <Button onClick={() => setChangeCompanyCity(false)} variant="textSecondary">{t("accountProfile.cancel")}</Button>
                                        <Button onClick={() => updateProfileData('companyCity')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                                    </div>
                                </div> : <div>
                                    <Typography className={classes.profileValue} variant="subtitle1" component="div">{companyProfileCity}</Typography>
                                    {isEditAllowed && <Typography onClick={() => editData('companyCity')} variant="bodyXs" color="primary" className={classes.changeLink}>{t("accountProfile.edit")}</Typography>}
                                </div>}
                            </div>
                            <div className={classes.infoRow}>
                                <Typography className={classes.profileTitle} variant="bodyXs" color="textLight">LLM Region</Typography>
                                {changeLLlmRegion ? <div>
                                    <Multiselect
                                        insertValue={true}
                                        testId="multiselect-llm-region"
                                        className={classes.rowWrap}
                                        customClass={classnames([classes.multiselect])}
                                        labelClass={classes.labelClass}
                                        iconArrow={<ArrowDown className={classes.arrowDown} />}
                                        options={llmRegionOptions}
                                        value={llmRegionName}
                                        onChange={handleLLlmRegionChange}
                                    />
                                    <div className={classes.buttons}>
                                        <Button onClick={() => setChangeLLlmRegion(false)} variant="textSecondary">{t("accountProfile.cancel")}</Button>
                                        <Button onClick={() => updateProfileData('llmRegion')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                                    </div>
                                </div> : <div>
                                    <Typography className={classes.profileValue} variant="subtitle1" component="div">{llmRegionName}</Typography>
                                    {isEditAllowed && <Typography onClick={() => editData('llmRegion')} variant="bodyXs" color="primary" className={classes.changeLink}>{t("accountProfile.edit")}</Typography>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className={classes.profile}>
                    <div className={classes.box}>
                        <Typography variant="paragraph" color="black" component="div" align="center" gutterBottom>{t("accountProfile.userSeatsTitle")}</Typography>
                        <div className={classes.calculator}>
                            <div className={classes.plusMinusIconBig}>{profile && profile.team && (userSeats > profile.team.number_of_team_users || (initialUserSeats < userSeats && !(userSeats < profile.team.number_of_team_users))) && <MinusBig className={classes.bigCalcButton} onClick={handleSubtractUserSeats} />}</div>
                            <Typography variant="h3" color="black" component="div" align="center" className={classes.availableRadarsNumber}>
                                <strong>{userSeats}</strong>
                                {userSeats - initialUserSeats !== 0 && <Typography variant="subtitle1" color="black" component="div" align="center" className={classnames([classes.creditsNumberChangeBig, { [classes.changeMinus]: (userSeats - initialUserSeats < 0), [classes.changePlus]: (userSeats - initialUserSeats > 0)}])}><strong>{(userSeats - initialUserSeats > 0) && "+"}{userSeats - initialUserSeats}</strong></Typography>}
                            </Typography>
                            <PlusBig className={classes.bigCalcButton} onClick={handleAddUserSeats} />
                        </div>
                    </div>
                </div>
                {!!updateSubscriptionFailureMessage && <Typography variant="body" component="div" gutterBottom color="danger">
                        {updateSubscriptionFailureMessage}
                    </Typography>}
                {isEditAllowed && <>
                    <Button onClick={handleCancelBoxesUpdates} className={classes.cancelButton} variant="tertiary">{t("accountProfile.cancel")}</Button>
                    <Button onClick={() => updateProfileData('boxes')} variant="primary">{t("accountProfile.saveChanges")}</Button>
                </>}
                <div className={classes.teamHeader}>
                    <TextField testId="header-search-field" onChange={handleSearchValueChange} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput}></TextField>
                    <div className={classes.rightAlign}>
                        <Button disabled={user && user.role !== "admin" && user.role !== "super_admin"} variant="tertiary" onClick={() => setAddExistingUserModalOpened(true)} className={classes.addExistingUserBtn}>
                            {t("accountProfile.addExistingUser")}
                        </Button>
                        <Button disabled={user && user.role !== "admin" && user.role !== "super_admin"} variant="secondary" onClick={() => setAddNewUserModalOpened(true)}>
                            {t("accountProfile.addNewUser")}
                        </Button>
                    </div>
                </div>
                {team && team.length > 0 ? <TableContainer className={classes.tableContainerRoot}>
                    <Table className={classes.teamTable} aria-label="simple table">
                        <TableHead className={classes.hideOnMobile}>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('accountProfile.name')}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('accountProfile.role')}</Typography></TableCell>
                                {/* <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('profileSettings.teamPermissions')}</Typography></TableCell> */}
                                {/* <TableCell align="left"><Typography variant="bodyButton" color="secondaryDark" component="div">{t('profileSettings.role')}</Typography></TableCell> */}
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {team && team.map((row) => (
                                <Row
                                    row={row}
                                    user={user}
                                    key={row.id}
                                    t={t}
                                    classes={classes}
                                    isEditAllowed={isEditAllowed}
                                    team={profile.team}
                                    plan={profile.plan}
                                    dispatch={dispatch}
                                    handleDeleteUser={() => handleDeleteUser(row)}
                                    />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer> :
                    <div className={classes.noData}>
                        <img src={ProfileLockImage} alt={t('accountProfile.noData')} />
                        <Typography className={classes.noDataLabel} variant="bodyButton" color="secondaryDark">{t('accountProfile.noData')}</Typography>
                    </div>}
                    <AddNewUserModal team={profile.team} dialogOpened={addNewUserModalOpened} onClose={() => setAddNewUserModalOpened(false)} />
                    <AddExistingUserModal team={profile.team} dialogOpened={addExistingUserModalOpened} onClose={() => setAddExistingUserModalOpened(false)} />
                    <AreYouSureModal
                        dialogOpened={areYouSureModalOpened}
                        titleCopy={selectedUserForDeletion && t('accountProfile.removeTeamMember', { name: selectedUserForDeletion.name })}
                        confirmCopy={t('accountProfile.yesRemoveTeamMember')}
                        onConfirm={() => dispatch(removeTeamMemberRequest({ teamId: profile.team.id, userId: selectedUserForDeletion.id, userEmail: selectedUserForDeletion.email, planId: profile.plan.id, planType: profile.plan.type }))}
                        onChange={() => setAreYouSureModalOpened(false)}
                    />
            </>}
        </div>
    )
};
export default AccountProfile;