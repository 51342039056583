import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchTechnologiesRequest = createAction(
    `${REDUCER_PREFIX}/fetchTechnologiesRequest`
);
export const createTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/createTechnologyRequest`
);
export const updateTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/updateTechnologyRequest`
);
export const updateTechnologyEditorRequest = createAction(
    `${REDUCER_PREFIX}/updateTechnologyEditorRequest`
);
export const publishTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/publishTechnologyRequest`
)
export const unPublishTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/unPublishTechnologyRequest`
);
export const deleteTechnologyRequest = createAction(
    `${REDUCER_PREFIX}/deleteTechnologyRequest`
);
export const getTechnologyProfileRequest = createAction(
    `${REDUCER_PREFIX}/getTechnologyProfileRequest`
)
export const postGetClosestRequest = createAction(
    `${REDUCER_PREFIX}/postGetClosestRequest`
)
export const getTechnologyWithCompaniesRequest = createAction(
    `${REDUCER_PREFIX}/getTechnologyWithCompaniesRequest`
)
export const updateCompanyKanbanRequest = createAction(
    `${REDUCER_PREFIX}/updateCompanyKanbanRequest`
)
export const releaseCompaniesRequest = createAction(
    `${REDUCER_PREFIX}/releaseCompaniesRequest`
)
