import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialog: {
        padding: spacing(4),
        background: palette.primary.backgroundLight
    },
    dialogFilter: {
        padding: `${spacing(9)}px ${spacing(3)}px`,
        background: palette.primary.backgroundLight,
        borderRadius: spacing(2)
    },
    dialogScrollPaper: {
        maxWidth: 800,
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
    },
    dialogTitle: {
    },
    dialogSubtitle: {
        color: palette.secondary.dark,
        marginTop: spacing(3)
    },
    dialogContent: {
        marginTop: spacing(4)
    },
    confirmBtn: {
        background: palette.error.main,
        borderColor: palette.error.main,
        marginRight: spacing(2),
        "&:hover": {
            background: palette.error.light,
            borderColor: palette.error.light,
        }
    },
    ctas: {
        display: "flex",
        justifyContent: "center"
    }
}));
