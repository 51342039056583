import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) => createStyles({
    root: {
        // marginRight: 20
    },
    nav: {
        display: "flex",
        justifyContent: "space-between"
    },
    infoActions: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing(4)
    },
    breadCrumb: {
        display: "flex",
        alignItems: "flex-end"
    },
    breadCrumbIndustry: {
        display: "flex",
        flexDirection: "column"
    },
    breadCrumbSubIndustry: {
        display: "flex",
        flexDirection: "column"
    },
    imgArrowRight: {
        marginLeft: spacing(2),
        marginRight: spacing(2),
        marginBottom: 6
    },
    request100Button: {
        background: "transparent",
        marginRight: spacing(2),
        border: "none",
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },
    },
    addNewCompanyBtn: {
        background: "transparent",
        marginLeft: spacing(2),
        border: "none",
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },
    },
    mr16: {
        marginRight: spacing(2)
    },
    actions: {
        display: "flex",
        alignItems: "center"
    },
    searchFilters: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: spacing(3)
    },
    header: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    refineAndSearch: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    startEndornment: {
        marginRight: `${spacing(1)}px`
    },
    goBack: {
        display: "inline-flex",
        alignItems: "center",
        "& img": {
            marginRight: spacing(1)
        },
        cursor: "pointer"
    },
    requestLink: {
        cursor: "pointer"
    },
    headerInput: {
        "& input": {
            width: 167,
        },
    },
    filterWrap: {
        marginLeft: `${spacing(2)}px`,
        display: "flex",
        alignItems: "center"
    },
    sortedBy: {
        marginRight: spacing(1)
    },
    multiselectAdjust: {
        border: "none!important",
        background: "transparent",
        borderRadius: `${spacing(3)}px!important`,
        "&:hover": {
            background: palette.primary.backgroundLight,
            border: "none"
        }
    },
    arrowIcon: {
        marginRight: `${spacing(1)}px`,
        cursor: 'pointer',
        transform: "rotate(0deg) scale(1.4)"
    },
    rotateIcon: {
        transform: "rotate(180deg) scale(1.4)"
    },
    filterButton: {
        background: "transparent!important",
        color: palette.secondary.dark,
        border: "none",
        padding: `${spacing(1)}px`,
        minWidth: 102,
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },

    },
}));