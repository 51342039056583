import React, { useEffect, useState } from 'react';
import { styled } from '@material-ui/styles';
import {Link} from 'react-router-dom';

//Components
import { Card } from '@material-ui/core'
import { CardContent } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import Typography from "../Typography/Typography";
import { useTheme } from '@material-ui/core/styles';
import classnames from 'classnames';

//Hooks
import { useStyles } from "./style";
//Assets
import { ReactComponent as ArrowDown } from '../../assets/icons/ArrowDown.svg';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const CollapseComponent = ({ data, children, onCollapseClick, selectedCard }) => {
    //NOTE: all commented code here is for multiple selection of cards in board
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles();
    const theme = useTheme();
    // const [cardSelected, setCardSelected] = useState(selectedCard);
    // useEffect(() => {
    //     setCardSelected(selectedCard)
    // }, [selectedCard])

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    // const handleClickOnCollapse = (e) => {
    //     onCollapseClick(e)
    // };
    // useEffect(() => {
    //     console.log("card SELECTED", selectedCard)
    // }, [selectedCard])

    return (
        // onClick={(e) => handleClickOnCollapse(e)} className={classnames({ [classes.selectedCard]: cardSelected })}
        <div>
            {
                data &&
                <Card sx={{ maxWidth: 345 }}>
                    <CardActions disableSpacing>
                        <div className={classes.priorityTitle}>
                            <Typography className={classes.priority} style={{ backgroundColor: data && data.priority === 0 ? theme.palette.error.main : theme.palette.success.dark }} variant="bodyXs">{data && data.priority}</Typography>
                            <Typography className={classes.title} variant="body">
                                {
                                    data && data.name &&
                                    <Link
                                        to={ '/company/' + data.slug +'/preview?cid=' + data.id }
                                        className={ classes.companyName}
                                    >
                                        {data.name}
                                    </Link>
                                }
                            </Typography>
                        </div>
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            test-id={`expand-${data.id}`}
                        >
                            <ArrowDown />
                        </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            {children}
                        </CardContent>
                    </Collapse>
                </Card>
            }
        </div>
    );
};
export default CollapseComponent;
