const getUsersSelector = state => state.userprofiles.pool;
const getSubscriptionUser = state => state.userprofiles.subscriptionUser;
const updateUserStatusSelector = state => state.userprofiles.updateUserStatus;
const createUserStatusSelector = state => state.userprofiles.createUserStatus;
const deleteUserStatusSelector = state => state.userprofiles.deleteUserStatus;
const createUserMessageFailureSelector = state => state.userprofiles.createUserMessageFailure;

export {
    getUsersSelector,
    updateUserStatusSelector,
    createUserStatusSelector,
    getSubscriptionUser,
    createUserMessageFailureSelector,
    deleteUserStatusSelector
};