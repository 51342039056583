import { call } from "redux-saga/effects";
import axios from "axios";
import {
    GET_COUNTRIES_URL,
} from "../../../constants/endpoints";

function* getCountries() {
    const response = yield call(axios, {
        method: "GET",
        url: `${GET_COUNTRIES_URL}`,
    }
    );
    return response.data;
}
export {
    getCountries
};