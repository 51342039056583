import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const fetchIndustriesRequest = createAction(
    `${REDUCER_PREFIX}/fetchIndustriesRequest`
);

export const fetchIndustryRequest = createAction(
    `${REDUCER_PREFIX}/fetchIndustryRequest`
);

export const createIndustryRequest = createAction(
    `${REDUCER_PREFIX}/createIndustryRequest`
);

export const deleteIndustryRequest = createAction(
    `${REDUCER_PREFIX}/deleteIndustryRequest`
);

export const updateIndustryRequest = createAction(
    `${REDUCER_PREFIX}/updateIndustryRequest`
);

export const addSubindustryRequest = createAction(
    `${REDUCER_PREFIX}/addSubindustryRequest`
);

export const deleteSubindustryRequest = createAction(
    `${REDUCER_PREFIX}/deleteSubindustryRequest`
);

export const updateSubindustryRequest = createAction(
    `${REDUCER_PREFIX}/updateSubindustryRequest`
);
export const postGetClosestIndustryRequest = createAction(
    `${REDUCER_PREFIX}/postGetClosestIndustryRequest`
)
export const getSubIndustryWithCompaniesRequest = createAction(
    `${REDUCER_PREFIX}/getSubIndustryWithCompaniesRequest`
)
export const updateMLCompanySubIndustryRequest = createAction(
    `${REDUCER_PREFIX}/updateMLCompanySubIndustryRequest`
)
export const updateCompanyIndustryKanbanRequest = createAction(
    `${REDUCER_PREFIX}/updateCompanyIndustryKanbanRequest`
)
export const releaseSubIndustryCompaniesRequest = createAction(
    `${REDUCER_PREFIX}/releaseSubIndustryCompaniesRequest`
)
