import { all, takeEvery } from "redux-saga/effects";
import {
    fetchResultsRequest,
} from "../requests";
import {
    fetchResults,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchResults() {
    yield takeEvery(
        convertTypeFromAction(fetchResultsRequest),
        ({ payload: { data } }) => fetchResults(data)
    );
}

function* saga() {
    yield all([
        watchFetchResults(),
    ]);
}

export { saga };