import React from "react";

// UI components
import Typography from "../../../../../components/Typography/Typography";
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//UI Components
import Tooltip from '@material-ui/core/Tooltip';
//Assets
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info-icon.svg';
//Colors
import { useTheme } from '@material-ui/core/styles';

const ScoreBreakDownModal = ({ onChange, company, isProfile }) => {
    const { t } = useTranslation();
    const classes = useStyles({ isProfile });
    const theme = useTheme();

    const getFormattedNumber = value => {
        if (value) {
            return (value * 100).toFixed();
        } else return 0;
    };
    const getBorderColor = value => {
        if (value * 100 <= 25) {
            return theme.palette.error.main;
        } else if (value * 100 > 25 && value * 100 <= 50) {
            return theme.palette.primary.dark;
        } else if (value * 100 > 50 && value * 100 <= 75) {
            return theme.palette.warning.main;
        } else if (value * 100 > 75) {
            return theme.palette.success.main;
        }
    };

    return (
        <div
            className={classes.dialogRoot}
            onMouseLeave={() => onChange()}
        >
            <Typography variant="bodySmall" className={classes.title}><strong>{t('companyProfile.breakDownScoreTitle')}</strong></Typography>
            <div className={classes.dialogContent}>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.successScore")}</strong></Typography>

                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.successTooltipText")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body" className={classes.scoreCalculated}><strong>{getFormattedNumber(company.success_potential_score)}</strong></Typography>
                            <Typography variant="bodyXs" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.success_potential_score) + '%', borderColor: getBorderColor(company.success_potential_score) }}>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.successGrowth")}</strong></Typography>
                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.growthTooltipText")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body1" className={classes.scoreCalculated}>{getFormattedNumber(company.growth_score)} </Typography>
                            <Typography variant="caption" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.growth_score) + '%', borderColor: getBorderColor(company.growth_score) }}>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.successMaturity")}</strong></Typography>
                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.maturityTooltipText")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body1" className={classes.scoreCalculated}>{getFormattedNumber(company.maturity_score)} </Typography>
                            <Typography variant="caption" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.maturity_score) + '%', borderColor: getBorderColor(company.maturity_score) }}>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.successInnovation")}</strong></Typography>
                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.innovationTooltipText")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body1" className={classes.scoreCalculated}>{getFormattedNumber(company.innovation_score)} </Typography>
                            <Typography variant="caption" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.innovation_score) + '%', borderColor: getBorderColor(company.innovation_score) }}>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.successSustainability")}</strong></Typography>
                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.sustainabilityTooltipText")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body1" className={classes.scoreCalculated}>{getFormattedNumber(company.sustainability_score)} </Typography>
                            <Typography variant="caption" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.sustainability_score) + '%', borderColor: getBorderColor(company.sustainability_score) }}>
                        </div>
                    </div>
                </div>
                <div className={classes.scoreRow}>
                    <div className={classes.flexCenterJustify}>
                        <div className={classes.flexCenter}>
                            <Typography variant="bodyButton"><strong>{t("companyProfile.dataCompleteness")}</strong></Typography>
                            <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.dataCompletenessInfo")}</Typography>} arrow>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </div>
                        <div className={classes.flexCenter}>
                            <Typography variant="body1" className={classes.scoreCalculated}>{getFormattedNumber(company.completeness_score)} </Typography>
                            <Typography variant="caption" className={classes.scoreMax}> {t("companyProfile.100")}</Typography>
                        </div>
                    </div>
                    <div className={classes.progressWrap}>
                        <div className={classes.progress} style={{ width: getFormattedNumber(company.completeness_score) + '%', borderColor: getBorderColor(company.completeness_score) }}>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default ScoreBreakDownModal;