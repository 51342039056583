import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, typography }) => createStyles({
    root: {
        border: `1px solid ${palette.secondary.backgroundLight}`,
        borderRadius: spacing(1),
        padding: `${spacing(2)}px ${spacing(3)}px`,
        backgroundColor: palette.common.white
    },
    startupDesc: {
        whiteSpace: "pre-wrap",
        color: palette.text.main,
    },
    tags: {
        display: "flex",
        flexWrap: "wrap"
    },
    tag: {
        marginRight: spacing(1),
        marginTop: spacing(1),
    },
    tagSubIndustry: {
        backgroundColor: palette.success.backgroundLight
    },
    industryTitle: {
        marginTop: spacing(2),
        marginBottom: 4
    },
    updateEditWrap: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

    cardTitle: {
        whiteSpace: "pre-wrap",
        color: palette.text.main,
    },
    cardHeader: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%',
        "& svg": {
            stroke: '#666',
            "&:hover": {
                stroke: 'red'
            }
        }
    },
    cardBodyItem: {
        display: "flex",
        width: '100%',
        justifyContent: 'space-between',
        marginTop: spacing(2),
        "& :first-child": {
            color: palette.text.light,
            fontSize: 10,
            lineHeight: "10px",
        },
        "& :nth-child(2)": {
            fontSize: 12,
            lineHeight: "20px",
        },
    },
    cardFooter: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    cardFooterButton: {
        padding: 0,
        marginTop: spacing(2)
    },
    mlStatus: {
        height: '10px',
        width: '10px',
        marginTop: '20px',
        borderRadius: '50%'
    },
    green: {
        background: 'green'
    },
    yellow: {
        background: '#FFB612'
    },
    red: {
        background: 'red'
    },
    technologyLabelWrapper: {
        width: '100%'
    },
    technologyLabel: {
        width: '100%',
        background: palette.secondary.backgroundLight,
        padding: '5px 10px',
        borderRadius: spacing(1),
        fontSize: '10px'
    }
}));
