const getUserSelector = state => state.auth.user;
const getLoginMessageSelector = state => state.auth.loginMessage;
const getResetPasswordMessageSelector = state => state.auth.resetPasswordMessage;
const getResetPasswordStatusSelector = state => state.auth.resetPasswordStatus;
const getSendResetLinkMessageSelector = state => state.auth.sendResetLinkMessage;
const getSendResetLinkStatusSelector = state => state.auth.sendResetLinkStatus;
const getCheckResetLinkMessageSelector = state => state.auth.checkResetLinkMessage;
const getCheckResetLinkStatusSelector = state => state.auth.checkResetLinkStatus;
const getAccessTokenSelector = state => state.auth.accessToken;
const getLoginStatusSelector = state => state.auth.postLoginStatus;
const getLogoutStatusSelector = state => state.auth.postLogoutStatus;
const getUserStatusSelector = state => state.auth.getMeStatus;
const getMeStatusCodeSelector = state => state.auth.getMeStatusCode;
const getCookieStatusSelector = state => state.auth.getCookieStatus;

export {
    getUserSelector,
    getLoginMessageSelector,
    getResetPasswordMessageSelector,
    getResetPasswordStatusSelector,
    getSendResetLinkMessageSelector,
    getSendResetLinkStatusSelector,
    getCheckResetLinkMessageSelector,
    getCheckResetLinkStatusSelector,
    getAccessTokenSelector,
    getLoginStatusSelector,
    getLogoutStatusSelector,
    getUserStatusSelector,
    getCookieStatusSelector,
    getMeStatusCodeSelector
};