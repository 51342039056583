import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const getUsersRequest = createAction(
    `${REDUCER_PREFIX}/getUsersRequest`
);
export const createUserRequest = createAction(
    `${REDUCER_PREFIX}/createUserRequest`
);
export const updateUserRequest = createAction(
    `${REDUCER_PREFIX}/updateUserRequest`
);
export const getUserRequest = createAction(
    `${REDUCER_PREFIX}/getUserRequest`
);
export const deleteUserRequest = createAction(
    `${REDUCER_PREFIX}/deleteUserRequest`
);