import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    root: {
    },
    main: {
        background: palette.common.white,
        padding: spacing(4),
        marginTop: spacing(4),
        borderRadius: spacing(4),
        position: "relative"
    },
    wrap: {
        paddingBottom: spacing(3),
        display: "flex",
        justifyContent: "space-between"
    },
    imgWrap: {
        width: 48,
        height: 48,
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: spacing(1),
        marginRight: spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    scores: {
        padding: `${spacing(4)}px 0 0 ${spacing(4)}px`,
        display: "flex",
        flexDirection: "column",
        flex: "50%"
    },
    imagePlaceholder: {
        cursor: "pointer",
        maxWidth: 36
    },
    profileImage: {
        maxWidth: 36
    },
    companyName: {
        color: palette.secondary.light
    },
    matchScore: {
        color: palette.text.dark,
        display: "flex",
        alignItems: "center",
        marginTop: spacing(2),
        marginRight: 240
    },
    infoIcon: {
        cursor: "pointer",
        marginLeft: spacing(2)
    },
    valuerScoreNum: {
        color: palette.secondary.light,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    basicInfo: {
        marginTop: spacing(3)
    },
    infoTitle: {
        fontWeight: "500",
        color: palette.text.main,
        width: 196,

    },
    infoValue: {
        color: palette.text.lighter,
        width: 196,
    },
    link: {
        color: palette.secondary.dark,
        maxWidth: 200,
        overflowWrap: "anywhere"
    },
    money: {
        color: palette.success.main
    },
    description: {
        color: palette.text.main,
        borderBottom: `1px solid ${palette.text.backgroundMain}`,
        paddingBottom: spacing(3)
    },
    technologies: {
        marginTop: spacing(4),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    tag: {
        marginRight: spacing(1),
        marginTop: spacing(1),
        height: "auto!important",
        minHeight: 20
    },
    sdgWrap: {
        marginTop: spacing(3),
        display: "flex",
        flexDirection: "column"
    },
    sdg: {
        marginRight: spacing(1),
        marginTop: spacing(1),
        cursor: "pointer"
    },
    sdgTooltip: {
        textAlign: "center"
    },
    sdgTooltipTitle: {
        fontWeight: "700",
    },
    sdgUnlockToAccess: {
        color: palette.grey.main,
        verticalAlign: "super"
    },
    dataTitle: {
        color: palette.secondary.light
    },
    dataValue: {
        color: palette.primary.main,
        marginLeft: spacing(1)
    },
    dataCompleteness: {
        display: 'flex',
        cursor: "pointer"
    },
    tabs: {
        height: 74,
        borderBottom: `2px solid ${palette.text.backgroundMain}`,
        display: "flex",
        alignItems: "flex-end"
    },
    tab: {
        color: palette.text.light,
        minWidth: 76,
        marginRight: 58,
        cursor: 'pointer',
        textAlign: "center",
        borderBottom: "2px solid transparent",
        paddingBottom: spacing(2),
        marginTop: spacing(2)
    },
    activeTab: {
        color: palette.secondary.dark,
        minWidth: 76,
        marginRight: 58,
        borderBottom: "2px solid",
        borderBottomColor: palette.secondary.dark,
    },
    nameMenuWrap: {
        width: "100%"
    },

    valuerScoreBlock: {
        position: "absolute",
        top: 0,
        right: 0,
        background: palette.primary.backgroundMedium,
        boxShadow: `inset 1px 0px 0px #EFEFEE`,
        height: 164,
        width: 240,
        borderTopRightRadius: spacing(3)
    },
    valuerScore: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    chart: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: spacing(2)
    },
    scoreBreakdown: {
        marginTop: -spacing(1),
        marginBottom: spacing(2),
        cursor: "pointer"
    },
    infoIconChart: {
        // marginLeft: spacing(1)
        position: "absolute",
        top: 12,
        right: 12,
        cursor: "pointer"

    },
    score: {
        lineHeight: "40px",
        position: "absolute",
        top: 60
    },
    descriptionScores: {
        borderTop: "1px solid rgba(107, 119, 140, 0.1)",
        marginTop: spacing(2),
        display: "flex"
    },
    descriptionBox: {
        paddingRight: spacing(4),
        marginTop: spacing(4),
    },
    actionItems: {
        paddingTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        justifyContent: "space-between"
    },
    commentActionItems: {
        paddingTop: spacing(3),
        marginTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    commentActionItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    communicationIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`
    },
    numberOfEmployees: {
        minHeight: 18,
    },
    enrichedProfileTag: {
        color: palette.common.white,
        background: palette.primary.main,
        padding: `0 ${spacing(1)}px`,
        borderRadius: shape.borderRadius,
        height: spacing(3),
        display: "flex",
        alignItems: "center",
        width: 105
    },
    emptyTag: {
        height: spacing(3),
    },
    companyVoteActions: {
        paddingTop: spacing(3),
        marginTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    companyVote: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    questionMark: {
        marginRight: spacing(1)
    },
    recommendationUseful: {
        paddingRight: `${spacing(2)}px`
    },
    voteIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`,
        "&:hover": {
            cursor: "pointer",
        },
    },
    dislikeImg: {
        transform: "rotate(180deg)"
    },
    flexSpaceStart: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    flexColumn: {
        display: "flex",
        justifyContent: "space-between"
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%"
    },
    flexSpaceBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    moreIcon: {
        fill: palette.secondary.dark
    },
    moreIconOpened: {
        fill: palette.primary.dark
    },
    moreButton: {
        position: "absolute",
        top: spacing(4),
        right: spacing(4),
        cursor: "pointer"
    },
}));