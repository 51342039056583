import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import colors from './constants/colors';

const GTFlexaRegular = {
    fontFamily: 'GTFlexa',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url("./assets/fonts/GT-Flexa-Standard-Regular.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Regular.ttf")`
};

const GTFlexaLight = {
    fontFamily: 'GTFlexa Light',
    fontStyle: 'normal',
    fontWeight: 300,
    src: `url("./assets/fonts/GT-Flexa-Standard-Light.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Light.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Light.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Light.ttf")
			format("truetype")`
};

const GTFlexaThin = {
    fontFamily: 'GTFlexa Thin',
    fontStyle: 'normal',
    fontWeight: 100,
    src: `url("./assets/fonts/GT-Flexa-Standard-Thin.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Thin.ttf")
			format("truetype")`
};

const GTFlexaBold = {
    fontFamily: 'GTFlexa Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `url("./assets/fonts/GT-Flexa-Standard-Bold.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Bold.ttf")
			format("truetype")`
};

const GTFlexaBlack = {
    fontFamily: 'GTFlexa Black',
    fontStyle: 'normal',
    fontWeight: 900,
    src: `url("./assets/fonts/GT-Flexa-Standard-Black.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Black.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Black.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Black.ttf")
			format("truetype")`
};

const GTFlexaMedium = {
    fontFamily: 'GTFlexa Medium',
    fontStyle: 'normal',
    src: `url("./assets/fonts/GT-Flexa-Standard-Medium.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Standard-Medium.ttf")
			format("truetype")`
};

const GTFlexaMonoRegular = {
    fontFamily: 'GTFlexa Mono Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url("./assets/fonts/GT-Flexa-Mono-Regular.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Regular.ttf")`
};

const GTFlexaMonoLight = {
    fontFamily: 'GTFlexa Mono Light',
    fontStyle: 'normal',
    fontWeight: 300,
    src: `url("./assets/fonts/GT-Flexa-Mono-Light.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Light.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Light.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Light.ttf")
			format("truetype")`
};

const GTFlexaMonoThin = {
    fontFamily: 'GTFlexa Mono Thin',
    fontStyle: 'normal',
    fontWeight: 100,
    src: `url("./assets/fonts/GT-Flexa-Mono-Thin.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Thin.ttf")
			format("truetype")`
};

const GTFlexaMonoBold = {
    fontFamily: 'GTFlexa Mono Bold',
    fontStyle: 'normal',
    fontWeight: 700,
    src: `url("./assets/fonts/GT-Flexa-Mono-Bold.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Bold.ttf")
			format("truetype")`
};

const GTFlexaMonoBlack = {
    fontFamily: 'GTFlexa Mono Black',
    fontStyle: 'normal',
    fontWeight: 900,
    src: `url("./assets/fonts/GT-Flexa-Mono-Black.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Black.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Black.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Black.ttf")
			format("truetype")`
};

const GTFlexaMonoMedium = {
    fontFamily: 'GTFlexa Mono Medium',
    fontStyle: 'normal',
    src: `url("./assets/fonts/GT-Flexa-Mono-Medium.otf")
			format("embedded-opentype"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.woff2")
			format("woff2"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.woff")
			format("woff"),
		url("./assets/fonts/GT-Flexa-Mono-Medium.ttf")
			format("truetype")`
};

const theme = createMuiTheme({
    palette: {
        ...colors
    },
    typography: {
        fontFamily: "GT Flexa, sans-serif",
        h1: {
            fontSize: 71,
            lineHeight: "80px"
        },
        h2: {
            fontSize: 64,
            lineHeight: "72px"
        },
        h3: {
            fontSize: 56,
            lineHeight: "64px"
        },
        h4: {
            fontSize: 48,
            lineHeight: "56px"
        },
        h5: {
            fontSize: 40,
            lineHeight: "48px"
        },
        h6: {
            fontSize: 32,
            lineHeight: "40px"
        },
        subtitle1: {
            fontSize: 24,
            lineHeight: "32px"
        },
        subtitle2: {
            fontSize: 16,
            lineHeight: "24px",
        },
        paragraph: {
            fontSize: 20,
            lineHeight: "28px",
        },
        bodyXl: {
            fontSize: 18,
            lineHeight: "26px",
        },
        body: {
            fontSize: 16,
            lineHeight: "24px",
        },
        bodySmall: {
            fontSize: 14,
            lineHeight: "22px",
        },
        bodyButton: {
            fontSize: 14,
            lineHeight: "18px",
        },
        bodyXs: {
            fontSize: 12,
            lineHeight: "20px",
        },
        label: {
            fontSize: 10,
            lineHeight: "10px",
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [GTFlexaRegular, GTFlexaLight, GTFlexaThin, GTFlexaBold, GTFlexaMedium, GTFlexaBlack, GTFlexaMonoRegular, GTFlexaMonoLight, GTFlexaMonoThin, GTFlexaMonoBold, GTFlexaMonoMedium, GTFlexaMonoBlack],
                body: {
                    backgroundColor: "#FFF8F3"
                },
                a: {
                    cursor: 'pointer',
                    color: colors.primary.dark,

                    "&:hover": {
                        textDecoration: 'underline'
                    }
                }
            },
        },
        MuiMobileStepper: {
            root: {
                padding: 0,
                background: colors.common.white
            },
            dot: {
                width: 10,
                height: 10,
                margin: "0 4px"
            }
        },
        MuiInput: {
            underline: {
                "&:before": {
                    borderBottom: `2px solid ${colors.primary.main}`
                }
            }
        },
        MuiTooltip: {
            arrow: {
                color: `${colors.secondary.dark} !important`,
            },
            tooltip: {
                backgroundColor: `${colors.secondary.dark} !important`,
                fontFamily: "GT Flexa Mono, sans-serif",
                boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04) !important",
                padding: `16px 24px !important`,
                borderRadius: "16px",
                fontSize: 10,
                lineHeight: "10px",
                color: `${colors.common.white} !important`
            },
        }
    },
    spacing: [0, 8, 16, 24, 32, 40, 56, 72, 80, 96, 120],
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 825,
            lg: 1280,
            xl: 1920
        }
    }
})
export default theme
