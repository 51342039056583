import { makeStyles, createStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    //modal
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialog: {
        background: palette.common.white,
        borderRadius: spacing(2)
    },
    dialogModal: {
        background: palette.common.white,
        borderRadius: 16
    },
    dialogScrollPaper: {
        maxWidth: 1000,
    },
    dialogContent: {
        padding: 0,
        paddingTop: "0!important"
    },
    //
    // profile
    main: {
        background: palette.common.white,
        padding: spacing(4),
        borderRadius: spacing(4),
        position: "relative"
    },
    wrap: {
        paddingBottom: spacing(3),
        display: "flex",
        justifyContent: "space-between"
    },
    imgWrap: {
        width: 48,
        height: 48,
        border: `1px solid ${palette.text.backgroundMain}`,
        borderRadius: spacing(1),
        marginRight: spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    scores: {
        padding: `${spacing(4)}px 0 0 ${spacing(4)}px`,
        display: "flex",
        flexDirection: "column",
        flex: "50%"
    },
    imagePlaceholder: {
        cursor: "pointer",
        maxWidth: 36
    },
    profileImage: {
        maxWidth: 36
    },
    companyName: {
        color: palette.secondary.light
    },
    matchScore: {
        color: palette.text.dark,
        display: "flex",
        alignItems: "center",
        marginTop: spacing(2),
        marginRight: 240
    },
    infoIcon: {
        cursor: "pointer",
        marginLeft: spacing(2)
    },
    valuerScoreNum: {
        color: palette.secondary.light,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    basicInfo: {
        marginTop: spacing(3)
    },
    infoTitle: {
        fontWeight: "500",
        color: palette.text.main,
        width: 196,

    },
    infoValue: {
        color: palette.text.lighter,
        width: 196,
    },
    link: {
        color: palette.secondary.dark,
        maxWidth: 200,
        overflowWrap: "anywhere"
    },
    money: {
        color: palette.success.main
    },
    description: {
        color: palette.text.main,
    },
    technologies: {
        marginTop: spacing(2),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    tag: {
        marginRight: spacing(1),
        marginTop: spacing(1)
    },
    sdgWrap: {
        marginTop: spacing(3),
        display: "flex",
        flexDirection: "column"
    },
    sdg: {
        marginRight: spacing(1),
        marginTop: spacing(1),
        cursor: "pointer"
    },
    sdgTooltip: {
        textAlign: "center"
    },
    sdgTooltipTitle: {
        fontWeight: "700",
    },
    sdgUnlockToAccess: {
        color: palette.grey.main,
        verticalAlign: "super"
    },
    dataTitle: {
        color: palette.secondary.light
    },
    dataValue: {
        color: palette.primary.main,
        marginLeft: spacing(1)
    },
    dataCompleteness: {
        display: 'flex',
        cursor: "pointer"
    },

    nameImageWrap: {
        width: "100%"
    },
    valuerScoreBlock: {
        background: palette.primary.backgroundMedium,
        boxShadow: `inset 1px 0px 0px #EFEFEE`,
        height: "calc(100% + 32px)",
        width: 240,
        borderTopRightRadius: spacing(3),
        marginRight: -spacing(4),
        marginTop: -spacing(4),
        position: "relative",
        "&::after": {
            position: "absolute",
            backgroundColor: "rgba(107, 119, 140, 0.1)",
            content: '""',
            right: 0,
            bottom: 0,
            height: 1,
            width: 904
        }
    },
    valuerScore: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    chart: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: spacing(2)
    },
    scoreBreakdown: {
        marginTop: -spacing(1),
        marginBottom: spacing(2),
        cursor: "pointer"
    },
    infoIconChart: {
        position: "absolute",
        top: 12,
        right: 12,
        cursor: "pointer"

    },
    score: {
        lineHeight: "40px",
        position: "absolute",
        top: 60
    },
    descriptionScores: {
        marginTop: spacing(2),
        display: "flex"
    },
    descriptionBox: {
        paddingRight: spacing(4),
        marginTop: spacing(4),
        borderRight: `1px solid ${palette.text.backgroundMain}`,
        flex: "50%"
    },
    actionItems: {
        paddingTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        justifyContent: "space-between"
    },
    commentActionItems: {
        paddingTop: spacing(3),
        marginTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    commentActionItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    communicationIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`
    },
    numberOfEmployees: {
        minHeight: 18,
    },
    enrichedProfileTag: {
        color: palette.common.white,
        background: palette.primary.main,
        padding: `0 ${spacing(1)}px`,
        borderRadius: shape.borderRadius,
        height: spacing(3),
        display: "flex",
        alignItems: "center",
        width: 105
    },
    emptyTag: {
        height: spacing(3),
    },
    companyVoteActions: {
        paddingTop: spacing(3),
        marginTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    companyVote: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    questionMark: {
        marginRight: spacing(1)
    },
    recommendationUseful: {
        paddingRight: `${spacing(2)}px`
    },
    voteIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`,
        "&:hover": {
            cursor: "pointer",
        },
    },
    dislikeImg: {
        transform: "rotate(180deg)"
    },
    flexSpaceStart: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: spacing(1)
    },
    flexSpaceBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    textarea: {
        background: palette.common.white,
        border: "1px solid",
        borderColor: palette.text.lighter,
        width: 407,
        fontFamily: "GT Flexa, sans-serif",
        outline: 'none',
        padding: spacing(1),
        resize: 'none',
        minHeight: 196,
        maxHeight: 196,
        overflow: "auto",
        borderRadius: spacing(1),
        color: palette.text.lighter,
    },
    buttons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: `0 ${spacing(5)}px ${spacing(2)}px 0`
    },
    cancelBtn: {
        background: "transparent",
        marginRight: spacing(2),
        border: "none",
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },
    },
    saveBtn: {
        marginLeft: spacing(1)
    },
    textInput: {
        "& input": {
            width: 60
        },
    },
    textInputName: {
        "& input": {
            width: 289
        },
    },
    textInput180: {
        "& input": {
            width: 180
        },
    },
    currencyInputs: {
        display: "flex"
    },
    textInput85: {
        "& input": {
            width: 80,
        },
    },
    ml8: {
        marginLeft: spacing(1)
    },
    multiselect: {
        border: "none!important",
        borderRadius: spacing(1),
        marginTop: spacing(1),
        marginLeft: -spacing(1)
    },
    arrowDown: {
        position: "absolute",
        right: spacing(2),
        cursor: 'pointer',
        zIndex: 1
    },
    labelClass: {
        color: palette.text.main,
        fontSize: 14,
        lineHeight: "18px",

    },
    tagBtn: {
        cursor: "pointer"
    }
    //
}))