import React from 'react';
import useStyles from "./style";

const Column = ({ children, testId }) => {
    const classes = useStyles();
    return (
        <div className={classes.column} test-id={`column-${testId}`}>
            {children}
        </div>
    );
}
export default Column;