import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const getAdminUsersRequest = createAction(
    `${REDUCER_PREFIX}/getAdminUsersRequest`
);
export const createAdminUserRequest = createAction(
    `${REDUCER_PREFIX}/createAdminUserRequest`
);
export const updateAdminUserRequest = createAction(
    `${REDUCER_PREFIX}/updateAdminUserRequest`
);
export const getAdminUserRequest = createAction(
    `${REDUCER_PREFIX}/getAdminUserRequest`
);
export const deleteAdminUserRequest = createAction(
    `${REDUCER_PREFIX}/deleteAdminUserRequest`
);