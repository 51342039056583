import React, { useEffect, useState } from "react";
import { useStyles } from "./style.js";

const ScoreChart = ({ value }) => {
    let [numToDeg, setNumToDeg] = useState(value * 3.6);
    useEffect(() => {
        setNumToDeg(value && (value * 3.6).toFixed());
    }, [value]);

    const classes = useStyles({ numToDeg });
    return (
        <figure>
            <div className={classes.chartWrap}>
                <span className={classes.percentage}>{value}</span>
            </div>
        </figure>
    )
};
export default ScoreChart;