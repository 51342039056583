import React, { useState } from "react";
import classnames from "classnames";
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//Components
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import TextField from "../../../../components/TextField/TextField";
//Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../../../assets/icons/SearchPlaceholderIcon.svg";
//Redux
import { getAllTechnologiesTotalCountState } from "../../../../store/technologies/selectors";
import { useSelector } from "react-redux";
const Header = ({
    onSearch,
    onAddTechnology,
    user
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const totalCount = useSelector(getAllTechnologiesTotalCountState);
    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        onSearch(event.target.value);
    };
    return (
        <div className={classes.root}>
            <div className={classes.infoActions}>
                <div className={classes.titleResultsNum}>
                    <Typography variant="subtitle1" color="secondaryDark">{t("technology.technologies")}</Typography>
                    <Typography variant="label" color="secondaryDark">{t("technology.totalCount", { total: totalCount })}</Typography>
                </div>
                <div className={classes.actions}>
                    <Button
                        onClick={onAddTechnology}
                        variant="primary"
                        disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}
                        test-id="add-technology"
                    >
                        {t("technology.addTechnology")}
                    </Button>
                </div>
            </div>
            <div className={classes.searchFilters}>
                <div className={classes.header}>
                    <div className={classes.refineAndSearch}>
                        <TextField testId="search-technologies" endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Header;