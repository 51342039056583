import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
    root: {
        padding: spacing(4),
        maxWidth: 1146,
        margin: "0 auto"
    },
    technologiesBlocks: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    }
}));