import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    dialogRoot: {
        justifyContent: "center",
        textAlign: "left",
        position: "absolute",
        right: "0!important",
        top: 165,
        padding: spacing(4),
        background: palette.common.white,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: spacing(2),
        width: 320,
        border: `1px solid ${palette.text.backgroundDark}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 99,
        "&::before": {
            content: '""',
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            top: "-15px",
            left: ({ isProfile }) => isProfile ? "60%" : "75%",
            marginLeft: "-10px",
            borderTop: `10px solid ${palette.text.backgroundDark}`,
            marginTop: "5px",
            transform: "rotate(180deg)"
        },
        "&::after": {
            content: '""',
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            top: "-7px",
            left: ({ isProfile }) => isProfile ? "60%" : "75%",
            marginLeft: "-10px",
            borderTop: `10px solid ${palette.common.white}`,
            marginTop: "-2px",
            zIndex: 1,
            transform: "rotate(180deg)"
        }
    },
    dialogContent: {
        width: "100%",
        overflow: "auto",
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
    },
    title: {
        color: palette.secondary.dark,
    },
    scoreCalculated: {
        color: palette.secondary.dark,
        marginRight: 4
    },
    scoreMax: {
        color: palette.grey.main
    },
    progressWrap: {
        height: 4,
        background: palette.text.backgroundMain,
        borderRadius: shape.borderRadius,
        position: "relative",
        marginTop: 5,

    },
    progress: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        background: palette.primary.main,
        border: `2px solid ${palette.primary.dark}`,
        height: 4,
    },
    scoreRow: {
        marginTop: spacing(4)
    },
    infoIcon: {
        cursor: "pointer"
    },
    flexCenterJustify: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    }

}))