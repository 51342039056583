import React from "react";
import classnames from "classnames";
//Hooks
import { useStyles } from "./style";
import { useEditCompanyHook } from "../../../hooks/useEditCompanyHook.js"
//Components
import Typography from "../../../../../components/Typography/Typography";
import Button from "../../../../../components/Button/Button";
import TextField from "../../../../../components/TextField/TextField";
import Multiselect from "../../../../../components/Multiselect/Multiselect";
import Grow from '@material-ui/core/Grow';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import ScoreChart from "../ScoreChart/ScoreChart";
import ScoreBreakDownModal from "../ScoreBreakDownModal/ScoreBreakDownModal.js";
import Link from "../../../../../components/Link/Link";
import Tag from "../../../../../components/Tag/Tag";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//Assets
import { ReactComponent as ImagePlaceholder } from "../../../../../assets/icons/ImagePlaceholder.svg";
import { ReactComponent as InfoIconNew } from "../../../../../assets/icons/InfoIconNew.svg";
import InfoIcon from '../../../../../assets/icons/InfoIcon.svg';
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/ArrowDownGrey.svg";

const CompanyEditModal = ({ handleClose, dialogOpened, companyData, isIndustry }) => {
    const classes = useStyles();
    const {
        t,
        slug,
        search,
        company,
        dispatch,
        postSearchResponse,
        searchResultsStatus,
        getCompanyStatus,
        setCompanyResult,
        name,
        degree_of_fit,
        website,
        number_of_employees,
        total_funding_amount_currency,
        total_funding_amount_dollars,
        hq_country,
        investment_stage,
        description,
        sectors,
        subsectors,
        enrichment_request,
        founded_date,
        formatNumber,
        breakDownModal,
        setShowBreakdownModal,
        companyResult,
        handleNewDescriptionText,
        newDescriptionText,
        handleFoundedDateChange,
        newDateText,
        newWebsiteText,
        handleWebsiteTextChange,
        newFundingAmountDollarsText,
        newFundingAmountCurrencyText,
        handleFundingTextChange,
        handleCurrencyTextChange,
        handleCountryChange,
        newCountryText,
        allCountries,
        investmentStageText,
        handleInvestmentStageChange,
        allStageOptions,
        allNumEmployeesOptions,
        allNumEmployeesText,
        handleNumEmployeeTextChange,
        handleSavingCompany,
        closeModal,
        handleTitleChange,
        newTitleText
    } = useEditCompanyHook({ handleClose, dialogOpened, companyData, isIndustry });

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialogModal, scrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    handleClose();
                }
            }}
            open={dialogOpened}>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.main}>
                    <div className={classes.wrap}>
                        <div className={classes.nameImageWrap}>
                            <div className={classes.flexSpaceStart}>
                                <div className={classes.flexColumn}>
                                    <div className={classes.flexCenter}>
                                        <div className={classes.imgWrap}>
                                            {companyData && companyData.icon ? <img alt={name} className={classes.profileImage} src={companyData && companyData.icon}></img> :
                                                <Tooltip title={<Typography color="white" variant="body2">{t("companyProfile.companyLogoInfo")}</Typography>} arrow>
                                                    <ImagePlaceholder className={classes.imagePlaceholder} />
                                                </Tooltip>
                                            }
                                        </div>
                                        <TextField value={newTitleText} className={classes.textInputName} onChange={handleTitleChange}></TextField>
                                    </div>
                                    <div className={classes.technologies}>
                                        <div className={classes.flexColumn}>
                                            <div className={classes.flexWrap}>
                                                {sectors && sectors.map((tag, i) => (
                                                    <Tag key={`sector-${i}`} className={classes.tag}>
                                                        <Typography variant="caption" component="span">
                                                            {tag.name}
                                                        </Typography>
                                                    </Tag>
                                                ))}
                                                {/* <Tag className={classnames([classes.tag, classes.tagBtn])}>
                                                    <Typography variant="caption" component="span">
                                                        {t("companyProfile.addIndustry")}
                                                    </Typography>
                                                </Tag> */}
                                            </div>
                                            <div className={classes.flexWrap}>
                                                {subsectors && subsectors.map((tag, i) => (
                                                    <Tag key={`sector-${i}`} className={classes.tag} variant="sub">
                                                        <Typography variant="caption" component="span">
                                                            {tag.name}
                                                        </Typography>
                                                    </Tag>
                                                ))}
                                                {/* <Tag className={classnames([classes.tag, classes.tagBtn])} variant="sub">
                                                    <Typography variant="caption" component="span">
                                                        {t("companyProfile.addSubIndustry")}
                                                    </Typography>
                                                </Tag> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={classes.valuerScoreBlock}>
                                <Tooltip title={<Typography component="div" color="white" variant="body2">{t("companyProfile.infoDescription")}</Typography>} arrow>
                                    <img
                                        src={InfoIcon}
                                        alt={t('results.valuerScore')}
                                        className={classes.infoIconChart}
                                    />
                                </Tooltip>
                                <div className={classes.valuerScore}>
                                    <ScoreChart value={companyData && companyData.valuer_score && Math.round(companyData.valuer_score * 100)} />
                                    <Typography onMouseEnter={() => setShowBreakdownModal(true)} className={classes.scoreBreakdown} color="secondarydark" variant="bodyXs">{t("companyProfile.scoreBreakdown")}</Typography>
                                    {breakDownModal && <ScoreBreakDownModal isProfile={true} company={companyData} onChange={() => setShowBreakdownModal(false)}></ScoreBreakDownModal>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.descriptionScores}>
                        <div className={classes.descriptionBox}>
                            <Typography variant="body" className={classes.description} component="div">
                                <TextareaAutosize
                                    className={classes.textarea}
                                    minRows={1}
                                    maxRows={3}
                                    placeholder={t('companyProfile.addDescripton')}
                                    value={newDescriptionText}
                                    onChange={handleNewDescriptionText}
                                    onKeyPress={handleNewDescriptionText}
                                />
                            </Typography>
                        </div>
                        <div className={classes.scores}>
                            <div className={classes.flexSpaceBetween}>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.yearOfInception")}</Typography>
                                    <TextField value={newDateText} component="div" className={classes.textInput} onChange={handleFoundedDateChange}></TextField>
                                </div>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.location")}</Typography>
                                    <Multiselect removeLabel testId="multiselect-location" placeholder={t("companyProfile.selectLoaction")} iconArrow={<ArrowDown className={classes.arrowDown} />} customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="180px" options={allCountries} value={newCountryText} onChange={(e) => handleCountryChange(e)}></Multiselect>
                                </div>
                            </div>
                            <div className={classnames([classes.basicInfo, classes.flexSpaceBetween])}>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.currentStage")}</Typography>
                                    <Multiselect removeLabel testId="multiselect-location" placeholder={t("companyProfile.selectLoaction")} iconArrow={<ArrowDown className={classes.arrowDown} />} customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="180px" options={allStageOptions} value={investmentStageText} onChange={(e) => handleInvestmentStageChange(e)}></Multiselect>
                                </div>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.fundingRaised")}</Typography>
                                    <div className={classes.currencyInputs}>
                                        <TextField className={classes.textInput80} placeholder={t("companyProfile.amount")} value={newFundingAmountDollarsText} component="div"
                                            onChange={handleFundingTextChange}></TextField>

                                        {/* <TextField placeholder={t("companyProfile.currency")} value={newFundingAmountCurrencyText} component="div" className={classnames([classes.textInput, classes.ml8])} onChange={handleCurrencyTextChange}></TextField> */}
                                    </div>
                                </div>
                            </div>
                            <div className={classnames([classes.basicInfo, classes.flexSpaceBetween])}>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.companySize")}</Typography>
                                    <Multiselect removeLabel testId="multiselect-location" placeholder={t("companyProfile.selectLoaction")} iconArrow={<ArrowDown className={classes.arrowDown} />} customClass={classes.multiselect} labelClass={classes.labelClass} className={classes.rowWrap} width="180px" options={allNumEmployeesOptions} value={allNumEmployeesText} onChange={(e) => handleNumEmployeeTextChange(e)}></Multiselect>
                                </div>
                                <div className={classes.flexColumn}>
                                    <Typography className={classes.infoTitle} variant="body2">{t("companyProfile.website")}</Typography>
                                    <TextField value={newWebsiteText} component="div" className={classes.textInput180} onChange={handleWebsiteTextChange}></TextField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <Button onClick={() => closeModal()} className={classes.cancelBtn} variant="tertiary">{t("companyProfile.cancel")}</Button>
                    <Button onClick={() => handleSavingCompany()} className={classes.saveBtn} variant="primary">{t("companyProfile.save")}</Button>
                </div>
            </MuiDialogContent>
        </Dialog >
    )
};
export default CompanyEditModal;