import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import classnames from "classnames";
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/TextField/TextField";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Modal from '../../../components/Modal/Modal.js';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";

//Assets
import CloseIcon from '../../../assets/icons/CloseIcon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/EyeIconNew.svg';
import { ReactComponent as EyeIconActive } from '../../../assets/icons/EyeIconNewActive.svg';
//Redux
import { createTeamMemberRequest } from "../../../store/subscriptions/requests";
import { createTeamMemberMessageFailureSelector, createTeamMemberStatusSelector } from "../../../store/subscriptions/selectors";
import { actions as subscriptionsActions } from "../../../store/subscriptions/slice";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";

const AddNewUserModal = ({
    dialogOpened,
    onClose,
    team
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const createTeamMemberMessageFailure = useSelector(createTeamMemberMessageFailureSelector);
    const createTeamMemberStatus = useSelector(createTeamMemberStatusSelector);
    const [emailValue, setEmailValue] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

    const create = () => {
        dispatch(createTeamMemberRequest({ teamId: team.id, data: { name: nameValue, email: emailValue, password: passwordValue, "password_confirmation": confirmPasswordValue } }));
    };

    useEffect(() => {
        createTeamMemberStatus === REQUEST_SUCCESS && handleClose();
    }, [createTeamMemberStatus])

    const handleClose = () => {
        onClose();
        setEmailValue("");
        setNameValue("");
        setPasswordValue("");
        setConfirmPasswordValue("");
        setPasswordFieldType(true);
        setPasswordConfirmationFieldType(true);
        dispatch(subscriptionsActions.clearCreateTeamMemberMessageFailure());
    };

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPasswordValue(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    }

    const handleNameChange = (event) => {
        setNameValue(event.target.value);
    }

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(prevPasswordFieldType => !prevPasswordFieldType);
    };
    const [passwordFieldType, setPasswordFieldType] = useState(true);

    const handlePasswordConfirmationFieldTypeChange = () => {
        setPasswordConfirmationFieldType(prevPasswordFieldType => !prevPasswordFieldType);
    };
    const [passwordConfirmationFieldType, setPasswordConfirmationFieldType] = useState(true);

    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Modal
            onClose={handleClose}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px"
        >
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="paragraph" component="div" className={classes.filterDialogTitle}>{t('users.addNewUserTitle')}</Typography>
            </MuiDialogTitle>
            <Box className={classes.rows}>
                <div className={classes.row}>
                    <TextField testId="header-name-field" value={nameValue || ""} className={classes.modalInput} fullWidth label={t("users.nameInputPlaceholder")} onChange={handleNameChange}></TextField>
                </div>
                <div className={classes.row}>
                    <TextField
                        testId="header-email-field"
                        autocomplete="one-time-code"
                        value={emailValue || ""}
                        className={classes.modalInput}
                        fullWidth
                        label={t("users.emailInputPlaceholder")}
                        onChange={handleEmailChange}
                        error={!!emailValue && !validateEmail(emailValue)}
                        {...!!emailValue && !validateEmail(emailValue) && { helperText: t("common.emailError") }}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        testId="header-password-field"
                        value={passwordValue || ""}
                        className={classes.modalInput}
                        autocomplete="one-time-code"
                        type={passwordFieldType ? "password" : "text"}
                        fullWidth
                        label={t("users.passwordInputPlaceholder")}
                        onChange={handlePasswordChange}
                        error={!!passwordValue && passwordValue.length < 8}
                        {...(!!passwordValue && passwordValue.length < 8) && { helperText: t("common.passwordError") }}
                        endAdornment={passwordFieldType ? <EyeIcon className={classes.eye} onClick={handlePasswordFieldTypeChange} /> : <EyeIconActive className={classes.eye} onClick={handlePasswordFieldTypeChange} />}
                    />
                </div>
                <div className={classes.row}>
                    <TextField
                        autocomplete="one-time-code"
                        testId="header-confirm-password-field"
                        value={confirmPasswordValue || ""}
                        className={classes.modalInput}
                        fullWidth
                        type={passwordConfirmationFieldType ? "password" : "text"}
                        label={t("users.confirmPasswordInputPlaceholder")}
                        onChange={handleConfirmPasswordChange}
                        error={!!confirmPasswordValue && passwordValue !== confirmPasswordValue}
                        {...(!!confirmPasswordValue && passwordValue !== confirmPasswordValue) && { helperText: t("common.passwordConfirmError") }}
                        endAdornment={passwordConfirmationFieldType ? <EyeIcon className={classes.eye} onClick={handlePasswordConfirmationFieldTypeChange} /> : <EyeIconActive className={classes.eye} onClick={handlePasswordConfirmationFieldTypeChange} />}
                    />
                </div>
            </Box>
            {!!createTeamMemberMessageFailure && <Typography variant="body" component="div" gutterBottom color="danger">
                {createTeamMemberMessageFailure}
            </Typography>}
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.rowWrapButtons}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={handleClose}
                        test-id="cancel-filters"
                    >
                        {t("users.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        className={classes.showResultsButton}
                        disabled={!emailValue || !passwordValue || (!!passwordValue && passwordValue.length < 8) || (passwordValue !== confirmPasswordValue) || (!!emailValue && !validateEmail(emailValue))}
                        onClick={create}
                        test-id="show-results"
                    >
                        {t("users.createNewUser")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
export default AddNewUserModal;