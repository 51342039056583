import React, { useState, useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// UI components
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import FormControl from '@material-ui/core/FormControl';
import Link from '../../../components/Link/Link';
import Box from '@material-ui/core/Box';
import { UserProvider } from '../../Layout/UserProvider';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style.js";
import { useRouterHook } from "../../../common/useRouterHook";
// Assets
import { ReactComponent as EyeIcon } from '../../../assets/icons/EyeIconNew.svg';
import { ReactComponent as EyeIconActive } from '../../../assets/icons/EyeIconNewActive.svg';
// Redux
import { postLoginRequest, getMeRequest } from "../../../store/auth/requests";
import { useSelector, useDispatch } from 'react-redux';
import { getLoginStatusSelector, getLoginMessageSelector, getLogoutStatusSelector } from "../../../store/auth/selectors";
import { actions as authActions } from "../../../store/auth/slice";
import { actions as industriesActions } from "../../../store/industries/slice";
import { actions as technologiesActions } from "../../../store/technologies/slice";

// Constants
import {
    REQUEST_SUCCESS
} from "../../../constants/statuses";

const SignIn = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const dispatch = useDispatch();
    const loginStatus = useSelector(getLoginStatusSelector);
    const loginMessage = useSelector(getLoginMessageSelector);
    const reCaptchaRef = createRef();
    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    const { search } = useLocation();

    const handleCaptchaChange = () => {
        const query = new URLSearchParams(search);
        const token = query.get('token');
        dispatch(postLoginRequest({ emailValue, passwordValue, token }));
    }

    const signIn = e => {
        e.preventDefault();
        if (process.env.REACT_APP_ENV === 'prod') {
            reCaptchaRef.current.execute();
        } else {
            handleCaptchaChange();
        }
    }

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(!passwordFieldType);
    };
    const [passwordFieldType, setPasswordFieldType] = useState(true);
    const handleEmailChange = e => {
        setEmailValue(e.target.value);
        setEmailValid(validateEmail(e.target.value));
    };
    const handlePasswordChange = e => {
        setPasswordValue(e.target.value);
    };
    useEffect(() => {
        if (loginStatus === REQUEST_SUCCESS) {
            setTimeout(() => {
                openPage('industries');
            }, 1000)
        }
    }, [loginStatus]);
    
    useEffect(() => {
        dispatch(authActions.resetGetMeStatusCode());
        dispatch(industriesActions.resetIndustriesStatusCode());
        dispatch(technologiesActions.resetTechnologiesStatusCode());
    }, [])

    useEffect(() => {
        process.env.REACT_APP_ENV === 'prod' && loginMessage && reCaptchaRef.current.reset();
    }, [loginMessage])

    return (
        <Box display="flex" flexDirection="row" className={classes.root}>
            <div className={classes.formWrapperRoot}>
                <Box display="flex" flex={1} flexDirection="column" className={classes.formWrapper}>
                    <Typography variant="h4" color="black"><strong>{t('signIn.title')}</strong></Typography>
                    <form onSubmit={signIn} className={classes.form}>
                        <FormControl className={classes.inputWrapper}>
                            <TextField
                                fullWidth
                                label={t('signIn.email')}
                                id="email"
                                value={emailValue}
                                className={classes.signInInput}
                                inputProps={{ classes: { root: classes.input } }}
                                onChange={handleEmailChange}
                                error={!emailValid}
                                InputProps={{
                                    classes: {
                                        underline: classes.input
                                    }
                                }}
                                InputLabelProps={{ classes: { root: classes.inputLabel, shrink: classes.labelShrinked, focused: classes.focusedLabel } }}
                                {...!emailValid && { helperText: t('signIn.emailError') }}
                            />
                        </FormControl>
                        <FormControl className={classes.inputWrapper}>
                            <InputLabel required={true} classes={{ root: classes.inputLabel, shrink: classes.labelShrinked, focused: classes.focusedLabel }} htmlFor="password">{t('signIn.password')}</InputLabel>
                            <Input
                                fullWidth
                                label={t('signIn.password')}
                                InputLabelProps={{ classes: { root: classes.inputLabel, shrink: classes.labelShrinked, focused: classes.focusedLabel } }}
                                id="password"
                                endAdornment={passwordFieldType ? <EyeIcon className={classes.eye} onClick={handlePasswordFieldTypeChange} /> : <EyeIconActive className={classes.eye} onClick={handlePasswordFieldTypeChange} />}
                                type={passwordFieldType ? "password" : "text"}
                                value={passwordValue}
                                onChange={handlePasswordChange}
                                classes={{
                                    underline: classes.input
                                }}
                                error={passwordValue && passwordValue.length < 8}
                                {...passwordValue && passwordValue.length < 8 && { helperText: t("signUp.weakPassword", { count: 8 - passwordValue.length }) }}
                            />
                        </FormControl>
                        {process.env.REACT_APP_ENV === 'prod' &&
                            <FormControl>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_APP_KEY}
                                    size="invisible"
                                    badge="bottomleft"
                                    ref={reCaptchaRef}
                                    onChange={handleCaptchaChange}
                                />
                            </FormControl>
                        }
                        {/* <Box display="flex" flexDirection="row" justifyContent="flex-end" className={classes.rememberMeForgotWrapper}>
                            <Link href="forgot-password" variant="body2" color="primary" className={classes.forgotPassword}>
                                {t('signIn.forgotPassword')}
                            </Link>
                        </Box> */}

                        <div className={classes.createAccountButton}>
                            {loginMessage && <Typography component="div" className={classes.formError} variant="caption" color="error">{loginMessage}</Typography>}
                            <Button
                                variant="primaryDarkContained"
                                disabled={!emailValid}
                            >
                                {t("signIn.signIn")}
                            </Button>
                        </div>
                    </form>
                </Box>
            </div>
        </Box>
    )
}

export default SignIn;