// Static data
export const stageOptions = [
    { id: 6, name: "Unspecified" },
    { id: 7, name: "Private Equity" },
    { id: 8, name: "Early" },
    { id: 9, name: "Growth" },
    { id: 10, name: "Mezzanine" },
    { id: 11, name: "Start-up" },
    { id: 12, name: "Late" },
];

export const fundingOptionsMin = [
    { id: 13, name: "0" },
    { id: 14, name: "100.000" },
    { id: 15, name: "1.000.000" },
    { id: 16, name: "5.000.000" },
    { id: 17, name: "10.000.000" },
    { id: 18, name: "50.000.000" },
    { id: 19, name: "100.000.000" },
    { id: 20, name: "250.000.000" },
    { id: 21, name: "1.000.000.000" },
];

export const fundingOptionsMaxData = [
    { id: 22, name: "100.000" },
    { id: 23, name: "1.000.000" },
    { id: 24, name: "5.000.000" },
    { id: 25, name: "10.000.000" },
    { id: 26, name: "50.000.000" },
    { id: 27, name: "100.000.000" },
    { id: 28, name: "250.000.000" },
    { id: 29, name: "1.000.000.000" },
    { id: 30, name: "5.000.000.000" },
    { id: 31, name: "max" },
];

export const yearOptionsMin = [
    { id: 32, name: "1950" },
    { id: 33, name: "1960" },
    { id: 34, name: "1970" },
    { id: 35, name: "1980" },
    { id: 36, name: "1990" },
    { id: 37, name: "2000" },
    { id: 38, name: "2005" },
    { id: 39, name: "2010" },
    { id: 40, name: "2015" },
    { id: 41, name: "2016" },
    { id: 42, name: "2017" },
    { id: 43, name: "2018" },
    { id: 44, name: "2019" },
    { id: 45, name: "2020" },
    { id: 46, name: "2021" },
];

export const yearOptionsMaxData = [
    { id: 47, name: "1960" },
    { id: 48, name: "1970" },
    { id: 49, name: "1980" },
    { id: 50, name: "1990" },
    { id: 51, name: "2000" },
    { id: 52, name: "2005" },
    { id: 53, name: "2010" },
    { id: 54, name: "2015" },
    { id: 55, name: "2016" },
    { id: 56, name: "2017" },
    { id: 57, name: "2018" },
    { id: 58, name: "2019" },
    { id: 59, name: "2020" },
    { id: 60, name: "2021" },
];

export const teamSizeOptions = [
    { name: "0 to 10" },
    { name: "11 to 50" },
    { name: "51 to 100" },
    { name: "101 to 250" },
    { name: "251 to 500" },
    { name: "501 to 1000" },
    { name: "1001 to 5000" },
    { name: "5001 to 10000" },
    { name: "10001+" },
];

export const valuerScoreOptionsMin = [
    { id: 71, name: 50 },
    { id: 72, name: 60 },
    { id: 73, name: 70 },
    { id: 74, name: 80 },
    { id: 75, name: 90 },
    { id: 76, name: 95 },
];

export const valuerScoreOptionsMaxData = [
    { id: 77, name: 50 },
    { id: 78, name: 60 },
    { id: 79, name: 70 },
    { id: 80, name: 80 },
    { id: 81, name: 90 },
    { id: 82, name: 95 },
    { id: 83, name: 100 },
];

export const selectedFiltersEmptyData = {
    sector: "",
    subsector: "",
    stage: "",
    fundingMin: "",
    fundingMax: "",
    teamSize: "",
    location: "",
    yearMin: "",
    yearMax: "",
    valuerScoreMin: "",
    valuerScoreMax: "",
    search: ""
}

export const filterParamMapping = {
    sector: "sector",
    subsector: "subsector",
    stage: "stage",
    fundingMin: "funding-from",
    fundingMax: "funding-to",
    teamSize: "team-size",
    location: "country",
    yearMin: "founded-from",
    yearMax: "founded-to",
    valuerScoreMin: "valuer-score-from",
    valuerScoreMax: "valuer-score-to",
    search: "search"
}