import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from 'react-redux';
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useAuthHook } from "../../common/useAuthHook";
// Components
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import Link from '../../components/Link/Link';
import IndustryCard from "../Industries/IndustryCard/IndustryCard";
// Assets
import { ReactComponent as ArrowRight } from '../../assets/icons/ArrowRight.svg';
// Redux
import { fetchIndustryRequest } from "../../store/industries/requests";
import {
    getIndustrySelector,
    addSubindustryStatusSelector,
    deleteSubindustryStatusSelector,
    updateSubindustryStatusSelector
} from "../../store/industries/selectors";
// Constants
import {
    REQUEST_SUCCESS
} from "../../constants/statuses";

const data = {
    title: "Accomodation and Food",
    slug: "accomodation-and-food",
    icon: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis imperdiet accumsan. Morbi sollicitudin velit id lacinia sollicitudin. Nullam erat enim, tincidunt nec urna eget, consequat mattis felis. Morbi sollicitudin velit id lacinia sollicitudin. Nullam erat enim, tincidunt nec urna eget.",
    subsectors: [
        {
            name: "Agriculture",
            slug: "agriculture",
            description: "agriculture",
            lastUpdated: "January 20, 2021",
            userUpdated: "gka@valuer.ai",
            inProgress: false,
            ready: true
        },
        {
            name: "Aviation",
            slug: "aviation",
            description: "aviation",
            lastUpdated: null,
            userUpdated: null,
            inProgress: true,
            ready: false
        },
        {
            name: "AI",
            slug: "ai",
            description: "ai",
            lastUpdated: null,
            userUpdated: null,
            inProgress: false,
            ready: false
        },
    ]
}

const IndustryProfile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    let { pathname } = useLocation();
    const { user } = useAuthHook();
    const addSubindustryStatus = useSelector(addSubindustryStatusSelector);
    const deleteSubindustryStatus = useSelector(deleteSubindustryStatusSelector);
    const updateSubindustryStatus = useSelector(updateSubindustryStatusSelector);
    const industryId = pathname.substring(12, pathname.length);
    const industry = useSelector(getIndustrySelector);
    const handleGoBack = () => {
        openPage("/industries");
    };

    useEffect(() => {
        dispatch(fetchIndustryRequest({ id: industryId }));
    }, []);

    useEffect(() => {
        if (addSubindustryStatus === REQUEST_SUCCESS || deleteSubindustryStatus === REQUEST_SUCCESS || updateSubindustryStatus === REQUEST_SUCCESS) {
            dispatch(fetchIndustryRequest({ id: industryId }));
        }
    }, [addSubindustryStatus, deleteSubindustryStatus, updateSubindustryStatus]);

    const handleSave = () => {
        dispatch(fetchIndustryRequest({ id: industryId }));
    }

    return (
        <div className={classes.root}>
            <Typography onClick={handleGoBack} variant="bodyButton" rotateIcon color="secondaryDark" component="span" className={classes.goBack}>
                <ArrowRight className={classes.goBackIcon} />
                {t('common.goBack')}
            </Typography>
            <IndustryCard data={industry[0]} className={classes.card} profile onSave={handleSave} user={user} />
        </div>
    )
};
export default IndustryProfile;