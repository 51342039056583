export const EnTranslation = {
    signIn: {
        title: 'Sign-in to Valuer Admin',
        signIn: 'Sign-in',
        forgotPassword: 'Forgot your password?',
        email: 'Email',
        password: 'Password',
        emailError: 'This is not a valid email',
        passwordError: 'This is not a valid password'
    },
    sidebar: {
        startNewSearch: 'Start new search',
        search: 'Search',
        radars: 'Radar(s)',
        searchHistory: 'Search history',
        comingSoon: 'Coming soon',
        trends: 'Trends',
        technologies: 'Technologies',
        clusters: 'Clusters',
        resources: 'Resources',
        industries: 'Industries',
        kanban: 'Kanban',
        userNameExample: 'Avatar',
        profileAndSettings: 'Profile & settings',
        logOut: 'Log out',
        appVersion: 'v4.0',
        upgradeText: 'Upgrade your account from free to single/team',
        upgradeCta: 'Upgrade account',
        usersManagement: 'Users Management',
        users: 'Valuer users',
        profiles: 'Profiles'
    },
    topNav: {
        getMore: 'Get more credits',
        signIn: 'Sign in',
        signUp: 'Sign up'
    },
    board: {
        arrow: 'arrow-img',
        100: '/100',
        goBack: 'Go back',
        lastUpdate: 'Last released:',
        industry: 'Industry',
        subIndustry: 'Sub-industry',
        request100: 'ML request',
        save: 'Save',
        ready: 'Ready',
        editCompaniesKanban: 'Edit Companies Kanban',
        sortedBy: 'Sorted by',
        filters: 'Filters',
        edit: 'Edit',
        appliedFilters: 'Applied filters',
        technologyTitle: 'Technology',
        moveAllCompanies: 'Move all companies',
        move: 'Move'
    },
    companyProfile: {
        infoDescription:
            'The Valuer score is an objective ranking of a company that sums up different sub-scores the database calculates. Measured on a scale from 1 to 100, it reflects the company’s success, growth potential, maturity, innovativeness, and sustainability based on the available data and in comparison to the other companies in our database.',
        addDescripton: 'Add descripotion here...',
        save: 'Save',
        amount: 'Amount',
        currency: 'Currency',
        selectLoaction: 'Select location',
        addIndustry: '+ Add industry',
        addSubIndustry: '+ Add sub-industry',
        sectors: 'Industries',
        subsectors: 'Sub-industries',

        headerMenu: {
            share: 'Share',
            radar: {
                add: 'Add to radar',
                create: 'Create new radar'
            },
            download: 'Download as CSV'
        },
        enrichmentBanner: {
            title: {
                guest: 'Interesting company, right?',
                expired: 'Interesting company, right?',
                paying: 'Want to learn more?',
                underEnrichment: ''
            },
            description: {
                guest: 'By signing up for a <a href="https://app.valuer.ai/sign-up">free Valuer trial</a>, you can kick start our in-house team of professional analysts to provide you with a current and accurate enrichment of this company.',
                expired:
                    '<a href="https://www.valuer.ai/pricing">Upgrade Subscription</a> to order a research report containing analysis on product portfolio, business model, value prop, and much more.',
                paying: 'Order an enrichment to unlock the full company profile.',
                underEnrichment:
                    'The company is currently under the enrichment process. You can follow up its status on <a target="_blank" href="{{href}}">Orders</a> page.'
            },
            button: {
                guest: '',
                expired: '',
                paying: 'Request enrichment',
                underEnrichment: ''
            },
            link: {
                guest: '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                expired:
                    '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                paying: '<a target="_blank" href="{{href}}">Here you can preview how a full company profile looks.</a>',
                underEnrichment: ''
            }
        },
        executiveEnrichmentBanner: {
            title: 'Executive team',
            description:
                'When you upgrade, you’ll get your hands on Executive Teams. This tab grants you access to the information you need to know when it comes to the executive team of a particular company. With this, you’ll get to see who is who and their specific role within a company, paired with background information and any other relevant information.'
        },
        successEnrichmentBanner: {
            funding: {
                title: 'Funding',
                description:
                    'When you unlock Funding you’ll instantly get access to up-to-date information on the current investment round a company is in, how much money they received in each round, securing date, and who the investors were. This, in total, paired with the total funding amount, gives you a pretty practical overview of where a company is at.'
            },
            customers: {
                title: 'Customers',
                description:
                    "Here it's pretty straightforward. With Customers, you will get access to who the biggest customers of a particular company are plus their website addresses so you can slip further down the innovative rabbit hole."
            }
        },
        business_model: {
            title: 'Business model',
            description:
                'This section explains how a company operates in a business context, its target customers, and how it generates revenue.'
        },
        value_proposition: {
            title: 'Value proposition',
            description:
                'Here, you can learn the core values that the company promises to deliver to customers through its products, expertise, and services.'
        },
        product_portfolio: {
            title: 'Product Portfolio',
            description:
                'This section contains an in-depth overview of the company’s product portfolio, summarised in an easily digestible way.'
        },
        market_opportunities: {
            title: 'Market opportunities',
            description:
                'Here, you will learn how the company’s target market is expected to grow and what are the market’s most important growth drivers and key global players.'
        },
        achievements: {
            title: 'Achievements',
            description:
                'This section summarizes the company’s awards, accomplishments, and other significant milestones.'
        },
        sustainability: {
            title: 'Sustainability',
            description: 'This section summarizes the company’s sustainability plan.'
        },
        backToResults: 'Go back',
        getShareableLink: 'Share',
        shareWarning: 'Warning, anyone with access to this link will be able to access this page.',
        copiedToClipboard: 'Copied to clipboard',
        companyLogoInfo: 'The company logo is added as part of the full profile request',
        match: 'match',
        matchScoreText:
            'The Match score calculates how much the company fits your search criteria. This means the score is dynamic and depends on your search wording.',
        matchScoreTextTechnologies:
            'The Match score calculates how much the company fits the technology description.',
        valuerScoreInfo:
            'The Valuer score is an objective ranking of a company that sums up different sub-scores the database calculates. Measured on a scale from 1 to 100, it reflects the company’s success, growth potential, maturity, innovativeness, and sustainability based on the available data and in comparison to the other companies in our database.',
        valuerScore: 'Valuer Score',
        website: 'Website',
        companySize: 'Team size',
        companyStage: 'Company stage',
        fundingRaised: 'Funding',
        location: 'Country',
        currentStage: 'Company stage',
        technologies: 'Technologies',
        dataCompleteness: 'Data completeness',
        dataCompletenessInfo:
            'The Data Completeness score communicates how much data our database currently has about the company.  An enriched company will have a higher completeness score since more fields have been evaluated.',
        profile: 'Profile',
        executiveTeam: 'Executive Team',
        success: 'Success',
        comments: 'Comments',
        businessModel: 'Business Model',
        valueProposition: 'Value Proposition',
        productPortfolio: 'Product Portfolio',
        technologyOverview: 'Technology Overview',
        marketOpportunities: 'Market Opportunities',
        companyProfileIsLocked: 'Want to dig deeper?',
        enrichingTitle: 'Want to dig deeper?',
        status: 'Status:',
        requestSent: 'Request Sent',
        availableText: 'Available within 1 working week',
        unlockAccess: 'Submit research request to get bespoke insights',
        requestFullProfile: 'Submit research request',
        orderAnalystResearchPaper: 'Order company enrichment',
        requestEnrichment: 'Request enrichment',
        cancel: 'Cancel',
        done: 'Done',
        thisWillCost: 'This will cost {{count}} credit.',
        thisWillCost__plural: 'This will cost {{count}} credits.',
        youHaveCredits: 'Currently, you have {{count}} credit.',
        youHaveCredits__plural: 'Currently, you have {{count}} credits.',
        analystsCanPrepare: 'The enrichment will be ready in 5 workdays.',
        enrichRequestModalFooter: {
            text: 'Any questions?',
            link: 'Talk to sales.'
        },
        enrichedLabel: 'Enriched Profile',
        weReceivedOrder: 'You ordered a company enrichment',
        weSentConfirmation:
            'We will notify you when it’s ready.\n' +
            "In the meantime, you can follow the progress in <a target='_blank' href={{href}}>Orders</a>.",
        wellSendEmail: '... and will receive one again when your data is ready.',
        dontHaveEnoughCreditsTitle: 'You do not have enough credits',
        youCan: 'You can ',
        topUp: 'top up',
        toComplete: ' your credits to complete the order.',
        topUpCredits: 'Top up credits',
        numberOfCredits: 'Number of credits',
        buyNow: 'Buy now',
        vatAdded: 'Vat will be added as applicable',
        alwaysRunning: 'Always running out of credits? ',
        upgradeYourPlan: 'Upgrade your plan',
        price: 'Price:',
        eur: '{{count}} EUR',
        seeMore: 'See more',
        scoreBreakdown: 'See Score Breakdown',
        breakDownScoreTitle: 'Breakdown Of The Valuer Score',
        successScore: 'Success',
        successGrowth: 'Growth',
        successMaturity: 'Maturity',
        successInnovation: 'Innovation',
        successSustainability: 'Sustainability',
        successTooltipText: `The Success score uses company data, including its
								funding history, age, and size, to calculate how
								successful it currently is.`,
        growthTooltipText: `The Growth score uses a combination of data, including
								the company’s development and funding history, to
								forecast how likely the company is to grow in the
								future.`,
        maturityTooltipText: `The Maturity score uses a combination of data, including
								a company’s development stage, funding history, and size
								to reflect how self-sustaining it currently is.`,
        innovationTooltipText: `The Innovation score reflects how unique and original a
								company’s approach is at addressing an issue or a market
								gap compared to its peers.`,
        sustainabilityTooltipText: `The Sustainability score reflects the scale at which a
								company’s products and services can be used to make
								business operations more sustainable.`,
        100: '/ 100',
        sdg: 'SDG',
        sustainableDevelopmentGoals: 'Sustainable Development Goal',
        thisCompanyWorksTowards: 'This company works towards',
        mlResultShareContent: 'This link is no longer available.',
        unlockItToAccessSdg: 'Unlock it to access this information ',
        tags: 'Tags',
        addNewTag: 'Add new tag',
        enterNewTagName: 'Enter new tag name',
        errorNewTagEmpty: 'Field is empty',
        errorNewTagMinLength: 'The tag name must be at least 2 characters.',
        removeTag: 'Remove tag',
        requestReport: 'Request report',
        addToRadar: 'Add to radar',
        yearOfInception: 'Year of inception',
        readMore: 'Read more',
        goToNext: 'Go to next ({{name}})',
        like: 'Like',
        disLike: 'Dislike',
        freeUserTitle:
            'Interesting company, right? Upgrade your subscription to order bespoke research and further analysis on product/service model, business model, value prop, and much more.',
        freeUserSubtitle: 'You are just a few steps away',
        toOrder:
            'to order a research report containing analysis on product portfolio, business model, value prop, and much more.',
        wantToDig: 'Want to dig deeper?',
        submit: 'Submit a research request',
        researchRequest: '(1 credit) to receive the full research report on this company.',
        whatWillIGet: 'What will I get?',
        businessModelDesc:
            "Business models let you know how the inner engine of a company is running. By upgrading your Valuer subscription, you get immediate access to data and detailed descriptions of a company’s business model. So you can determine how they’re operating and where exactly they're headed.",
        valuePropositionDesc:
            'By upgrading your subscription you can discover the Value Proposition of a company right here in this tab. Don’t judge a book by its cover, instead find out what a company promises to deliver to its customers through products, experience, communication, services, etc. See where a company’s core values lie and determine if this company is the right fit for your needs.',
        productPortfolioDesc:
            'Get to the bread and butter of a company through its product portfolio. Here you can get an in-depth overview of what a company is developing and delivering. This is where the final product of a company’s innovation gets put on display and packaged in an easy-to-digest way. To get you the information you need, while cutting the unnecessaries.',
        technologyOverviewDesc:
            'This is where the magic happens. Jump down the technological rabbit hole and discover what makes up the backbone of innovative companies. Get extensive data and information on the technology that makes a company tick and see for yourself how any company you’re interested in can develop, deliver, and create their products and solutions.',
        marketOpportunitiesDesc:
            'Discover the launchpad for any company through our Market Opportunities. Here you’ll find information on where a company is gaining traction and carving out its own position in the market. Here you can gain access to information and hard numbers from various reports, market projections, and any other relevant information.',
        futurePlansDesc:
            "Blast off. Here you will find information on what a company has achieved and where it's headed. Find out what a company’s plan is for the future and the figures related to its investment round, maturity, milestones, accolades, and much more.",
        businessModelTitle: 'Business model',
        valuePropositionTitle: 'Value Proposition',
        productPortfolioTitle: 'Product Portfolio',
        technologyOverviewTitle: 'Technology Overview',
        marketOpportunitiesTitle: 'Market opportunities',
        futurePlansTitle: 'Achievements and Future Plans',
        upgradeSubscription: 'Upgrade Subscription',
        commentsTitle: 'ExecutiveTeam',
        commentsDesc:
            'What you get here when you upgrade your Valuer subscription is the ability to leave a comment on any company’s profile. Leave a comment for yourself or leave one for your team; it’s up to you.',
        executiveTeamLockDesc:
            'When you upgrade, you’ll get your hands on Executive Teams. This tab grants you access to the information you need to know when it comes to the executive team of a particular company. With this, you’ll get to see who is who and their specific role within a company, paired with background information and any other relevant information.',
        fundingTitle: 'Funding',
        fundingDesc:
            'When you unlock Funding you’ll instantly get access to up-to-date information on the current investment round a company is in, how much money they received in each round, securing date, and who the investors were. This, in total, paired with the total funding amount, gives you a pretty practical overview of where a company is at.',
        customersTitle: 'Customers',
        customersDesc:
            "Here it's pretty straightforward. With Customers, you will get access to who the biggest customers of a particular company are plus their website addresses so you can slip further down the innovative rabbit hole.",
        industriesLabel: 'Industries:',
        showMore: 'Show more',
        showLess: 'Show less',
        seeLookALike: 'See look-alikes'
    },
    companySuccess: {
        funding: 'Funding',
        totalFunding: 'Total funding',
        customers: 'Customers',
        identified: 'identified customers',
        secured: 'Secured on',
        amount: 'Funding amount',
        securingDate: 'Securing date',
        fundingType: 'Funding Type',
        investors: 'Investors',
        total: 'Total',
        unspecified: 'Unspecified'
    },
    startupCard: {
        industry: 'Industry',
        subIndustry: 'Subindustry',
        website: 'Website',
        lastUpdate: 'Last update:'
    },
    kanbanCard: {
        userPrefix: 'By:',
        deadlinePrefix: 'Deadline:',
        lastUpdatedPrefix: 'Last update:',
        previewCompanyButton: 'Preview company',
        previewTechnologyButton: 'Go to technology'
    },
    filterModal: {
        filtersTitle: 'Search results filter',
        resetButton: 'Reset filters',
        show: 'Show',
        results: 'results',
        sectorFilter: 'Industry',
        subsectorFilter: 'Sub-industry',
        stageFilter: 'Investment Stage',
        fundingFilter: 'Funding amount',
        teamFilter: 'Team size',
        locationFilter: 'Location',
        yearFilter: 'Year of inception',
        valuerScoreFilter: 'Valuer score',
        closeX: 'close',
        cancel: 'Cancel'
    },
    technology: {
        technologies: 'Technologies',
        addTechnology: 'Add technology',
        threeDots: 'Three dots',
        technologyStatus: 'Technology Status:',
        published: 'Published',
        notPublished: 'Not published',
        lastUpdate: 'Last update:',
        anotherUserWorking: 'Another user is working on this technology',
        edit: 'Edit technology',
        editCompaniesKanban: 'Edit Companies (Kanban)',
        openTechnologyProfile: 'Open technology profile',
        deleteTechnology: 'Delete technology',
        totalCount: '{{total}} results'
    },
    technologyPreview: {
        detailed_description: {
            title: 'Description',
            description:
                'This section summarizes all essential information about the technology. To achieve this, our team of technology experts digests complex concepts into understandable words.'
        },
        applications: {
            title: 'Applications',
            description:
                'Here you can learn how different industries or verticals apply the technology in real-world scenarios. This can help you visualize how the technology could fit your needs.'
        },
        benefits: {
            title: 'Benefits',
            description:
                'Want to find out how this technology can help companies improve operations or alleviate pains? This section will help you learn about the potential benefits from using this technology.'
        },
        maturity: {
            title: 'Maturity',
            description:
                'How developed is this technology? This section will help you understand if the technology has been around for some time or is still in its nascent stage and has some pains to iron out.'
        },
        market_opportunities: {
            title: 'Market Opportunities',
            description:
                'This section contains intel on the technology’s expected developments and market predictions.'
        },
        sustainability: {
            title: 'Sustainability',
            description:
                'Can this technology help your company become more sustainable? Here you can learn if the technology can help you align business operations with sustainability regulations.'
        },
        whatWillIGet: 'What Will I Get?',
        placeholderTitle: 'Other content'
    },
    addTechnologyModal: {
        closeIcon: 'close',
        createNewTechnology: 'Add new technology',
        editTechnology: 'Edit technology',
        technologyTitle: 'Technology name',
        description: 'Technology description (for ML request)',
        keywords: 'Key words/ Synonyms',
        keywordsLabel: "You can add multiple key words by hitting 'enter' after each word.",
        technologyDescriptionPlaceholder: 'Description',
        cancel: 'Cancel',
        saveChanges: 'Save',
        saveMLRequest: 'Save & ML request',
        technologyTitlePlaceholder: 'Technology name',
        maximumNumCharacters: 'Maximum number of characters exceeded.',
        previewContentModal: 'Preview content',
        detailedDescription: 'Detailed description',
        descriptionTooLong: 'Description text is too long (maximum 512 characters)'
    },
    warningModal: {
        successWhenEdit: 'The technology has been succesfully edited.',
        successWhenCreated: 'The technology {{name}} has been succesfully created.',
        unsavedChangesText: 'You have unsaved changes to the content. What do you want to do?',
        publishSuccessText: 'The technology {{name}} has been succesfully published.',
        unpublishSuccessText: 'The technology {{name}} has been succesfully unpublished.',
        unpublish: 'Are you sure you want to unpublish the {{name}} technology?',
        publish: 'Are you sure you want to publish the {{name}} technology?',
        deleteText: 'Are you sure you want to delete the technology {{name}} and all its data?',
        deleteSuccessText: 'Success message when admin DELETES a technology',
        noCancel: 'No, CANCEL',
        yesPublish: 'Yes, PUBLISH',
        yesUnpublish: 'Yes, UNPUBLISH',
        yesDelete: 'Yes, DELETE',
        checkIcon: 'Success check icon',
        closeIcon: 'Close icon',
        goBack: 'Go back',
        dontSave: "Don't save",
        saveChanges: 'Save changes'
    },
    industries: {
        title: 'Industries (Admin)',
        results: '{{count}} result',
        results_plural: '{{count}} results',
        addIndustry: 'Add industry',
        industryName: 'Industry name',
        industryDescription: 'Industry Description',
        subindustriesNumber: 'Number of subindustries',
        subindustries: 'Sub-industries',
        preview: 'Preview 2D',
        edit: 'Edit'
    },
    industryProfile: {
        editBio: 'Edit Bio',
        deleteIndustry: 'Delete Industry',
        subsectorInProgress: 'Another User Is Working On The Sub-Industry',
        mlRequest: 'Go to Kanban',
        saveChanges: 'Save Changes',
        cancel: 'Cancel',
        save: 'Save',
        add: 'Add',
        subsectorNameLabel: 'Sub-industry Name',
        subsectorDescLabel: 'Sub-industry Description',
        notUpdated: 'Not released',
        lastUpdate: 'Last update:',
        deleteSubIndustry: 'Delete sub-industry',
        addSubindustry: '+ Add sub-industry',
        deleteIndustryTitle: 'Are you sure you want to delete this industry: {{name}}?',
        deleteIndustrySubtitle: 'This action will affect all sub-industries in {{name}}.',
        deleteSubindustryTitle: 'Are you sure you want to delete this sub-industry: {{name}}?',
        saveIndustryBioTitle: "Are you sure you want to change this industry's bio: {{name}}?",
        saveIndustryBioSubtitle:
            'This action will affect the ML request for all sub-industries and will be displayed on the client side.',
        sendMlRequestTitle: 'Are you sure you want to send ML request for new 100 companies?',
        sendMlRequestSubtitle: 'This action will remove the companies currently from ”Raw output”.',
        releaseSubindustryTitle: 'Are you sure you want to release the selected companies?',
        releaseSubindustrySubtitle: 'This action affect the client view.',
        warningSubtitle: 'This action is irreversible.',
        yesSure: 'Yes, am sure I want it',
        noBack: 'No, take me back',
        successTitle: 'Success message when Admin completes some action',
        successSubtitle: 'This action was successfull',
        nameLabel: 'Industry name',
        descriptionLabel: 'Industry Description'
    },
    common: {
        goBack: 'Go back',
        loadingButton: 'Loading...',
        emailError: 'This is not a valid email',
        passwordError: 'This is not a valid password',
        passwordConfirmError: "Passwords don't match",
        errorMessage: 'This is not a valid {{label}}',
        irreversibleAction: 'This action is irreversible.',
        noTakeMeBack: 'No, take me back'
    },
    technologyProfile: {
        openClientView: 'Open client view',
        goBack: 'Go back',
        overview: 'Technology overview',
        companies: 'Companies using this technology',
        edit: 'Edit technology',
        editCompaniesKanban: 'Edit Companies (Kanban)',
        deleteTechnology: 'Delete technology',
        relevantIndustries: 'Relevant industries',
        relevantSubindustries: 'Relevant sub-industries',
        detailedDescription: 'Detailed description',
        technologyBenefits: 'Technology benefits',
        applications: 'Applications',
        maturity: 'Maturity',
        marketOpportunities: 'Market opportunities',
        sustainability: 'Sustainability',
        addContent: '+ Add content',
        editContent: 'Edit content',
        numCompanies: '{{number}} companies'
    },
    results: {
        valuerScore: 'Valuer Score',
        inceptionYear: 'Year of inception',
        country: 'Country',
        currentStage: 'Current stage',
        fundings: 'Funding',
        team: 'Team',
        addToRadar: 'Add to radar',
        match: '{{number}}% match',
        companyDetails: 'View company profile',
        isThisRecommendationUseful: 'Do you like this company?',
        infoDescription:
            'The Valuer score is an objective ranking of a company that sums up different sub-scores the database calculates. Measured on a scale from 1 to 100, it reflects the company’s success, growth potential, maturity, innovativeness, and sustainability based on the available data and in comparison to the other companies in our database.',
        logoDescription: 'The company logo is added as part of the full profile request',
        matchDescription:
            'The Match score calculates how much the company fits your search criteria. This means the score is dynamic and depends on your search wording.',
        readyForMore: 'Want to see the remaining results?',
        instantAccess: 'instant access',
        signUpAndGet: 'Sign-up and get ',
        toAll: ' to all ',
        companies: ' 100 companies',
        thatMatch: '  that match your requirements.',
        signUpNow: 'Sign up',
        noResults: "Oops... we didn't find anything that matches your search :(",
        noResultsSub: 'Try to search for something more general',
        startNewSearch: 'Start new search',
        appliedResults: 'Applied filters',
        enrichedProfile: 'Enriched Profile',
        scoreBreakdown: 'Score Breakdown',
        companyAlreadyAdded: 'Company already added to this radar',
        startFreeTrial: 'Sign up to unlock results.',
        forFree: 'For free.',
        getInstantAccess: 'Get instant access to all companies that match your requirements.',
        removeCompany: 'Remove Company',
        areYouSure: 'Are you sure you want to remove the company {{companyName}} from this radar?',
        actionIrreversible: 'This action is irreversible',
        yesRemove: 'Yes, remove company',
        noTakeBack: 'No, take me back',
        website: 'Website',
        questionMark: 'Question mark',
        like: 'Like',
        dislike: 'Dislike',
        companyComments: 'Company comments',
        areYouSureRemoveRadar: 'Are you sure you want to remove {{radarName}} radar?',
        yesRemoveRadar: 'Yes, remove radar',
        noSearchesTitle: 'You made it here. Welcome!',
        noResultsTitle: 'Hmmm - no results found',
        noResultsSubtitle:
            'Likely there was a glitch. Maybe you were too specific. Try and revise your search again.',
        noSearchesSubtitle: 'Now try your first search.',
        startSearch: 'Get started',
        searchAgain: 'Search again',
        leaveComment: 'Leave A Comment'
    },
    users: {
        title: 'Valuer Users',
        addUser: '+ Add user',
        name: 'Name',
        email: 'Email',
        role: 'Role',
        super_admin: 'Super admin',
        admin: 'Admin',
        editor: 'Editor',
        moderator: 'Moderator',
        free_trial: 'Free trial',
        free: 'Free',
        single: 'Single user',
        pro: 'Pro',
        teams: 'Teams',
        custom_subscription: 'Custom',
        deleteUser: 'Delete user',
        makeEditor: 'Make editor',
        makeModerator: 'Make moderator',
        makeAdmin: 'Make Admin',
        makeSuperAdmin: 'Make Super Admin',
        journalist: 'Journalist',
        addNewUserTitle: 'Add new Valuer User',
        nameInputPlaceholder: 'Name',
        emailInputPlaceholder: 'Email',
        passwordInputPlaceholder: 'Password',
        confirmPasswordInputPlaceholder: 'Confirm Password',
        roleInputPlaceholder: 'User role',
        createNewUser: 'Create new user',
        cancel: 'Cancel',
        removeTeamMember: 'Remove team member'
    },
    profiles: {
        title: 'Profiles',
        deleteSubscription: 'Delete subscription',
        deleteUser: 'Delete user',
        companyName: 'Company name',
        adminEmail: 'Admin email',
        addNewProfile: 'Add new profile',
        addNewProfileModalTitle: 'Add new custom profile',
        customerName: 'Customer Name',
        password: 'Password',
        confirmPassword: 'Confirm Password',
        radars: 'Radars',
        credits: 'Credits',
        userSeats: 'User seats',
        startDate: 'Start date',
        expirationDate: 'Expiration date',
        createNewSubcription: 'Create new subcription'
    },
    accountProfile: {
        title: 'Profile and Account Settings',
        manageProfileSettings: 'Manage Profile Settings',
        profileSettings: 'Profile Settings',
        manageTeam: 'Manage Team',
        usersTeam: 'Users/ Team',
        companyProfileSettings: 'Company Profile Settings',
        companyName: 'Company name',
        edit: 'Edit',
        cancel: 'Cancel',
        saveChanges: 'Save changes',
        country: 'Country',
        city: 'City',
        enterCity: 'Enter City',
        enterCountry: 'Enter company country',
        enterName: 'Enter company name',
        currentPlan: 'Current Plan',
        subscriptionType: 'Subscription type',
        creditsTitle: 'Credits',
        radarsTitle: 'Available Radars',
        monthlyCredits: 'Monthly Credits',
        topUpCredits: 'Top up Credits',
        totalCredits: 'Total Credits',
        userSeatsTitle: 'User seats',
        name: 'Name',
        role: 'Role',
        deleteSubscription: 'Delete subscription',
        addNewUser: '+ Add New User',
        addExistingUser: '+ Add Existing User',
        addExistingUserModalTitle: 'Add Existing Valuer user to this Profile',
        resendInvite: 'Resend invite',
        resendValidation: 'Resend validation',
        noData: 'No team members',
        yesDeleteProfile: 'Yes, delete profile',
        yesDeleteUser: 'Yes, delete user',
        deleteUserTitle: 'Are you sure you want to Delete {{name}}?',
        removeTeamMember: 'Are you sure you want to remove team member?',
        yesRemoveTeamMember: 'Yes, remove team member'
    }
};
