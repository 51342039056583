import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    resultsContent: {
        marginTop: spacing(3),
        display: "flex",
        flexWrap: "wrap"
    },
    resultsLoader: {
        width: "100%"
    },
}));