import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as filteringActions } from "../../filtering/slice";
import { actions as authActions } from "../../auth/slice";
import {
    getResults,
} from "../../results/saga/helpers";

function* fetchResults(data) {
    try {
        yield put(filteringActions.clearResults())
        yield put(actions.getResultsPending());
        const searchResults = yield call(getResults, data);
        yield put(actions.getResultsSuccess());
        const sliceData = { data: searchResults.data, page: data && data.page };
        yield put(actions.setResults(sliceData));
    } catch (err) {
        yield put(actions.getResultsFailure());
    }
}

export {
    fetchResults,
};
