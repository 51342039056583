const getIndustriesStatusSelector = state => state.industries.getIndustriesStatus;
const createIndustryStatusSelector = state => state.industries.createIndustryStatus;
const updateIndustryStatusSelector = state => state.industries.updateIndustryStatus;
const deleteIndustryStatusSelector = state => state.industries.deleteIndustryStatus;
const addSubindustryStatusSelector = state => state.industries.addSubindustryStatus;
const deleteSubindustryStatusSelector = state => state.industries.deleteSubindustryStatus;
const updateSubindustryStatusSelector = state => state.industries.updateSubindustryStatus;
const getIndustriesSelector = state => state.industries.pool;
const getIndustrySelector = state => state.industries.industry;
const getSubIndustrySelector = state => state.industries.subIndustry
const getSubIndustryStatusSelector = state => state.industries.getSubIndustryWithCompaniesStatus
const getLastReleaseInfoSelector = state => state.industries.lastReleaseInfo;
const getClosestSubIndustryStatusSelector = state => state.industries.postGetClosestStatus;
const updateMLCompanyStatusSelector = state => state.industries.updateMLCompanyStatus;
const releaseCompaniesStatusSelector = state => state.industries.releaseCompaniesStatus;
const postSaveCompaniesDataStatusSelector = state => state.industries.postSaveCompaniesDataStatus;
const updateMLCompanyKanbanStatusSelector = state => state.industries.updateMLCompanyKanbanStatus;
const getIndustriesStatusCodeSelector = state => state.industries.industriesStatusCode;

export {
    getIndustriesStatusSelector,
    getIndustriesSelector,
    createIndustryStatusSelector,
    getIndustrySelector,
    updateIndustryStatusSelector,
    deleteIndustryStatusSelector,
    addSubindustryStatusSelector,
    deleteSubindustryStatusSelector,
    updateSubindustryStatusSelector,
    getSubIndustrySelector,
    getSubIndustryStatusSelector,
    getLastReleaseInfoSelector,
    getClosestSubIndustryStatusSelector,
    updateMLCompanyStatusSelector,
    releaseCompaniesStatusSelector,
    postSaveCompaniesDataStatusSelector,
    updateMLCompanyKanbanStatusSelector,
    getIndustriesStatusCodeSelector
};