import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) => createStyles({
    root: {
        // marginRight: 20
    },
    infoActions: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing(4)
    },
    titleResultsNum: {
        display: "flex",
        flexDirection: "column"
    },
    mr16: {
        marginRight: spacing(2)
    },
    actions: {
        display: "flex",
        alignItems: "center"
    },
    searchFilters: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: spacing(3)
    },
    header: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
    },
    refineAndSearch: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    startEndornment: {
        marginRight: `${spacing(1)}px`
    },
    requestLink: {
        cursor: "pointer"
    },
    headerInput: {
        "& input": {
            width: 167,
        },
    },
}));