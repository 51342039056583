import React, { useState, useEffect } from "react";
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useSelector, useDispatch } from 'react-redux';
import { useAuthHook } from "../../common/useAuthHook";
// Components
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "../../components/Typography/Typography";
import IndustryCard from "./IndustryCard/IndustryCard";
// Assets
import { ReactComponent as KeyboardArrowDownIcon } from "../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as SearchPlaceholderIcon } from "../../assets/icons/SearchPlaceholderIcon.svg";
// Redux
import { fetchIndustriesRequest } from "../../store/industries/requests";
import { getIndustriesSelector } from "../../store/industries/selectors";
import { actions as authActions } from "../../store/auth/slice";

const Row = props => {
    const classes = useStyles();
    const { row, key, user, testId } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    return (
        <React.Fragment key={key}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.row} test-id={testId} onClick={() => setOpen(!open)}>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="secondaryDark">{row.name}</Typography></TableCell>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="secondaryDark">{row.subindustries.length}</Typography></TableCell>
                <TableCell className={classes.cell} align="right">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        test-id={!!open ? `collapse-${testId}` : `expand-${testId}`}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={classes.cell} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <IndustryCard data={row} user={user} testId={testId} />
                </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
const Industries = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const { user } = useAuthHook();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const industries = useSelector(getIndustriesSelector);

    useEffect(() => {
        dispatch(fetchIndustriesRequest());
    }, [dispatch])

    const handleClickOnSearch = () => {
    };

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        {t("industries.title")}
                    </Typography>
                    <Typography variant="label" gutterBottom color="textLight" component="div" className={classes.mono}>
                        {t("industries.results", { count: industries.length })}
                    </Typography>
                    <TextField testId="header-search-field" onClick={handleClickOnSearch} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField>
                </div>
                <Button onClick={() => openPage("/industries/add")} disabled={user && user.role !== "admin" && user.role !== "editor" && user.role !== "super_admin"}>
                    {t("industries.addIndustry")}
                </Button>
            </div>
             <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                    <TableRow className={classes.row}>
                        <TableCell className={classes.cell} align="left"><Typography variant="bodyButton" color="secondaryDark">{t("industries.industryName")}</Typography></TableCell>
                        <TableCell className={classes.cell} align="left"><Typography variant="bodyButton" color="secondaryDark">{t("industries.subindustriesNumber")}</Typography></TableCell>
                        <TableCell className={classes.cell}/>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {industries.map((row, i) => (
                        <Row key={row.id} row={row} user={user} testId={`industry-${i + 1}`} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};
export default Industries;