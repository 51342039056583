import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, breakpoints }) => createStyles({
    dialogRoot: {
        display: "flex",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0
    },
    dialogFilter: {
        padding: ({ paddingSpace }) => paddingSpace || spacing(3),
        background: ({ background }) => background || palette.common.white,
        borderRadius: ({ borderRadius }) => borderRadius || palette.borderRadius,
        width: "100%",
        [breakpoints.down("sm")]: {
            padding: spacing(2)
        },
        [breakpoints.up("lg")]: {
            maxHeight: ({ maxHeight }) => maxHeight || "unset",
            minHeight: ({ minHeight }) => minHeight || "unset"
        }
    },
    dialogScrollPaper: {
        maxWidth: ({ maxWidth }) => maxWidth || 800,
        width: "100%"
    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(2),
        top: spacing(2),
        color: palette.grey[500],
        padding: 0
    },
    dialogContent: {
        padding: 0
    },

}));
