import { put, call, select } from "redux-saga/effects";
import { actions } from "../slice";
import {
    getAllTechnologies,
    postCreateTechnology,
    postUpdateTechnology,
    publishTechnology,
    deleteTechnology,
    getTechnology,
    postUpdateTechnologyEditor,
    postGetClosest,
    postCompaniesData,
    getTechnologyWithCompanies,
    updateCompanyKanban,
    releaseCompanies
} from "./helpers";
import { getCookie } from "../../auth/saga/helpers";

function* fetchTechnologies() {
    try {
        yield put(actions.getTechnologiesPending());
        const response = yield call(getAllTechnologies);
        yield put(actions.setTechnologies(response));
        yield put(actions.getTechnologiesSuccess());
    } catch (err) {
        yield put(actions.getTechnologiesFailure(err.response.status));
    }
}
function* createTechnology(title, description, keywords) {
    try {
        yield put(actions.createTechnologyPending());
        const response = yield call(postCreateTechnology, title, description, keywords);
        yield put(actions.setCreatedTechnology(response))
        yield put(actions.createTechnologySuccess(response));
    } catch (err) {
        yield put(actions.createTechnologyFailure(err && err.response && err.response.status));
    }
}
function* updateTechnology(technology) {
    try {
        yield put(actions.updateTechnologyPending());
        const response = yield call(postUpdateTechnology, technology);
        yield put(actions.updateTechnologySuccess(response));
    } catch (err) {
        yield put(actions.updateTechnologyFailure(err && err.response && err.response.status));
    }
}
function* updateTechnologyEditor(technology) {
    try {
        yield put(actions.updateTechnologyPending());
        const response = yield call(postUpdateTechnologyEditor, technology);
        yield put(actions.updateTechnologySuccess(response));
    } catch (err) {
        yield put(actions.updateTechnologyFailure(err && err.response && err.response.status));
    }
}
function* postPublishTechnology(technology) {
    try {
        yield put(actions.publishTechnologyPending());
        const response = yield call(publishTechnology, technology);
        yield put(actions.publishTechnologySuccess(response));
    } catch (err) {
        yield put(actions.publishTechnologyFailure(err && err.response && err.response.status));
    }
}
function* postUnPublishTechnology(technology) {
    try {
        yield put(actions.publishTechnologyPending());
        yield call(publishTechnology, technology);
        yield put(actions.publishTechnologyPending());
    } catch (err) {
        yield put(actions.publishTechnologyFailure(err && err.response && err.response.status));
    }
}
function* postDeleteTechnology(technology) {
    try {
        yield put(actions.deleteTechnologyPending());
        const response = yield call(deleteTechnology, technology);
        yield put(actions.deleteTechnologySuccess(response));
    } catch (err) {
        yield put(actions.deleteTechnologyFailure(err && err.response && err.response.status));
    }
}
function* getTechnologyProfile(id, data, clearResults) {
    try {
        if (clearResults) {
            yield put(actions.clearCompaniesResults());
        }
        yield put(actions.getTechnologyPending());
        const response = yield call(getTechnology, id, data);
        yield put(actions.setTechnologyProfile(response.data))
        yield put(actions.getTechnologySuccess(response));
    } catch (err) {
        yield put(actions.getTechnologyFailure(err && err.response && err.response.status));
    }
}
function* submitGetClosest(technologyName, id) {
    try {
        yield call(getCookie);
        yield put(actions.postGetClosestPending());

        const response = yield call(postGetClosest, technologyName);
        yield put(actions.postGetClosestSuccess(response));
        let mlCompanies = response.data;
        const companies = JSON.stringify(mlCompanies);
        let postResultsData = new FormData();
        postResultsData.append("companies", companies);
        yield put(actions.postSaveCompaniesDataPending());
        const postResponse = yield call(postCompaniesData, postResultsData, id);
        yield put(actions.setTechnology(postResponse));
        yield put(actions.postSaveCompaniesDataSuccess(postResponse));

    } catch (err) {
        yield put(actions.postSaveCompaniesDataFailure(err && err.response && err.response.status))
        if (err && err.response && err.response.status === 442) {
            yield put(actions.postGetClosestFailure(err && err.response && err.response.status));
        } else if (err && err.response && err.response.status >= 500) {
            yield put(actions.postGetClosestTimeout());
        } else {
            yield put(actions.postGetClosestFailure(err && err.response && err.response.status));
        }
    }
}
function* submitGetTechnologyWithCompanies(id, filterString, orderBy, orderDirection) {
    try {
        yield put(actions.getTechnologyWithCompaniesPending());
        const response = yield call(getTechnologyWithCompanies, id, filterString, orderBy, orderDirection);
        yield put(actions.setTechnology(response));
        yield put(actions.getTechnologyWithCompaniesSuccess(response));
    } catch (err) {
        yield put(actions.getTechnologyWithCompaniesFailure(err && err.response && err.response.status));
    }
}
function* submitUpdateCompanyKanban(companies, isBoard) {
    try {
        isBoard ? yield put(actions.updateTechnologyKanbanStatusPending()) :
            yield put(actions.updateTechnologyKanbanPending());
        const response = yield call(updateCompanyKanban, companies);
        isBoard ? yield put(actions.updateTechnologyKanbanStatusSuccess(response)) : yield put(actions.updateTechnologyKanbanSuccess(response));
    } catch (err) {
        isBoard ? yield put(actions.updateTechnologyKanbanStatusFailure(err && err.response && err.response.status)) : yield put(actions.updateTechnologyKanbanFailure(err && err.response && err.response.status));
    }
}
function* submitReleaseCompanies(id) {
    try {
        yield put(actions.releaseCompaniesPending());
        const response = yield call(releaseCompanies, id);
        yield put(actions.processRelease(response));
        yield put(actions.releaseCompaniesSuccess(response));
    } catch (err) {
        yield put(actions.releaseCompaniesFailure(err && err.response && err.response.status));
    }
}


export {
    fetchTechnologies,
    createTechnology,
    updateTechnology,
    postPublishTechnology,
    postUnPublishTechnology,
    postDeleteTechnology,
    getTechnologyProfile,
    updateTechnologyEditor,
    submitGetClosest,
    submitGetTechnologyWithCompanies,
    submitUpdateCompanyKanban,
    submitReleaseCompanies,
};
