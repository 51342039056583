import { all, takeEvery } from "redux-saga/effects";
import {
    fetchIndustriesRequest,
    fetchIndustryRequest,
    createIndustryRequest,
    deleteIndustryRequest,
    updateIndustryRequest,
    addSubindustryRequest,
    deleteSubindustryRequest,
    updateSubindustryRequest,
    postGetClosestIndustryRequest,
    getSubIndustryWithCompaniesRequest,
    updateMLCompanySubIndustryRequest,
    updateCompanyIndustryKanbanRequest,
    releaseSubIndustryCompaniesRequest
} from "../requests";
import {
    fetchIndustries,
    fetchIndustry,
    createIndustry,
    removeIndustry,
    updateIndustry,
    addSubindustry,
    removeSubindustry,
    updateSubindustry,
    submitGetClosestIndustry,
    submitGetSubIndustryWithCompanies,
    submitUpdateMLCompanySubIndustry,
    submitUpdateCompanyIndustryKanban,
    submitReleaseSubIndustryCompanies
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchIndustries() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustriesRequest),
        () => fetchIndustries()
    );
}

function* watchFetchIndustry() {
    yield takeEvery(
        convertTypeFromAction(fetchIndustryRequest),
        ({ payload: { id } }) => fetchIndustry(id)
    );
}

function* watchPostIndustry() {
    yield takeEvery(
        convertTypeFromAction(createIndustryRequest),
        ({ payload: { data } }) => createIndustry(data)
    );
}

function* watchDeleteIndustry() {
    yield takeEvery(
        convertTypeFromAction(deleteIndustryRequest),
        ({ payload: { id } }) => removeIndustry(id)
    );
}

function* watchPutIndustry() {
    yield takeEvery(
        convertTypeFromAction(updateIndustryRequest),
        ({ payload: { data } }) => updateIndustry(data)
    );
}

function* watchPostSubindustry() {
    yield takeEvery(
        convertTypeFromAction(addSubindustryRequest),
        ({ payload: { data } }) => addSubindustry(data)
    );
}

function* watchDeleteSubindustry() {
    yield takeEvery(
        convertTypeFromAction(deleteSubindustryRequest),
        ({ payload: { id } }) => removeSubindustry(id)
    );
}

function* watchPutSubindustry() {
    yield takeEvery(
        convertTypeFromAction(updateSubindustryRequest),
        ({ payload: { data } }) => updateSubindustry(data)
    );
}

function* watchPostGetClosestIndustryRequest() {
    yield takeEvery(
        convertTypeFromAction(postGetClosestIndustryRequest),
        ({ payload: { id, subIndustry } }) => submitGetClosestIndustry(id, subIndustry)
    );
}

function* watchGetSubIndustryWithCompaniesRequest() {
    yield takeEvery(
        convertTypeFromAction(getSubIndustryWithCompaniesRequest),
        ({ payload: { id } }) => submitGetSubIndustryWithCompanies(id)
    );
}

function* watchUpdateMLCompanySubIndustry() {
    yield takeEvery(
        convertTypeFromAction(updateMLCompanySubIndustryRequest),
        ({ payload: { companies, isBoard } }) => submitUpdateMLCompanySubIndustry(companies, isBoard)
    );
}

function* watchUpdateCompanyIndustryKanban() {
    yield takeEvery(
        convertTypeFromAction(updateCompanyIndustryKanbanRequest),
        ({ payload: { companies } }) => submitUpdateCompanyIndustryKanban(companies)
    );
}
function* watchReleaseSubIndustryCompanies() {
    yield takeEvery(
        convertTypeFromAction(releaseSubIndustryCompaniesRequest),
        ({ payload: { id, companyIds } }) => submitReleaseSubIndustryCompanies(id, companyIds)
    );
}

function* saga() {
    yield all([
        watchFetchIndustries(),
        watchPostIndustry(),
        watchDeleteIndustry(),
        watchFetchIndustry(),
        watchPutIndustry(),
        watchPostSubindustry(),
        watchDeleteSubindustry(),
        watchPutSubindustry(),
        watchPostGetClosestIndustryRequest(),
        watchGetSubIndustryWithCompaniesRequest(),
        watchUpdateMLCompanySubIndustry(),
        watchUpdateCompanyIndustryKanban(),
        watchReleaseSubIndustryCompanies()
    ]);
}

export { saga };