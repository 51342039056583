import { call } from "redux-saga/effects";
import axios from "axios";
import {
    GET_KANBAN_ITEMS,
    updateKanbanItemUrl,
    deleteKanbanItemUrl
} from "../../../constants/endpoints";

function* getKanbanItems() {
    const response = yield call(axios, {
        method: "GET",
        url: GET_KANBAN_ITEMS,
        withCredentials: true
    });

    return response.data;
}
function* updateKanbanItem(data) {
    const { id, kanbanStatus } = data;

    const response = yield call(axios, {
        method: "POST",
        url: updateKanbanItemUrl(id),
        withCredentials: true,
        data: {
            "kanban-status": kanbanStatus
        }
    });

    return response.data;
}

function* deleteKanbanItem(id) {
    const response = yield call(axios, {
        method: "POST",
        url: deleteKanbanItemUrl(id),
        withCredentials: true
    });

    return response.data;
}

export {
    getKanbanItems,
    updateKanbanItem,
    deleteKanbanItem
};
