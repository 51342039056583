import { call } from "redux-saga/effects";
import axios from "axios";
import {
    RESULTS_URL,
} from "../../../constants/endpoints";

function* getResults(data) {
    const { orderBy, orderDirection } = data;
    const response = yield call(axios, {
        method: "GET",
        url: `${RESULTS_URL}`,
        withCredentials: true
    }
    );
    return response.data;
}
export {
    getResults
};
