import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./style.js";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import classnames from "classnames";

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#999999",
        fontSize: 14,
        lineHeight: "18px"
    }
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

const Multiselect = ({
    className,
    id,
    labelProps,
    label,
    error,
    onChange,
    value,
    options,
    iconArrow,
    width,
    disabled,
    removeLabel,
    customClass,
    labelClass,
    placeholder,
    testId
}) => {
    const classes = useStyles({ width, removeLabel });
    const multiSelectClasses = classnames(customClass, classes.select, { [classes.filled]: value !== "" });
    const labelClasses = classnames(classes.headerLabel, labelClass);

    return (
        <div className={className}>
            <InputLabel
                disableAnimation={true}
                focused={false}
                shrink={true}
                error={!!error}
                {...labelProps}
                className={labelClasses}
                htmlFor={id}
            >
                {label}
            </InputLabel>
            <Select
                id={id}
                className={multiSelectClasses}
                classes={{ select: classes.root, disabled: classes.disabled }}
                value={value}
                onChange={onChange}
                IconComponent={() => iconArrow}
                disabled={disabled}
                disableUnderline
                displayEmpty
                test-id={testId}
                renderValue={
                    value !== "" ? undefined : () => <Placeholder>{placeholder}</Placeholder>
                }

            >
                {options && options.map((option, i) => {
                    return <MenuItem key={`option-${i}`} value={option.name}>{option.name}</MenuItem>
                })}
            </Select>
        </div>
    )
}
export default Multiselect;