import React, { createRef, useEffect, useState } from 'react';
import axios from 'axios';
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from './style';
import { useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames';
//Components
import Profile from './Profile/Profile';
import ExecutiveTeam from './ExecutiveTeam/ExecutiveTeam';
import Success from './Success/Success';
import Link from '../../components/Link/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Tag from '../../components/Tag/Tag';
import ContentLoader from '../../components/ContentLoader/ContentLoader';

//Redux
import Typography from '../../components/Typography/Typography';
//Assets
import { ReactComponent as ImagePlaceholder } from '../../assets/icons/ImagePlaceholder.svg';

const CompanyPreview2 = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [company, setCompany] = useState({});
    let { slug } = useParams();

    const queryParamString = useLocation().search.substring(1);
    const searchParam = new URLSearchParams(queryParamString);

    const descRef = createRef(null);
    const [descHeight, setDescHeight] = useState(0);
    const [descExpanded, setDescExpanded] = useState(false);

    const [scoreDegree, setScoreDegree] = useState();

    const [activeTab, setActiveTab] = useState('profile');

    useEffect(() => {
        descRef && descRef.current && setDescHeight(descRef.current.offsetHeight);
    }, [descRef]);

    const handleShowMore = () => {
        setDescExpanded(!descExpanded);
    };

    // get company
    useEffect(() => {
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                    // }/api/companies/${slug}/preview?cid=${searchParam.get('cid')}`
                }/api/admin/kanban/company`,
                {
                    withCredentials: true,
                    params: {
                        cid: searchParam.get('cid')
                    }
                }
            )
            .then((res) => setCompany(res.data.data));
    }, [slug]);

    useEffect(() => {
        if (company.degree_of_fit * 100 < 40) {
            setScoreDegree('weak');
        } else if (company.degree_of_fit * 100 >= 40 && company.degree_of_fit * 100 < 60) {
            setScoreDegree('good');
        } else {
            setScoreDegree('strong');
        }
    }, [company]);

    const formatNumber = (value) => {
        return value
            ? Math.round(value)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : '';
    };

    const handleTabSelected = (value) => {
        setActiveTab(value);
    };

    return (
        <div>
            {company ? (
                <div className={classes.root}>
                    {company ? (
                        <div>
                            <div className={classnames([classes.flexCenter, classes.flexColumn])}>
                                <div className={classes.imgWrap}>
                                    {company && company.icon ? (
                                        <img
                                            alt={company.name}
                                            className={classes.profileImage}
                                            src={company && company.icon}></img>
                                    ) : company.icon ? (
                                        <img
                                            alt={company.name}
                                            className={classes.profileImage}
                                            src={company.icon}></img>
                                    ) : (
                                        <Tooltip
                                            title={
                                                <Typography color="white" variant="body2">
                                                    {t('companyProfile.companyLogoInfo')}
                                                </Typography>
                                            }
                                            arrow>
                                            <ImagePlaceholder
                                                className={classes.imagePlaceholder}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                <Typography variant="subtitle1" component="div">
                                    {company?.name}
                                </Typography>
                            </div>
                            <div className={classes.tabLinksWrapper}>
                                <div
                                    className={classes.tabLinks}
                                    data-intercom-target="Company navigation tabs">
                                    <div
                                        className={classnames([
                                            classes.tabLink,
                                            activeTab === 'profile' ? classes.activeTabLink : ''
                                        ])}
                                        onClick={() => handleTabSelected('profile')}
                                        data-intercom-target="Profile section tab">
                                        <Typography
                                            variant="bodyXs"
                                            component="div"
                                            className={classes.tabLinkText}>
                                            Profile
                                        </Typography>
                                    </div>
                                    <div
                                        className={classnames([
                                            classes.tabLink,
                                            activeTab === 'executive' ? classes.activeTabLink : ''
                                        ])}
                                        onClick={() => handleTabSelected('executive')}
                                        data-intercom-target="Executive team section tab">
                                        <Typography
                                            variant="bodyXs"
                                            component="div"
                                            className={classes.tabLinkText}>
                                            Executive team
                                        </Typography>
                                    </div>
                                    <div
                                        className={classnames([
                                            classes.tabLink,
                                            activeTab === 'success' ? classes.activeTabLink : ''
                                        ])}
                                        onClick={() => handleTabSelected('success')}
                                        data-intercom-target="Success section tab">
                                        <Typography
                                            variant="bodyXs"
                                            component="div"
                                            className={classes.tabLinkText}>
                                            Success
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {activeTab === 'profile' && company && (
                                <div
                                    className={classes.main}
                                    data-intercom-target="Company summary">
                                    <div className={classes.wrap}>
                                        <div
                                            className={classnames([
                                                classes.nameImageWrap,
                                                {
                                                    [classes.nameImageWrapPartialWidth]:
                                                        !!company.degree_of_fit,
                                                    [classes.nameImageWrapFullWidth]:
                                                        !company.degree_of_fit
                                                }
                                            ])}>
                                            <div className={classes.flexSpaceStart}>
                                                <div className={classes.flexColumn}>
                                                    <div className={classes.technologies}>
                                                        <div className={classes.flexRow}>
                                                            <Tag
                                                                className={classes.tag}
                                                                variant="lightGreen"
                                                                clickable={true}>
                                                                <Typography
                                                                    variant="caption"
                                                                    component="span"
                                                                    className={classes.mono}>
                                                                    Company
                                                                </Typography>
                                                            </Tag>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={classnames([classes.descriptionBox])}
                                            ref={descRef}
                                            data-intercom-target="Company description section">
                                            <Typography variant="body" component="div">
                                                <div
                                                    className={classnames([
                                                        {
                                                            [classes.descriptionExpanded]:
                                                                descExpanded,
                                                            [classes.description]: !descExpanded
                                                        }
                                                    ])}
                                                    dangerouslySetInnerHTML={{
                                                        __html: company && company.description
                                                    }}></div>
                                            </Typography>
                                            {descHeight > 48 && (
                                                <div
                                                    className={classnames([
                                                        classes.showMore,
                                                        'laptop'
                                                    ])}>
                                                    <Typography
                                                        variant="bodySmall"
                                                        color="textLighter"
                                                        onClick={handleShowMore}>
                                                        {descExpanded
                                                            ? t('companyProfile.showLess')
                                                            : t('companyProfile.showMore')}
                                                    </Typography>
                                                </div>
                                            )}
                                        </div>
                                        <div className={classes.descriptionScores}>
                                            <div className={classes.scores}>
                                                <div
                                                    className={classnames([
                                                        classes.scoresRows,
                                                        classes.flexSpaceBetween,
                                                        classes.flexRow
                                                    ])}>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.sectors')}:
                                                        </Typography>
                                                        <div>
                                                            {company &&
                                                                company.sectors &&
                                                                company.sectors.map(
                                                                    (sector, index) => (
                                                                        <Typography
                                                                            key={`${sector}-${index}`}
                                                                            className={classnames([
                                                                                classes.infoTitle,
                                                                                classes.mono
                                                                            ])}
                                                                            variant="body2">
                                                                            {sector}
                                                                            {index + 1 <
                                                                                company.sectors
                                                                                    .length && ', '}
                                                                        </Typography>
                                                                    )
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.fundingRaised')}:
                                                        </Typography>
                                                        <Typography
                                                            className={classnames([
                                                                classnames([
                                                                    classes.infoTitle,
                                                                    classes.mono
                                                                ]),
                                                                classes.money
                                                            ])}
                                                            variant="body2">
                                                            {company?.total_funding_amount_dollars
                                                                ? parseInt(
                                                                      company.total_funding_amount_dollars
                                                                  )
                                                                      .toFixed()
                                                                      .toString()
                                                                      .replace(
                                                                          /\B(?=(\d{3})+(?!\d))/g,
                                                                          ','
                                                                      ) + ' USD'
                                                                : 'N/a'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classnames([
                                                        classes.scoresRows,
                                                        classes.basicInfo,
                                                        classes.flexSpaceBetween,
                                                        classes.flexRow
                                                    ])}>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.subsectors')}:
                                                        </Typography>
                                                        <div>
                                                            {company?.subsectors &&
                                                            company.subsectors.length ? (
                                                                company.subsectors.map(
                                                                    (subsector, index) => {
                                                                        return (
                                                                            <Typography
                                                                                key={`${subsector}-${index}`}
                                                                                className={classnames(
                                                                                    [
                                                                                        classes.infoTitle,
                                                                                        classes.mono
                                                                                    ]
                                                                                )}
                                                                                variant="body2">
                                                                                {subsector}
                                                                                {index + 1 <
                                                                                    company
                                                                                        .subsectors
                                                                                        .length &&
                                                                                    ', '}
                                                                            </Typography>
                                                                        );
                                                                    }
                                                                )
                                                            ) : (
                                                                <Typography
                                                                    className={classnames([
                                                                        classes.infoTitle,
                                                                        classes.mono
                                                                    ])}
                                                                    variant="body2">
                                                                    Unspecified
                                                                </Typography>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.location')}:
                                                        </Typography>
                                                        <Typography
                                                            className={classnames([
                                                                classes.infoTitle,
                                                                classes.mono
                                                            ])}
                                                            variant="body2">
                                                            {company?.hq_city && company?.hq_country
                                                                ? company.hq_city +
                                                                  ', ' +
                                                                  company.hq_country
                                                                : 'Unspecified'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classnames([
                                                        classes.scoresRows,
                                                        classes.basicInfo,
                                                        classes.flexSpaceBetween,
                                                        classes.flexRow
                                                    ])}>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.yearOfInception')}:
                                                        </Typography>
                                                        <Typography
                                                            className={classnames([
                                                                classes.infoTitle,
                                                                classes.mono
                                                            ])}
                                                            variant="body2">
                                                            {company?.founded_date ?? 'Unspecified'}
                                                        </Typography>
                                                    </div>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.companySize')}:
                                                        </Typography>
                                                        <Typography
                                                            className={classnames([
                                                                classes.infoTitle,
                                                                classes.mono
                                                            ])}
                                                            variant="body2">
                                                            {company?.number_of_employees ??
                                                                'Unspecified'}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div
                                                    className={classnames([
                                                        classes.scoresRows,
                                                        classes.basicInfo,
                                                        classes.flexSpaceBetween,
                                                        classes.flexRow
                                                    ])}>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.companyStage')}:
                                                        </Typography>
                                                        <Typography
                                                            className={classnames([
                                                                classes.infoTitle,
                                                                classes.mono
                                                            ])}
                                                            variant="body2">
                                                            {company?.investment_stage ??
                                                                'Unspecified'}
                                                        </Typography>
                                                    </div>
                                                    <div className={classnames([classes.scoreRow])}>
                                                        <Typography
                                                            className={classes.infoValue}
                                                            variant="body2">
                                                            {t('companyProfile.website')}:
                                                        </Typography>
                                                        {company && company.website && (
                                                            <Typography
                                                                className={classnames([
                                                                    classes.infoTitle,
                                                                    classes.mono
                                                                ])}
                                                                variant="body2">
                                                                <Link
                                                                    target="_blank"
                                                                    className={classes.link}
                                                                    href={company.website ?? ''}>
                                                                    {company.website
                                                                        ? company.website.replace(
                                                                              /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g,
                                                                              ''
                                                                          )
                                                                        : 'Unspecified'}
                                                                </Link>
                                                            </Typography>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.sdgWrap}>
                                        <Typography className={classes.infoTitle} variant="body1">
                                            {t('companyProfile.comments')}:
                                        </Typography>
                                        <Typography
                                            className={classnames([
                                                classes.infoValue,
                                                classes.commentsText
                                            ])}
                                            variant="body2">
                                            {company.comments_cnt || 0}{' '}
                                            {t('companyProfile.comments').toLowerCase()}
                                        </Typography>
                                    </div>
                                    {(function () {
                                        if (company && company.sdgs && company.sdgs.length > 0) {
                                            return (
                                                <div className={classes.sdgWrap}>
                                                    <Typography
                                                        className={classes.infoTitle}
                                                        variant="body1">
                                                        {t('companyProfile.sdg')}:
                                                    </Typography>
                                                    <div className={classes.flexWrap}>
                                                        {company &&
                                                            company.sdgs &&
                                                            company.sdgs.map((sdg, i) => (
                                                                <Tooltip
                                                                    key={`sdg-${i}`}
                                                                    arrow
                                                                    classes={{
                                                                        arrow: classes.tooltipArrow,
                                                                        tooltip:
                                                                            classes.tooltipContent
                                                                    }}
                                                                    placement="top"
                                                                    title={
                                                                        <Typography
                                                                            color="white"
                                                                            variant="body2"
                                                                            className={
                                                                                classes.sdgTooltip
                                                                            }>
                                                                            <div
                                                                                className={
                                                                                    classes.sdgTooltipTitle
                                                                                }>
                                                                                {t(
                                                                                    'companyProfile.thisCompanyWorksTowards'
                                                                                )}
                                                                            </div>
                                                                            {t(
                                                                                'companyProfile.sustainableDevelopmentGoals'
                                                                            )}{' '}
                                                                            {sdg.number}: {sdg.name}
                                                                        </Typography>
                                                                    }>
                                                                    <img
                                                                        className={classes.sdg}
                                                                        src={
                                                                            require(`../../assets/icons/sdgs/${sdg.icon_name}.png`)
                                                                                .default
                                                                        }
                                                                        alt={sdg.name}></img>
                                                                </Tooltip>
                                                            ))}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })()}
                                </div>
                            )}

                            <div>
                                {activeTab === 'profile' && company && (
                                    <Profile company={company} />
                                )}
                                {activeTab === 'executive' && company && (
                                    <ExecutiveTeam company={company} />
                                )}
                                {activeTab === 'success' && company && (
                                    <Success
                                        funding={company && company.total_funding_amount_dollars}
                                        company={company}
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};
export default CompanyPreview2;
