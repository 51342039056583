import { all, takeEvery } from "redux-saga/effects";
import {
    getAdminUsersRequest,
    createAdminUserRequest,
    updateAdminUserRequest,
    getAdminUserRequest,
    deleteAdminUserRequest
} from "../requests";
import {
    getAdminUsers,
    createAdminUser,
    updateAdminUser,
    getAdminUser,
    deleteAdminUser
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchGetAdminUsers() {
    yield takeEvery(
        convertTypeFromAction(getAdminUsersRequest),
        ({ payload: { search, sortBy, sortDirection, perPage, page } }) => getAdminUsers(search, sortBy, sortDirection, perPage, page)
    );
}

function* watchCreateAdminUser() {
    yield takeEvery(
        convertTypeFromAction(createAdminUserRequest),
        ({ payload: { data } }) => createAdminUser(data)
    );
}
function* watchUpdateAdminUser() {
    yield takeEvery(
        convertTypeFromAction(updateAdminUserRequest),
        ({ payload: { id, data } }) => updateAdminUser(id, data)
    );
}

function* watchGetAdminUser() {
    yield takeEvery(
        convertTypeFromAction(getAdminUserRequest),
        ({ payload: { id } }) => getAdminUser(id)
    );
}

function* watchDeleteAdminUser() {
    yield takeEvery(
        convertTypeFromAction(deleteAdminUserRequest),
        ({ payload: { id } }) => deleteAdminUser(id)
    );
}

function* saga() {
    yield all([
        watchGetAdminUsers(),
        watchCreateAdminUser(),
        watchUpdateAdminUser(),
        watchGetAdminUser(),
        watchDeleteAdminUser()
    ]);
}

export { saga };