import React, { useEffect, useState } from 'react';
//Hooks
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
// Redux
import { deleteKanbanItemRequest } from '../../../../store/kanban/requests';
import { useDispatch } from 'react-redux';
//Components
import Button from '../../../../components/Button/Button';
//Assets
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/DeleteIcon.svg';
import classnames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

const KanbanCard = ({ justDropped, fetchMlDone, fetchMlFailed, item, columnId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const allStatuses = [
        {
            label: 'ML fetching done.',
            value: 1
        },
        {
            label: 'ML fetching in progress',
            value: 2
        },
        {
            label: 'ML fetching error.',
            value: 3
        }
    ];
    const [currentStatus, setCurrentStatus] = useState(1);

    const isTechnology = item.is_technology;
    const url = isTechnology
        ? `/technologies-profile/${item.eid}/preview`
        : `/company/${item.slug}/preview?cid=${item.eid}`;

    const deleteCard = (id) => {
        dispatch(deleteKanbanItemRequest(id));
        document.getElementById(`card-${id}`).style.display = 'none';
    };

    useEffect(() => {
        if (justDropped) {
            setCurrentStatus(2);
        }
        if (fetchMlDone) {
            setCurrentStatus(1);
        }
        if (fetchMlFailed) {
            setCurrentStatus(3);
        }
    }, [fetchMlDone, justDropped]);

    return (
        <div className={classes.root}>
            {item && (
                <>
                    <div className={classes.cardHeader}>
                        <div>{item.name}</div>
                        <div onClick={() => deleteCard(item.id)}>
                            <DeleteIcon />
                        </div>
                    </div>

                    {isTechnology && (
                        <div className={classes.technologyLabelWrapper}>
                            <span className={classes.technologyLabel}>Technology</span>
                        </div>
                    )}

                    <div className={classes.cardBodyItem}>
                        <span>{t('kanbanCard.userPrefix')}</span>
                        <span>{item.requested_by}</span>
                    </div>
                    <div className={classes.cardBodyItem}>
                        <span>{t('kanbanCard.deadlinePrefix')}</span>
                        <span>{item.deadline}</span>
                    </div>
                    <div className={classes.cardBodyItem}>
                        <span>{t('kanbanCard.lastUpdatedPrefix')}</span>
                        <span>{item.updated_at}</span>
                    </div>

                    <div className={classes.cardFooter}>
                        <Button
                            className={classes.cardFooterButton}
                            variant="textPrimary"
                            onClick={() => window.open(url, '_blank')}>
                            {isTechnology
                                ? t('kanbanCard.previewTechnologyButton')
                                : t('kanbanCard.previewCompanyButton')}
                        </Button>
                        {/*{*/}
                        {/*    !isTechnology && columnId === 2 &&*/}
                        {/*    <Tooltip title={`${allStatuses.find(status => status.value === currentStatus).label}`} arrow>*/}
                        {/*        <div className={classnames([*/}
                        {/*            classes.mlStatus,*/}
                        {/*            { [classes.green]: currentStatus === 1 },*/}
                        {/*            { [classes.yellow]: currentStatus === 2 },*/}
                        {/*            { [classes.red]: currentStatus === 3 }])}/>*/}
                        {/*    </Tooltip>*/}
                        {/*}*/}
                    </div>
                </>
            )}
        </div>
    );
};
export default KanbanCard;
