import { call } from "redux-saga/effects";
import axios from "axios";
import {
    INDUSTRIES_URL,
    CREATE_INDUSTRY_URL,
    getIndustryUrl,
    getSubindustryUrl,
    CREATE_SUBINDUSTRY_URL,
    putSubindustryUrl,
    GET_CLOSEST_URL,
    postCompaniesSubIndustry,
    subIndustryCompanies,
    updateMLCompanyIndustry,
    releaseCompaniesIndustryURL,
    GET_CLUSTERS
} from "../../../constants/endpoints";
let username = "DataTeam";
let password = "hDPKG8vsJJ8RgvxY";

let basicAuth = "Basic " + btoa(username + ":" + password);
function* getIndustries() {
    const response = yield call(axios, {
        method: "GET",
        url: `${INDUSTRIES_URL}`,
        withCredentials: true
    });
    return response.data;
}

function* getIndustry(id) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getIndustryUrl(id)}`,
        withCredentials: true
    });
    return response.data;
}

function* postIndustry(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${CREATE_INDUSTRY_URL}`,
        withCredentials: true,
        data
    });
    return response.data;
}

function* deleteIndustry(id) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${getIndustryUrl(id)}`,
        withCredentials: true
    });
    return response.data;
}

function* putIndustry(data) {
    const { id } = data;
    const industryId = id ? id : data.get("id");
    const response = yield call(axios, {
        method: "POST",
        url: `${getIndustryUrl(industryId)}`,
        withCredentials: true,
        data
    });
    return response.data;
}

function* postSubindustry(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${CREATE_SUBINDUSTRY_URL}`,
        withCredentials: true,
        data
    });
    return response.data;
}

function* deleteSubindustry(id) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${getSubindustryUrl(id)}`,
        withCredentials: true
    });
    return response.data;
}

function* putSubindustry(data) {
    const { id, name, description } = data;
    const response = yield call(axios, {
        method: "PUT",
        url: `${putSubindustryUrl(id, name, description)}`,
        withCredentials: true
    });
    return response.data;
}

function* postGetClosestIndustry(subIndustry) {
    let data = {
        theme_of_search: subIndustry.industry_name,
        challenge: subIndustry.industry_description,
        cluster_specifications: [
            {
                name: subIndustry.sub_industry_name,
                description: subIndustry.sub_industry_description,
                sectors: [subIndustry.industry_name],
                subsectors: [subIndustry.sub_industry_name],
                result_limit: 100,
                completeness_score_limit: 0.2,
                valuer_score_limit: 0.2,
                priority_limit: 0,
                semantic_similarity_limit: 0.3
            }
        ]
    };
    const response = yield call(axios, {
        method: "POST",
        url: `${GET_CLUSTERS}`,
        headers: {
            Authorization: basicAuth,
        },
        data: data
    });
    return response.data;
}

function* postCompaniesData(data, id) {
    const response = yield call(axios, {
        method: "POST",
        url: `${postCompaniesSubIndustry(id)}`,
        data,
        withCredentials: true
    }
    );
    return response.data;
}
function* getSubIndustryWithCompanies(id) {
    const response = yield call(axios, {
        method: "GET",
        url: `${subIndustryCompanies(id)}`,
        withCredentials: true
    }
    );
    return response.data;
}
function* updateMLCompanyKanban(companies) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${updateMLCompanyIndustry}`,
        withCredentials: true,
        data: { companies: companies }
    }
    );
    return response.data;
}
function* releaseCompanies(id, companyIds) {
    const response = yield call(axios, {
        method: "POST",
        url: `${releaseCompaniesIndustryURL(id)}`,
        withCredentials: true,
        data: {
            ids: companyIds
        }
    }
    );
    return response.data;
}

export {
    getIndustries,
    postIndustry,
    deleteIndustry,
    getIndustry,
    putIndustry,
    postSubindustry,
    deleteSubindustry,
    putSubindustry,
    postGetClosestIndustry,
    postCompaniesData,
    getSubIndustryWithCompanies,
    updateMLCompanyKanban,
    releaseCompanies
};
