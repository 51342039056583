import { makeStyles, createStyles } from "@material-ui/core";
import { Rotate90DegreesCcw } from "@material-ui/icons";

export default makeStyles(({ palette, spacing }) => ({
    root: {
        padding: spacing(4),
        background: palette.common.white,
        maxWidth: 1146,
        margin: "0 auto"
    },
    button: {
        display: "block"
    },
    tagText: {},
    tag: {
        padding: "4px 12px",
        borderRadius: spacing(1),
        width: "fit-content",
        "& > $tagText": {
            color: palette.common.white
        }
    },
    admin: {
        background: palette.success.main
    },
    superAdmin: {
        background: palette.primary.main
    },
    editor: {
        background: palette.secondary.main
    },
    moderator: {
        background: palette.text.light
    },
    freeTrial: {
        background: palette.warning.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    free: {
        background: palette.text.backgroundMain,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    single: {
        background: palette.secondary.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    pro: {
        background: palette.primary.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    teams: {
        background: palette.success.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    custom: {
        background: palette.error.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    journalist: {
        background: palette.error.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    cell: {
        padding: `${spacing(2)}px ${spacing(1)}px`,
        border: "none",
        lineHeight: "initial"
    },
    wideCell: {
        minWidth: 180
    },
    headRow: {
        borderRadius: `${spacing(2)}px`,
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderTop: `1px solid ${palette.text.backgroundMain}`,
    },
    row: {
        borderRadius: `${spacing(2)}px`,
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderTop: `1px solid ${palette.text.backgroundMain}`,
        "&:hover": {
            background: palette.primary.backgroundMedium
        }
    },
    avatar: {
        marginRight: spacing(6),
        width: 72,
        height: 72
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    info: {
        display: "flex",
        paddingTop: spacing(3)
    },
    sector: {
        marginRight: spacing(1)
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: spacing(4)
    },
    moreIcon: {
        fill: palette.text.light,
        "&:hover": {
            fill: palette.primary.dark
        }
    },
    headerCellClickable: {
        cursor: "pointer",
        "&:hover": {
            background: palette.primary.backgroundMedium
        }
    },
    title: {
        display: "flex"
    },
    up: {
        transform: "rotate(180deg)"
    }
}));