import { useTranslation } from 'react-i18next';
import useStyles from "./style.js";
// Components
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from "../Typography/Typography";
import classnames from "classnames";

const TextField = ({
    className,
    id,
    labelProps,
    inputProps,
    placeholder,
    label,
    error,
    errorLabel,
    onChange,
    onClick,
    startAdornment,
    endAdornment,
    type,
    value,
    onKeyUp,
    inputClass,
    testId,
    fullWidth,
    disabled,
    autocomplete
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const textFieldClasses = classnames([classes.textField, inputClass, { [classes.fullWidth]: fullWidth}]);

    return (
        <div className={className}>
            {label && <InputLabel
                disableAnimation={true}
                focused={false}
                shrink={true}
                error={!!error}
                {...labelProps}
                className={classes.inputLabel}
                classes={{ shrink: classes.inputLabel, root: classes.inputLabel, asterisk: classes.asterisk, error: classes.errorLabel }}
                htmlFor={id}
            >
                {label}
            </InputLabel>}
            <InputBase
                {...inputProps}
                value={value}
                id={id}
                error={!!error}
                className={textFieldClasses}
                classes={{ focused: classes.focusedInput, error: classes.errorInput }}
                placeholder={placeholder}
                onChange={onChange}
                onClick={onClick}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                type={type}
                onKeyUp={onKeyUp}
                test-id={testId}
                disabled={disabled}
                autoComplete={autocomplete}
            />
            {!!error && <Typography variant="caption" color="danger" component="div">{errorLabel ? errorLabel : t('common.errorMessage', { label })}</Typography>}
        </div>
    )
}

TextField.defaultProps = {
    error: false,
    disabled: false
};

export default TextField;