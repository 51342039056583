import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    root: {
        // padding: spacing(2),
        margin: `0 auto`,
        padding: spacing(4),
        maxWidth: "1724px"
    },
    appliedFiltersWrap: {
        marginTop: spacing(1),
        display: "flex",
        flexDirection: "column"
    },
    appliedFilters: {
        display: "flex",
        flexWrap: "wrap"
    },
    filterTag: {
        background: palette.common.white,
        border: `1px solid ${palette.secondary.main};`,
        color: palette.secondary.main,
        padding: `0 ${spacing(1)}px`,
        borderRadius: shape.borderRadius,
        boxSizing: "border-box",
        marginTop: spacing(1),
        marginRight: spacing(1),

    },
    closeIcon: {
        maxWidth: spacing(1),
        maxHeight: spacing(1),
        marginLeft: spacing(1),
        cursor: "pointer"
    },
}));