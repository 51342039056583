import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    fundingOptionsMaxData,
    yearOptionsMaxData,
    valuerScoreOptionsMaxData,
    selectedFiltersEmptyData,
    filterParamMapping
} from "../../../constants/filters";
import { getFilterResultsCountRequest } from "../../../store/filtering/requests";
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { fetchSubsectorsRequest } from "../../../store/sectors/requests";
import {
    objectHasEmptyValues
} from "../../../utils/objectHasEmptyValues";

const useFilters = ({
    allSectors,
    getFilterResultsTotalPages,
    sortValue,
    sortDirection
}) => {
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({});
    let { search } = useLocation();
    const [yearOptionsMax, setYearOptionsMax] = useState(yearOptionsMaxData);
    const [fundingOptionsMax, setFundingOptionsMax] = useState(fundingOptionsMaxData);
    const [valuerScoreOptionsMax, setValuerScoreOptionsMax] = useState(valuerScoreOptionsMaxData);
    const [isFilterFetching, setIsFilterFetching] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState(selectedFiltersEmptyData);
    const [displayedFilters, setDisplayedFilters] = useState(selectedFiltersEmptyData);
    const [filterNameValue, setFilterNameValue] = useState("");
    const [modalFilterNameValue, setModalFilterNameValue] = useState("");
    // Empties filters and selectedFilters
    const resetFilters = () => {
        setSelectedFilters(selectedFiltersEmptyData);
    }

    let fundingMaxOptionsOriginal = _.cloneDeep(fundingOptionsMaxData);
    let yearInceptionMaxOptionsOriginal = _.cloneDeep(yearOptionsMaxData);
    let valuerScoreMaxOptionsOriginal = _.cloneDeep(valuerScoreOptionsMaxData);

    const fetchAllSubsectors = (sector) => {
        dispatch(fetchSubsectorsRequest(sector.slug));
    };

    // Field changes
    const handleFieldChange = (event, field) => {
        setSelectedFilters(prevSelectedFilters => {
            return {
                ...prevSelectedFilters,
                [field]: event.target.value
            }
        })
    };
    const handleSectorChange = (event) => {
        handleFieldChange(event, "sector");
        let slug = allSectors.filter(f => f.name === event.target.value);
        fetchAllSubsectors(slug[0]);
    };
    const handleFundingMinChange = (event) => {
        handleFieldChange(event, "fundingMin");
        let data = fundingMaxOptionsOriginal.filter(f => f.name > event.target.value);
        setFundingOptionsMax(data)
    };
    const handleYearMinChange = (event) => {
        handleFieldChange(event, "yearMin");
        let data = yearInceptionMaxOptionsOriginal.filter(f => f.name > event.target.value);
        setYearOptionsMax(data);
    };
    const handleValuerScoreMinChange = (event) => {
        handleFieldChange(event, "valuerScoreMin");
        let data = valuerScoreMaxOptionsOriginal.filter(f => f.name > event.target.value);
        setValuerScoreOptionsMax(data);
    }

    const resetFilter = (filterName) => {
        setSelectedFilters(prevSelectedFilters => {
            return {
                ...prevSelectedFilters,
                [filterName]: "",
                ...filterName === "sector" && { subsectors: "" }
            }
        })
    };

    const setModalFilters = () => {
        Object.keys(displayedFilters).forEach(filter => {
            setSelectedFilters(prevSelectedFilters => {
                return {
                    ...prevSelectedFilters,
                    [filter]: displayedFilters[filter]
                }
            })
        })
    };

    const handleFilterRemove = filter => {
        setSelectedFilters(prevSelectedFilters => {
            return {
                ...prevSelectedFilters,
                [filter]: ""
            }
        });
        setDisplayedFilters(prevDisplayedFilters => {
            return {
                ...prevDisplayedFilters,
                [filter]: ""
            }
        });
        let val = filters.filter(f => f !== filter);
        resetFilter(filter);
        setFilters(val);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const sid = urlParams.get("sid");
        const data = { sid, filterNameValue: modalFilterNameValue, orderBy: sortValue, orderDirection: sortDirection }
        // dispatch(getFilterResultsCountRequest({ data }));
    }, [modalFilterNameValue]);

    useEffect(() => {
        let hasModalFilter = false;
        let value = "";
        Object.keys(selectedFilters).forEach(filter => {
            if (selectedFilters[filter] !== "") {
                hasModalFilter = true;
                value = value.concat(`${filterParamMapping[filter]}=${selectedFilters[filter]}&`);
                setModalFilterNameValue(value);
            }
        });
        if (!hasModalFilter) {
            setModalFilterNameValue("");
        }
        hasModalFilter = false;
    }, [selectedFilters]);

    useEffect(() => {
        let hasFilter = false;
        let value = "";
        Object.keys(displayedFilters).forEach(filter => {
            if (displayedFilters[filter] !== "") {
                hasFilter = true;
                value = value.concat(`${filterParamMapping[filter]}=${displayedFilters[filter]}&`);
                setFilterNameValue(value);
            }
        });
        if (!hasFilter) {
            setFilterNameValue("");
        }
        hasFilter = false;
    }, [displayedFilters])

    const handleShowResults = () => {
        setDisplayedFilters(selectedFilters);
    }

    const handleShowFilters = value => {
        let temp = [];
        for (const key in value) {
            if (Object.keys(value[key]).length > 0) {
                temp.push(value[key])
            }
        }
        setFilters(temp);
    };

    return {
        filters,
        setFilters,
        selectedFilters,
        setDisplayedFilters,
        filterNameValue,
        setFilterNameValue,
        modalFilterNameValue,
        setModalFilterNameValue,
        resetFilters,
        handleSectorChange,
        handleFundingMinChange,
        handleYearMinChange,
        handleValuerScoreMinChange,
        resetFilter,
        setModalFilters,
        setSelectedFilters,
        displayedFilters,
        fundingOptionsMax,
        valuerScoreOptionsMax,
        yearOptionsMax,
        handleFieldChange,
        handleFilterRemove,
        handleShowResults,
        handleShowFilters,
        isFilterFetching,
        setIsFilterFetching
    };
}

export default useFilters;