import React, { useEffect, useState } from "react";
import moment from 'moment';
import classnames from "classnames";
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
import { useParams } from "react-router-dom";
import { useAuthHook } from "../../common/useAuthHook";
//Components
import Header from "./components/Header/Header";
import Profile from "./components/Profile/Profile";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getTechnologyProfileRequest } from "../../store/technologies/requests";
import { getTechnologyProfileState, getTechnologyProfileStatus } from "../../store/technologies/selectors";
import { REQUEST_SUCCESS } from "../../constants/statuses";
import ContentLoader from "../../components/ContentLoader/ContentLoader";


const TechnologiesProfile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useAuthHook();
    const getTechnologyStatusSelector = useSelector(getTechnologyProfileStatus);
    const getTechnologyStateSelector = useSelector(getTechnologyProfileState);
    const [technology, setTechnology] = useState(getTechnologyStateSelector);
    const [loading, setLoading] = useState(true);

    let { id } = useParams();

    useEffect(() => {
        setTechnology(getTechnologyStateSelector);
    }, [getTechnologyStateSelector]);
    useEffect(() => {
        getTechnologyStatusSelector === REQUEST_SUCCESS && setLoading(false);
    }, [getTechnologyStatusSelector]);

    useEffect(() => {
        dispatch(getTechnologyProfileRequest({ id, data: { page: 1, orderBy: "match", orderDirection: "desc" }, clearResults: true, }))
    }, [dispatch]);
    return (
        <div className={classes.root}>
            <Header user={user} technologyId={technology && technology.id}/>
            {!loading ? <Profile technology={technology} user={user}></Profile> : <ContentLoader />}
        </div>
    )
}
export default TechnologiesProfile;
