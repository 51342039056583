// Hooks
import { useSelector } from 'react-redux';
// Redux
import { getUserSelector, getUserStatusSelector } from "../store/auth/selectors";

export const useAuthHook = () => {
    const user = useSelector(getUserSelector);
    const getMeStatus = useSelector(getUserStatusSelector);
    const isAuthenticated = !!user;

    return { isAuthenticated, getMeStatus, user };
}
