import React, { useEffect, useState, useRef, createRef } from "react";
import { useStyles } from "./style.js";
import Tag from "../../components/Tag/Tag";
import Typography from "../../components/Typography/Typography";
import Tooltip from '@material-ui/core/Tooltip';

const Sectors = ({ sectors, cardRef }) => {
    const classes = useStyles();
    const [tags, setTags] = useState([]);
    const myRefs = useRef([]);
    const [invisibleTags, setInvisibleTags] = useState([]);
    const sectorsRef = createRef(null);

    const useRefDimensions = (ref) => {
        const [dimensions, setDimensions] = useState({ width: 0 })

        useEffect(() => {
            if (ref.current) {
                const { current } = ref
                setDimensions({ width: current.offsetWidth })
            }
        }, [ref])
        return dimensions
    }

    const dimensions = useRefDimensions(cardRef)

    useEffect(() => {
        if (dimensions.width > 0) {

            let widths = 38;
            let tempTags = [];
            let tempInvisibleTags = [];
            myRefs.current && myRefs.current.forEach((ref, i) => {
                // widths += ref && (ref.offsetWidth + 8);
                ref ? widths += ref.offsetWidth + 8 : widths += 100;
                if (widths > dimensions.width) {
                    tempInvisibleTags = [...tempInvisibleTags, sectors[i]];
                } else {
                    if (!tempTags.includes(sectors[i])) {
                        tempTags = [...tempTags, sectors[i]]
                    }
                }
            });
            setTags(tempTags)
            setInvisibleTags(tempInvisibleTags)
        }

    }, [myRefs, dimensions.width, sectors]);

    return (
        <div className={classes.tags}>
            <div ref={sectorsRef}>
                {tags.length > 0 && tags && tags.map((tag, i) => (
                    <Tag key={`sector-${i}`} className={classes.tag} ref={el => (myRefs.current[i] = el)}>
                        <Typography variant="caption" component="span">
                            {tag.name}
                        </Typography>
                    </Tag>
                ))}
                {tags.length === 0 && sectors && sectors.map((tag, i) => (
                    //   className={classes.tagPlaceholder} 
                    <Tag key={`sector-${i}`} className={classes.tag} ref={el => (myRefs.current[i] = el)}>
                        <Typography variant="caption" component="span">
                            {tag.name}
                        </Typography>
                    </Tag>
                ))}
                {invisibleTags && invisibleTags.length > 0 && (
                    <Tooltip title={invisibleTags.map((invisibleTag, i) => <Typography color="white" component="div" key={`invisible-tag-${i}`} variant="body2">{invisibleTag.name}</Typography>)} arrow>
                        <Typography className={classes.typographyTag} variant="caption" component="span">
                            +{invisibleTags.length}
                        </Typography>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

export default Sectors;