import { call } from "redux-saga/effects";
import axios from "axios";
import {
    POST_LOGIN,
    POST_LOGOUT,
    GET_ME,
    RESET_PASSWORD,
    SEND_RESET_LINK,
    GET_COOKIES,
    CHECK_RESET_LINK,
} from "../../../constants/endpoints";

function* login(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${POST_LOGIN}`,
        data,
        withCredentials: true
    });
    if (response.data.data) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.data.access_token;
        axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    }
    return response.data;
}

function* postResetPassword(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${RESET_PASSWORD}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* postSendResetLink(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${SEND_RESET_LINK}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* postCheckResetLink(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${CHECK_RESET_LINK}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* logout() {
    const response = yield call(axios, {
        method: "POST",
        url: `${POST_LOGOUT}`,
        withCredentials: true
    });
    return response.data;
}
function* getMe() {
    const response = yield call(axios, {
        method: "GET",
        url: `${GET_ME}`,
        withCredentials: true
    });
    return response.data;
}
function* getCookie() {
    const response = yield call(axios, {
        method: "GET",
        url: `${GET_COOKIES}`,
        withCredentials: true
    })
    return response.data;
}

export {
    login,
    logout,
    getMe,
    postResetPassword,
    postSendResetLink,
    postCheckResetLink,
    getCookie,
};