import { all, takeEvery } from "redux-saga/effects";
import {
    postLoginRequest,
    postLogoutRequest,
    getMeRequest,
    sendResetLinkRequest,
    checkResetLinkRequest,
    resetPasswordRequest,
    fetchCookieRequest
} from "../requests";
import {
    postLogin,
    postLogout,
    fetchMe,
    sendResetLink,
    checkResetLink,
    resetPassword,
    fetchCookie,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchPostLogin() {
    yield takeEvery(
        convertTypeFromAction(postLoginRequest),
        ({ payload: { emailValue, passwordValue, token } }) => postLogin(emailValue, passwordValue, token)
    );
}

function* watchPostLogout() {
    yield takeEvery(
        convertTypeFromAction(postLogoutRequest),
        () => postLogout()
    );
}
function* watchSendResetLink() {
    yield takeEvery(
        convertTypeFromAction(sendResetLinkRequest),
        ({ payload: { data } }) => sendResetLink(data)
    );
}

function* watchCheckResetLink() {
    yield takeEvery(
        convertTypeFromAction(checkResetLinkRequest),
        ({ payload: { data } }) => checkResetLink(data)
    );
}

function* watchResetPassword() {
    yield takeEvery(
        convertTypeFromAction(resetPasswordRequest),
        ({ payload: { data } }) => resetPassword(data)
    );
}

function* watchGetMe() {
    yield takeEvery(
        convertTypeFromAction(getMeRequest),
        () => fetchMe()
    );
}
function* watchGetCookie() {
    yield takeEvery(
        convertTypeFromAction(fetchCookieRequest),
        () => fetchCookie()
    );
}
function* saga() {
    yield all([
        watchPostLogin(),
        watchPostLogout(),
        watchGetMe(),
        watchResetPassword(),
        watchSendResetLink(),
        watchCheckResetLink(),
        watchGetCookie(),
    ]);
}

export { saga };