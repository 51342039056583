import React, { useEffect, useState } from 'react';
import moment from 'moment';
//Hooks
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
//Components
import Typography from '../../../../components/Typography/Typography';
import Tag from '../../../../components/Tag/Tag';
import Link from '../../../../components/Link/Link';
import CompanyEditModal from '../modals/CompanyEditModal/CompanyEditModal';

const StartupCard = ({ company, isIndustry }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);

    const openEditStartupModal = () => {
        setShowEditCompanyModal(true);
    };
    const handleHideEditCompanyModal = () => {
        setShowEditCompanyModal(false);
    };

    return (
        <div className={classes.root}>
            <Typography variant="bodyXs" className={classes.startupDesc}>
                {company.description}
            </Typography>
            <Typography className={classes.industryTitle} component="div" variant="label">
                {t('startupCard.industry')}
            </Typography>
            <div className={classes.tags}>
                {company.sectors &&
                    company.sectors.length > 0 &&
                    company.sectors.map((tag, i) => (
                        <Tag key={`sector-${i}`} className={classes.tag}>
                            <Typography variant="caption" component="span">
                                {tag.name}
                            </Typography>
                        </Tag>
                    ))}
            </div>
            <Typography className={classes.industryTitle} component="div" variant="label">
                {t('startupCard.subIndustry')}
            </Typography>
            <div className={classes.tags}>
                {company.subsectors &&
                    company.subsectors.length > 0 &&
                    company.subsectors.map((tag, i) => (
                        <Tag key={`sector-${i}`} className={classes.tag} variant="sub">
                            <Typography variant="caption" component="span">
                                {tag.name}
                            </Typography>
                        </Tag>
                    ))}
            </div>
            <Typography className={classes.industryTitle} component="div" variant="label">
                {t('startupCard.website')}
            </Typography>
            <Link color="textSecondary" href={company.website} target="_blank">
                {company.website}
            </Link>
            <Typography className={classes.industryTitle} component="div" variant="label">
                {t('startupCard.lastUpdate')}
            </Typography>
            <div className={classes.updateEditWrap}>
                <div>
                    <Typography color="secondaryDark" variant="bodyXs">
                        {isIndustry ? company.updatedAt : company.updated_at_human_date} -{' '}
                    </Typography>
                    <Typography color="secondaryDark" variant="bodyXs">
                        {isIndustry
                            ? company.updated_by
                            : company.updated_by_user && company.updated_by_user.email}
                    </Typography>
                </div>
                {/*<Link onClick={() => openEditStartupModal()}>{t("board.edit")}</Link>*/}
            </div>
            <CompanyEditModal
                isIndustry={isIndustry}
                dialogOpened={showEditCompanyModal}
                handleClose={handleHideEditCompanyModal}
                companyData={company}></CompanyEditModal>
        </div>
    );
};
export default StartupCard;
