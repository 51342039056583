import React, { useState, useEffect } from "react";

// UI components
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
import { useDispatch } from 'react-redux';
//UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
//Assets
import CloseIcon from '../../../../assets/icons/CloseIcon.svg';
import Eye from '../../../../assets/icons/eye.png';
import {useRouterHook} from "../../../../common/useRouterHook";

const AddContentModal = ({ dialogOpened, onChange, onDataSaved, data, onPreview, dataChanged, technologyId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { openPage } = useRouterHook();
    const [text, setText] = useState(data ? EditorState.createWithContent(convertFromRaw(JSON.parse(data))) : EditorState.createEmpty());
    const [isDataChanged, setIsDataChanged] = useState(false);
    useEffect(() => {
        data ? setText(EditorState.createWithContent(convertFromRaw(JSON.parse(data)))) : setText(EditorState.createEmpty());
    }, [data])
    const disableSaveButton = () => {
        if (!text) {
            return true;
        } else {
            return false;
        }
    };
    const handleSaveChanges = e => {
        let convertedData = convertToRaw(text.getCurrentContent());
        onDataSaved(convertedData);
    };
    const onPreviewContentModal = () => {
        openPage(`/technologies-profile/${technologyId}/preview`);
        // onPreview(text)
    };
    const onEditorStateChange = editorState => {
        setText(editorState);
        setIsDataChanged(true)
    };
    const cancelModal = () => {
        if (isDataChanged) {
            let convertedData = convertToRaw(text.getCurrentContent());
            dataChanged(convertedData);
        } else {
            onChange();
        }
    }

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialog, scrollPaper: classes.dialogScrollPaper }}
            onClose={cancelModal}
            open={dialogOpened}
        >
            <IconButton className={classes.closeButton} onClick={cancelModal}>
                <img src={CloseIcon} alt={t('addTechnologyModal.closeIcon')} />
            </IconButton>

            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                {data ? <Typography variant="h6" className={classes.dialogTitle}>{t('addTechnologyModal.editTechnology')}</Typography> : <Typography variant="h6" className={classes.dialogTitle}>{t('addTechnologyModal.detailedDescription')}</Typography>}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Editor
                    editorState={text}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName={classes.editorClassName}
                    onEditorStateChange={onEditorStateChange}
                />
                <div className={classes.cancelAndCreate}>
                    <Button
                        className={classes.previewButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={onPreviewContentModal}
                    >
                        <img src={Eye} />
                        {t("addTechnologyModal.previewContentModal")}
                    </Button>
                    <div>
                        <Button
                            className={classes.cancelButton}
                            variant="tertiary"
                            disabled={false}
                            onClick={() => cancelModal()}
                        >
                            {t("addTechnologyModal.cancel")}
                        </Button>
                        <Button
                            className={classes.saveButton}
                            variant="secondary"
                            disabled={disableSaveButton()}
                            onClick={handleSaveChanges}
                        >
                            {t("addTechnologyModal.saveChanges")}
                        </Button>
                    </div>
                </div>
            </MuiDialogContent>
        </Dialog >
    )
}

export default AddContentModal;
