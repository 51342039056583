import React, { useState, useEffect } from "react";
import classnames from 'classnames';
import useInfiniteScroll from "../../common/useInfiniteScroll.js";
import debounce from "lodash/debounce";
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
import { useSelector, useDispatch } from 'react-redux';
import { useAuthHook } from "../../common/useAuthHook";
// Components
import Button from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from "../../components/Typography/Typography";
import Menu from "../../components/Menu/Menu";
import AddNewUserModal from "./AddNewUserModal/AddNewUserModal";
import AreYouSureModal from "../../components/AreYouSureModal/AreYouSureModal";
// Assets
import { ReactComponent as KeyboardArrowDownIcon } from "../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as SearchPlaceholderIcon } from "../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/IconMore.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/ArrowDown.svg";
// Redux
import { getAdminUsersRequest, updateAdminUserRequest, deleteAdminUserRequest } from "../../store/adminusers/requests";
import { getAdminUsersSelector, updateAdminUserStatusSelector, getAdminUserstatusSelector, deleteAdminUserStatusSelector } from "../../store/adminusers/selectors";
import { actions as adminusersActions} from "../../store/adminusers/slice";
// Constants
import {
    REQUEST_SUCCESS
} from "../../constants/statuses";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

const Row = props => {
    const classes = useStyles();
    const { row, key, orderBy, user, handleDeleteUser } = props;
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    };

    const makeSuperAdmin = id => {
        dispatch(updateAdminUserRequest({ id, data: { role: "super_admin"} }));
    }
    const makeAdmin = id => {
        dispatch(updateAdminUserRequest({ id, data: { role: "admin"} }));
    }
    const makeEditor = id => {
        dispatch(updateAdminUserRequest({ id, data: { role: "editor"} }));
    }
    const makeModerator = id => {
        dispatch(updateAdminUserRequest({ id, data: { role: "moderator"} }));
    }
    const deleteUser = row => {
        handleDeleteUser(row);
    }

    const showMenuItems = (row, user) => {
        let menuItems = [];
        switch (row.role) {
            case "super_admin":
                menuItems = [
                    {
                        label: t("users.makeAdmin"),
                        onClick: () => makeAdmin(row.id)
                    },
                    {
                        label: t("users.makeEditor"),
                        onClick: () => makeEditor(row.id)
                    },
                    {
                        label: t("users.makeModerator"),
                        onClick: () => makeModerator(row.id)
                    }
                ]
                break;
            case "admin":
                menuItems = [
                    {
                        label: t("users.makeSuperAdmin"),
                        onClick: () => makeSuperAdmin(row.id)
                    },
                    {
                        label: t("users.makeEditor"),
                        onClick: () => makeEditor(row.id)
                    },
                    {
                        label: t("users.makeModerator"),
                        onClick: () => makeModerator(row.id)
                    }
                ]
                break;
            case "editor":
                menuItems = [
                    {
                        label: t("users.makeSuperAdmin"),
                        onClick: () => makeSuperAdmin(row.id)
                    },
                    {
                        label: t("users.makeAdmin"),
                        onClick: () => makeAdmin(row.id)
                    },
                    {
                        label: t("users.makeModerator"),
                        onClick: () => makeModerator(row.id)
                    }
                ]
                break;
            case "moderator":
                menuItems = [
                    {
                        label: t("users.makeSuperAdmin"),
                        onClick: () => makeSuperAdmin(row.id)
                    },
                    {
                        label: t("users.makeAdmin"),
                        onClick: () => makeAdmin(row.id)
                    },
                    {
                        label: t("users.makeEditor"),
                        onClick: () => makeEditor(row.id)
                    }
                ]
                break;
            default:
                menuItems = [
                    {
                        label: t("users.makeSuperAdmin"),
                        onClick: () => makeSuperAdmin(row.id)
                    },
                    {
                        label: t("users.makeAdmin"),
                        onClick: () => makeAdmin(row.id)
                    },
                    {
                        label: t("users.makeEditor"),
                        onClick: () => makeEditor(row.id)
                    },
                    {
                        label: t("users.makeModerator"),
                        onClick: () => makeModerator(row.id)
                    }
                ]
        }
        row.id !== user.id && (user.role === "super_admin" || user.role === "admin") && menuItems.push({
            label: t("users.deleteUser"),
            onClick: () => deleteUser(row.id),
            warningItem: true
        })
        return menuItems;
    }

    return (
        <React.Fragment key={key}>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={classes.row} onClick={() => setOpen(!open)}>
                <TableCell className={classnames([classes.cell, classes.wideCell])} align="left"><Typography variant="body" color="black">{row.name}</Typography></TableCell>
                <TableCell className={classnames([classes.cell, classes.wideCell])} align="left"><Typography variant="body" className={classes.mono} color="textLight">{row.email}</Typography></TableCell>
                <TableCell className={classes.cell} align="left"><Typography variant="body" color="black"><Tag value={row.role}/></Typography></TableCell>
                {user && user.role === "super_admin" && <TableCell className={classes.cell} align="right">
                    <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        menuItems={showMenuItems(row, user)}
                    >
                        <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                    </Menu>
                </TableCell>}
            </TableRow>
        </React.Fragment>
    )
}

const Tag = ({ value }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    let className;
    switch (value) {
        case "super_admin":
            className = classes.superAdmin;
            break;
        case "free_trial":
            className = classes.freeTrial;
            break;
        default:
            className = classes[value];
    }
    return (
        <div className={classnames([classes.tag, className])}><Typography variant="bodySmall" className={classes.tagText}>{t(`users.${value}`)}</Typography></div>
    );
}

const Users = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const adminUsers = useSelector(getAdminUsersSelector);
    const updateAdminUserStatus = useSelector(updateAdminUserStatusSelector);
    const getAdminUserStatus = useSelector(getAdminUserstatusSelector);
    const deleteAdminUserStatus = useSelector(deleteAdminUserStatusSelector);
    const [addNewUserModalOpened, setAddNewUserModalOpened] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const { user } = useAuthHook();

    useEffect(() => {
        dispatch(getAdminUsersRequest({search: searchValue, sortBy, sortDirection, perPage: 30}));
    }, [dispatch, searchValue, sortBy, sortDirection]);

    const handleSearchValueChange = e => {
        setSearchValue(e.target.value)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [totalUsers, setTotalUsers] = useState(1);

    useEffect(() => {
        if (adminUsers && adminUsers.users && adminUsers.users.pagination) {
            setCurrentPage(adminUsers.users.pagination.current_page);
            setTotalUsers(adminUsers.users.pagination.total)
        }
    }, [adminUsers]);

    const handleSorting = field => {
        if (sortBy === "" && sortDirection === "") {
            setSortBy(field);
            setSortDirection("desc");
        }
        if (sortBy !== field && sortDirection !== "") {
            setSortBy("");
            setSortDirection("");
        }
        if (sortBy === field && sortDirection === "asc") {
            setSortBy(field);
            setSortDirection("desc");
        }
        if (sortBy === field && sortDirection === "desc") {
            setSortBy(field);
            setSortDirection("asc");
        }
    }

    const moreData = () => {
        dispatch(getAdminUsersRequest({search: searchValue, sortBy, sortDirection, perPage: 30, page: currentPage + 1 }));
    }

    const [isFetching, setIsFetching] = useInfiniteScroll(debounce(moreData, 400), totalUsers);

    const [areYouSureModalOpened, setAreYouSureModalOpened] = useState(false);
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState();

    useEffect(() => {
        if (deleteAdminUserStatus === REQUEST_SUCCESS) {
            setAreYouSureModalOpened(false);
            setSelectedUserForDeletion(null);
        }
    }, [deleteAdminUserStatus])

    const handleDeleteUser = user => {
        setAreYouSureModalOpened(true);
        setSelectedUserForDeletion(user);
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.headerTitle}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                        {t("users.title")}
                    </Typography>
                    <TextField testId="header-search-field" onChange={handleSearchValueChange} endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput}></TextField>
                </div>
                {user && user.role === "super_admin" && <Button variant="secondary" onClick={() => setAddNewUserModalOpened(true)}>
                    {t("users.addUser")}
                </Button>}
            </div>
            <TableContainer>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow className={classes.headRow}>
                            <TableCell
                                className={classnames([classes.cell, classes.wideCell, classes.headerCellClickable])}
                                align="left"
                                onClick={() => handleSorting('name')}
                            >
                                <Typography
                                    variant="bodyButton"
                                    color="black"
                                    className={classes.title}
                                >
                                    {sortBy === "name" ? <strong>{t("users.name")}</strong> : t("users.name")}
                                    <ArrowDown className={classnames([{ [classes.up]: (sortBy === "name" && sortDirection === "asc") }])}/>
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classnames([classes.cell, classes.wideCell, classes.headerCellClickable])}
                                align="left"
                                onClick={() => handleSorting('email')}
                            >
                                <Typography
                                    variant="bodyButton"
                                    color="black"
                                    className={classes.title}
                                >
                                    {sortBy === "email" ? <strong>{t("users.email")}</strong> : t("users.email")}
                                    <ArrowDown className={classnames([{ [classes.up]: (sortBy === "email" && sortDirection === "asc") }])}/>
                                </Typography>
                            </TableCell>
                            <TableCell
                                className={classnames([classes.cell, classes.headerCellClickable])}
                                align="left"
                                onClick={() => handleSorting('role')}
                            >
                                <Typography
                                    variant="bodyButton"
                                    color="black"
                                    className={classes.title}
                                >
                                    {sortBy === "role" ? <strong>{t("users.role")}</strong> : t("users.role")}
                                    <ArrowDown className={classnames([{ [classes.up]: (sortBy === "role" && sortDirection === "asc") }])}/>
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.cell}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {adminUsers && adminUsers.users && adminUsers.users.results.map((row) => (
                        <Row key={row.id} row={row} user={user} handleDeleteUser={() => handleDeleteUser(row)} />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <AddNewUserModal dialogOpened={addNewUserModalOpened} onClose={() => setAddNewUserModalOpened(false)} />
            <AreYouSureModal
                dialogOpened={areYouSureModalOpened}
                titleCopy={selectedUserForDeletion && t('accountProfile.deleteUserTitle', { name: selectedUserForDeletion.name })}
                confirmCopy={t('accountProfile.yesDeleteUser')}
                onConfirm={() => dispatch(deleteAdminUserRequest({ id: selectedUserForDeletion.id }))}
                onChange={() => setAreYouSureModalOpened(false)}
            />
        </div>
    )
};
export default Users;