import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) => createStyles({
    tags: {
        display: "flex",
        // marginTop: spacing(2),
    },
    tag: {
        marginRight: spacing(1)
    },
    tagPlaceholder: {
        background: palette.common.white,
        color: palette.common.white
    },
    typographyTag: {
        alignItems: "center",
        background: palette.primary.backgroundLight,
        height: 20,
        color: palette.text.main,
        padding: "4px 12px",
        borderRadius: "8px",
        display: "inline-flex",
        fontFamily: "GT Flexa Mono, sans-serif",
        cursor: "pointer",
        ...typography.caption
    },
}));
