import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, typography, breakpoints }) => createStyles({
    resultsRoot: {
        margin: `0 auto`,
        padding: spacing(4),
        maxWidth: "1136px"
    },
    signUpBox: {
        maxWidth: 640,
        width: "100%",
        left: "calc(50% - 320px)",
        top: spacing(5),
        background: palette.secondary.main,
        color: palette.common.white,
        padding: spacing(4)
    },
    signUpText: {
        maxWidth: 344,
        marginTop: spacing(1),
        marginBottom: spacing(2)
    },
    signInCopy: {
        marginTop: spacing(2)
    },
    selectedCard: {
        border: `1px solid ${palette.primary.main} !important`
    },
    appliedFiltersWrap: {
        marginTop: spacing(1),
        display: "flex",
        flexDirection: "column"
    },
    appliedFilters: {
        display: "flex",
        flexWrap: "wrap"
    },
    filterTag: {
        background: palette.common.white,
        border: `1px solid ${palette.secondary.main};`,
        color: palette.secondary.main,
        padding: `0 ${spacing(1)}px`,
        borderRadius: shape.borderRadius,
        boxSizing: "border-box",
        marginTop: spacing(1),
        marginRight: spacing(1),

    },
    closeIcon: {
        maxWidth: spacing(1),
        maxHeight: spacing(1),
        marginLeft: spacing(1),
        cursor: "pointer"
    },
    root: {
        // background: palette.common.white,
        // border: "1px solid #F0F1F3",
        flex: "calc(50% - 8px)",
        maxWidth: "calc(50% - 8px)",
        // boxShadow: "0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
        // borderRadius: 4,
        marginBottom: spacing(2),
        // padding: spacing(3),
        "&:nth-child(odd)": {
            marginRight: spacing(2)
        },
        "&:hover": {
            boxShadow: "0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04)",
            cursor: "pointer"
        },
        position: "relative"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "flex-start",
        justifyContent: "space-between",
        // width: "calc(100% - 163px)"
    },
    headerLeft: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: 325
    },
    tags: {
        display: "flex",
        marginTop: spacing(2),
    },
    tag: {
        marginRight: spacing(1)
    },
    avatar: {
        border: "1px solid #F0F1F3",
        borderRadius: 4,
        padding: 4,
        marginRight: spacing(2)
    },
    match: {
        color: palette.success.main,
        display: "flex",
        alignItems: "center"
    },
    infoIconMatch: {
        marginleft: 4
    },
    arrowDown: {
        marginLeft: spacing(2)
    },
    score: {
        lineHeight: "40px",
        position: "absolute",
        top: 60
    },
    scoreCaption: {
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center"
    },
    infoIcon: {
        // marginLeft: spacing(1)
        position: "absolute",
        top: 12,
        right: 12

    },
    sdgTag: {
        marginRight: spacing(1),
        marginTop: spacing(1),
        height: "20px"
    },
    typographyTag: {
        alignItems: "center",
        background: palette.primary.backgroundLight,
        height: 20,
        color: palette.text.main,
        padding: "4px 12px",
        borderRadius: "8px",
        display: "inline-flex",
        fontFamily: "GT Flexa Mono, sans-serif",
        ...typography.caption
    },
    description: {
        marginBottom: spacing(3),
        width: "100%",
        color: palette.text.main,
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
        display: "-webkit-box",
        overflowY: "hidden",
        maxHeight: 48
    },
    infoItems: {
        padding: `${spacing(3)}px 0 0 0`,
        display: "flex",
        flexWrap: "wrap",
        minHeight: 353
    },
    mt24: {
        marginTop: spacing(3),
    },
    mt0: {
        marginTop: -9
    },
    info: {
        marginBottom: spacing(3),
        flex: "50%"
    },
    infoTitle: {
        marginBottom: 2,
        color: palette.text.main
    },
    addToRadar: {
        background: "transparent",
        color: palette.text.main,
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
        },
        position: "relative"
    },
    rssButton: {
        border: "none",
        background: "transparent!important",
        color: palette.text.main,
        "&:hover": {
            background: `${palette.primary.backgroundLight}!important`,
            border: "none"
        },
        position: "relative"
    },
    rssButtonFreeUser: {
        background: palette.primary.backgroundLight,
        color: palette.common.black
    },
    actionItems: {
        paddingTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        justifyContent: "space-between"
    },
    commentActionItems: {
        paddingTop: spacing(3),
        marginTop: spacing(3),
        borderTop: "1px solid #F0F1F3",
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between"
    },
    commentActionItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    questionMark: {
        marginRight: spacing(1)
    },
    communicationIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`,
    },
    likeIcon: {
        padding: `${spacing(1)}px ${spacing(2)}px`,
        marginLeft: spacing(2)
    },
    numberOfEmployees: {
        minHeight: 18,
    },
    enrichedProfileTag: {
        color: palette.common.white,
        background: palette.primary.main,
        padding: `0 ${spacing(1)}px`,
        borderRadius: shape.borderRadius,
        height: spacing(3),
        display: "flex",
        alignItems: "center",
        width: 105
    },
    emptyTag: {
        height: spacing(3),
    },
    valuerScoreBlock: {
        position: "absolute",
        top: 0,
        right: 0,
        background: palette.primary.backgroundMedium,
        boxShadow: `inset 1px 0px 0px #EFEFEE`,
        height: 164,
        width: 164,
        borderTopRightRadius: spacing(3),
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "calc(100% - 496px)",
            width: 496,
            height: 1,
            background: palette.text.backgroundMain
        }
    },
    valuerScore: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    sdg: {
        marginRight: spacing(1),
    },
    sdgTooltip: {
        textAlign: "center",
        color: palette.common.white,
    },
    sdgTooltipTitle: {
        fontWeight: "700",
    },
    chart: {
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: spacing(2)
    },
    scoreBreakdown: {
        marginTop: -spacing(1),
        position: "relative",
        color: palette.secondary.dark
    },
    addPopup: {
        width: 185,
        maxHeight: 202,
        background: palette.common.white,
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: spacing(1),
        border: '1px solid',
        borderColor: palette.text.backgroundDark,
        bottom: 70,
        left: '-25px',
        overflow: "auto",
        paddingTop: spacing(1),
        paddingBottom: spacing(1),
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        "&::-webkit-scrollbar": {
            width: 2
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px transparent"
        },
        "&::-webkit-scrollbar-thumb": {
            background: palette.text.main,
            borderRadius: 0,
        }
    },
    radarNameWrapper: {
        width: "100%",
        display: "flex",
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "&:hover": {
            background: palette.text.backgroundLight,
        },
        cursor: 'pointer'
    },
    radarName: {
        display: "flex",
        alignItems: "center"
    },
    rotateArrow: {
        transform: "rotate(180deg)",
    },
    arrowIcon: {
        marginLeft: spacing(1),
    },
    disabled: {
        // pointerEvents: "none",
        opacity: 0.5
    },
    sdg: {
        marginRight: spacing(1),
    },
    sdgTooltip: {
        textAlign: "center",
        color: palette.common.white,
    },
    sdgTooltipTitle: {
        fontWeight: "700",
    },
    guestBlock: {
        width: "100%",
        background: palette.success.backgroundLight,
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.16)',
        borderRadius: spacing(2),
        padding: `${spacing(8)}px ${spacing(8)}px ${spacing(5)}px ${spacing(8)}px`,
        width: "86%",
        position: "absolute",
        marginTop: 100,
        textAlign: "center",
        marginBottom: 77
    },
    guestBlockContent: {
        display: "flex",
        flexDirection: "column"
    },
    freeStartTrial: {
        position: "absolute",
        right: 0,
    },
    readyText: {
        color: palette.text.dark
    },
    startTrialText: {
        color: palette.text.dark,
        marginTop: spacing(1)
    },
    getAccessText: {
        marginTop: spacing(3),
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    actions: {
        marginTop: spacing(4),
        display: "flex",
        justifyContent: "center"
    },
    signUp: {
        background: palette.success.dark,
        width: 200,
        border: "none", marginLeft: spacing(3)
    },
    signIn: {
        background: "transparent",
        border: `1px solid ${palette.success.dark}`,
        color: palette.success.dark,
        width: 200,
    },
    cardName: {
        display: "flex",
        flexDirection: "column",
        minHeight: 61
    },
    headerWrapper: {
        minHeight: 132
    },
    noFilteredResults: {
        margin: `${spacing(9)}px auto`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    noFilteredResultsImg: {
        marginTop: spacing(4)
    },
    dislikeImg: {
        transform: "rotate(180deg)",
        padding: `${spacing(1)}px ${spacing(2)}px`,
    },
    noResults: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: `${spacing(8)}px 0`
    },
    checkEnrichedCompanyImg: {
        marginRight: `${spacing(1)}px`
    },
    enrichedCompanyInfo: {
        display: "flex"
    },
    enrichedCompanyInfoTitle: {
        color: palette.success.dark
    },
    enrichedCompany: {
        display: "flex",
        alignItems: "center",
        backgroundColor: palette.success.backgroundLight,
        padding: `${spacing(1)}px`,
        borderRadius: `${spacing(1)}px`
    },
    loader: {
        margin: `${spacing(10)}px auto`
    },
    sectorTagsWrapper: {
        maxWidth: "calc(100% - 140px)",
        marginTop: spacing(2)
    },
    scoreMatchWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: -20
    },
    infoIconNew: {
        marginRight: 140,
        marginLeft: spacing(1)
    },
    arrowUp: {
        position: "fixed",
        bottom: 10,
        right: 80,
        fill: "#0B295F",
        cursor: "pointer",
        zIndex: 2,
        "&:hover": {
            fill: "#485E87"
        }
    },
    resultsLoader: {
        width: "100%"
    },
    formFieldsWrapper: {
        maxHeight: 287,
        overflowY: "auto",
        marginBottom: spacing(3)
    },
    ctasWrapper: {
        display: "flex",
        flexDirection: "row"
    },
    createAccountButton: {
        margin: `0 ${spacing(2)}px 0 0`
    },
    signInCopy: {
        textAlign: "left",
        marginBottom: 6
    },
    form: {
        marginTop: spacing(2)
    },
    signUpBtn: {
        marginTop: 4
    },
    passwordInputWrapper: {
        marginBottom: spacing(3),
        width: "calc(50% - 8px)"
    },
    confirmPasswordInputWrapper: {
        marginBottom: spacing(3),
        marginLeft: spacing(2),
        width: "calc(50% - 8px)"
    },
    totalMatch: {
        border: `1px solid ${palette.common.black}`
    },
    [breakpoints.down("md")]: {
        blurryBg: {
            height: 980
        },
        guestBlock: {
            padding: "24px 24px 62px 24px"
        },
        ctasWrapper: {
            flexDirection: "column"
        },
        signInCopy: {
            textAlign: "center",
            marginBottom: spacing(2)
        },
        signUpBtn: {
            marginBottom: spacing(4)
        },
        formFieldsWrapper: {
            maxHeight: "none"
        },
        checkboxRoot: {
            alignSelf: "flex-start",
            paddingTop: 3
        },
        checkboxLabel: {
            textAlign: "left",
        },
        confirmPasswordInputWrapper: {
            width: "100%",
            marginBottom: spacing(3),
            marginLeft: 0
        },
        passwordInputWrapper: {
            width: "100%",
            marginBottom: spacing(3)
        }
    },
}));
