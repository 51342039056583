import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classnames from 'classnames';
// Hooks
import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
//Components
import Typography from '../../../../components/Typography/Typography';
import Tag from '../../../../components/Tag/Tag';
import Button from '../../../../components/Button/Button';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '../../../../components/Menu/Menu';
//Assets
import CircleDots from '../../../../assets/icons/CircleDots.png';
import { ReactComponent as WhiteCheck } from '../../../../assets/icons/CheckWhite.svg';
import { ReactComponent as WhiteDelete } from '../../../../assets/icons/DeleteWhite.svg';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/icon-more.svg';
import { useRouterHook } from '../../../../common/useRouterHook';
const TechnologyCard = ({
    technology,
    onSwitchToggleAction,
    onDeleteTechnology,
    onEditTechnology,
    user,
    testId
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const [technologyActive, setTechnologyActive] = useState(!!technology.published);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const toggleRadarStatus = (e, technology) => {
        e.preventDefault();
        e.stopPropagation();
        setTechnologyActive((technologyActive) => !technologyActive);
        onSwitchToggleAction(!technologyActive, technology);
    };
    const handleMenuClick = () => {
        setIsMenuOpened((prevIsMenuOpened) => !prevIsMenuOpened);
    };
    const openTechnologyProfile = () => {
        localStorage.setItem('technology-id', technology.id);
        openPage(`technologies-profile/${technology.id}`);
    };
    return (
        <div
            className={classes.block}
            onClick={() => openTechnologyProfile()}
            test-id={`technology-${testId}`}>
            <div className={classes.titleActions}>
                <Typography variant="subtitle1" color="secondaryDark">
                    {technology.name}
                </Typography>
                {user && user.role !== 'moderator' && (
                    <Menu
                        btnClassName={classes.moreButton}
                        onChange={handleMenuClick}
                        testId={`menu-${testId}`}
                        menuItems={[
                            {
                                label: t('technology.edit'),
                                onClick: () => onEditTechnology(technology),
                                testId: `edit-technology-${testId}`
                            },
                            {
                                label: t('technology.editCompaniesKanban'),
                                onClick: () =>
                                    openPage(
                                        `/technologies-profile/${technology.id}/technologies-board`
                                    ),
                                testId: `edit-companies-${testId}`
                            },
                            {
                                label: t('technology.openTechnologyProfile'),
                                onClick: () => openPage(`technologies-profile/${technology.id}`),
                                testId: `open-technology-${testId}`
                            },
                            {
                                label: t('technology.deleteTechnology'),
                                onClick: () => onDeleteTechnology(technology),
                                testId: `delete-technology-${testId}`
                            }
                        ]}>
                        <MoreIcon
                            className={classnames([
                                classes.moreIcon,
                                { [classes.moreIconOpened]: isMenuOpened }
                            ])}
                        />
                    </Menu>
                )}
            </div>
            <Typography
                component="div"
                className={classes.description}
                variant="subtitle2"
                color="textMain">
                {technology?.technology?.description.length > 220
                    ? technology?.description?.substring(0, 220) + '...'
                    : technology?.description}
            </Typography>
            <div className={classes.status}>
                <div className={classes.status}>
                    <Typography
                        className={classes.technologyStatus}
                        variant="body"
                        color="secondaryDark">
                        {t('technology.technologyStatus')}
                    </Typography>
                    <Tag className={classes.tag} variant={technologyActive ? 'sub' : 'error'}>
                        <Typography variant="bodyXs">
                            {technologyActive
                                ? t('technology.published')
                                : t('technology.notPublished')}
                        </Typography>
                    </Tag>
                    {!!technology.anotherWorking && (
                        <Tooltip
                            title={
                                <Typography color="white" component="div" variant="body2">
                                    {t('technology.anotherUserWorking')}
                                </Typography>
                            }
                            arrow>
                            <img
                                className={classes.anotherUserWorking}
                                src={CircleDots}
                                alt={t('technology.anotherUserWorking')}
                            />
                        </Tooltip>
                    )}
                </div>
                {technologyActive && <WhiteCheck className={classes.checkMark} />}
                <Switch
                    checked={!!technologyActive}
                    onClick={(e) => toggleRadarStatus(e, technology)}
                    name="reactivateTechnology"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    classes={{
                        root: classes.rootSwitch,
                        switchBase: classes.switchBase,
                        thumb: classes.thumb,
                        track: classes.track,
                        checked: classes.checked
                    }}
                    focusVisibleClassName={classes.focusVisible}
                    disableRipple
                    disabled={
                        user &&
                        user.role !== 'admin' &&
                        user.role !== 'super_admin' &&
                        user.role !== 'editor'
                    }
                />
                {!technologyActive && <WhiteDelete className={classes.deleteMark} />}
            </div>
            <div className={classes.lastUpdateFooter}>
                <Typography className={classes.lastUpdate} variant="bodySmall" color="textlighter">
                    {t('technology.lastUpdate')}
                </Typography>
                <Typography variant="bodySmall" color="textlighter">
                    {moment(technology.updated_at).fromNow()} -{' '}
                </Typography>
                <Typography variant="bodySmall" color="textlighter">
                    {' '}
                    {technology.user_name}
                </Typography>
            </div>
        </div>
    );
};
export default TechnologyCard;
