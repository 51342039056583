import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, typography }) => ({
    root: {
        padding: `${spacing(2)}px 0`,
        background: palette.common.white,
        position: "relative"
    },
    avatar: {
        marginRight: spacing(6),
        width: 72,
        height: 72,
        borderRadius: "50%"
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    info: {
        display: "flex",
        paddingTop: spacing(3)
    },
    editInfo: {
        paddingTop: spacing(1)
    },
    sector: {
        marginRight: spacing(1),
        marginBottom: spacing(1)
    },
    activeSector: {
        boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: spacing(4)
    },
    addCtas: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: spacing(2)
    },
    editPreviewCtas: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: spacing(2)
    },
    edit: {
        marginLeft: spacing(3)
    },
    subindustriesTitle: {
        marginTop: spacing(5),
        marginBottom: spacing(2)
    },
    moreIcon: {
        fill: palette.secondary.dark
    },
    moreIconOpened: {
        fill: palette.primary.dark
    },
    moreButton: {
        position: "absolute",
        top: spacing(4),
        right: spacing(4),
        cursor: "pointer"
    },
    inProgressIcon: {
        marginRight: "0 !important",
        marginLeft: spacing(1)
    },
    inProgressPill: {
        backgroundColor: `${palette.text.backgroundLight} !important`,
        border: `1px solid ${palette.text.light} !important`,
        borderRadius: spacing(1),
        padding: "5px 14px",
        display: "inline-flex",
        color: `${palette.text.light} !important`,
        cursor: "default",
        marginRight: spacing(1),
        marginBottom: spacing(1)
    },
    enabledPill: {
        cursor: "pointer"
    },
    tooltip: {
        padding: `16px 24px !important`,
        borderRadius: "16px",
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    mlRequestWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: spacing(6)
    },
    fullWidth: {
        width: "100%",
        marginLeft: 134
    },
    halfWidth: {
        width: "70%",
        marginBottom: spacing(2),
        "& input": {
            // ...typography.subtitle1,
            color: palette.secondary.dark
        }
    },
    editAvatar: {
        position: "absolute",
        top: spacing(3),
        left: spacing(3),
        width: 120,
        height: 120,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid rgba(0, 0, 0, 0.5)`,
        borderRadius: "50%",
        background: palette.primary.backgroundMedium,
        "& img": {
            margin: 0
        },
        "&:hover": {
            background: "rgba(0, 0, 0, 0.5)",
            cursor: "pointer",
            "& $avatar": {
                display: "none"
            },
            "& $cameraIcon": {
                display: "block"
            }
        }
    },
    cameraIcon: {
        display: "none"
    },
    addSubsector: {
        marginTop: spacing(4)
    },
    addSubsectorName: {
        marginBottom: spacing(2)
    },
    editSelectedSubsector: {
        marginTop: spacing(5)
    },
    marginTop: {
        marginTop: spacing(2)
    },
    lastUpdatedValue: {
        color: palette.common.black,
        fontFamily: "GT Flexa, sans-serif"
    }
}));