import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
//Components
import Column from './Column';
import { DraggableCard } from './Card';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from 'lodash';
import useStyles from './style';
import Button from '../../components/Button/Button';
import Multiselect from '../../components/Multiselect/Multiselect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

//Hooks
import { useSelector, useDispatch } from 'react-redux';
//Assets
import ArrowDown from '../../assets/icons/ArrowDown.png';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/ArrowDownGrey.svg';
// Hooks
import { useTranslation } from 'react-i18next';
//Redux
import { updateCompanyKanbanRequest } from '../../store/technologies/requests';
import { updateMLCompanySubIndustryRequest } from '../../store/industries/requests';
import { fetchKanbanItemsRequest, updateKanbanItemRequest } from '../../store/kanban/requests';
import { updateKanbanItemStatusSelector } from '../../store/kanban/selectors';

const Board = ({ isKanban = false, isIndustry, cards, columns, setColumns, headerColors }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    //NOTE: all commented code here is for multiple selection of cards in board
    // const [selectedCardIds, setSelectedCardIds] = useState([]);
    // const [newSelectedCards, setNewSelectedCards] = useState([]);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [selectedColumnToMove, setSelectedColumnToMove] = useState('');
    const [menuIndex, setMenuIndex] = useState(null);
    const [moveAllIdsObj, setMoveAllIdsObj] = useState([]);
    const [notification, setNotification] = useState(null);
    const [justDroppedCard, setJustDroppedCard] = useState(null);
    const updateKanbanItemStatus = useSelector(updateKanbanItemStatusSelector);
    const [updateKanbanItemRequestSent, setUpdateKanbanItemRequestSent] = useState(false);
    const [toaster, setToaster] = useState();
    const [loadingMsg, setLoadingMsg] = useState('Loading, please hold...');

    useEffect(() => {
        setUpdateKanbanItemRequestSent(updateKanbanItemStatus === 'REQUEST_PENDING');
        const shouldUpdateKanban =
            isKanban && updateKanbanItemRequestSent && updateKanbanItemStatus === 'REQUEST_SUCCESS';

        shouldUpdateKanban && dispatch(fetchKanbanItemsRequest());
    }, [dispatch, isKanban, updateKanbanItemRequestSent, updateKanbanItemStatus]);

    useEffect(() => {
        if (!!toaster && !!notification) {
            toast.update(toaster, {
                render: notification.msg,
                type: notification.error ? 'error' : 'success',
                isLoading: false
            });
            setTimeout(() => toast.dismiss(toaster), 1000);
        }
    }, [toaster, notification]);

    useEffect(() => {
        const item = cards && cards.find((item) => item?.id === justDroppedCard?.id);
        let showToaster = false;

        if (item && updateKanbanItemRequestSent && justDroppedCard.destColumnId === 3) {
            console.log('sending email...');

            setLoadingMsg('Sending email. please hold...');

            showToaster = true;
        }

        showToaster && setToaster(toast.loading(loadingMsg));
    }, [updateKanbanItemRequestSent]);

    const convertKanbanStatus = (oldStatus) => {
        let newStatus;

        switch (oldStatus) {
            case 1:
                newStatus = 1;
                break;
            case 2:
                newStatus = 3;
                break;
            case 3:
                newStatus = 7;
                break;
            case 4:
                newStatus = 8;
                break;
            default:
                newStatus = oldStatus;
        }

        return newStatus;
    };

    const moveCard = (cardId, destColumnId, index) => {
        setNotification(null);
        setJustDroppedCard({ id: cardId, destColumnId });

        let newColumns = columns.map((column) => ({
            ...column,
            cardIds: _.flowRight(
                // 2) If this is the destination column, insert the cardId.
                (ids) =>
                    column.id === destColumnId
                        ? [...ids.slice(0, index), cardId, ...ids.slice(index)]
                        : ids,
                // 1) Remove the cardId for all columns
                (ids) => ids.filter((id) => id !== cardId)
            )(column.cardIds)
        }));
        setColumns(newColumns);

        let compData = [];
        compData.push({ id: cardId, kanban_status: destColumnId });

        if (isKanban) {
            dispatch(
                updateKanbanItemRequest({
                    id: cardId,
                    kanbanStatus: convertKanbanStatus(destColumnId + 1)
                })
            );
        } else {
            isIndustry
                ? dispatch(
                      updateMLCompanySubIndustryRequest({ companies: compData, isBoard: true })
                  )
                : dispatch(updateCompanyKanbanRequest({ companies: compData, isBoard: true }));
        }
    };

    const openMenu = (column, index) => {
        setMenuIndex(index);
        setIsMenuOpened((prevIsMenuOpened) => !prevIsMenuOpened);
    };
    const handleFieldChange = (e, column) => {
        setSelectedColumnToMove(e.target.value);
        setMoveAllIdsObj({ id: column.id, cardIds: column.cardIds });
    };
    const moveAllCompanies = () => {
        let compData = [];
        let destColumnId = columns && columns.filter((f) => f.name === selectedColumnToMove)[0].id;
        moveAllIdsObj &&
            moveAllIdsObj.cardIds &&
            moveAllIdsObj.cardIds.forEach((id) =>
                compData.push({ id: id, kanban_status: destColumnId })
            );
        isIndustry
            ? dispatch(updateMLCompanySubIndustryRequest({ companies: compData, isBoard: true }))
            : dispatch(updateCompanyKanbanRequest({ companies: compData, isBoard: true }));
        let newCols = columns;
        newCols &&
            newCols.forEach((f) => {
                if (f.id === destColumnId) {
                    f.cardIds.push(...moveAllIdsObj.cardIds);
                }
            });
        newCols &&
            newCols.forEach((f) => {
                if (f.id === moveAllIdsObj.id) {
                    f.cardIds = [];
                }
            });
        setColumns(newCols);
        setIsMenuOpened(false);
        setMenuIndex(null);
        setSelectedColumnToMove('');
    };

    // Notifications
    const handleNotification = (e, error = false) => {
        setNotification({
            cardId: e.tid,
            msg: e.message,
            error: error
        });
    };

    // useEffect(() => {
    //     // TODO@jkica: lets move this to app.js and add to window object if we are going to have more implementations of websockets
    //     let PusherClient = new Pusher('2fcc838cbcdbf5f3ab1c', {
    //         cluster: 'eu',
    //         wsHost: window.location.hostname,
    //         wsPort: process.env.REACT_APP_WS_PORT,
    //         wssPort: process.env.REACT_APP_WS_PORT,
    //         forceTLS: process.env.REACT_APP_WS_FORCE_TLS,
    //         enableStats: false,
    //         enabledTransports: ['ws', 'wss']
    //     });
    //     Pusher.logToConsole = process.env.REACT_APP_ENV === 'local';
    //
    //     let echo = new Echo({
    //         broadcaster: 'pusher',
    //         key: '2fcc838cbcdbf5f3ab1c',
    //         client: PusherClient
    //     });
    //
    //     if (echo.channel('company-updated')) {
    //         echo.channel('company-updated')
    //             .subscribed(() =>
    //                 console.log('Successfully subscribed to "company-updated" channel.')
    //             )
    //             .listen('EnrichmentReadyEvent', (e) => handleNotification(e));
    //     }
    //
    //     // disconnect on component unmount
    //     return () => echo.disconnect();
    // }, []);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={classnames([classes.board, { [classes.kanbanBoard]: isKanban }])}>
                {columns.map((column, index) => (
                    <div key={`column-${index}`} className={classes.columnTitle}>
                        <div
                            className={classes.columnHeader}
                            test-id={`column-header-${column.id}`}>
                            <div
                                className={classnames([
                                    isKanban && index < 3
                                        ? classes.kanbanColumnHeaderColor
                                        : classes.columnHeaderColor,
                                    isKanban ? classes.width100 : ''
                                ])}
                                style={{ backgroundColor: headerColors[index] }}>
                                <span>{column.name}</span>
                                {!isKanban && (
                                    <span>
                                        {column && column.cardIds && column.cardIds.length}
                                        {index === columns && columns.length - 1 && (
                                            <span>{t('board.100')}</span>
                                        )}
                                    </span>
                                )}
                            </div>
                            {!isKanban && isMenuOpened && menuIndex === index && (
                                <div className={classes.moveAllMenu}>
                                    <div className={classes.multiselectWrapper}>
                                        <Multiselect
                                            testId="multiselect-column-options"
                                            iconArrow={
                                                <ArrowDownIcon className={classes.arrowDown} />
                                            }
                                            placeholder="Select column"
                                            customClass={classes.multiselect}
                                            labelClass={classes.labelClass}
                                            className={classes.rowWrap}
                                            width="178px"
                                            label={t('board.moveAllCompanies')}
                                            options={
                                                columns &&
                                                columns.filter((col) => col.id !== column.id)
                                            }
                                            value={selectedColumnToMove}
                                            onChange={(e) =>
                                                handleFieldChange(e, column)
                                            }></Multiselect>
                                    </div>
                                    <Button
                                        onClick={moveAllCompanies}
                                        disabled={!selectedColumnToMove}
                                        className={classes.moveButton}
                                        variant="primary">
                                        {t('board.move')}
                                    </Button>
                                </div>
                            )}
                            {!isKanban && (
                                <img
                                    src={ArrowDown}
                                    alt={t('board.arrow')}
                                    onClick={() => openMenu(column, index)}
                                    test-id={`column-header-arrow-${column.id}`}
                                />
                            )}
                        </div>
                        <Column
                            id={column.id}
                            testId={column.id}
                            key={column.id}
                            title={column.title}>
                            {column &&
                                column.cardIds &&
                                column.cardIds
                                    .map((cardId) => cards.find((card) => card.id === cardId))
                                    .map((card, index) => (
                                        // onSelectionChange={handleItemSelection}
                                        //         selectedCardIds={selectedCardIds}
                                        <DraggableCard
                                            isIndustry={isIndustry}
                                            isKanban={isKanban}
                                            dispatch={dispatch}
                                            key={card.id}
                                            id={card.id}
                                            columnId={column.id}
                                            columnIndex={index}
                                            title={card.title}
                                            data={card}
                                            moveCard={moveCard}
                                            testId={index}
                                            fetchMlDone={
                                                notification && notification.cardId == card.id
                                            }
                                            fetchMlFailed={
                                                notification &&
                                                notification.cardId == card.id &&
                                                notification.error
                                            }
                                            justDropped={
                                                justDroppedCard && justDroppedCard.id == card.id
                                            }
                                        />
                                    ))}
                            {column && column.cardIds && column.cardIds.length === 0 && (
                                <DraggableCard
                                    isSpacer
                                    isKanban={isKanban}
                                    dispatch={dispatch}
                                    columnId={column && column.id}
                                    columnIndex={0}
                                    moveCard={moveCard}
                                />
                            )}
                        </Column>
                    </div>
                ))}
            </div>
            <ToastContainer
                position="bottom-right"
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </DndProvider>
    );
};
export default Board;
