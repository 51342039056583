import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import {
    login,
    logout,
    getMe,
    postResetPassword,
    postSendResetLink,
    postCheckResetLink,
    getCookie,
} from "./helpers";

function* postLogin(emailValue, passwordValue, token = null) {
    try {
        yield call(getCookie);
        yield put(actions.postLoginPending());
        let data = new FormData();
        data.append("email", emailValue); // "jondoe@mail.com"
        data.append("password", passwordValue); // "asdasdasd"
        if (token) {
            data.append("token", token); // invitation token
        }
        const loginResponse = yield call(login, data);
        if (loginResponse.data) {
            yield put(actions.setAccessToken(loginResponse.data.access_token));
            yield put(actions.postLoginSuccess());
        } else {
            yield put(actions.postLoginFailure(loginResponse.message));
        }
    } catch (err) {
        yield put(actions.postLoginFailure());
    }
}
function* postLogout() {
    try {
        yield call(logout);
        yield put(actions.postLogoutPending());
        yield put(actions.setUser({}));
        yield put(actions.postLogoutSuccess());
        yield put(actions.postLogoutUndefined());
    } catch (err) {
        yield put(actions.postLogoutFailure());
    }
}
function* fetchCookie() {
    try {
        yield put(actions.getCookiePending());
        yield call(getCookie);
        yield put(actions.getCookieSuccess());
    } catch (err) {
        yield put(actions.getCookieFailure());
    }
}
function* checkResetLink(data) {
    try {
        yield put(actions.checkResetLinkPending());
        const response = yield call(postCheckResetLink, data);
        yield put(actions.checkResetLinkSuccess(response.data));
    } catch (err) {
        yield put(actions.checkResetLinkFailure());
    }
}
function* sendResetLink(data) {
    try {
        yield put(actions.sendResetLinkPending());
        const response = yield call(postSendResetLink, data);
        yield put(actions.sendResetLinkSuccess(response.data.responseMsg));
    } catch (err) {
        yield put(actions.sendResetLinkFailure());
    }
}
function* resetPassword(data) {
    try {
        yield put(actions.resetPasswordPending());
        const response = yield call(postResetPassword, data);
        yield put(actions.resetPasswordSuccess(response.data.responseMsg));
    } catch (err) {
        yield put(actions.resetPasswordFailure());
    }
}
function* fetchMe() {
    try {
        const response = yield call(getMe);
        yield put(actions.getMePending());
        yield put(actions.setUser(response.data));
        yield put(actions.getMeSuccess(response));
    } catch (err) {
        yield put(actions.setUser(null));
        yield put(actions.getMeFailure());
    }
}

export {
    postLogin,
    postLogout,
    fetchMe,
    sendResetLink,
    resetPassword,
    checkResetLink,
    fetchCookie,
};