import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/TextField/TextField";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Modal from '../../../components/Modal/Modal.js';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";

//Assets
import CloseIcon from '../../../assets/icons/CloseIcon.svg';
//Redux
import { addExistingUserRequest } from "../../../store/subscriptions/requests";
import { addExistingUserMessageFailureSelector, addExistingUserStatusSelector } from "../../../store/subscriptions/selectors";
import { actions as subscriptionsActions } from "../../../store/subscriptions/slice";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";

const AddExistingUserModal = ({
    dialogOpened,
    onClose,
    team
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const addExistingUserMessageFailure = useSelector(addExistingUserMessageFailureSelector);
    const addExistingUserStatus = useSelector(addExistingUserStatusSelector);
    const [emailValue, setEmailValue] = useState("");

    const create = () => {
        dispatch(addExistingUserRequest({ teamId: team.id, email: emailValue }));
    };

    useEffect(() => {
        addExistingUserStatus === REQUEST_SUCCESS && handleClose();
    }, [addExistingUserStatus])

    const handleClose = () => {
        onClose();
        setEmailValue("");
        dispatch(subscriptionsActions.clearAddExistingUserMessageFailure());
    };

    const handleEmailChange = (event) => {
        setEmailValue(event.target.value);
    }

    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Modal
            onClose={handleClose}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px"
        >
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="paragraph" component="div" className={classes.filterDialogTitle}>{t('accountProfile.addExistingUserModalTitle')}</Typography>
            </MuiDialogTitle>
            <Box className={classes.rows}>
                <div className={classes.row}>
                    <TextField
                        testId="header-email-field"
                        autocomplete="one-time-code"
                        value={emailValue || ""}
                        className={classes.modalInput}
                        fullWidth
                        label={t("users.emailInputPlaceholder")}
                        onChange={handleEmailChange}
                        error={!!emailValue && !validateEmail(emailValue)}
                        {...!!emailValue && !validateEmail(emailValue) && { helperText: t("common.emailError") }}
                    />
                </div>
            </Box>
            {!!addExistingUserMessageFailure && <Typography variant="body" component="div" gutterBottom color="danger">
                {addExistingUserMessageFailure}
            </Typography>}
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.rowWrapButtons}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={handleClose}
                        test-id="cancel-filters"
                    >
                        {t("users.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        className={classes.showResultsButton}
                        disabled={!emailValue || (!!emailValue && !validateEmail(emailValue))}
                        onClick={create}
                        test-id="show-results"
                    >
                        {t("users.createNewUser")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
export default AddExistingUserModal;