import { all, takeEvery } from "redux-saga/effects";
import {
    fetchTechnologiesRequest,
    createTechnologyRequest,
    updateTechnologyRequest,
    publishTechnologyRequest,
    unPublishTechnologyRequest,
    deleteTechnologyRequest,
    getTechnologyProfileRequest,
    updateTechnologyEditorRequest,
    postGetClosestRequest,
    getTechnologyWithCompaniesRequest,
    updateCompanyKanbanRequest,
    releaseCompaniesRequest,
} from "../requests";
import {
    fetchTechnologies,
    createTechnology,
    updateTechnology,
    postPublishTechnology,
    postUnPublishTechnology,
    postDeleteTechnology,
    getTechnologyProfile,
    updateTechnologyEditor,
    submitGetClosest,
    submitGetTechnologyWithCompanies,
    submitUpdateCompanyKanban,
    submitReleaseCompanies,
} from "./workers";

const convertTypeFromAction = actionCreator => actionCreator.toString();

function* watchFetchTechnologies() {
    yield takeEvery(
        convertTypeFromAction(fetchTechnologiesRequest),
        () => fetchTechnologies()
    );
}
function* watchCreateTechnologies() {
    yield takeEvery(
        convertTypeFromAction(createTechnologyRequest),
        ({ payload: { title, description, keywords } }) => createTechnology(title, description, keywords)
    );
}
function* watchUpdateTechnologies() {
    yield takeEvery(
        convertTypeFromAction(updateTechnologyRequest),
        ({ payload: technology }) => updateTechnology(technology)
    );
}
function* watchUpdateTechnologiesEditor() {
    yield takeEvery(
        convertTypeFromAction(updateTechnologyEditorRequest),
        ({ payload: technology }) => updateTechnologyEditor(technology)
    );
}
function* watchPublishTechnologies() {
    yield takeEvery(
        convertTypeFromAction(publishTechnologyRequest),
        ({ payload: technology }) => postPublishTechnology(technology)
    );
}
function* watchUnPublishTechnologies() {
    yield takeEvery(
        convertTypeFromAction(unPublishTechnologyRequest),
        ({ payload: technology }) => postUnPublishTechnology(technology)
    );
}
function* watchDeleteTechnologies() {
    yield takeEvery(
        convertTypeFromAction(deleteTechnologyRequest),
        ({ payload: technology }) => postDeleteTechnology(technology)
    );
}
function* watchGetTechnologyProfileRequest() {
    yield takeEvery(
        convertTypeFromAction(getTechnologyProfileRequest),
        ({ payload: { id, data, clearResults } }) => getTechnologyProfile(id, data, clearResults)
    );
}
function* watchPostGetClosestRequest() {
    yield takeEvery(
        convertTypeFromAction(postGetClosestRequest),
        ({ payload: { technologyDescription, id } }) => submitGetClosest(technologyDescription, id)
    );
}
function* watchGetTechnologyWithCompaniesRequest() {
    yield takeEvery(
        convertTypeFromAction(getTechnologyWithCompaniesRequest),
        ({ payload: { id, filterString, orderBy, orderDirection } }) => submitGetTechnologyWithCompanies(id, filterString, orderBy, orderDirection)
    );
}
function* watchUpdateCompanyKanban() {
    yield takeEvery(
        convertTypeFromAction(updateCompanyKanbanRequest),
        ({ payload: { companies, isBoard } }) => submitUpdateCompanyKanban(companies, isBoard)
    );
}
function* watchReleaseCompanies() {
    yield takeEvery(
        convertTypeFromAction(releaseCompaniesRequest),
        ({ payload: { id } }) => submitReleaseCompanies(id)
    );
}

function* saga() {
    yield all([
        watchFetchTechnologies(),
        watchCreateTechnologies(),
        watchUpdateTechnologies(),
        watchPublishTechnologies(),
        watchUnPublishTechnologies(),
        watchDeleteTechnologies(),
        watchGetTechnologyProfileRequest(),
        watchUpdateTechnologiesEditor(),
        watchPostGetClosestRequest(),
        watchGetTechnologyWithCompaniesRequest(),
        watchUpdateCompanyKanban(),
        watchReleaseCompanies(),
    ]);
}

export { saga };
