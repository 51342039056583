import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, typography }) => createStyles({
    root: {
    },
    startupDesc: {
        whiteSpace: "pre-wrap",
        color: palette.text.main,
    },
    tags: {
        display: "flex",
        flexWrap: "wrap"
    },
    tag: {
        marginRight: spacing(1),
        marginTop: spacing(1),
    },
    tagSubIndustry: {
        backgroundColor: palette.success.backgroundLight
    },
    industryTitle: {
        marginTop: spacing(2),
        marginBottom: 4
    },
    updateEditWrap: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

}));