import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette, spacing, shape, typography }) => createStyles({
    select: {
        ...typography.body2,
        minWidth: 135,
        background: palette.common.white,
        borderRadius: spacing(1),
        border: "1px solid",
        // borderColor: `${palette.text.lighter} !important`,
        fontFamily: "GT Flexa, sans-serif",
        color: palette.secondary.dark,
        width: ({ width }) => width ? width : 'auto',
        minHeight: 35
    },
    root: {
        zIndex: 2,
        paddingLeft: spacing(1),
        position: "relative"
    },
    headerLabel: {
        color: palette.text.main,
        fontSize: 14,
        fontFamily: "GT Flexa Mono, sans-serif",
        lineHeight: '16px',
        marginBottom: `${spacing(1)}px`,
        minHeight: 16,
        transform: "none",
        display: ({ removeLabel }) => removeLabel ? "none" : "block"
    },
    disabled: {
        opacity: 0.3,
        // background: palette.text.backgroundMain,
        borderRadius: spacing(1)
    },
    filled: {
        // borderColor: `${palette.secondary.main} !important`
    }
}));