import { put, call } from "redux-saga/effects";
import { actions } from "../slice";

import {
    getCountries
} from "./helpers";

function* getAllCountries() {
    try {
        yield put(actions.getCountriesPending());
        const countries = yield call(getCountries);
        yield put(actions.setCountries(countries))
        yield put(actions.getCountriesSuccess());
    } catch (err) {
        yield put(actions.getCountriesFailure());
    }
}
export {
    getAllCountries
}