import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    chartWrap: {
        width: 100,
        height: 100,
        borderRadius: "50%",
        backgroundImage: ({ numToDeg }) => `conic-gradient(${palette.primary.light} 0deg, ${palette.primary.light} ${numToDeg}deg, transparent ${numToDeg}deg),
        repeating-conic-gradient(${palette.primary.light} 0deg 0.5deg, ${palette.primary.backgroundMedium} 0.5deg 3deg)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: spacing(2)
    },
    percentage: {
        width: "75%",
        height: "75%",
        background: palette.primary.backgroundMedium,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: spacing(4),
        fontWeight: "bold",
    },

}));