import { call } from "redux-saga/effects";
import axios from "axios";
import {
    GET_ALL_TECHNOLOGIES,
    CREATE_TECHNOLOGY,
    UPDATE_TECHNOLOGY,
    DELETE_TECHNOLOGY,
    GET_TECHNOLOGY,
    GET_CLOSEST_URL,
    postCompaniesTechnology,
    technologyCompanies,
    UPDATE_COMPANY_KANBAN_URL,
    releaseCompaniesURL
} from "../../../constants/endpoints";
let username = "DataTeam";
let password = "hDPKG8vsJJ8RgvxY";

let basicAuth = "Basic " + btoa(username + ":" + password);
function* getAllTechnologies() {
    const response = yield call(axios, {
        method: "GET",
        url: `${GET_ALL_TECHNOLOGIES}`,
        withCredentials: true
    }
    );
    return response.data;
}
function* postCreateTechnology(title, description, keywords) {
    const response = yield call(axios, {
        method: "POST",
        url: `${CREATE_TECHNOLOGY}`,
        withCredentials: true,
        data: {
            name: title,
            ml_description: description,
            keywords
        }
    }
    );
    return response.data;
}
function* postUpdateTechnology(technology) {
    let { id, name, ml_description, keywords } = technology;
    const response = yield call(axios, {
        method: "PUT",
        url: `${UPDATE_TECHNOLOGY}/${id}`,
        withCredentials: true,
        data: {
            name: name,
            ml_description: ml_description,
            keywords
        }
    }
    );
    return response.data;
}
function* postUpdateTechnologyEditor(technology) {
    let { id } = technology;
    const response = yield call(axios, {
        method: "PUT",
        url: `${UPDATE_TECHNOLOGY}/${id}`,
        withCredentials: true,
        data: technology
    }
    );
    return response.data;
}
function* publishTechnology(technology) {
    let { id, published } = technology;
    const response = yield call(axios, {
        method: "PUT",
        url: `${UPDATE_TECHNOLOGY}/${id}`,
        withCredentials: true,
        data: {
            published: published
        }
    }
    );
    return response.data;
}
function* deleteTechnology(technology) {
    let { id } = technology;
    const response = yield call(axios, {
        method: "DELETE",
        url: `${DELETE_TECHNOLOGY}/${id}`,
        withCredentials: true,
    }
    );
    return response.data;
}
function* getTechnology(id, data) {
    const { page, orderBy, orderDirection, filterNameValue } = data;
    let url = filterNameValue ? `${GET_TECHNOLOGY}/${id}/?sort-direction=${orderDirection}&sort-by=${orderBy}${filterNameValue ? "&" + filterNameValue : ""}`
        : `${GET_TECHNOLOGY}/${id}/?sort-direction=${orderDirection}&sort-by=${orderBy}${!!page ? `&page=${page}` : ""}`
    const response = yield call(axios, {
        method: "GET",
        url: url,
        withCredentials: true,
    }
    );
    return response.data;
}
function* postGetClosest(technologyDescription) {
    const data = {
        description: technologyDescription,
        result_limit: 100
    };
    const response = yield call(axios, {
        method: "POST",
        url: `${GET_CLOSEST_URL}`,
        headers: {
            Authorization: basicAuth,
        },
        data
    });
    return response.data;
}
function* postCompaniesData(data, id) {
    const response = yield call(axios, {
        method: "POST",
        url: `${postCompaniesTechnology(id)}`,
        data,
        withCredentials: true
    }
    );
    return response.data;
}
function* getTechnologyWithCompanies(id, filterString, orderBy, orderDirection) {
    let direction = orderBy === "match" ? orderDirection === "desc" ? "asc" : "desc" : orderDirection;
    const filterNameValueEnd = filterString ? filterString.slice(-1) : "";
    let url = filterString ? `${technologyCompanies(id)}?${filterString}${filterNameValueEnd === "&" ? "" : "&"}sort-direction=${direction}&sort-by=${orderBy}` : `${technologyCompanies(id)}?sort-direction=${direction}&sort-by=${orderBy}`
    const response = yield call(axios, {
        method: "GET",
        url: `${url}`,
        withCredentials: true
    }
    );
    return response.data;
}
function* updateCompanyKanban(companies) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${UPDATE_COMPANY_KANBAN_URL}`,
        withCredentials: true,
        data: { companies: companies }
    }
    );
    return response.data;
}
function* releaseCompanies(id) {
    const response = yield call(axios, {
        method: "GET",
        url: `${releaseCompaniesURL(id)}`,
        withCredentials: true
    }
    );
    return response.data;
}

export {
    getAllTechnologies,
    postCreateTechnology,
    postUpdateTechnology,
    publishTechnology,
    deleteTechnology,
    getTechnology,
    postUpdateTechnologyEditor,
    postGetClosest,
    postCompaniesData,
    getTechnologyWithCompanies,
    updateCompanyKanban,
    releaseCompanies
};
