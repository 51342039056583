import { spawn } from "redux-saga/effects";
import { saga as auth } from "./auth";
import { saga as sectors } from "./sectors";
import { saga as results } from "./results";
import { saga as filtering } from "./filtering";
import { saga as countries } from "./countries";
import { saga as industries } from "./industries";
import { saga as technologies } from "./technologies";
import { saga as adminusers } from "./adminusers";
import { saga as userprofiles } from "./userprofiles";
import { saga as subscriptions } from "./subscriptions";
import { saga as kanban } from "./kanban";

export default function* rootSaga() {
    yield spawn(auth);
    yield spawn(sectors);
    yield spawn(countries);
    yield spawn(filtering);
    yield spawn(results);
    yield spawn(industries);
    yield spawn(technologies);
    yield spawn(adminusers);
    yield spawn(userprofiles);
    yield spawn(subscriptions);
    yield spawn(kanban);
}
