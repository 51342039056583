import React, { useEffect, useState, createRef } from "react";
import classnames from "classnames";
// Components
import Typography from "../../../../components/Typography/Typography";
import { default as UICard } from "../../../../components/Card/Card";
import Button from "../../../../components/Button/Button";
import Sectors from "../../../../components/Sectors/Sectors";
import Tooltip from '@material-ui/core/Tooltip';
import ScoreBreakDownModal from "../../../IndustriesBoard/components/modals/ScoreBreakDownModal/ScoreBreakDownModal";
import ScoreChart from "../../../IndustriesBoard/components/modals/ScoreChart/ScoreChart";
// Assets
import NoImagePlaceholder from '../../../../assets/images/no-image-placeholder.png';
import InfoIcon from '../../../../assets/icons/InfoIcon.svg';
import CheckEnrichedCompany from '../../../../assets/icons/CheckEnrichedCompany.svg';
import { ReactComponent as InfoIconGrey } from "../../../../assets/icons/InfoIconNew.svg";
//Hooks
import { useAuthHook } from "../../../../common/useAuthHook";
import { useStyles } from "./style";
import { useRouterHook } from "../../../../common/useRouterHook";
import { useTranslation } from 'react-i18next';

const Card = ({ result, className }) => {
    const {
        name,
        deck,
        sectors,
        description,
        founded_date,
        hq_country,
        investment_stage,
        number_of_employees,
        total_funding_amount_dollars,
        slug,
        ready_for_release,
        priority,
        enrichment_request,
        valuer_score,
        id,
        degree_of_fit
    } = result;
    const { user } = useAuthHook();
    const cardRef = createRef(null);
    const sectorsTagsWrapperRef = createRef(null);
    const classes = useStyles();
    const { t } = useTranslation();
    const [imageSrc, setImageSrc] = useState(NoImagePlaceholder);
    const [imageIsValid, setImageIsValid] = useState(false);
    const { openPage } = useRouterHook();

    useEffect(() => {
        if (deck && deck.icon) {
            setImageSrc(deck.icon);
            setImageIsValid(true);
        } else {
            setImageSrc(NoImagePlaceholder);
            setImageIsValid(false);
        }
    }, [deck]);

    const onImgError = () => {
        setImageSrc(NoImagePlaceholder);
        setImageIsValid(false);
    };
    const [breakDownModal, setShowBreakdownModal] = useState(false);
    const openCompany = () => {
        openPage(`/company/${slug}/preview?cid=${result.id}`);
    };

    return (
        <UICard
            testId={`${id}-company-card`}
            className={classnames([
                classes.root,
                { [classes.totalMatch]: (degree_of_fit * 100).toFixed() === '100' },
                className
            ])}
            ref={cardRef}
            onClick={() => openCompany()}>
            <div className={classes.headerWrapper}>
                <div className={classes.scoreMatchWrap}>
                    <Typography
                        variant="bodyXs"
                        color={(degree_of_fit * 100).toFixed() === '100' ? 'primaryDark' : 'black'}>
                        {t('results.match', { number: (degree_of_fit * 100).toFixed() })}
                    </Typography>
                    <Tooltip
                        title={
                            <Typography color="white" variant="body2">
                                {t('companyProfile.matchScoreText')}
                            </Typography>
                        }
                        arrow>
                        <InfoIconGrey className={classes.infoIconNew} />
                    </Tooltip>
                </div>
                <div className={classes.header}>
                    <div className={classes.headerLeft}>
                        <Tooltip
                            disableHoverListener={imageIsValid}
                            title={
                                <Typography component="div" color="white" variant="body2">
                                    {t('results.logoDescription')}
                                </Typography>
                            }
                            arrow>
                            <img
                                width="48"
                                height="48"
                                src={imageSrc}
                                alt={name}
                                className={classes.avatar}
                                onError={onImgError}
                            />
                        </Tooltip>
                        <Typography variant="subtitle1">
                            <strong>{name}</strong>
                        </Typography>
                    </div>
                    <div className={classes.valuerScoreBlock}>
                        <Tooltip
                            title={
                                <Typography component="div" color="white" variant="body2">
                                    {t('results.infoDescription')}
                                </Typography>
                            }
                            arrow>
                            <img
                                src={InfoIcon}
                                alt={t('results.valuerScore')}
                                className={classes.infoIcon}
                            />
                        </Tooltip>
                        <div className={classes.valuerScore}>
                            <ScoreChart value={valuer_score && Math.round(valuer_score * 100)} />
                            <div
                                onMouseEnter={() => setShowBreakdownModal(true)}
                                onMouseLeave={() => setShowBreakdownModal(false)}>
                                <Typography
                                    className={classes.scoreBreakdown}
                                    color="secondarydark"
                                    variant="bodyXs">
                                    {t('results.scoreBreakdown')}
                                </Typography>
                                {breakDownModal && (
                                    <ScoreBreakDownModal
                                        company={result}
                                        dialogOpened={breakDownModal}
                                        onChange={() =>
                                            setShowBreakdownModal(false)
                                        }></ScoreBreakDownModal>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.sectorTagsWrapper} ref={sectorsTagsWrapperRef}>
                    <Sectors sectors={sectors} cardRef={sectorsTagsWrapperRef} />
                </div>
                {enrichment_request !== 0 && deck && deck.sdgs && deck.sdgs.length > 0 && (
                    <div className={classes.info}>
                        <div>
                            {deck.sdgs.map((sdg, i) => (
                                <Tooltip
                                    key={`sdg-${i}`}
                                    arrow
                                    classes={{
                                        arrow: classes.tooltipArrow,
                                        tooltip: classes.tooltipContent
                                    }}
                                    placement="top"
                                    title={
                                        <Typography
                                            variant="bodySmall"
                                            color="white"
                                            component="div"
                                            className={classes.sdgTooltip}>
                                            <div className={classes.sdgTooltipTitle}>
                                                {t('companyProfile.thisCompanyWorksTowards')}
                                            </div>
                                            {t('companyProfile.sustainableDevelopmentGoals')}{' '}
                                            {sdg.number}: {sdg.name}
                                        </Typography>
                                    }>
                                    <img
                                        className={classes.sdgTag}
                                        src={
                                            require(`../../../../assets/icons/sdgs/${sdg.icon_name}.png`)
                                                .default
                                        }
                                        alt={sdg.name}></img>
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div
                className={classnames([
                    deck && deck.sdgs && deck.sdgs.length > 0 ? classes.mt0 : classes.mt24,
                    classes.infoItems
                ])}>
                <Typography variant="body" color="textSecondary" className={classes.description}>
                    {description && description}
                </Typography>
                <div className={classes.info}>
                    <Typography variant="bodyButton" component="div" className={classes.infoTitle}>
                        {t('results.inceptionYear')}
                    </Typography>
                    <Typography variant="bodySmall" color="textlighter">
                        {founded_date}
                    </Typography>
                </div>
                <div className={classes.info}>
                    <Typography variant="bodyButton" component="div" className={classes.infoTitle}>
                        {t('results.country')}
                    </Typography>
                    <Typography variant="bodySmall" color="textlighter">
                        {hq_country}
                    </Typography>
                </div>
                <div className={classes.info}>
                    <Typography variant="bodyButton" component="div" className={classes.infoTitle}>
                        {t('results.currentStage')}
                    </Typography>
                    <Typography variant="bodySmall" color="textlighter">
                        {investment_stage}
                    </Typography>
                </div>
                <div className={classes.info}>
                    <Typography variant="bodyButton" component="div" className={classes.infoTitle}>
                        {t('results.fundings')}
                    </Typography>
                    <Typography variant="bodySmall" color="textlighter" className={classes.match}>
                        {total_funding_amount_dollars &&
                            parseInt(total_funding_amount_dollars)
                                .toFixed()
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                        USD
                    </Typography>
                </div>
                <div className={classes.info}>
                    <Typography variant="bodyButton" component="div" className={classes.infoTitle}>
                        {t('results.team')}
                    </Typography>
                    <Typography
                        variant="bodySmall"
                        color="textlighter"
                        component="div"
                        className={classes.numberOfEmployees}>
                        {number_of_employees}
                    </Typography>
                </div>
                {ready_for_release && (
                    <div className={classnames([classes.info, classes.enrichedCompanyInfo])}>
                        <div className={classes.enrichedCompany}>
                            <img
                                src={CheckEnrichedCompany}
                                alt={t('results.enrichedProfile')}
                                className={classes.checkEnrichedCompanyImg}
                            />
                            <Typography
                                variant="bodyButton"
                                component="div"
                                className={classnames([
                                    classes.infoTitle,
                                    classes.enrichedCompanyInfoTitle
                                ])}>
                                {t('results.enrichedProfile')}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.actionItems}>
                <Button
                    variant="tertiary"
                    iconMargin
                    className={classnames(
                        classes.rssButton,
                        user &&
                            user.subscription &&
                            user.subscription.name === 'free' &&
                            classes.rssButtonFreeUser
                    )}>
                    {t('results.companyDetails')}
                </Button>
            </div>
        </UICard>
    );
};

export default Card;
