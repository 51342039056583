import React, {useEffect, useState} from "react";

// UI components
import Typography from "../../../../components/Typography/Typography";
import IconButton from '@material-ui/core/IconButton';
import Button from "../../../../components/Button/Button";
import TextField from '../../../../components/TextField/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grow from '@material-ui/core/Grow';
// Hooks
import {useTranslation} from 'react-i18next';
import {useStyles} from "./style";
import {useDispatch, useSelector} from 'react-redux';
//UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {WithContext as ReactTags} from 'react-tag-input';
//Assets
import CloseIcon from '../../../../assets/icons/CloseIcon.svg';
//Redux
import {
    createTechnologyStateResponseSelector,
    createTechnologyStatusSelector,
    updateTechnologyStatusSelector
} from "../../../../store/technologies/selectors";
import {createTechnologyRequest, updateTechnologyRequest} from "../../../../store/technologies/requests";
import {REQUEST_SUCCESS} from "../../../../constants/statuses";

const RemoveComponent = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { className, onRemove } = props;
    return (
        <button onClick={onRemove} className={className}>
            <img src={CloseIcon} alt={t("industry.remove")} className={classes.removeComponent} />
        </button>
    )
}

const AddNewTechnologyModal = ({ dialogOpened, onChange, data, onDataCreated, onDataEdited, onSaveML, dataChanged }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState(data);
    const [technologyTitle, setTechnologyTitle] = useState(data ? data.name : '');
    const [technologyDescription, setTechnologyDescription] = useState(data ? data.ml_description : '');
    const [technologyKeywords, setTechnologyKeywords] = useState(data && data.keywords != null ? data.keywords : []);
    const delimiters = [10, 13, 188];
    const createTechnologyStatus = useSelector(createTechnologyStatusSelector);
    const updateTechnologyStatus = useSelector(updateTechnologyStatusSelector);
    const createTechnologyResponse = useSelector(createTechnologyStateResponseSelector);
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isMLSave, setIsMLSave] = useState(false);
    useEffect(() => {
        setModalData(data);
    }, [data])
    useEffect(() => {
        setTechnologyTitle(modalData ? modalData.name : "");
        setTechnologyDescription(modalData ? modalData.ml_description : "");
        setTechnologyKeywords(modalData && modalData.keywords != null ? modalData.keywords : []);
    }, [modalData]);

    const handleTitle = (event) => {
        setTechnologyTitle(event.target.value);
        setIsDataChanged(true);
    };
    const handleDescription = (event) => {
        setTechnologyDescription(event.target.value);
        setIsDataChanged(true);
    };
    const deleteTag = i => {
        setTechnologyKeywords(technologyKeywords.filter((tag, index) => index !== i));
    };
    const addTag = tag => {
        setTechnologyKeywords([...technologyKeywords, tag.text]);
        setIsDataChanged(true);
    };
    const disableSaveButton = () => {
        return !technologyTitle || !technologyDescription;
    };
    const handleSaveMLRequest = () => {
        setIsMLSave(true);
        dispatch(createTechnologyRequest({
            title: technologyTitle,
            description: technologyDescription,
            keywords: technologyKeywords.map(item => typeof item === 'object' ? item.text : item)
        }))
    };
    const handleSaveChanges = e => {
        e.preventDefault();
        e.stopPropagation();
        if (!modalData) {
            dispatch(createTechnologyRequest({
                title: technologyTitle,
                description: technologyDescription,
                keywords: technologyKeywords.map(item => typeof item === 'object' ? item.text : item)
            }))
        } else {
            dispatch(updateTechnologyRequest({
                id: modalData.id,
                name: technologyTitle,
                ml_description: technologyDescription,
                keywords: technologyKeywords.map(item => typeof item === 'object' ? item.text : item),
            }));
        }
    };
    useEffect(() => {
        createTechnologyStatus === REQUEST_SUCCESS && onDataCreated(isMLSave, createTechnologyResponse);
        updateTechnologyStatus === REQUEST_SUCCESS && onDataEdited();
    }, [createTechnologyStatus, updateTechnologyStatus]);
    const cancelModal = () => {
        if (isDataChanged) {
            dataChanged({
                id: modalData && modalData.id,
                title: technologyTitle,
                description: technologyDescription,
                keywords: technologyKeywords
            });
        } else {
            onChange();
        }
    }

    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialog, scrollPaper: classes.dialogScrollPaper }}
            onClose={cancelModal}
            open={dialogOpened}
        >
            {dialogOpened ? (
                <IconButton className={classes.closeButton} onClick={cancelModal}>
                    <img src={CloseIcon} alt={t('addTechnologyModal.closeIcon')} className={classes.stepIcon} />
                </IconButton>
            ) : null}
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                {modalData ? <Typography variant="subtitle1" className={classes.dialogTitle}>{t('addTechnologyModal.editTechnology')}</Typography> : <Typography variant="subtitle1" className={classes.dialogTitle}>{t('addTechnologyModal.createNewTechnology')}</Typography>}
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.flexColumn}>
                    <Typography variant="bodySmall" className={classes.technologyTitle}>{t('addTechnologyModal.technologyTitle')}*</Typography>
                    <TextField inputClass={classes.inputClass} className={classes.radarTitleInput} onChange={handleTitle} value={technologyTitle} placeholder={t('addTechnologyModal.technologyTitlePlaceholder')}></TextField>

                </div>
                <div className={classes.flexColumn}>
                    <Typography variant="bodySmall" className={classes.technologyDescription}>{t('addTechnologyModal.description')}*</Typography>
                    <TextareaAutosize
                        className={classes.textarea}
                        minRows={6}
                        placeholder={t('addTechnologyModal.technologyDescriptionPlaceholder')}
                        value={technologyDescription}
                        onChange={handleDescription}
                    />
                    {/* <div className={classes.maximumCharactersWrap}>
                        {technologyDescription && technologyDescription.length > 150 && <Typography className={classes.maximumCharactersWarning} variant="caption">{t("addTechnologyModal.maximumNumCharacters")}</Typography>}
                    </div> */}
                </div>
                <div className={classes.flexColumn}>
                    <Typography variant="bodySmall" className={classes.technologyDescription}>{t('addTechnologyModal.keywords')}*</Typography>
                    <Typography variant="bodySmall" color="textSecondary">{t('addTechnologyModal.keywordsLabel')}*</Typography>
                    <div className={classes.tagsWrap}>
                        <ReactTags
                            classNames={{ tag: classes.tagsClass, tagInputField: classes.tagInputFieldClass, remove: classes.removeClass }}
                            tags={technologyKeywords.map(item => {return {id: item ,text: item}})}
                            handleDelete={deleteTag}
                            handleAddition={addTag}
                            delimiters={delimiters}
                            allowAdditionFromPaste={true}
                            removeComponent={RemoveComponent}
                            autofocus={false} />
                    </div>
                </div>
                <div className={classes.warningWrap} />
                <div className={classes.cancelAndCreate}>
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={() => cancelModal()}
                    >
                        {t("addTechnologyModal.cancel")}
                    </Button>
                    <Button
                        className={classes.saveButton}
                        variant="secondary"
                        disabled={disableSaveButton()}
                        onClick={(e) => handleSaveChanges(e)}
                    >
                        {t("addTechnologyModal.saveChanges")}
                    </Button>
                    {!modalData && <Button
                        variant="primary"
                        disabled={disableSaveButton}
                        onClick={handleSaveMLRequest}
                    >
                        {t("addTechnologyModal.saveMLRequest")}
                    </Button>}
                </div>
            </MuiDialogContent>
        </Dialog >
    )
}

export default AddNewTechnologyModal;
