import { combineReducers } from "@reduxjs/toolkit";
import { reducer as auth } from "./auth";
import { reducer as sectors } from "./sectors";
import { reducer as results } from "./results";
import { reducer as filtering } from "./filtering";
import { reducer as countries } from "./countries";
import { reducer as industries } from "./industries";
import { reducer as technologies } from "./technologies";
import { reducer as adminusers } from "./adminusers";
import { reducer as userprofiles } from "./userprofiles";
import { reducer as subscriptions } from "./subscriptions";
import { reducer as kanban } from "./kanban";

export default combineReducers({
    auth,
    sectors,
    results,
    filtering,
    countries,
    industries,
    technologies,
    adminusers,
    userprofiles,
    subscriptions,
    kanban,
});
