// Bases
const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const ADMIN_API_URL = `${API_URL}/admin`;
export const AI_URL = "https://ai.valuer.ai";
// Auth
export const GET_COOKIES = `${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`;
export const POST_LOGIN = `${ADMIN_API_URL}/login`;
export const POST_LOGOUT = `${ADMIN_API_URL}/logout`;
export const CHECK_RESET_LINK = `${API_URL}/password/check-reset-link`;
export const SEND_RESET_LINK = `${API_URL}/password/send-reset-link`;
export const RESET_PASSWORD = `${API_URL}/password/reset`;
export const GET_ME = `${ADMIN_API_URL}/me`;
//Sectors - subsectors - countries
export const SECTORS_URL = `${API_URL}/sectors`;
export const SUBSECTORS_URL = `${API_URL}/subsectors`;
export const GET_COUNTRIES_URL = `${API_URL}/countries`
//Filtering
export const FILTERING_API = `${API_URL}/search/results/filtered?`;
//Results
export const RESULTS_URL = `${API_URL}/results`;
// Industries
export const INDUSTRIES_URL = `${ADMIN_API_URL}/industries`;
export const CREATE_INDUSTRY_URL = `${INDUSTRIES_URL}/create`;
export const CREATE_SUBINDUSTRY_URL = `${ADMIN_API_URL}/subindustries/add`;
export const getIndustryUrl = id => `${ADMIN_API_URL}/industries/${id}`;
export const getSubindustryUrl = id => `${ADMIN_API_URL}/subindustries/${id}`;
export const putSubindustryUrl = (id, name, desc) => `${ADMIN_API_URL}/subindustries/${id}?name=${name}&description=${desc}`;
//Technologies
export const GET_ALL_TECHNOLOGIES = `${API_URL}/admin/technologies`;
export const CREATE_TECHNOLOGY = `${API_URL}/admin/technologies`;
export const UPDATE_TECHNOLOGY = `${API_URL}/admin/technologies`;
export const DELETE_TECHNOLOGY = `${API_URL}/admin/technologies`;
export const GET_TECHNOLOGY = `${API_URL}/admin/technologies`;
export const postCompaniesTechnology = id => `${API_URL}/admin/technologies/${id}/kanban/store`;
export const technologyCompanies = id => `${API_URL}/admin/technologies/${id}/kanban/`
export const UPDATE_COMPANY_KANBAN_URL = `${API_URL}/admin/technologies/companies/`
export const releaseCompaniesURL = id => `${API_URL}/admin/technologies/${id}/kanban/release`
// Kanban
export const GET_KANBAN_ITEMS = `${ADMIN_API_URL}/kanban`;
export const updateKanbanItemUrl = id => `${ADMIN_API_URL}/kanban/update/${id}`;
export const deleteKanbanItemUrl = id => `${ADMIN_API_URL}/kanban/delete/${id}`;
//Industries kanban
export const postCompaniesSubIndustry = id => `${API_URL}/admin/subindustries/${id}/kanban/store`;
export const subIndustryCompanies = id => `${API_URL}/admin/subindustries/${id}/kanban/get`;
export const updateMLCompanyIndustry = `${API_URL}/admin/subindustries/companies/`;
export const releaseCompaniesIndustryURL = id => `${API_URL}/admin/subindustries/${id}/kanban/release`;
//Companies data
export const GET_CLOSEST_URL = `${AI_URL}/get_closest`;
export const GET_CLUSTERS = `${AI_URL}/cluster/get_clusters`;
//Admin Users
export const ADMINUSERS_URL = `${API_URL}/admin/adminusers/`;
export const USERS_URL = `${API_URL}/admin/users/`;
export const SUBSCRIPTIONS_URL = `${API_URL}/admin/subscriptions/`;
export const getAdminUsersUrl = data => {
    return data ? `${ADMINUSERS_URL}?search=${data.get("search")}&sort-by=${data.get("sort-by")}&sort-direction=${data.get("sort-direction")}&per-page=${data.get("per-page")}&page=${data.get("page")}` : ADMINUSERS_URL;
};
export const getUsersUrl = data => {
    return data ? `${USERS_URL}?search=${data.get("search")}&sort-by=${data.get("sort-by")}&sort-direction=${data.get("sort-direction")}&per-page=${data.get("per-page")}` : USERS_URL;
};
export const getSubscriptionsUrl = (search, page, userId) => {
    return (userId && search) ? `${SUBSCRIPTIONS_URL}?user_id=${userId}&search=${search}&page=${page || 1}` : userId ? `${SUBSCRIPTIONS_URL}?user_id=${userId}&page=${page || 1}` : `${SUBSCRIPTIONS_URL}?search=${search}&page=${page || 1}`;
};
export const createSubscriptionsUrl = data => {
    return `${SUBSCRIPTIONS_URL}?user_id=${data.userId}&max_active_radars=${data.maxRadars}&max_credits=${data.maxCredits}&max_users=${data.maxUsers}&start_date=${data.startDate}&expire_date=${data.expirationDate}&customer_name=${data.customerName}&company_name=${data.companyName}&password=${data.password}&password_confirmation=${data.passwordConfirmation}&email=${data.email}`;
};
export const createUsersUrl = data => {
    return `${USERS_URL}?user_id=${data.userId}&max_active_radars=${data.maxRadars}&max_credits=${data.maxCredits}&max_users=${data.maxUsers}&start_date=${data.startDate}&expire_date=${data.expirationDate}&name=${data.customerName}&company_name=${data.companyName}&email=${data.email}`;
};
export const TEAMS_URL = `${ADMIN_API_URL}/teams`;
export const getResendInvitationUrl = (teamId, userEmail) => {
    return `${TEAMS_URL}/${teamId}/resend-invitation?user_email=${userEmail}`
}
export const getResendEmailVerificationUrl = (teamId, userEmail) => {
    return `${TEAMS_URL}/${teamId}/resend-email-verification?user_email=${userEmail}`
}
export const postTeamUserUrl = teamId => {
    return `${TEAMS_URL}/${teamId}/users`
}
