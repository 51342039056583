import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { getFilterResults } from "../saga/helpers";
// import { actions as resultsActions } from "../../results/slice";

function* filterResults(data) {
    const filterNameValue = data && data.filterNameValue;
    const orderBy = data && data.orderBy;
    const orderDirection = data && data.orderDirection;
    const page = data && data.page;
    const sid = data && data.sid;
    try {
        // if (page < 2) {
        //     yield put(resultsActions.clearResults());
        // }
        yield put(actions.getFilteringPending());
        const filteredResults = yield call(getFilterResults, filterNameValue, orderBy, orderDirection, page, sid);
        const sliceData = { data: filteredResults.data, page: page }
        yield put(actions.setFilteredResults(sliceData))
        yield put(actions.getFilteringSuccess());
    } catch (err) {
        yield put(actions.getFilteringFailure());
    }
}

function* getFilterResultsCount(data) {
    const filterNameValue = data && data.filterNameValue;
    const orderBy = data && data.orderBy;
    const page = data && data.page;
    const orderDirection = data && data.orderDirection;
    const sid = data && data.sid;
    try {
        yield put(actions.getFilterResultsCountPending());
        const filteredResults = yield call(getFilterResults, filterNameValue, orderBy, orderDirection, page, sid);
        const sliceData = { data: filteredResults.data, page: data && data.page }
        yield put(actions.setFilterResultsCount(sliceData))
        yield put(actions.getFilterResultsCountSuccess());
    } catch (err) {
        yield put(actions.getFilterResultsCountFailure());
    }
}

export {
    filterResults,
    getFilterResultsCount
}