import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, typography }) => createStyles({
	
	modalWrap: {
		maxWidth: "1000px",
		maxHeight: "1000px",
		padding: spacing(2),
		// position: "absolute",
		left: 0,
		right: 0,
		marginLeft: "auto",
		marginRight: "auto",
		background: palette.primary.backgroundLight,
		// zIndex: 1,
		border: `1px solid ${palette.secondary.backgroundLight}`,
		borderRadius: spacing(3),
		display: "flex",
		flexFlow: "row wrap"
	},
	wrapper: {
		minWidth: "1000px",
		background: palette.primary.backgroundLight,
	},
	modalHeader: {
		height: "50px",
		width: "100%"
	},
	modalBody: {
		width: "100%",
		display: "flex",
		justifyContent: "start",
		paddingLeft: "100px",
		paddingRight: "100px",
		flexDirection: "row",
		flexWrap: "wrap",
		minHeight: "300px"
	},
	content: {
		margin: "20px 0 40px"
	},
	title: {
		fontSize: spacing(3),
		paddingTop: "20px",
		color: palette.grey.dark,
	},
	inputLabel: {
		marginBottom: "5px"
	},
	inputField: {
		minWidth: "350px"
	},
	submitBtn: {
		marginTop: "10px",
		color: "white",
		minHeight: "45px"
	},
	breakItem: {
		flexBasis: "100%",
		height: 0
	},
	divider: {
		width: "100%",
		margin: "20px 0",
	},
	resultsBox: {
		width: "100%",
		marginBottom: spacing(3)
	},
	resultsList: {
		width: "100%",
		backgroundColor: palette.primary.backgroundMain
	},
	actionBtn: {
		color: "white",
	},
	closeBtn: {
		position: "absolute",
		right: spacing(1),
		top: spacing(1),
		color: palette.grey[500],
	},
	
}));