import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import moment from 'moment';
// Hooks
import useStyles from "./style";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../../common/useRouterHook";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from "react-router";
// Components
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import TextField from "../../../components/TextField/TextField";
import Menu from "../../../components/Menu/Menu";
import Tooltip from '@material-ui/core/Tooltip';
import WarningModal from "./modals/WarningModal/WarningModal";
import SuccessModal from "./modals/SuccessModal/SuccessModal";
// Assets
import DefaultIndustryAvatar from '../../../assets/icons/DefaultIndustryAvatar.svg';
import { ReactComponent as MoreIcon } from "../../../assets/icons/icon-more.svg";
import { ReactComponent as InProgressIcon } from "../../../assets/icons/InProgressIcon.svg";
import { ReactComponent as Camera } from "../../../assets/icons/Camera.svg";
import { ReactComponent as Ready } from "../../../assets/icons/Ready.svg";
// Redux
import {
    createIndustryRequest,
    deleteIndustryRequest,
    updateIndustryRequest,
    addSubindustryRequest,
    deleteSubindustryRequest,
    updateSubindustryRequest
} from "../../../store/industries/requests";
import {
    createIndustryStatusSelector,
    updateIndustryStatusSelector,
    getIndustrySelector,
    deleteIndustryStatusSelector
} from "../../../store/industries/selectors";
import {
    REQUEST_SUCCESS
} from "../../../constants/statuses";

const IndustryCard = ({ data, className, profile, add, onSave, user, testId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const createIndustryStatus = useSelector(createIndustryStatusSelector);
    const updateIndustryStatus = useSelector(updateIndustryStatusSelector);
    const deleteIndustryStatus = useSelector(deleteIndustryStatusSelector);
    const industry = useSelector(getIndustrySelector);
    const [imageSrc, setImageSrc] = useState(DefaultIndustryAvatar);
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isEditMode, setIsEditMode] = useState(!data);
    const [isAddSubsectorMode, setIsAddSubsectorMode] = useState(false);
    const [titleValue, setTitleValue] = useState(data && data.name);
    const [selectedSubsector, setSelectedSubsector] = useState({});
    const [selectedSubsectorName, setSelectedSubsectorName] = useState("");
    const [selectedSubsectorDesc, setSelectedSubsectorDesc] = useState("");
    const [descriptionValue, setDescriptionValue] = useState(data && data.description);
    const [newSubsectorNameValue, setNewSubsectorNameValue] = useState("");
    const [newSubsectorDescValue, setNewSubsectorDescValue] = useState("");
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isIndustryDelete, setIsIndustryDelete] = useState(false);
    const [isIndustryChange, setIsIndustryChange] = useState(false);
    const [fileUploadError, setFileUploadError] = useState(false);
    const [avatarFile, setAvatarFile] = useState();
    let { pathname } = useLocation();
    const industryId = pathname.substring(12, pathname.length);
    const [kanbanButtonDisabled, setKanbanButtonDisabled] = useState(true);

    useEffect(() => {
        setIsEditMode(!data);
        if (data) {
            setTitleValue(data.name);
            setDescriptionValue(data.description);
        }
    }, [data])

    const handleDeleteSubindustry = () => {
        setShowWarningModal(!showWarningModal);
    };

    const handleDeleteIndustry = () => {
        setIsIndustryDelete(true);
        setShowWarningModal(!showWarningModal);
    };

    const handleSuccess = () => {
        // setShowSuccessModal(!showSuccessModal);
        dispatch(updateSubindustryRequest({ data: { id: selectedSubsector.id, name: selectedSubsectorName, description: selectedSubsectorDesc } }));
        setSelectedSubsector({});
    };

    useEffect(() => {
        setSelectedSubsectorName(selectedSubsector.name);
        setSelectedSubsectorDesc(selectedSubsector.description);
    }, [selectedSubsector])

    useEffect(() => {
        if (data && data.avatar) {
            setImageSrc(data.avatar);
        } else {
            setImageSrc(DefaultIndustryAvatar);
        }
    }, [data])

    const handleMenuClick = () => {
        setIsMenuOpened(prevIsMenuOpened => !prevIsMenuOpened)
    }

    const handleSubsectorClick = subsector => {
        selectedSubsector === subsector ? setSelectedSubsector({}) : setSelectedSubsector(subsector);
        setIsAddSubsectorMode(false);
    }

    const handleSelectedSubsectorNameChange = e => {
        setSelectedSubsectorName(e.target.value)
    }

    const handleSelectedSubsectorDescChange = e => {
        setSelectedSubsectorDesc(e.target.value)
    }

    const handleAddSubsectorClick = () => {
        setIsAddSubsectorMode(true);
        setSelectedSubsector({});
    }

    const handleGoToIndustry = id => {
        openPage(`/industries/${id}`)
    }

    const handleSaveChanges = () => {
        setIsIndustryChange(true);
        setShowWarningModal(!showWarningModal);
    }

    const handleAdd = () => {
        dispatch(createIndustryRequest({ data: { name: titleValue || "", description: descriptionValue || "" } }))
    }

    const handleWarningModalClose = () => {
        setIsIndustryDelete(false);
        setShowWarningModal(false);
        setIsIndustryChange(false);
    }

    useEffect(() => {
        if (updateIndustryStatus === REQUEST_SUCCESS) {
            onSave();
            setIsEditMode(false);
        }
        if (deleteIndustryStatus === REQUEST_SUCCESS) {
            openPage("/industries");
        }
        if (!!industry.id && createIndustryStatus === REQUEST_SUCCESS) {
            openPage(`/industries/${industry.id}`);
        }
    }, [createIndustryStatus, updateIndustryStatus, deleteIndustryStatus, setIsEditMode, industry])

    const handleYes = () => {
        if (isIndustryDelete) {
            dispatch(deleteIndustryRequest({ id: data && data.id }));
        } else if (isIndustryChange) {
            dispatch(updateIndustryRequest({ data: { id: industryId, name: titleValue, description: descriptionValue, "_method": "PUT" } }))
        } else {
            dispatch(deleteSubindustryRequest({ id: selectedSubsector && selectedSubsector.id }));
            setSelectedSubsector({});
        }
        handleWarningModalClose();
    }

    const handleAddSubindustry = () => {
        dispatch(addSubindustryRequest({ data: { "sector-id": data.id, name: newSubsectorNameValue, description: newSubsectorDescValue } }));
        setIsAddSubsectorMode(false);
        setNewSubsectorNameValue("");
        setNewSubsectorDescValue("");
    }

    const handleEditImage = () => {
        inputFile.current.click();
    };
    const onFileUploadChange = e => {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        let f = files[0]
        let size = 2097152;
        let file_size = files[0].size;
        if (file_size >= size) {
            setFileUploadError(true)
            return false;
        }
        let formData = new FormData();
        formData.append("logo", f);
        formData.append("id", industryId);
        formData.append("name", titleValue);
        formData.append("description", descriptionValue);
        formData.append("_method", "PUT");
        dispatch(updateIndustryRequest({ data: formData }))
    };
    const onImgError = () => {
        setImageSrc(DefaultIndustryAvatar)
    };

    const goToKanban = () => {
        openPage(`/industries-board/${selectedSubsector.id}`)
    };

    useEffect(() => {
        setKanbanButtonDisabled(!dataValidForKanban())
    }, [selectedSubsector, data]);
    const dataValidForKanban = () => {
        return Object.keys(selectedSubsector).length > 0 && selectedSubsector.name && selectedSubsector.description && data && data.name && data.description;
    };

    const handleCancelAddSubindustry = () => {
        setIsAddSubsectorMode(false);
        setNewSubsectorNameValue("");
        setNewSubsectorDescValue("");
    }

    const handleCancelAddEditIndustry = () => {
        setTitleValue(data ? data.name : "");
        setDescriptionValue(data ? data.description : "");
        !add ? setIsEditMode(false) : openPage('/industries');
    }

    return (
        <div className={classnames(classes.root, className)}>
            <div className={classnames([classes.info, { [classes.editInfo]: isEditMode }])}>
                {isEditMode && !add ? <div className={classes.editAvatar} onClick={handleEditImage}>
                    <img
                        width="72"
                        height="72"
                        src={imageSrc}
                        alt={data && data.name}
                        className={classes.avatar}
                        onError={onImgError}
                    />
                    <input type='file' id='file' ref={inputFile} style={{ display: 'none' }} onChange={onFileUploadChange} />
                    <Camera className={classes.cameraIcon} />
                </div> :
                    <div className={classes.avatar}>
                        <img
                            width="72"
                            height="72"
                            src={imageSrc}
                            alt={data && data.name}
                            className={classes.avatar}
                            onError={onImgError}
                        />
                    </div>}
                <div className={classnames([{ [classes.fullWidth]: isEditMode }])}>
                    {isEditMode ? <TextField labelProps={{ required: true }} className={classes.halfWidth} label={t("industryProfile.nameLabel")} value={titleValue} onChange={e => setTitleValue(e.target.value)}></TextField> :
                        <Typography variant="subtitle1" gutterBottom component="div">
                            {data && data.name}
                        </Typography>
                    }
                    {isEditMode ? <TextField labelProps={{ required: true }} multiline fullWidth minRows={4} label={t("industryProfile.descriptionLabel")} value={descriptionValue} onChange={e => setDescriptionValue(e.target.value)}></TextField> : <><Typography variant="bodyXs" gutterBottom component="div" className={classes.mono}>
                        {t("industries.industryDescription")}
                    </Typography>
                        <Typography variant="bodyButton" gutterBottom component="div">
                            {data && data.description}
                        </Typography></>}
                    {isEditMode && <div className={classes.addCtas}>
                        <Button variant="textLight" onClick={handleCancelAddEditIndustry} test-id={`cancel-${data && data.id}`}>
                            {t("industryProfile.cancel")}
                        </Button>
                        <Button variant="primary" test-id={`save-${data && data.id}`} className={classes.edit} onClick={add ? handleAdd : handleSaveChanges} disabled={!titleValue || !descriptionValue || (user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor")}>
                            {t("industryProfile.saveChanges")}
                        </Button>
                    </div>}
                </div>
                {profile && !add && (user && user.role !== "moderator") && <Menu
                    btnClassName={classes.moreButton}
                    onChange={handleMenuClick}
                    menuItems={[
                        {
                            label: t("industryProfile.editBio"),
                            onClick: () => setIsEditMode(true),
                            testId: `edit-bio-${data && data.id}`
                        },
                        {
                            label: t("industryProfile.deleteIndustry"),
                            onClick: handleDeleteIndustry,
                            warningItem: true,
                            testId: `delete-industry-${data && data.id}`
                        }
                    ]}
                    testId={`menu-${data && data.id}`}
                >
                    <MoreIcon className={classnames([classes.moreIcon, { [classes.moreIconOpened]: isMenuOpened }])} />
                </Menu>}
            </div>
            {!add && (
                <>
                    <Typography variant="subtitle2" gutterBottom component="div" className={classes.subindustriesTitle}>
                        {t("industries.subindustries")}
                    </Typography>
                    {data && data.subindustries && data.subindustries.map(subsector => (
                        <>
                            {subsector.inProgress ? <Tooltip classes={{ tooltip: classes.tooltip }} enterDelay={350} key={subsector.slug} title={<Typography variant="body2" color="white">{t(`industryProfile.subsectorInProgress`)}</Typography>} arrow>
                                <Typography
                                    className={classes.inProgressPill}
                                >
                                    {subsector.name}
                                    {subsector.inProgress && <InProgressIcon className={classes.inProgressIcon} />}
                                </Typography>
                            </Tooltip> :
                                <Tooltip classes={{ tooltip: classes.tooltip }} enterDelay={350} key={subsector.slug} title={<Typography variant="body2" color="white">{subsector.ready_for_release ? t(`industryProfile.lastUpdated`) : t(`industryProfile.notUpdated`)}</Typography>} arrow>
                                    <Button
                                        variant="pill"
                                        className={classnames([classes.sector, classes.selectedSubsector, { [classes.inProgressPill]: subsector.inProgress, [classes.activeSector]: selectedSubsector === subsector }])}
                                        {...!profile && { disabled: true }}
                                        onClick={() => handleSubsectorClick(subsector)}
                                        {...selectedSubsector === subsector && { active: true }}
                                        disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}
                                        test-id={`tag-${testId ? `${testId}-${subsector.id}` : subsector.id}`}
                                    >
                                        {subsector.name}
                                        {subsector.ready_for_release && <Ready className={classes.inProgressIcon} />}
                                    </Button>
                                </Tooltip>}
                        </>
                    ))}
                </>
            )}
            {!isAddSubsectorMode && !add && profile && <Button
                variant="pill"
                className={classnames([classes.sector, classes.selectedSubsector])}
                onClick={handleAddSubsectorClick}
                disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}
                test-id={`add-subindustry-${data && data.id}`}
            >
                {t("industryProfile.addSubindustry")}
            </Button>}
            {/* Add subsector section */}
            {isAddSubsectorMode && <div className={classes.addSubsector}>
                <TextField labelProps={{ required: true }}  value={newSubsectorNameValue} className={classes.addSubsectorName} label={t("industryProfile.subsectorNameLabel")} onChange={e => setNewSubsectorNameValue(e.target.value)}></TextField>
                <TextField labelProps={{ required: true }} fullWidth multiline minRows={4} value={newSubsectorDescValue} label={t("industryProfile.subsectorDescLabel")} onChange={e => setNewSubsectorDescValue(e.target.value)}></TextField>
                <div className={classes.addCtas}>
                    <Button variant="textLight" onClick={handleCancelAddSubindustry}>
                        {t("industryProfile.cancel")}
                    </Button>
                    <Button variant="primary" className={classes.edit} disabled={!newSubsectorDescValue || !newSubsectorNameValue || (user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor")} onClick={handleAddSubindustry}>
                        {t("industryProfile.add")}
                    </Button>
                </div>
            </div>}
            {/* Edit selected subsector section */}
            {Object.keys(selectedSubsector).length > 0 && <div className={classes.editSelectedSubsector}>
                <TextField labelProps={{ required: true }} multiline value={selectedSubsectorName} label={t("industryProfile.subsectorNameLabel")} onChange={e => handleSelectedSubsectorNameChange(e)}></TextField>
                {selectedSubsector.lastUpdated && <Typography variant="bodySmall" align="right" className={classes.mono} gutterBottom component="div">
                    {t("industryProfile.lastUpdate")}<Typography variant="bodySmall" align="right" className={classes.lastUpdatedValue} gutterBottom component="span">{moment(selectedSubsector.lastUpdated).fromNow()} - {selectedSubsector.userUpdated}</Typography>
                </Typography>}
                <TextField labelProps={{ required: true }} multiline fullWidth value={selectedSubsectorDesc} minRows={4} className={classnames([{ [classes.marginTop]: !selectedSubsector.lastUpdated }])} label={t("industryProfile.subsectorDescLabel")} onChange={e => handleSelectedSubsectorDescChange(e)}></TextField>
                <div className={classes.editPreviewCtas}>
                    <Button
                        test-id={`delete-subindustry-${data && data.id}`}
                        variant="textWarning"
                        onClick={handleDeleteSubindustry}
                        disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}
                    >
                        {t("industryProfile.deleteSubIndustry")}
                    </Button>
                    <div>
                        <Button variant="textLight" onClick={() => setSelectedSubsector({})} test-id={`cancel-subindustry-${data && data.id}`}>
                            {t("industryProfile.cancel")}
                        </Button>
                        <Button
                            variant="secondary"
                            className={classes.edit}
                            disabled={!selectedSubsectorName || !selectedSubsectorDesc || (user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor")}
                            onClick={handleSuccess}
                            test-id={`save-subindustry-${data && data.id}`}
                        >
                            {t("industryProfile.save")}
                        </Button>
                    </div>
                </div>
            </div>}
            {/* CTAs section */}
            {!add && (
                <>
                    {profile ? <div className={classes.mlRequestWrapper}>
                        <Button
                            onClick={() => goToKanban()}
                            variant="secondary"
                            className={classes.edit}
                            disabled={kanbanButtonDisabled || (user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor")}
                            test-id={`go-to-kanban-${data && data.id}`}
                        >
                            {t("industryProfile.mlRequest")}
                        </Button>
                    </div> :
                        <div className={classes.addCtas}>
                            <Button variant="tertiary" test-id={`preview-industry-${testId}`}>
                                {t("industries.preview")}
                            </Button>
                            <Button variant="secondary" test-id={`edit-industry-${testId}`} className={classes.edit} onClick={() => handleGoToIndustry(data.id)} disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"}>
                                {t("industries.edit")}
                            </Button>
                        </div>}
                </>
            )}
            <WarningModal
                dialogOpened={showWarningModal}
                warningTitle={isIndustryDelete ? t('industryProfile.deleteIndustryTitle', { name: data && data.name }) : isIndustryChange ? t('industryProfile.saveIndustryBioTitle', { name: data && data.name }) : t('industryProfile.deleteSubindustryTitle', { name: selectedSubsector.name })}
                onClose={handleWarningModalClose}
                onYes={handleYes}
                warningSubtitle={isIndustryDelete ? t('industryProfile.deleteIndustrySubtitle', { name: data && data.name }) : isIndustryChange ? t('industryProfile.saveIndustryBioSubtitle') : t('industryProfile.warningSubtitle')}
            />
            <SuccessModal dialogOpened={showSuccessModal} onClose={() => setShowSuccessModal(false)} />
        </div>
    )
};
export default IndustryCard;