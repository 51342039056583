import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    flexSpaceCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    flexRelative: {
        display: "flex",
        position: "relative"
    },
    flexCenter: {
        display: "flex",
        alignItems: "center"
    }
}));