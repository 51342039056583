import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape }) => createStyles({
    root: {
        marginTop: spacing(5),
    },
    block: {
        padding: spacing(4),
        background: palette.common.white,
        width: "calc(33% - 8px)",
        borderRadius: spacing(1),
        marginBottom: spacing(2),
        marginRight: spacing(2),
        "&:nth-child(3n+3)": {
            marginRight: 0
        },
    },
    blockTitle: {
        color: palette.secondary.dark,
        marginTop: spacing(2),
        marginBottom: spacing(6)
    },
    blockText: {
        color: palette.text.main,
    },
    imgBlock: {
        width: "100%",
        height: 160,
        // background: palette.primary.backgroundLight,
        borderRadius: spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    ml8: {
        marginLeft: spacing(1)
    },
    readMore: {
        marginTop: spacing(3),
        cursor: 'pointer',
        display: "flex",
        alignItems: "center"
    },
    shTitle: {
        marginTop: spacing(2),
        maxWidth: 727
    },
    shSubtitle: {
        marginTop: spacing(2)
    },
    shButton: {
        marginTop: spacing(3),
        background: palette.primary.dark
    },
    freeUserRoot: {
        background: palette.common.white,
        borderRadius: spacing(3),
        padding: "64px 0 78px 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap"
    }
}));

