import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing }) => ({
    // * {
    //     boxSizing: "border-box";
    // },

    board: {
        whiteSpace: "nowrap",
        display: "flex",
        marginTop: spacing(4),
        maxWidth: 1660,
        minHeight: '70vh',
        height: "calc(100vh - 245px)",
    },
    kanbanBoard: {
        overflowX: "auto",
        width: "calc(100vw - 300px)",
    },
    column: {
        display: "inline-block",
        verticalAlign: "top",
        width: 395,
        borderRadius: "8px 8px 0px 0px",
        marginRight: 20,
        background: palette.text.backgroundLight,
        marginTop: spacing(2),
        border: `1px solid ${palette.secondary.backgroundLight}`,
        height: "100%",
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    card: {
        padding: 15,
        color: palette.common.black,
        marginBottom: 10,
        cursor: "move"
    },
    noData: {
        height: "100%!important",
        background: "red!important"
    },
    cardSpacer: {
        opacity: 0,
        height: "100%"
    },
    cardDragging: {
        opacity: 0
    },
    columnTitle: {
        display: "flex",
        flexDirection: "column"
    },
    columnHeader: {
        background: palette.secondary.backgroundLight,
        width: 395,
        borderRadius: 12,
        height: 56,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        "& div": {

        },

        "& img": {
            position: "absolute",
            top: 16,
            right: 24,
            cursor: "pointer"
        }
    },
    columnHeaderColor: {
        width: "83%",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        borderRadius: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "& span": {
            color: palette.common.white,
        },
    },
    kanbanColumnHeaderColor: {
        height: "100%",
        borderRadius: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "& span": {
            color: palette.text.primary,
        },
    },
    moveAllMenu: {
        position: "absolute",
        right: 0,
        top: 60,
        background: palette.common.white,
        borderRadius: 4,
        padding: `${spacing(1)}px 0`,
        width: 225,
        display: "flex",
        flexDirection: "column",
        zIndex: 99,
        boxShadow: "0px 4px 10px rgb(0 0 0 / 10%)"
    },
    moveButton: {
        width: 100,
        marginLeft: spacing(3)
    },
    menuItemTitle: {
        padding: `${spacing(1)}px ${spacing(3)}px`
    },
    multiselectWrapper: {
        padding: `${spacing(1)}px ${spacing(3)}px`
    },
    arrowDown: {
        position: "absolute",
        right: spacing(2),
        cursor: 'pointer',
        zIndex: 1
    },
    multiselect: {
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: spacing(1)
    },
    labelClass: {
        color: palette.text.main,
        fontSize: 14,
        lineHeight: "18px"
    },
    width100: {
        width: '100%'
    }
    // indreaseHeight: {
    //     height: ({ heightAlter }) => heightAlter ? heightAlter + "px" : "auto"
    // }
}));
