import { call } from "redux-saga/effects";
import axios from "axios";
import {
    getSubscriptionsUrl,
    SUBSCRIPTIONS_URL,
    createSubscriptionsUrl,
    deleteSubscriptionsUrl,
    TEAMS_URL,
    postTeamUserUrl,
    getResendEmailVerificationUrl,
    getResendInvitationUrl
} from "../../../constants/endpoints";

function* fetchSubscriptions(search, page, userId) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getSubscriptionsUrl(search, page, userId)}`,
        withCredentials: true
    });
    return response.data;
}

function* postCreateSubscription(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${createSubscriptionsUrl(data)}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* putUpdateSubscription(id, type, data) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${SUBSCRIPTIONS_URL}${id}/${type}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* fetchSubscriptionUser(id, type) {
    const response = yield call(axios, {
        method: "GET",
        url: `${SUBSCRIPTIONS_URL}${id}/${type}`,
        withCredentials: true
    });
    return response.data;
}

function* postDeleteSubscription(planId, planType) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${SUBSCRIPTIONS_URL}${planId}/${planType}`,
        withCredentials: true
    });
    return response.data;
}

function* putChangeTeamOwner(teamId, ownerId, planId, planType) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${TEAMS_URL}/${teamId}`,
        withCredentials: true,
        data: {
            "owner_id": ownerId,
            "plan_id": planId,
            "plan_type": planType
        }
    });
    return response.data;
}

function* getResendUserInvitation(teamId, userEmail) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getResendInvitationUrl(teamId, userEmail)}`,
        withCredentials: true
    });
    return response.data;
}

function* getResendEmailVerification(teamId, userEmail) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getResendEmailVerificationUrl(teamId, userEmail)}`,
        withCredentials: true
    });
    return response.data;
}

function* postCreateTeamMember(teamId, data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${postTeamUserUrl(teamId)}`,
        withCredentials: true,
        data
    });
    return response.data;
}

function* putAddExistingUser(teamId, email) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${postTeamUserUrl(teamId)}`,
        withCredentials: true,
        data: {
            email
        }
    });
    return response.data;
}

function* deleteRemoveTeamMember(teamId, userEmail, planId, planType) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${TEAMS_URL}/${teamId}/${userEmail}`,
        withCredentials: true,
        data: {
            "plan_id": planId,
            "plan_type": planType
        }
    });
    return response.data;
}

function* fetchTeam(search, teamId) {
    const response = yield call(axios, {
        method: "GET",
        url: `${TEAMS_URL}/${teamId}?search=${search}`,
        withCredentials: true
    });
    return response.data;
}

export {
    fetchSubscriptions,
    postCreateSubscription,
    putUpdateSubscription,
    fetchSubscriptionUser,
    postDeleteSubscription,
    putChangeTeamOwner,
    getResendUserInvitation,
    deleteRemoveTeamMember,
    getResendEmailVerification,
    postCreateTeamMember,
    fetchTeam,
    putAddExistingUser
};