import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, shape, breakpoints }) => createStyles({
    root: {
        padding: `${spacing(5)}px ${spacing(4)}px ${spacing(8)}px ${spacing(4)}px`,
        [breakpoints.down("lg")]: {
            padding: `${spacing(5)}px ${spacing(3)}px!important`,
        },
        [breakpoints.down("sm")]: {
            padding: `${spacing(5)}px ${spacing(2)}px!important`,
        },
        margin: `0 auto`,
        maxWidth: "1136px"
    },
    links: {
        paddingBottom: spacing(1),
        display: "flex",
        [breakpoints.down("sm")]: {
            maxWidth: "100vw",
            overflow: "auto",
            "&::-webkit-scrollbar": {
                display: "none",
                "-ms-overflow-style": "none",
                scrollbarWidth: "none"
            },
        }
    },
    link: {
        "&:last-child": {
            marginRight: 0
        },
        cursor: "pointer",
        marginTop: spacing(4),
        color: palette.grey.main,
        // width: "calc(25% - 110px)",
        width: "calc(25% - 33px)",
        minWidth: 120,
        marginRight: spacing(2),
        padding: spacing(3),
        borderRadius: 24,
        background: palette.primary.backgroundMedium,
        [breakpoints.down("md")]: {
            minWidth: "108px!important",
            padding: `${spacing(1)}px ${spacing(3)}px!important`,
            width: "fit-content"
        },
        [breakpoints.down("lg")]: {
            minWidth: 168,
            padding: spacing(3)
        },
        [breakpoints.down("sm")]: {
            height: 58,
            minWidth: "125px!important",
            display: "flex",
            alignItems: "center"
        }
    },
    description: {
        [breakpoints.down("lg")]: {
            display: "none!important"
        }
    },
    active: {
        color: palette.secondary.light,
        position: "relative",
        background: palette.success.backgroundLight
    },
    imgWrapper: {
        minHeight: 112
    },
    subtitle: {
        marginBottom: spacing(1),
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    title: {
        marginBottom: spacing(2),
        [breakpoints.down("sm")]: {
            display: "none!important"
        }
    },
    borderActive: {
        position: 'absolute',
        content: "",
        height: 2,
        width: ({ tabView }) => tabView === 'profile' ? 54 : 121,
        background: palette.secondary.main,
        top: 25,
        left: -6,
        right: 0
    },
    profileWrap: {
        padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        display: "flex",
        [breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    companyWrap: {
        borderTop: "1px solid #F1F1F1",
        padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        display: "flex",
        flexDirection: "column"
    },
    editLogo: {
        width: 250,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [breakpoints.down("sm")]: {
            margin: "auto"
        }

    },
    hideImg: {
        [breakpoints.down("sm")]: {
            display: "none!important"
        }
    },
    avatarWrapper: {
        width: 142,
        height: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.success.backgroundLight,
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    avatar: {
        overflow: "hidden",
        height: 142,
        width: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    avatarImg: {
        width: 150,
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    companyIllustration: {
        width: 142,
        height: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.primary.backgroundMedium
    },
    edit: {
    },
    maximumSize: {
        color: palette.grey.main,
    },
    uploadBtn: {
        margin: `${spacing(4)}px 0 ${spacing(2)}px 0`
    },
    profileTitle: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    profileValue: {
        marginBottom: spacing(1)
    },
    changeLink: {
        fontFamily: "GT Flexa Mono, sans-serif",
        marginTop: spacing(1),
        cursor: "pointer"
    },
    infoRow: {
        "&:nth-child(n+2)": {
            marginTop: spacing(6)
        }
    },
    buttons: {
        marginTop: spacing(1),
        display: "flex",
        justifyContent: "flex-end"
    },
    textInput: {
        "& input": {
            width: 420
        }
    }, eye: {
        position: "absolute",
        right: spacing(1),
        cursor: "pointer"
    },
    noData: {
        marginTop: spacing(6),
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    noDataLabel: {
        marginTop: spacing(6)
    },
    billingHeaderBoxes: {
        display: "flex",
        flexDirection: "row",
        marginTop: spacing(2),
        [breakpoints.down("sm")]: {
            flexDirection: "column",
            marginTop: 0
        }
    },
    billingHeaderBox: {
        width: "calc(33% - 12px)",
        marginRight: spacing(3),
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: spacing(3),
        padding: spacing(4),
        "&:last-child": {
            marginRight: 0
        },
        [breakpoints.down("sm")]: {
            width: "100%",
            marginTop: spacing(3)

        }
    },
    hideOnMobile: {
        [breakpoints.down("sm")]: {
            display: "none!important"
        }
    },
    cellAvatarWrap: {
        [breakpoints.down("sm")]: {
            width: "50px!important",
            paddingLeft: "0px!important",
            paddingRight: "0px!important",
        }

    },
    cellMoreWrap: {
        [breakpoints.down("sm")]: {
            width: "60px!important"
        }

    },
    hideOnSmallScreens: {
        [breakpoints.down("xl")]: {
            display: "none!important"
        }
    },
    invoicesTitle: {
        [breakpoints.up("xl")]: {
            display: "none!important"
        },
        [breakpoints.down("lg")]: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            paddingLeft: spacing(2),
            paddingBottom: spacing(2),
            marginTop: spacing(4)
        }
    },
    upgradePlan: {
        fontFamily: "GT Flexa Mono, sans-serif",
        cursor: "pointer"
    },
    seeCost: {
        fontFamily: "GT Flexa Mono, sans-serif",
        cursor: "pointer"
    },
    nextIssueDate: {
        margin: `${spacing(1)}px 0`
    },
    tableRow: {
        height: 74,
        color: palette.primary.dark,
        "&:nth-child(2)": {
            "& $teamUserAvatar": {
                background: palette.primary.backgroundLight
            }
        }
    },
    regularRow: {
        [breakpoints.down("xl")]: {
            display: "none!important"
        }
    },
    mobileRow: {
        [breakpoints.up("xl")]: {
            display: "none!important",
        },
        [breakpoints.down("xl")]: {
            display: "flex!important",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(224, 224, 224, 1)!important"
        }

    },
    removeBorder: {
        border: "none!important"
    },
    columnTitles: {
        display: "flex",
        flexDirection: "column"
    },
    teamTable: {
        marginTop: spacing(5),
        paddingBottom: spacing(8)
    },
    statusWrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    warningIcon: {
        position: "absolute",
        left: -30
    },
    teamUserAvatar: {
        width: 48,
        height: 48,
        borderRadius: "50%",
        background: palette.success.backgroundLight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: 31
        }
    },
    email: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    moreIcon: {
        cursor: "pointer"
    },
    moreIconWrapper: {
        position: "relative",
        width: 24
    },
    moreIconMenu: {
        position: "absolute",
        top: 20,
        right: 0,
        background: palette.common.white,
        border: `1px solid ${palette.text.backgroundDark}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: spacing(1),
        zIndex: 2,
        minWidth: 135,
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    moreIconMenuItem: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "&:hover": {
            cursor: "pointer",
            color: palette.error.main
        }
    },
    dialogScrollPaper: {
        padding: "80px 64px",
        maxWidth: 640,
        borderRadius: 16,
        background: palette.primary.backgroundLight,
        [breakpoints.down("sm")]: {
            padding: "80px 24px",
            width: 500,
            textAlign: "center"
        }

    },
    closeButton: {
        position: 'absolute !important',
        right: spacing(4),
        top: spacing(3),
        color: palette.grey[500],
        [breakpoints.down("sm")]: {
            top: 11,
            right: 15
        }
    },
    dialodCtas: {
        marginTop: spacing(4),
        [breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    deleteButton: {
        [breakpoints.down("sm")]: {
            marginTop: spacing(3)
        }
    },
    credits: {
        border: `1px solid ${palette.text.backgroundDark}`,
        borderRadius: spacing(3),
        padding: spacing(4),
        width: 330,
        marginTop: spacing(4),
        [breakpoints.down("md")]: {
            width: "100%!important"
        }
    },
    creditsHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginBottom: spacing(4)
    },
    availableCredits: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
    },
    seeCreditSummary: {
        color: palette.primary.dark,
        cursor: "pointer",
        textDecoration: "underline",
        fontFamily: "GT FLexa Mono, sans-serif",
        marginTop: spacing(2)
    },
    profile: {
        display: "flex",
        justifyContent: "space-between",
    },
    "@media screen and (max-width: 1200px)": {
        profile: {
            flexDirection: "column!important"
        }
    },
    creditsInfoWrap: {
        [breakpoints.down("xl")]: {
            margin: "auto"
        },
        [breakpoints.down("md")]: {
            width: "100%!important"
        }
    },
    tableWrap: {
        borderTop: `1px solid ${palette.text.backgroundLight}`,
        borderBottom: `1px solid ${palette.text.backgroundLight}`,
        marginBottom: spacing(3),
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    costInfoRow: {
        padding: `${spacing(2)}px 0`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 420,
        [breakpoints.down("sm")]: {
            width: "auto"
        },
        [breakpoints.up("sm")]: {
            width: 280
        },
        [breakpoints.up("xl")]: {
            width: 420,
        }
    },
    costBreakdownDialogRoot: {
        padding: "64px",
        maxWidth: 640,
        borderRadius: 16,
        background: palette.common.white,
        [breakpoints.down("md")]: {
            maxWidth: 328,
            padding: spacing(3)
        },
        [breakpoints.down("sm")]: {
            borderRadius: 0,
            padding: spacing(2),
            width: "100vw",
            maxWidth: "100vw"
        },
        // [breakpoint.down("xs")]: {
        //     position: "absolute",
        //     top: 0,
        //     left: 0,
        //     width: "100vw",
        //     height: "100vh",
        //     margin: 0,
        //     borderRadius: 0,
        //     padding: spacing(2)
        // }
    },
    // dialogRoot: {
    //     [breakpoint.down("xs")]: {
    //         height: "calc(100vh + 64px)!important"
    //     }
    // },
    dialogContent: {
        padding: 0
    },
    dialogTitle: {
        padding: `0 0 40px 0`
    },
    tableContainerRoot: {
        boxShadow: "none",
        overflow: "visible !important"
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    arrowDownBlack: {
        stroke: palette.success.dark,
        marginLeft: spacing(1)
    },
    logoTopNav: {
        paddingTop: "6px",
        height: 32
    },
    arrowImg: {
        stroke: palette.success.dark
    },
    flexy: {
        display: "flex"
    },
    teamsMenu: {
        textTransform: "none",
        background: palette.success.backgroundDark,
        color: palette.success.dark,
        borderRadius: 12,
        marginLeft: spacing(1),
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    topNavLeftSide: {
        display: "flex",
        flexDirection: "row"
    },
    teamsButton: {
        marginTop: 50,
        marginBottom: spacing(1),
        borderLeft: `1px solid ${palette.text.backgroundMain}`,
        display: "flex",
        alignItems: "center",
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    leaveTeamLink: {
        "&:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        }
    },
    talkToSales: {
        cursor: "pointer",
        textDecoration: "underline"
    },
    leaveTeamText: {
        cursor: "default"
    }
}));
