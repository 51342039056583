import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    ML_REQUEST_TIMEOUT
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    technologyProfile: null,
    technologyCompanies: [],
    technology: null,
    getTechnologiesStatus: REQUEST_UNDEFINED,
    createTechnologyStatus: REQUEST_UNDEFINED,
    updateTechnologyStatus: REQUEST_UNDEFINED,
    publishTechnologyStatus: REQUEST_UNDEFINED,
    unPublishTechnologyStatus: REQUEST_UNDEFINED,
    deleteTechnologyStatus: REQUEST_UNDEFINED,
    getTechnologyStatus: REQUEST_UNDEFINED,
    postGetClosestStatus: REQUEST_UNDEFINED,
    postSaveCompaniesDataStatus: REQUEST_UNDEFINED,
    getTechnologyWithCompaniesStatus: REQUEST_UNDEFINED,
    updateTechnologyKanbanStatus: REQUEST_UNDEFINED,
    updateTechnologyKanbanStateStatus: REQUEST_UNDEFINED,
    releaseCompaniesStatus: REQUEST_UNDEFINED,
    lastReleaseInfo: null,
    createdTechnologyResponse: null,
    totalCount: null,
    currentPage: 1,
    totalPages: 0,
    technologiesStatusCode: null
};

export const REDUCER_PREFIX = "technologies";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getTechnologiesPending: state => {
            state.getTechnologiesStatus = REQUEST_PENDING;
        },
        getTechnologiesSuccess: state => {
            state.getTechnologiesStatus = REQUEST_SUCCESS;
        },
        getTechnologiesFailure: (state, { payload }) => {
            state.getTechnologiesStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        createTechnologyPending: state => {
            state.createTechnologyStatus = REQUEST_PENDING;
        },
        createTechnologySuccess: (state, { payload }) => {
            state.createTechnologyStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        createTechnologyFailure: (state, { payload }) => {
            state.createTechnologyStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        updateTechnologyPending: state => {
            state.updateTechnologyStatus = REQUEST_PENDING;
        },
        updateTechnologySuccess: (state, { payload }) => {
            state.updateTechnologyStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        updateTechnologyFailure: (state, { payload }) => {
            state.updateTechnologyStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        publishTechnologyPending: state => {
            state.publishTechnologyStatus = REQUEST_PENDING;
        },
        publishTechnologySuccess: (state, { payload }) => {
            state.publishTechnologyStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        publishTechnologyFailure: (state, { payload }) => {
            state.publishTechnologyStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        deleteTechnologyPending: state => {
            state.deleteTechnologyStatus = REQUEST_PENDING;
        },
        deleteTechnologySuccess: (state, { payload }) => {
            state.deleteTechnologyStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        deleteTechnologyFailure: (state, { payload }) => {
            state.deleteTechnologyStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        getTechnologyPending: state => {
            state.getTechnologyStatus = REQUEST_PENDING;
        },
        getTechnologySuccess: (state, { payload }) => {
            state.getTechnologyStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        getTechnologyFailure: (state, { payload }) => {
            state.getTechnologyStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        setTechnologies: (state, { payload }) => {
            state.pool = payload.data.technologies;
            state.totalCount = state.pool.length;
        },
        setTechnologyProfile: (state, { payload }) => {
            state.technologyProfile = payload.technology;
            payload.companies.results.forEach(company => {
                const {
                    name,
                    degree_of_fit,
                    deck,
                    sectors,
                    subsectors,
                    description,
                    founded_date,
                    hq_city,
                    hq_country,
                    investment_stage,
                    number_of_employees,
                    total_funding_amount_dollars,
                    id,
                    slug,
                    success_potential_score,
                    growth_score,
                    maturity_score,
                    innovation_score,
                    completeness_score,
                    website,
                    sustainability_score,
                    enrichment_request,
                    ready_for_release,
                    priority,
                    search_id,
                    valuer_score,
                    user_vote
                } = company;

                const uniqueResult = {
                    id,
                    name,
                    degree_of_fit,
                    deck,
                    sectors,
                    subsectors,
                    description,
                    founded_date,
                    hq_city,
                    hq_country,
                    investment_stage,
                    number_of_employees,
                    total_funding_amount_dollars: total_funding_amount_dollars ? Math.round(parseInt(total_funding_amount_dollars)) : "",
                    success_potential_score,
                    growth_score,
                    maturity_score,
                    innovation_score,
                    slug,
                    website,
                    sustainability_score,
                    completeness_score,
                    enrichment_request,
                    ready_for_release,
                    priority,
                    search_id,
                    valuer_score,
                    user_vote
                };
                state.technologyCompanies.push(uniqueResult);

            });
            state.totalCount = payload.companies.pagination ? payload.companies.pagination.total : payload.companies.results.length;
            state.currentPage = payload.companies.pagination ? payload.companies.pagination.current_page : 1;
            state.totalPages = payload.companies.pagination ? payload.companies.pagination.total_pages : 1;
        },
        clearCompaniesResults: state => {
            state.technologyCompanies = [];
            state.currentPage = 1;
            state.searchType = null;
            state.totalPages = null;
        },
        postGetClosestPending: state => {
            state.postGetClosestStatus = REQUEST_PENDING;
        },
        postGetClosestSuccess: (state, { payload }) => {
            state.postGetClosestStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        postGetClosestFailure: (state, { payload }) => {
            state.postGetClosestStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        postGetClosestTimeout: state => {
            state.postGetClosestStatus = ML_REQUEST_TIMEOUT;
        },
        postSaveCompaniesDataPending: state => {
            state.postSaveCompaniesDataStatus = REQUEST_PENDING;
        },
        postSaveCompaniesDataSuccess: (state, { payload }) => {
            state.postSaveCompaniesDataStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        postSaveCompaniesDataFailure: (state, { payload }) => {
            state.postSaveCompaniesDataStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        setTechnology: (state, { payload }) => {
            state.technology = payload.data.technology;
        },
        getTechnologyWithCompaniesPending: state => {
            state.getTechnologyWithCompaniesStatus = REQUEST_PENDING;
        },
        getTechnologyWithCompaniesSuccess: (state, { payload }) => {
            state.getTechnologyWithCompaniesStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        getTechnologyWithCompaniesFailure: (state, { payload }) => {
            state.getTechnologyWithCompaniesStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        updateTechnologyKanbanPending: state => {
            state.updateTechnologyKanbanStatus = REQUEST_PENDING;
        },
        updateTechnologyKanbanSuccess: (state, { payload }) => {
            state.updateTechnologyKanbanStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        updateTechnologyKanbanFailure: (state, { payload }) => {
            state.updateTechnologyKanbanStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        updateTechnologyKanbanStatusPending: state => {
            state.updateTechnologyKanbanStateStatus = REQUEST_PENDING;
        },
        updateTechnologyKanbanStatusSuccess: (state, { payload }) => {
            state.updateTechnologyKanbanStateStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        updateTechnologyKanbanStatusFailure: (state, { payload }) => {
            state.updateTechnologyKanbanStateStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        releaseCompaniesPending: state => {
            state.releaseCompaniesStatus = REQUEST_PENDING;
        },
        releaseCompaniesSuccess: (state, { payload }) => {
            state.releaseCompaniesStatus = REQUEST_SUCCESS;
            state.technologiesStatusCode = payload.status_code;
        },
        releaseCompaniesFailure: (state, { payload }) => {
            state.releaseCompaniesStatus = REQUEST_FAILURE;
            state.technologiesStatusCode = payload;
        },
        processRelease: (state, { payload }) => {
            let { companies } = payload.data.technology;
            let release = companies && companies.filter(f => { return f.ready_for_release }).map(m => { return { date: m.published_date_human, user: m.released_by_user } });
            state.lastReleaseInfo = release[0];
        },
        setCreatedTechnology: (state, { payload }) => {
            state.createdTechnologyResponse = payload.data.technology;
        },
        resetTechnologiesStatusCode: state => {
            state.technologiesStatusCode = null;
        },
    }
});
export { reducer, actions };
