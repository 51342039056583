import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(({ palette, spacing, breakpoints }) => ({
    root: {
        padding: spacing(4),
        background: palette.text.backgroundLight,
        maxWidth: 1146,
        margin: "0 auto"
    },
    button: {
        display: "block"
    },
    tagText: {},
    tag: {
        padding: "4px 12px",
        borderRadius: spacing(1),
        width: "fit-content",
        "& > $tagText": {
            color: palette.common.white
        }
    },
    admin: {
        background: palette.success.main
    },
    superAdmin: {
        background: palette.primary.main
    },
    editor: {
        background: palette.secondary.main
    },
    moderator: {
        background: palette.text.light
    },
    freeTrial: {
        background: palette.warning.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    free: {
        background: palette.text.backgroundMain,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    single: {
        background: palette.secondary.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    pro: {
        background: palette.primary.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    teams: {
        background: palette.success.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    custom: {
        background: palette.error.backgroundLight,
        fontFamily: "GT Flexa Mono, sans-serif",
        "& > $tagText": {
            color: palette.common.black
        }
    },
    cell: {
        padding: `${spacing(2)}px ${spacing(1)}px`,
        border: "none",
        lineHeight: "initial"
    },
    headRow: {
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderBottom : `1px solid ${palette.text.backgroundMain}`
    },
    row: {
        borderRadius: `${spacing(2)}px`,
        border: "none",
        margin: `${spacing(1)}px 0`,
        borderTop: `1px solid ${palette.text.backgroundMain}`,
        "&:hover": {
            background: palette.primary.backgroundMedium,
            cursor: "pointer"
        }
    },
    rowNonClickable: {

    },
    avatar: {
        marginRight: spacing(6),
        width: 72,
        height: 72
    },
    mono: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    info: {
        display: "flex",
        paddingTop: spacing(3)
    },
    sector: {
        marginRight: spacing(1)
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: spacing(4)
    },
    cards: {
        display: "flex",
        flexWrap: "wrap"
    },
    card: {
        position: "relative",
        border: `1px solid ${palette.text.lightest}`,
        borderRadius: spacing(2),
        padding: `${spacing(3)}px ${spacing(2)}px ${spacing(5)}px ${spacing(2)}px`,
        flexGrow: 1,
        width: "calc(33% - 16px)",
        background: palette.common.white,
        marginRight: spacing(2),
        marginBottom: spacing(2),
        "&:nth-child(3n+3)": {
            marginRight: 0
        }
    },
    dateCalendar: {
        position: "absolute",
        bottom: 56,
        left: 0,
        boxShadow: "0px 0px 10px rgb(0 0 0 / 20%)",
        borderRadius: spacing(2),
        border: `1px solid ${palette.text.lightest}`
    },
    dateCalendarWrapper: {
        position: "relative"
    },
    clickable: {
        cursor: "pointer"
    },
    cardLabel: {
        margin: `${spacing(2)}px 0 4px 0`
    },
    moreIcon: {
        fill: palette.text.light,
        cursor: "pointer",
        "&:hover": {
            fill: palette.primary.dark
        }
    },
    moreButton: {
        position: "absolute",
        right: spacing(2),
        top: spacing(3),
        padding: 0,
        minWidth: "20px"
    },
    links: {
        paddingBottom: spacing(1),
        display: "flex",
        [breakpoints.down("sm")]: {
            maxWidth: "100vw",
            overflow: "auto",
            "&::-webkit-scrollbar": {
                display: "none",
                "-ms-overflow-style": "none",
                scrollbarWidth: "none"
            },
        }
    },
    link: {
        "&:last-child": {
            marginRight: 0
        },
        cursor: "pointer",
        marginTop: spacing(4),
        color: palette.grey.main,
        // width: "calc(25% - 110px)",
        width: "calc(25% - 33px)",
        minWidth: 232,
        marginRight: spacing(2),
        padding: spacing(3),
        borderRadius: 24,
        background: palette.primary.backgroundMedium,
        [breakpoints.down("md")]: {
            width: "fit-content"
        },
        [breakpoints.down("sm")]: {
            height: 58,
            minWidth: "125px!important",
            display: "flex",
            alignItems: "center"
        }
    },
    active: {
        color: palette.secondary.light,
        position: "relative",
        background: palette.success.backgroundLight
    },
    subtitle: {
        marginBottom: spacing(1),
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    title: {
        marginBottom: spacing(2),
        [breakpoints.down("sm")]: {
            display: "none!important"
        }
    },
    borderActive: {
        position: 'absolute',
        content: "",
        height: 2,
        width: ({ tabView }) => tabView === 'profile' ? 54 : 121,
        background: palette.secondary.main,
        top: 25,
        left: -6,
        right: 0
    },
    profileWrap: {
        padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        display: "flex",
        [breakpoints.down("sm")]: {
            flexDirection: "column"
        }
    },
    profile: {
        display: "flex",
        margin: `${spacing(4)}px 0`
    },
    companyWrap: {
        borderTop: "1px solid #F1F1F1",
        padding: `${spacing(4)}px 0 ${spacing(6)}px 0`,
        display: "flex",
        flexDirection: "column",
        marginTop: spacing(3)
    },
    editLogo: {
        width: 250,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [breakpoints.down("sm")]: {
            margin: "auto"
        }

    },
    hideImg: {
        [breakpoints.down("sm")]: {
            display: "none!important"
        }
    },
    avatarWrapper: {
        width: 142,
        height: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.success.backgroundLight,
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    avatar: {
        overflow: "hidden",
        height: 142,
        width: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    avatarImg: {
        width: 150,
        [breakpoints.down("sm")]: {
            width: 80,
            height: 80
        }
    },
    companyIllustration: {
        width: 142,
        height: 142,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: palette.primary.backgroundMedium
    },
    edit: {
    },
    maximumSize: {
        color: palette.grey.main,
    },
    uploadBtn: {
        margin: `${spacing(4)}px 0 ${spacing(2)}px 0`
    },
    profileTitle: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    profileValue: {
        marginBottom: spacing(1)
    },
    changeLink: {
        fontFamily: "GT Flexa Mono, sans-serif",
        marginTop: spacing(1),
        cursor: "pointer"
    },
    infoRow: {
        "&:nth-child(n+2)": {
            marginTop: spacing(6)
        }
    },
    buttons: {
        marginTop: spacing(1),
        display: "flex",
        justifyContent: "flex-end"
    },
    textInput: {
        "& input": {
            width: 420
        }
    }, eye: {
        position: "absolute",
        right: spacing(1),
        cursor: "pointer"
    },
    noData: {
        marginTop: spacing(6),
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    noDataLabel: {
        marginTop: spacing(6)
    },
    billingHeaderBoxes: {
        display: "flex",
        flexDirection: "row",
        marginTop: spacing(2),
        [breakpoints.down("sm")]: {
            flexDirection: "column",
            marginTop: 0
        }
    },
    billingHeaderBox: {
        width: "calc(33% - 12px)",
        marginRight: spacing(3),
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: spacing(3),
        padding: spacing(4),
        "&:last-child": {
            marginRight: 0
        },
        [breakpoints.down("sm")]: {
            width: "100%",
            marginTop: spacing(3)

        }
    },
    box: {
        border: `1px solid ${palette.secondary.dark}`,
        borderRadius: spacing(3),
        padding: spacing(3),
        marginRight: spacing(2),
        width: 315,
        minWidth: 315,
        minHeight: 232
    },
    bigCalcButton: {
        cursor: "pointer"
    },
    calculator: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: spacing(3)
    },
    calculatorSmall: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    availableRadarsNumber: {
        margin: "0 27px",
        width: 64,
        position: "relative",
        "-webkit-tap-highlight-color":  "transparent",
        pointerEvents: "none",
        "&::selection": {
            "-webkit-tap-highlight-color":  "transparent",
            pointerEvents: "none"
        }
    },
    creditsNumber: {
        margin: 0,
        width: 34,
        position: "relative",
        "-webkit-tap-highlight-color":  "transparent",
        pointerEvents: "none",
        "&::selection": {
            "-webkit-tap-highlight-color":  "transparent",
            pointerEvents: "none"
        }
    },
    plusMinusIconBig: {
        width: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    plusMinusIcon: {
        width: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    calculatorSmallWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
        marginTop: 10
    },
    totalCalc: {
        borderTop: `1px solid ${palette.text.backgroundDark}`,
        paddingTop: spacing(1)
    },
    creditsNumberChange: {
        position: "absolute",
        bottom: -15,
        left: 0,
        right: 0
    },
    rightAlign: {
        display: "flex",
        justifyContent: "flex-end"
    },
    creditsNumberChangeBig: {
        position: "absolute",
        bottom: -35,
        left: 0,
        right: 0
    },
    changeMinus: {
        color: palette.error.main
    },
    changePlus: {
        color: "rgba(0, 122, 94, 0.75)"
    },
    rowWrap: {
        width: 168
    },
    multiselect: {
        width: "100% !important",
        borderRadius: `${spacing(3)}px !important`,
        borderColor: `${palette.text.lightest} !important`,
        "& svg": {
            width: 15,
            height: 15,
            marginRight: 10
        }
    },
    teamTable: {
        marginTop: spacing(5),
        paddingBottom: spacing(8)
    },
    statusWrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    warningIcon: {
        position: "absolute",
        left: -30
    },
    teamUserAvatar: {
        width: 48,
        height: 48,
        borderRadius: "50%",
        background: palette.success.backgroundLight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: 31
        }
    },
    email: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    moreIconWrapper: {
        position: "relative",
        width: 24
    },
    moreIconMenu: {
        position: "absolute",
        top: 20,
        right: 0,
        background: palette.common.white,
        border: `1px solid ${palette.text.backgroundDark}`,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: spacing(1),
        zIndex: 2,
        minWidth: 135,
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    moreIconMenuItem: {
        padding: `${spacing(2)}px ${spacing(3)}px`,
        "&:hover": {
            cursor: "pointer",
            color: palette.error.main
        }
    },
    cellMoreWrap: {
        position: "relative"
    },
    cancelButton: {
        marginRight: spacing(2)
    },
    goBack: {
        display: "inline-flex",
        alignItems: "center",
        "& img": {
            marginRight: spacing(1)
        },
        cursor: "pointer"
    },
    teamHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: spacing(4)
    },
    addExistingUserBtn: {
        marginRight: spacing(2)
    }
}));