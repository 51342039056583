import { call } from "redux-saga/effects";
import axios from "axios";
import {
    getAdminUsersUrl,
    ADMINUSERS_URL
} from "../../../constants/endpoints";

function* fetchAdminUsers(data) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getAdminUsersUrl(data)}`,
        withCredentials: true
    });
    return response.data;
}

function* postCreateAdminUser(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${getAdminUsersUrl()}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* putUpdateAdminUser(id, data) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${ADMINUSERS_URL}${id}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* getSingleAdminUser(data) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getAdminUsersUrl(data)}`,
        withCredentials: true
    });
    return response.data;
}

function* deleteSingleAdminUser(id) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${ADMINUSERS_URL}${id}`,
        withCredentials: true
    });
    return response.data;
}

export {
    fetchAdminUsers,
    postCreateAdminUser,
    putUpdateAdminUser,
    getSingleAdminUser,
    deleteSingleAdminUser
};