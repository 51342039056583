import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CenterFocusStrong } from '@material-ui/icons';

export const useStyles = makeStyles(({ palette, spacing, typography }) => createStyles({
    root: {
        height: "100vh",
        width: "100%",
        position: "relative"
    },
    formWrapper: {
        padding: `${spacing(5)}px ${spacing(9)}px`,
        background: palette.common.white,
        maxWidth: "1500px",
        alignItems: "center"
    },
    formWrapperRoot: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: palette.common.white,
        justifyContent: "center"
    },
    inputWrapper: {
        marginBottom: spacing(3),
        width: "100%"
    },
    passwordInputWrapper: {
        marginBottom: spacing(3),
        width: "calc(50% - 8px)"
    },
    confirmPasswordInputWrapper: {
        marginBottom: spacing(3),
        marginLeft: spacing(2),
        width: "calc(50% - 8px)"
    },
    divider: {
        width: "100%",
        height: 1,
        background: "#E3E5E9",
        marginBottom: spacing(3)
    },
    form: {
        marginTop: spacing(5),
        width: "50%",
    },
    checkboxRoot: {
        padding: `0 9px 0`,
        "& svg": {
            fill: palette.checkboxBorder,
            width: 19,
            height: 19
        }
    },
    formControlLabelRoot: {
        color: palette.grey.main,
        width: "100%",
        marginTop: spacing(1),
        marginRight: 0
    },
    formControlLabelSignInRoot: {
        color: palette.grey.main
    },
    createAccountButton: {
        margin: `${spacing(3)}px 0`
    },
    signInCopy: {
        marginBottom: spacing(1)
    },
    signInCopyText: {
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    goBack: {
        position: "absolute",
        left: 64,
        top: 64,
        zIndex: 2,
        fontFamily: "GT Flexa Mono, sans-serif"
    },
    rememberMeForgotWrapper: {
        marginBottom: spacing(3)
    },
    eye: {
        position: "absolute",
        right: spacing(1),
        cursor: "pointer"
    },
    formError: {
        marginBottom: spacing(1)
    },
    signInButton: {
        margin: `0 0 ${spacing(1)}px 0`
    },
    inputLabel: {
        color: palette.text.light
    },
    labelShrinked: {
        ...typography.bodyXs,
        fontFamily: "GT Flexa Mono, sans-serif",
        transform: "none",
        marginTop: -4,
        color: palette.text.light
    },
    focusedLabel: {
        color: `${palette.text.light} !important`
    },
    forgotPassword: {
        fontFamily: "GT Flexa Mono, sans-serif",
    },
    input: {
        "&.Mui-focused": {
            "&:after": {
                borderBottom: `2px solid ${palette.primary.dark}`
            },
            "&:before": {
                borderBottom: `2px solid ${palette.primary.dark}`
            }
        },
        "&:after": {
            borderBottom: `2px solid ${palette.text.lighter}`
        },
        "&:before": {
            borderBottom: `2px solid ${palette.text.lighter}`
        }
    },
    errorHelperText: {
        color: palette.error.main
    },
    signUpSubtitle: {
        color: palette.text.lighter,
        fontFamily: "GT Flexa Mono, sans-serif",
        marginBottom: spacing(1)
    },
    signUpIllustrationTitle: {
        marginBottom: spacing(6)
    }
}));
