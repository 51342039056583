import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    ML_REQUEST_TIMEOUT
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    industry: {},
    getIndustriesStatus: REQUEST_UNDEFINED,
    getIndustryStatus: REQUEST_UNDEFINED,
    createIndustryStatus: REQUEST_UNDEFINED,
    deleteIndustryStatus: REQUEST_UNDEFINED,
    updateIndustryStatus: REQUEST_UNDEFINED,
    addSubindustryStatus: REQUEST_UNDEFINED,
    deleteSubindustryStatus: REQUEST_UNDEFINED,
    updateSubindustryStatus: REQUEST_UNDEFINED,
    getSubIndustryWithCompaniesStatus: REQUEST_UNDEFINED,
    subIndustry: null,
    updateMLCompanyStatus: REQUEST_UNDEFINED,
    updateMLCompanyKanbanStatus: REQUEST_UNDEFINED,
    releaseCompaniesStatus: REQUEST_UNDEFINED,
    industriesStatusCode: null
};

export const REDUCER_PREFIX = "industries";


const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        createIndustryUndefined: state => {
            state.createIndustryStatus = REQUEST_UNDEFINED;
        },
        updateIndustryUndefined: state => {
            state.updateIndustryStatus = REQUEST_UNDEFINED;
        },
        deleteIndustryUndefined: state => {
            state.deleteIndustryStatus = REQUEST_UNDEFINED;
        },
        getIndustriesPending: state => {
            state.getIndustriesStatus = REQUEST_PENDING;
        },
        getIndustriesSuccess: state => {
            state.getIndustriesStatus = REQUEST_SUCCESS;
        },
        getIndustriesFailure: (state, { payload }) => {
            state.getIndustriesStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        getIndustryPending: state => {
            state.getIndustryStatus = REQUEST_PENDING;
        },
        getIndustrySuccess: state => {
            state.getIndustryStatus = REQUEST_SUCCESS;
        },
        getIndustryFailure: (state, { payload }) => {
            state.getIndustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        createIndustryPending: state => {
            state.createIndustryStatus = REQUEST_PENDING;
        },
        createIndustrySuccess: state => {
            state.createIndustryStatus = REQUEST_SUCCESS;
        },
        createIndustryFailure: (state, { payload }) => {
            state.createIndustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        deleteIndustryPending: state => {
            state.deleteIndustryStatus = REQUEST_PENDING;
        },
        deleteIndustrySuccess: (state, { payload }) => {
            state.deleteIndustryStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        deleteIndustryFailure: (state, { payload }) => {
            state.deleteIndustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        updateIndustryPending: state => {
            state.updateIndustryStatus = REQUEST_PENDING;
        },
        updateIndustrySuccess: (state, { payload }) => {
            state.updateIndustryStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        updateIndustryFailure: (state, { payload }) => {
            state.updateIndustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        addSubindustryPending: state => {
            state.addSubindustryStatus = REQUEST_PENDING;
        },
        addSubindustrySuccess: (state, { payload }) => {
            state.addSubindustryStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        addSubindustryFailure: (state, { payload }) => {
            state.addSubindustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        deleteSubindustryPending: state => {
            state.deleteSubindustryStatus = REQUEST_PENDING;
        },
        deleteSubindustrySuccess: (state, { payload }) => {
            state.deleteSubindustryStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        deleteSubindustryFailure: (state, { payload }) => {
            state.deleteSubindustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        updateSubindustryPending: state => {
            state.updateSubindustryStatus = REQUEST_PENDING;
        },
        updateSubindustrySuccess: (state, { payload }) => {
            state.updateSubindustryStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        updateSubindustryFailure: (state, { payload }) => {
            state.updateSubindustryStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        setIndustries: (state, { payload }) => {
            state.pool = payload;
        },
        setIndustry: (state, { payload }) => {
            state.industry = payload;
        },
        postGetClosestPending: state => {
            state.postGetClosestStatus = REQUEST_PENDING;
        },
        postGetClosestSuccess: (state, { payload }) => {
            state.postGetClosestStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        postGetClosestFailure: (state, { payload }) => {
            state.postGetClosestStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        postGetClosestTimeout: state => {
            state.postGetClosestStatus = ML_REQUEST_TIMEOUT;
        },
        postSaveCompaniesDataPending: state => {
            state.postSaveCompaniesDataStatus = REQUEST_PENDING;
        },
        postSaveCompaniesDataSuccess: (state, { payload }) => {
            state.postSaveCompaniesDataStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        postSaveCompaniesDataFailure: (state, { payload }) => {
            state.postSaveCompaniesDataStatus = ML_REQUEST_TIMEOUT;
            state.industriesStatusCode = payload === "Error: Request failed with status code 401" && 401;
        },
        getSubIndustryWithCompaniesPending: state => {
            state.getSubIndustryWithCompaniesStatus = REQUEST_PENDING;
        },
        getSubIndustryWithCompaniesSuccess: (state, { payload }) => {
            state.getSubIndustryWithCompaniesStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        getSubIndustryWithCompaniesFailure: (state, { payload }) => {
            state.getSubIndustryWithCompaniesStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        setSubindustry: (state, { payload }) => {
            state.subIndustry = payload.data;
        },
        processRelease: (state, { payload }) => {
            let { companies } = payload.data.industry;
            let release = companies && companies.filter(f => { return f.ready_for_release }).map(m => { return { date: m.ready_for_release_date_human, user: m.released_by_user } });
            state.lastReleaseInfo = release[0];
        },
        updateMlCompanyPending: state => {
            state.updateMLCompanyStatus = REQUEST_PENDING;
        },
        updateMlCompanySuccess: (state, { payload }) => {
            state.updateMLCompanyStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        updateMlCompanyFailure: (state, { payload }) => {
            state.updateMLCompanyStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        updateMlCompanyKanbanStatusPending: state => {
            state.updateMLCompanyKanbanStatus = REQUEST_PENDING;
        },
        updateMlCompanyKanbanStatusSuccess: (state, { payload }) => {
            state.updateMLCompanyKanbanStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        updateMlCompanyKanbanStatusFailure: (state, { payload }) => {
            state.updateMLCompanyKanbanStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        releaseCompaniesPending: state => {
            state.releaseCompaniesStatus = REQUEST_PENDING;
        },
        releaseCompaniesSuccess: (state, { payload }) => {
            state.releaseCompaniesStatus = REQUEST_SUCCESS;
            state.industriesStatusCode = payload.status_code;
        },
        releaseCompaniesFailure: (state, { payload }) => {
            state.releaseCompaniesStatus = REQUEST_FAILURE;
            state.industriesStatusCode = payload;
        },
        processRelease: (state, { payload }) => {
            let companies = (payload.data && payload.data.industry && payload.data.industry.companies) ? payload.data.industry.companies : [];
            let release = companies && companies.filter(f => { return f.ready_for_release }).map(m => { return { date: m.ready_for_release_date_human, user: m.released_by_user } });
            state.lastReleaseInfo = release[0];
        },
        resetIndustriesStatusCode: state => {
            state.industriesStatusCode = null;
        },
    }
});
export { reducer, actions };
