import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCookieRequest, getMeRequest } from '../store/auth/requests';
import { getCookieStatusSelector } from '../store/auth/selectors';

export const useCookiesHook = () => {
    const dispatch = useDispatch();
    const getCookieStatus = useSelector(getCookieStatusSelector);

    useEffect(() => {
        dispatch(fetchCookieRequest());
        setInterval(() => {
            dispatch(getMeRequest());
        }, 180000);
    }, [dispatch]);

    return { getCookieStatus };
};
