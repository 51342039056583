import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    getAdminUserstatus: REQUEST_UNDEFINED,
    createAdminUserStatus: REQUEST_UNDEFINED,
    updateAdminUserStatus: REQUEST_UNDEFINED,
    getAdminUserStatus: REQUEST_UNDEFINED,
    deleteAdminUserStatus: REQUEST_UNDEFINED,
    createAdminUserFailureMessage: null
};

export const REDUCER_PREFIX = "adminusers";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getAdminUsersPending: state => {
            state.getAdminUserstatus = REQUEST_PENDING;
        },
        getAdminUsersSuccess: state => {
            state.getAdminUserstatus = REQUEST_SUCCESS;
        },
        getAdminUsersFailure: state => {
            state.getAdminUserstatus = REQUEST_FAILURE;
        },
        getAdminUsersUndefined: state => {
            state.getAdminUserstatus = REQUEST_UNDEFINED;
        },
        setAdminUsers: (state, { payload }) => {
            if (payload.users.pagination.current_page === 1) {
                state.pool = payload;
            } else {
                let stateObj = [...state.pool.users.results];
                let newArray = stateObj.concat(payload.users.results);
                state.pool.users.results = newArray;
                state.pool.users.pagination = payload.users.pagination;
            }
        },
        createAdminUserPending: state => {
            state.createAdminUserStatus = REQUEST_PENDING;
        },
        createAdminUserSuccess: state => {
            state.createAdminUserStatus = REQUEST_SUCCESS;
            state.createAdminUserFailureMessage = null;
        },
        createAdminUserFailure: (state, { payload }) => {
            state.createAdminUserStatus = REQUEST_FAILURE;
            state.createAdminUserFailureMessage = payload;
        },
        clearCreateAdminUserFailureMessage: state => {
            state.createAdminUserFailureMessage = null;
        },
        updateAdminUserPending: state => {
            state.updateAdminUserStatus = REQUEST_PENDING;
        },
        updateAdminUserSuccess: (state, { payload }) => {
            state.updateAdminUserStatus = REQUEST_SUCCESS;
        },
        updateAdminUserFailure: state => {
            state.updateAdminUserStatus = REQUEST_FAILURE;
        },
        updateAdminUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            var objIndex = stateObj.findIndex(obj => {
                return obj.id === payload.id
              })
            stateObj[objIndex] = payload;
            state.pool.users.results = stateObj;
        },
        addAdminUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            stateObj.unshift(payload.user);
            state.pool.users.results = stateObj;
        },
        deleteAdminUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            state.pool.users.results = stateObj.filter( el => el.id !== payload );
        },
        getAdminUserPending: state => {
            state.getAdminUserStatus = REQUEST_PENDING;
        },
        getAdminUserSuccess: (state, { payload }) => {
            state.getAdminUserStatus = REQUEST_SUCCESS;
        },
        getAdminUserFailure: state => {
            state.getAdminUserStatus = REQUEST_FAILURE;
        },
        deleteAdminUserPending: state => {
            state.deleteAdminUserStatus = REQUEST_PENDING;
        },
        deleteAdminUserSuccess: (state, { payload }) => {
            state.deleteAdminUserStatus = REQUEST_SUCCESS;
        },
        deleteAdminUserFailure: state => {
            state.deleteAdminUserStatus = REQUEST_FAILURE;
        }
    }
});
export { reducer, actions };