import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import classnames from "classnames";
import moment from 'moment';
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/TextField/TextField";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Modal from '../../../components/Modal/Modal.js';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ClickAwayListener from 'react-click-away-listener';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";

//Assets
import CloseIcon from '../../../assets/icons/CloseIcon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/EyeIconNew.svg';
import { ReactComponent as EyeIconActive } from '../../../assets/icons/EyeIconNewActive.svg';
//Redux
import { createUserRequest } from "../../../store/userprofiles/requests";
import { createUserMessageFailureSelector, createUserStatusSelector } from "../../../store/userprofiles/selectors";
import { actions as userprofilesActions } from "../../../store/userprofiles/slice";
// Constants
import { REQUEST_SUCCESS } from "../../../constants/statuses";

const AddNewCustomProfileModal = ({
    dialogOpened,
    onClose,
    user
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const createUserMessageFailure = useSelector(createUserMessageFailureSelector);
    const createUserStatus = useSelector(createUserStatusSelector);
    const [adminEmailValue, setAdminEmailValue] = useState("");
    const [customerNameValue, setCustomerNameValue] = useState("");
    const [companyNameValue, setCompanyNameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
    const [radarsValue, setRadarsValue] = useState("0");
    const [creditsValue, setCreditsValue] = useState("0");
    const [userSeatsValue, setUserSeatsValue] = useState("0");
    const [startDateValue, setStartDateValue] = useState(null);
    const [startDateInputValue, setStartDateInputValue] = useState("")
    const [expirationDateValue, setExpirationDateValue] = useState(null);
    const [expirationDateInputValue, setExpirationDateInputValue] = useState("");
    const [isStartDateCalendarShown, setIsStartDateCalendarShown] = useState("");
    const [isExpirationDateCalendarShown, setIsExpirationDateCalendarShown] = useState("");

    useEffect(() => {
        startDateValue && setStartDateInputValue(moment(startDateValue).format('MMM DD, YYYY'));
    }, [startDateValue]);

    useEffect(() => {
        expirationDateValue && setExpirationDateInputValue(moment(expirationDateValue).format('MMM DD, YYYY'));
    }, [expirationDateValue]);

    const create = () => {
        dispatch(createUserRequest({
            data: {
                userId: user.id,
                email: adminEmailValue,
                password: passwordValue,
                password_confirmation: confirmPasswordValue,
                companyName: companyNameValue,
                customerName: customerNameValue,
                maxRadars: radarsValue,
                maxCredits: creditsValue,
                maxUsers: userSeatsValue,
                startDate: moment(startDateValue).format('YYYY-MM-DD'),
                expirationDate: moment(expirationDateValue).format('YYYY-MM-DD'),
            }
        }));
    };

    useEffect(() => {
        createUserStatus === REQUEST_SUCCESS && handleClose();
    }, [createUserStatus]);

    const handleClose = () => {
        onClose();
        setAdminEmailValue("");
        setCustomerNameValue("");
        setCompanyNameValue("");
        setPasswordValue("");
        setConfirmPasswordValue("");
        setRadarsValue("0");
        setCreditsValue("0");
        setUserSeatsValue("0");
        setStartDateValue(null);
        setStartDateInputValue("")
        setExpirationDateValue(null);
        setExpirationDateInputValue("");
        setIsStartDateCalendarShown("");
        setIsExpirationDateCalendarShown("");
        setPasswordFieldType(true);
        setPasswordConfirmationFieldType(true);
        dispatch(userprofilesActions.clearCreateUserMessageFailure());
    };

    const handleCompanyNameChange = (event) => {
        setCompanyNameValue(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPasswordValue(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    }

    const handleCustomerNameChange = (event) => {
        setCustomerNameValue(event.target.value);
    }

    const handleRadarsChange = (event) => {
        setRadarsValue(event.target.value);
    }

    const handleUserSeatsChange = (event) => {
        setUserSeatsValue(event.target.value);
    }

    const handleCreditsChange = (event) => {
        setCreditsValue(event.target.value);
    }

    const handleStartDateChange = (event) => {
        setStartDateValue(event);
        setIsStartDateCalendarShown(false);
    }

    const handleExpirationDateChange = (event) => {
        setExpirationDateValue(event);
        setIsExpirationDateCalendarShown(false);
    }

    const handleAdminEmailChange = (event) => {
        setAdminEmailValue(event.target.value);
    }

    const handlePasswordFieldTypeChange = () => {
        setPasswordFieldType(prevPasswordFieldType => !prevPasswordFieldType);
    };
    const [passwordFieldType, setPasswordFieldType] = useState(true);

    const handlePasswordConfirmationFieldTypeChange = () => {
        setPasswordConfirmationFieldType(prevPasswordFieldType => !prevPasswordFieldType);
    };
    const [passwordConfirmationFieldType, setPasswordConfirmationFieldType] = useState(true);

    const validateEmail = email => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <Modal
            onClose={handleClose}
            dialogOpened={dialogOpened}
            closeButtonIcon={CloseIcon}
            paddingSpace="32px"
            borderRadius="16px"
        >
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="paragraph" component="div" className={classes.filterDialogTitle}>{t('profiles.addNewProfileModalTitle')}</Typography>
            </MuiDialogTitle>
            <Box className={classes.rows}>
                <div className={classes.row}>
                    <div className={classes.half}>
                        <TextField
                            testId="header-admin-email-field"
                            autocomplete="one-time-code"
                            value={adminEmailValue || ""}
                            className={classes.modalInput}
                            fullWidth
                            label={t("profiles.adminEmail")}
                            onChange={handleAdminEmailChange}
                            error={!!adminEmailValue && !validateEmail(adminEmailValue)}
                            {...!!adminEmailValue && !validateEmail(adminEmailValue) && { helperText: t("common.emailError") }}
                        />
                    </div>
                    <div className={classes.half}>
                        <TextField testId="header-customer-name-field" value={customerNameValue || ""} className={classes.modalInput} fullWidth label={t("profiles.customerName")} onChange={handleCustomerNameChange}></TextField>
                    </div>
                </div>
                <div className={classes.row}>
                    <TextField testId="header-company-name-field" autocomplete="one-time-code" value={companyNameValue || ""} className={classes.modalInput} fullWidth label={t("profiles.companyName")} onChange={handleCompanyNameChange}></TextField>
                </div>
                <div className={classes.row}>
                    <div className={classes.half}>
                        <TextField
                            type={passwordFieldType ? "password" : "text"}
                            autocomplete="one-time-code"
                            testId="header-password-field"
                            value={passwordValue || ""}
                            className={classes.modalInput}
                            fullWidth
                            label={t("profiles.password")}
                            onChange={handlePasswordChange}
                            error={!!passwordValue && passwordValue.length < 8}
                            {...(!!passwordValue && passwordValue.length < 8) && { helperText: t("common.passwordError") }}
                            endAdornment={passwordFieldType ? <EyeIcon className={classes.eye} onClick={handlePasswordFieldTypeChange} /> : <EyeIconActive className={classes.eye} onClick={handlePasswordFieldTypeChange} />}
                        />
                    </div>
                    <div className={classes.half}>
                        <TextField
                            type={passwordConfirmationFieldType ? "password" : "text"}
                            testId="header-confirm-password-field"
                            autocomplete="one-time-code"
                            value={confirmPasswordValue || ""}
                            className={classes.modalInput}
                            fullWidth
                            label={t("profiles.confirmPassword")}
                            onChange={handleConfirmPasswordChange}
                            error={!!confirmPasswordValue && passwordValue !== confirmPasswordValue}
                        {...(!!confirmPasswordValue && passwordValue !== confirmPasswordValue) && { helperText: t("common.passwordConfirmError") }}
                            endAdornment={passwordConfirmationFieldType ? <EyeIcon className={classes.eye} onClick={handlePasswordConfirmationFieldTypeChange} /> : <EyeIconActive className={classes.eye} onClick={handlePasswordConfirmationFieldTypeChange} />}
                        />
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.third}>
                        <TextField type="number" testId="header-radars-field" value={radarsValue || ""} className={classes.modalInput} fullWidth label={t("profiles.radars")} onChange={handleRadarsChange}></TextField>
                    </div>
                    <div className={classes.third}>
                        <TextField type="number" testId="header-credits-field" value={creditsValue || ""} className={classes.modalInput} fullWidth label={t("profiles.credits")} onChange={handleCreditsChange}></TextField>
                    </div>
                    <div className={classes.third}>
                        <TextField type="number" testId="header-userSeats-field" value={userSeatsValue || ""} className={classes.modalInput} fullWidth label={t("profiles.userSeats")} onChange={handleUserSeatsChange}></TextField>
                    </div>
                </div>
                <div className={classes.row}>
                    <div className={classes.half}>
                        <ClickAwayListener onClickAway={() => setIsStartDateCalendarShown(false)}>
                            <div className={classes.row}>
                                <TextField onClick={() => setIsStartDateCalendarShown(!isStartDateCalendarShown)} testId="header-start-date-field" value={startDateInputValue || ""} className={classes.modalInput} fullWidth label={t("profiles.startDate")}></TextField>
                                {isStartDateCalendarShown && <div className={classes.dateCalendar}><Calendar onChange={handleStartDateChange} value={startDateValue} /></div>}
                            </div>
                        </ClickAwayListener>
                    </div>
                    <div className={classes.half}>
                        <ClickAwayListener onClickAway={() => setIsExpirationDateCalendarShown(false)}>
                            <div className={classes.row}>
                                <TextField onClick={() => setIsExpirationDateCalendarShown(!isExpirationDateCalendarShown)} testId="header-expiration-date-field" value={expirationDateInputValue || ""} className={classes.modalInput} fullWidth label={t("profiles.expirationDate")}></TextField>
                                {isExpirationDateCalendarShown && <div className={classes.dateCalendarRight}><Calendar onChange={handleExpirationDateChange} value={expirationDateValue} /></div>}
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
            </Box>
            {!!createUserMessageFailure && <Typography variant="body" component="div" gutterBottom color="danger">
                {createUserMessageFailure}
            </Typography>}
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.rowWrapButtons}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        className={classes.cancelButton}
                        variant="tertiary"
                        disabled={false}
                        onClick={handleClose}
                        test-id="cancel-filters"
                    >
                        {t("users.cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        className={classes.showResultsButton}
                        disabled={!adminEmailValue || !passwordValue || (!!passwordValue && passwordValue.length < 8) || (passwordValue !== confirmPasswordValue) || (!!adminEmailValue && !validateEmail(adminEmailValue))}
                        onClick={create}
                        test-id="show-results"
                    >
                        {t("users.createNewUser")}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
export default AddNewCustomProfileModal;