import { put, call } from "redux-saga/effects";
import { actions } from "../slice";
import { actions as usersActions } from "../../userprofiles/slice";
import {
    fetchSubscriptions,
    postCreateSubscription,
    putUpdateSubscription,
    fetchSubscriptionUser,
    postDeleteSubscription,
    putChangeTeamOwner,
    getResendEmailVerification,
    deleteRemoveTeamMember,
    postCreateTeamMember,
    getResendUserInvitation,
    fetchTeam,
    putAddExistingUser
} from "./helpers";

function* getSubscriptions(search, page, userId) {
    try {
        yield put(actions.getSubscriptionsPending());
        const response = yield call(fetchSubscriptions, search, page, userId);
        yield put(actions.getSubscriptionsSuccess());
        yield put(actions.setSubscriptions(response.data));
    } catch (err) {
        yield put(actions.getSubscriptionsFailure());
    }
}
function* createSubscription(data) {
    try {
        yield put(actions.createSubscriptionPending());
        const response = yield call(postCreateSubscription, data);
        if (response.message !== "Invalid data.") {
            yield put(actions.createSubscriptionSuccess());
            yield put(usersActions.addSubscription(response.data));
        } else {
            yield put(actions.createSubscriptionFailure(Object.values(response.data)[0]));
        }
    } catch (err) {
        yield put(actions.createSubscriptionFailure(err));
    }
}
function* updateSubscription(id, type, data) {
    try {
        yield put(actions.updateSubscriptionPending());
        const response = yield call(putUpdateSubscription, id, type, data);
        if (response.message === "Invalid data." || response.message === "Invalid data") {
            yield put(actions.updateSubscriptionFailureMessage([Object.values(response.data)[0]]));
        } else {
            yield put(actions.updateSubscriptionSuccess());
            yield put(actions.updateSubscription(response.data.user_profile));
        }
    } catch (err) {
        yield put(actions.updateSubscriptionFailure());
    }
}
function* getSubscriptionUser(id, type) {
    try {
        yield put(actions.getSubscriptionUserPending());
        const response = yield call(fetchSubscriptionUser, id, type);
        yield put(actions.getSubscriptionUserSuccess(response.data));
        yield put(actions.addSubscriptionProfile(response.data.user_profile));
    } catch (err) {
        yield put(actions.getSubscriptionUserFailure());
    }
}
function* deleteSubscription(planId, planType) {
    try {
        yield put(actions.deleteSubscriptionPending());
        const response = yield call(postDeleteSubscription, planId, planType);
        yield put(actions.deleteSubscriptionSuccess());
        yield put(actions.deleteSubscription(planId, planType));
    } catch (err) {
        yield put(actions.deleteSubscriptionFailure());
    }
}
function* changeTeamOwner(teamId, ownerId, planId, planType) {
    try {
        yield put(actions.changeTeamOwnerPending());
        const response = yield call(putChangeTeamOwner, teamId, ownerId, planId, planType);
        yield put(actions.changeTeamOwnerSuccess());
        yield put(actions.changeTeamOwner(response.data));
    } catch (err) {
        yield put(actions.changeTeamOwnerFailure());
    }
}
function* resendUserInvitation(teamId, userEmail) {
    try {
        yield put(actions.resendUserInvitationPending());
        yield call(getResendUserInvitation, teamId, userEmail);
        yield put(actions.resendUserInvitationSuccess());
    } catch (err) {
        yield put(actions.resendUserInvitationFailure());
    }
}
function* resendEmailVerification(teamId, userEmail) {
    try {
        yield put(actions.resendEmailVerificationPending());
        yield call(getResendEmailVerification, teamId, userEmail);
        yield put(actions.resendEmailVerificationSuccess());
    } catch (err) {
        yield put(actions.resendEmailVerificationFailure());
    }
}
function* createTeamMember(teamId, data) {
    yield put(actions.createTeamMemberPending());
    let response;
    
    try {
        response = yield call(postCreateTeamMember, teamId, data);
    } catch (e) {
        response = e.response;
    }
    
    if (response.data.status === 'error' || response.status === "error") {
        yield put(actions.createTeamMemberFailure(Object.values(response.data.data)));
    } else {
        yield put(actions.createTeamMemberSuccess());
        yield put(actions.addTeamMember(response.data));
    }
}
function* addExistingUser(teamId, email) {
    try {
        yield put(actions.addExistingUserPending());
        const response = yield call(putAddExistingUser, teamId, email);
        if (response.status === "error") {
            yield put(actions.addExistingUserFailure(response.message));
        } else {
            yield put(actions.addExistingUserSuccess());
            yield put(actions.addTeamMember(response.data));
        }
    } catch (err) {
        yield put(actions.addExistingUserFailure(err));
    }
}
function* removeTeamMember(teamId, userId, userEmail, planId, planType) {
    try {
        yield put(actions.removeTeamMemberPending());
        yield call(deleteRemoveTeamMember, teamId, userEmail, planId, planType);
        yield put(actions.removeTeamMemberSuccess());
        yield put(actions.removeTeamMember(userId));
    } catch (err) {
        yield put(actions.removeTeamMemberFailure());
    }
}
function* getTeam(search, teamId) {
    try {
        yield put(actions.getTeamPending());
        const response = yield call(fetchTeam, search, teamId);
        yield put(actions.getTeamSuccess());
        yield put(actions.setTeam(response.data));
    } catch (err) {
        yield put(actions.getTeamFailure());
    }
}

export {
    getSubscriptions,
    createSubscription,
    updateSubscription,
    getSubscriptionUser,
    deleteSubscription,
    changeTeamOwner,
    resendUserInvitation,
    removeTeamMember,
    resendEmailVerification,
    createTeamMember,
    getTeam,
    addExistingUser
};