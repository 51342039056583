const getAdminUsersSelector = state => state.adminusers.pool;
const getAdminUserstatusSelector = state => state.adminusers.getAdminUserstatus;
const updateAdminUserStatusSelector = state => state.adminusers.updateAdminUserStatus;
const createAdminUserStatusSelector = state => state.adminusers.createAdminUserStatus;
const createAdminUserFailureMessageSelector = state => state.adminusers.createAdminUserFailureMessage;
const deleteAdminUserStatusSelector = state => state.adminusers.deleteAdminUserStatus;

export {
    getAdminUsersSelector,
    updateAdminUserStatusSelector,
    createAdminUserStatusSelector,
    getAdminUserstatusSelector,
    createAdminUserFailureMessageSelector,
    deleteAdminUserStatusSelector
};