import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing, typography }) => createStyles({
    priorityTitle: {
        display: "flex",
        alignItems: "center"
    },
    priority: {
        backgroundColor: palette.error.main,
        borderRadius: "50%",
        width: spacing(2),
        height: spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: palette.common.white,
        marginRight: spacing(1)
    },
    title: {},
    selectedCard: {
        backgroundColor: palette.primary.backgroundDark,
    },
    companyName: {
        textDecoration: "none",
        color: "#333333"
    }
}));