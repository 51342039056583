import React, { useEffect, useState } from "react";
import moment from 'moment';
import classnames from "classnames";
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//Components
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import TextField from "../../../../components/TextField/TextField";
import Multiselect from "../../../../components/Multiselect/Multiselect";
import FiltersModal from "../modals/FilterModal/FiltersModal";
import AddNewCompanyModal from '../modals/AddNewCompany/AddNewCompanyModal';
//Assets
import ArrowLeft from "../../../../assets/icons/ArrowLeft.png";
import { ReactComponent as SearchPlaceholderIcon } from "../../../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/ArrowDownBlue.svg";
import { useRouterHook } from "../../../../common/useRouterHook";
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { releaseCompaniesRequest, postGetClosestRequest } from "../../../../store/technologies/requests";
import { deleteRawMLOutputStatusSelector, getPostGetClosestStatusSelector } from "../../../../store/technologies/selectors";
const Header = ({
    releasedInfo,
    technology,
    onSearch,
    onSortChange,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    searchResults,
    onFilterSelected,
    filters,
    allResults,
    total,
    resetFilters,
    stageOptions,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamSizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    valuerScoreMin,
    valuerScoreMax,
    handleFieldChange,
    handleSectorChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleValuerScoreMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    allCountries,
    setDisplayedFilters,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const sortOptions = [
        { id: 0, name: "Match", slug: "match" },
        { id: 1, name: "Valuer score", slug: "valuer_score" },
        { id: 2, name: "Success", slug: "success_potential_score" },
        { id: 3, name: "Growth", slug: "growth_score" },
        { id: 4, name: "Maturity", slug: "market_maturity_score" },
        { id: 5, name: "Innovation", slug: "innovation_score" },
        { id: 6, name: "Sustainability", slug: "sustainability_score" },
        { id: 7, name: "Data completeness", slug: "completeness_score" },
    ]
    const [sortValue, setSortValue] = useState(sortOptions[0]);
    const [sortDirection, setSortDirection] = useState("desc");
    const [filtersModalOpened, setFiltersModalOpened] = useState(false);
    const [showAddNewCompanyModal, setShowAddNewCompanyModal] = useState(false);
    const deleteRawMLStatusSelector = useSelector(deleteRawMLOutputStatusSelector);
    const getClosestStatus = useSelector(getPostGetClosestStatusSelector);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        onSearch(event.target.value);
    };
    const changeSortDirection = () => {
        setSortDirection(prevSortDirection => prevSortDirection === "asc" ? "desc" : "asc");
    };
    const handleSelectChange = (event) => {
        setSortValue(sortOptions.find(option => option.name === event.target.value));
        onSortChange(sortOptions.find(option => option.name === event.target.value).slug, sortDirection);
    };
    const openFiltersModal = () => {
        setModalFilters();
        setSelectedFilters(displayedFilters);
        setFiltersModalOpened(true);
    };
    const handleFiltersModalClose = () => {
        setFiltersModalOpened(!filtersModalOpened);
    }
    const handleShowResults = (value) => {
        setDisplayedFilters(selectedFilters);
    }
    const handleShowFilters = (value) => {
        onFilterSelected(value);
    };

    const handleRequestNew = () => {
        technology && window.confirm("Are you sure you want to request new batch?") && dispatch(postGetClosestRequest({ technologyDescription: technology.ml_description, id: technology.id }))
    };
    const handleReady = () => {
        technology && window.confirm("Are you sure you want to release the selected companies?") && dispatch(releaseCompaniesRequest({ id: technology.id }));
    };
    
    const toggleAddNewCompanyModal = (value) => {
        setShowAddNewCompanyModal(value)
    }
    
    const goBack = () => {
        let id = localStorage.getItem('technology-id');
        openPage(`/technologies-profile/${id}`);
    };

    useEffect(() => {
        onSortChange(sortValue.slug, sortDirection)
    }, [sortDirection]);

    return (
        <div className={classes.root}>
            <div className={classes.nav}>
                <div className={classes.goBack} onClick={() => goBack()}>
                    <img src={ArrowLeft} alt={t("board.goBack")} />
                    <Typography color="secondaryDark" variant="bodyButton">{t("board.goBack")}</Typography>
                </div>
                {releasedInfo && <div>
                    <Typography variant="body">{t("board.lastUpdate")} </Typography>
                    <Typography variant="body">{releasedInfo && releasedInfo.date} - {releasedInfo && releasedInfo.user && releasedInfo.user.email}</Typography>
                </div>}
            </div>
            <div className={classes.infoActions}>
                <div className={classes.breadCrumb}>
                    <div className={classes.breadCrumbSubIndustry}>
                        <Typography variant="bodyXs" color="textLight" color="textLighter">{t("board.technologyTitle")}</Typography>
                        <Typography variant="subtitle1" color="textLighter">{technology && technology.name}</Typography>
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button onClick={handleRequestNew} className={classes.request100Button} variant="tertiary" testId="request-button">{t("board.request100")}</Button>
                    <Button onClick={handleReady} variant="primary" testId="ready-button">{t("board.ready")}</Button>
                    <Button onClick={() => setShowAddNewCompanyModal(true)} className={classes.addNewCompanyBtn}>Add ML Company</Button>

                </div>
            </div>
            <div className={classes.searchFilters}>
                <div className={classes.header}>
                    <div className={classes.refineAndSearch}>
                        <TextField endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField>
                    </div>
                    <div className={classes.filterWrap}>
                        <Typography color="secondaryDark" className={classes.sortedBy} variant="bodyButton">{t("board.sortedBy")}</Typography>
                        <Multiselect customClass={classes.multiselectAdjust} removeLabel iconArrow={<ArrowIcon onClick={() => changeSortDirection()} className={classnames([classes.arrowIcon, { [classes.rotateIcon]: sortDirection === "asc" }])} />} options={sortOptions} value={sortValue.name} onChange={handleSelectChange}></Multiselect>
                    </div>
                    <div className={classes.filterWrap}>
                        <Button
                            iconMarginLeft
                            variant="textSecondary"
                            className={classes.filterButton}
                            onClick={() => openFiltersModal()}
                        >
                            {t("board.filters")}
                            <ArrowIcon />
                        </Button>
                    </div>
                </div>
            </div>
            {filtersModalOpened && <FiltersModal
                results={searchResults}
                dialogOpened={filtersModalOpened}
                onClose={handleFiltersModalClose}
                onShowResults={handleShowResults}
                onShowFilters={handleShowFilters}
                filters={filters}
                allPossibleResults={allResults}
                resetFilters={resetFilters}
                stageOptions={stageOptions}
                fundingOptionsMin={fundingOptionsMin}
                fundingOptionsMax={fundingOptionsMax}
                yearOptionsMin={yearOptionsMin}
                yearOptionsMax={yearOptionsMax}
                teamSizeOptions={teamSizeOptions}
                valuerScoreOptionsMin={valuerScoreOptionsMin}
                valuerScoreOptionsMax={valuerScoreOptionsMax}
                valuerScoreMin={valuerScoreMin}
                valuerScoreMax={valuerScoreMax}
                handleFieldChange={handleFieldChange}
                handleSectorChange={handleSectorChange}
                resetFilter={resetFilter}
                handleFundingMinChange={handleFundingMinChange}
                handleYearMinChange={handleYearMinChange}
                handleValuerScoreMinChange={handleValuerScoreMinChange}
                allSectors={allSectors}
                allSubsectors={allSubsectors}
                selectedFilters={selectedFilters}
                allCountries={allCountries}
                displayedFilters={displayedFilters}
            />}
            {
                showAddNewCompanyModal && <AddNewCompanyModal techId={technology.id} toggleAddNewCompanyModal={ (value) => toggleAddNewCompanyModal(value)} />
            }
        </div>
    )
};
export default Header;