const getAllTechnologiesStatusSelector = state => state.technologies.getTechnologiesStatus;
const getAlltechnologiesStateSelector = state => state.technologies.pool;
const getAllTechnologiesTotalCountState = state => state.technologies.totalCount;
const createTechnologyStatusSelector = state => state.technologies.createTechnologyStatus;
const createTechnologyStateResponseSelector = state => state.technologies.createdTechnologyResponse;
const updateTechnologyStatusSelector = state => state.technologies.updateTechnologyStatus;
const publishStatusSelector = state => state.technologies.publishTechnologyStatus;
const unPublishStatusSelector = state => state.technologies.unPublishTechnologyStatus;
const deleteTechnologyStatusSelector = state => state.technologies.deleteTechnologyStatus;
const getTechnologyProfileStatus = state => state.technologies.getTechnologyStatus;
const getTechnologyProfileState = state => state.technologies.technologyProfile;
const getPostGetClosestStatusSelector = state => state.technologies.postGetClosestStatus;
const postSaveCompaniesDataStatusSelector = state => state.technologies.postSaveCompaniesDataStatus;
const getTechnologySelector = state => state.technologies.technology;
const getTechnologyStatusSelector = state => state.technologies.getTechnologyWithCompaniesStatus;
const getLastReleaseInfoSelector = state => state.technologies.lastReleaseInfo;
const deleteRawMLOutputStatusSelector = state => state.technologies.deleteRawMLOutputStatus;
const updateTechnologyKanbanStatusSelector = state => state.technologies.updateTechnologyKanbanStatus;
const releaseCompaniesStatusSelector = state => state.technologies.releaseCompaniesStatus;
const updateTechnologyKanbanStateStatusSelector = state => state.technologies.updateTechnologyKanbanStateStatus;
const getTechnologyCompaniesSelector = state => state.technologies.technologyCompanies;
const getTotalCountSelector = state => state.technologies.totalCount;
const getTechnologyCompaniesTotalPagesSelector = state => state.technologies.totalPages;
const getTechnologiesStatusCodeSelector = state => state.technologies.technologiesStatusCode;

export {
    getAllTechnologiesStatusSelector,
    getAlltechnologiesStateSelector,
    getAllTechnologiesTotalCountState,
    createTechnologyStatusSelector,
    updateTechnologyStatusSelector,
    publishStatusSelector,
    unPublishStatusSelector,
    deleteTechnologyStatusSelector,
    getTechnologyProfileStatus,
    getTechnologyProfileState,
    getPostGetClosestStatusSelector,
    postSaveCompaniesDataStatusSelector,
    getTechnologySelector,
    getTechnologyStatusSelector,
    getLastReleaseInfoSelector,
    deleteRawMLOutputStatusSelector,
    createTechnologyStateResponseSelector,
    updateTechnologyKanbanStatusSelector,
    releaseCompaniesStatusSelector,
    updateTechnologyKanbanStateStatusSelector,
    getTechnologyCompaniesSelector,
    getTotalCountSelector,
    getTechnologyCompaniesTotalPagesSelector,
    getTechnologiesStatusCodeSelector
}