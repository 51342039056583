import { put, call, select } from "redux-saga/effects";
import { actions } from "../slice";
import { getSectors, getSubsectors } from "./helpers";
const getSearchSectors = state => state.search.sectors;

function* fetchSectors() {
    try {
        yield put(actions.fetchSectorsPending());
        const sectors = yield call(getSectors);
        yield put(actions.setSectors(sectors));
        yield put(actions.fetchSectorsSuccess());
    } catch (err) {
        yield put(actions.fetchSectorsFailure());
    }
}

function* fetchSubsectors(sectorsSlug) {
    try {
        yield put(actions.fetchSubsectorsPending());
        const response = yield call(getSubsectors, sectorsSlug);
        yield put(actions.setSubsectors(response.data[0]));
        yield put(actions.getAllSubsectors(response));
        yield put(actions.fetchSubsectorsSuccess());
    } catch (err) {
        yield put(actions.fetchSubsectorsFailure());
    }
}

export { fetchSectors, fetchSubsectors };
