import { call } from "redux-saga/effects";
import axios from "axios";
import {
    getUsersUrl,
    createUsersUrl,
    USERS_URL
} from "../../../constants/endpoints";

function* fetchUsers(data) {
    const response = yield call(axios, {
        method: "GET",
        url: `${getUsersUrl(data)}`,
        withCredentials: true
    });
    return response.data;
}

function* postCreateUser(data) {
    const response = yield call(axios, {
        method: "POST",
        url: `${createUsersUrl(data)}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* putUpdateUser(id, data) {
    const response = yield call(axios, {
        method: "PUT",
        url: `${USERS_URL}${id}`,
        data,
        withCredentials: true
    });
    return response.data;
}

function* getSingleUser(id) {
    const response = yield call(axios, {
        method: "GET",
        url: `${USERS_URL}${id}`,
        withCredentials: true
    });
    return response.data;
}

function* deleteSingleUser(id) {
    const response = yield call(axios, {
        method: "DELETE",
        url: `${USERS_URL}${id}`,
        withCredentials: true
    });
    return response.data;
}

export {
    fetchUsers,
    postCreateUser,
    putUpdateUser,
    getSingleUser,
    deleteSingleUser
};