import { createAction } from "@reduxjs/toolkit";
import { REDUCER_PREFIX } from "./slice";

export const getSubscriptionsRequest = createAction(
    `${REDUCER_PREFIX}/getSubscriptionsRequest`
);
export const createSubscriptionRequest = createAction(
    `${REDUCER_PREFIX}/createSubscriptionRequest`
);
export const updateSubscriptionRequest = createAction(
    `${REDUCER_PREFIX}/updateSubscriptionRequest`
);
export const getSubscriptionUserRequest = createAction(
    `${REDUCER_PREFIX}/getSubscriptionUserRequest`
);
export const deleteSubscriptionRequest = createAction(
    `${REDUCER_PREFIX}/deleteSubscriptionRequest`
);
export const changeTeamOwnerRequest = createAction(
    `${REDUCER_PREFIX}/changeTeamOwnerRequest`
);
export const resendUserInvitationRequest = createAction(
    `${REDUCER_PREFIX}/resendUserInvitationRequest`
);
export const resendEmailVerificationRequest = createAction(
    `${REDUCER_PREFIX}/resendEmailVerificationRequest`
);
export const removeTeamMemberRequest = createAction(
    `${REDUCER_PREFIX}/removeTeamMemberRequest`
);
export const createTeamMemberRequest = createAction(
    `${REDUCER_PREFIX}/createTeamMemberRequest`
);
export const addExistingUserRequest = createAction(
    `${REDUCER_PREFIX}/addExistingUserRequest`
);
export const getTeamRequest = createAction(
    `${REDUCER_PREFIX}/getTeamRequest`
);