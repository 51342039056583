import React, { useState } from "react";
import classnames from "classnames";
//Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
import { useRouterHook } from "../../../../../common/useRouterHook"
//Components
import Typography from "../../../../../components/Typography/Typography";
import Button from "../../../../../components/Button/Button";
import TextField from "../../../../../components/TextField/TextField";
import Multiselect from "../../../../../components/Multiselect/Multiselect";
import FiltersModal from "../../../../IndustriesBoard/components/modals/FilterModal/FiltersModal";
//Assets
import { ReactComponent as SearchPlaceholderIcon } from "../../../../../assets/icons/SearchPlaceholderIcon.svg";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/ArrowDownBlue.svg";
//Redux
import { getTotalCountSelector } from "../../../../../store/technologies/selectors";
import { useSelector } from "react-redux";

const Header = ({
    profile,
    onSearch,
    onSortChange,
    setModalFilters,
    setSelectedFilters,
    displayedFilters,
    searchResults,
    onFilterSelected,
    filters,
    allResults,
    resetFilters,
    stageOptions,
    fundingOptionsMin,
    fundingOptionsMax,
    yearOptionsMin,
    yearOptionsMax,
    teamSizeOptions,
    valuerScoreOptionsMin,
    valuerScoreOptionsMax,
    valuerScoreMin,
    valuerScoreMax,
    handleFieldChange,
    handleSectorChange,
    resetFilter,
    handleFundingMinChange,
    handleYearMinChange,
    handleValuerScoreMinChange,
    allSectors,
    allSubsectors,
    selectedFilters,
    allCountries,
    setDisplayedFilters,
    user
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const { openPage } = useRouterHook();
    const sortOptions = [
        { id: 0, name: "Match", slug: "match" },
        { id: 1, name: "Valuer score", slug: "valuer_score" },
        { id: 2, name: "Success", slug: "success_potential_score" },
        { id: 3, name: "Growth", slug: "growth_score" },
        { id: 4, name: "Maturity", slug: "market_maturity_score" },
        { id: 5, name: "Innovation", slug: "innovation_score" },
        { id: 6, name: "Sustainability", slug: "sustainability_score" },
        { id: 7, name: "Data completeness", slug: "completeness_score" },
    ]
    const [sortValue, setSortValue] = useState(sortOptions[0]);
    const [sortDirection, setSortDirection] = useState("desc");
    const [filtersModalOpened, setFiltersModalOpened] = useState(false);
    const getTotalCount = useSelector(getTotalCountSelector);

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
        onSearch(event.target.value);
    };
    const changeSortDirection = () => {
        setSortDirection(prevSortDirection => prevSortDirection === "asc" ? "desc" : "asc");
        onSortChange(sortValue.slug, sortDirection === "asc" ? "desc" : "asc");
    };
    const handleSelectChange = (event) => {
        setSortValue(sortOptions.find(option => option.name === event.target.value));
        onSortChange(sortOptions.find(option => option.name === event.target.value).slug, sortDirection);
    };
    const openFiltersModal = () => {
        setModalFilters();
        setSelectedFilters(displayedFilters);
        setFiltersModalOpened(true);
    };
    const handleFiltersModalClose = () => {
        setFiltersModalOpened(!filtersModalOpened);
    }
    const handleShowResults = (value) => {
        setDisplayedFilters(selectedFilters);
    }
    const handleShowFilters = (value) => {
        onFilterSelected(value);
    };

    const handleRequestNew = () => {
        console.log("request new 100");
    };
    const handleSave = () => {
        console.log("Saving....");
    };
    const openTechnologyKanban = () => {
        openPage(`/technologies-profile/${profile.id}/technologies-board`)
    };

    return (
        <div className={classes.root}>
            <div className={classes.searchFilters}>
                <div className={classes.header}>
                    <TextField endAdornment={<SearchPlaceholderIcon className={classes.startEndornment} />} value={searchValue} className={classes.headerInput} onChange={handleSearch}></TextField>
                    <div className={classes.filterWrap}>
                        <Typography color="secondaryDark" className={classes.sortedBy} variant="bodyButton">{t("board.sortedBy")}</Typography>
                        <Multiselect customClass={classes.multiselectAdjust} removeLabel iconArrow={<ArrowIcon onClick={() => changeSortDirection()} className={classnames([classes.arrowIcon, { [classes.rotateIcon]: sortDirection === "asc" }])} />} options={sortOptions} value={sortValue.name} onChange={handleSelectChange}></Multiselect>
                    </div>
                    {/* <div className={classes.filterWrap}>
                        <Button
                            iconMarginLeft
                            variant="textSecondary"
                            className={classes.filterButton}
                            onClick={() => openFiltersModal()}
                        >
                            {t("board.filters")}
                            <ArrowIcon />
                        </Button>
                    </div> */}
                </div>
                <div className={classes.numButton}>
                    <Typography variant="label">{t("technologyProfile.numCompanies", { number: getTotalCount })}</Typography>
                    <Button disabled={user && user.role !== "admin" && user.role !== "super_admin" && user.role !== "editor"} onClick={openTechnologyKanban} variant="primary">{t("board.editCompaniesKanban")}</Button>
                </div>
            </div>
            {filtersModalOpened && <FiltersModal
                results={searchResults}
                dialogOpened={filtersModalOpened}
                onClose={handleFiltersModalClose}
                onShowResults={handleShowResults}
                onShowFilters={handleShowFilters}
                filters={filters}
                allPossibleResults={allResults}
                resetFilters={resetFilters}
                stageOptions={stageOptions}
                fundingOptionsMin={fundingOptionsMin}
                fundingOptionsMax={fundingOptionsMax}
                yearOptionsMin={yearOptionsMin}
                yearOptionsMax={yearOptionsMax}
                teamSizeOptions={teamSizeOptions}
                valuerScoreOptionsMin={valuerScoreOptionsMin}
                valuerScoreOptionsMax={valuerScoreOptionsMax}
                valuerScoreMin={valuerScoreMin}
                valuerScoreMax={valuerScoreMax}
                handleFieldChange={handleFieldChange}
                handleSectorChange={handleSectorChange}
                resetFilter={resetFilter}
                handleFundingMinChange={handleFundingMinChange}
                handleYearMinChange={handleYearMinChange}
                handleValuerScoreMinChange={handleValuerScoreMinChange}
                allSectors={allSectors}
                allSubsectors={allSubsectors}
                selectedFilters={selectedFilters}
                allCountries={allCountries}
                displayedFilters={displayedFilters}
            />}
        </div>
    )
};
export default Header;