import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    getUserStatus: REQUEST_UNDEFINED,
    createUserStatus: REQUEST_UNDEFINED,
    updateUserStatus: REQUEST_UNDEFINED,
    deleteUserStatus: REQUEST_UNDEFINED,
    subscriptionUser: {},
    createUserMessageFailure: null
};

export const REDUCER_PREFIX = "userprofiles";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getUsersPending: state => {
            state.getUserStatus = REQUEST_PENDING;
        },
        getUsersSuccess: state => {
            state.getUserStatus = REQUEST_SUCCESS;
        },
        getUsersFailure: state => {
            state.getUserStatus = REQUEST_FAILURE;
        },
        setUsers: (state, { payload }) => {
            state.pool = payload.users.results;
        },
        createUserPending: state => {
            state.createUserStatus = REQUEST_PENDING;
        },
        createUserSuccess: state => {
            state.createUserStatus = REQUEST_SUCCESS;
            state.createUserMessageFailure = null;
        },
        createUserFailure: (state, { payload }) => {
            state.createUserStatus = REQUEST_FAILURE;
            state.createUserMessageFailure = payload;
        },
        clearCreateUserMessageFailure: state => {
            state.createUserMessageFailure = null;
        },
        updateUserPending: state => {
            state.updateUserStatus = REQUEST_PENDING;
        },
        updateUserSuccess: (state, { payload }) => {
            state.updateUserStatus = REQUEST_SUCCESS;
        },
        updateUserFailure: state => {
            state.updateUserStatus = REQUEST_FAILURE;
        },
        updateUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            var objIndex = stateObj.findIndex(obj => {
                return obj.id === payload.id
              })
            stateObj[objIndex] = payload;
            state.pool.users.results = stateObj;
        },
        addUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            stateObj.unshift(payload.user);
            state.pool.users.results = stateObj;
        },
        addSubscriptionUser: (state, { payload }) => {
            state.subscriptionUser = payload.user;
        },
        deleteUser: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            stateObj.filter( el => el.id !== payload );
            state.pool.users.results = stateObj;
        },
        getUserPending: state => {
            state.getUserStatus = REQUEST_PENDING;
        },
        getUserSuccess: (state, { payload }) => {
            state.getUserStatus = REQUEST_SUCCESS;
        },
        getUserFailure: state => {
            state.getUserStatus = REQUEST_FAILURE;
        },
        deleteUserPending: state => {
            state.deleteUserStatus = REQUEST_PENDING;
        },
        deleteUserSuccess: (state, { payload }) => {
            state.deleteUserStatus = REQUEST_SUCCESS;
        },
        deleteUserFailure: state => {
            state.deleteUserStatus = REQUEST_FAILURE;
        },
        addSubscription: (state, { payload }) => {
            let stateObj = [...state.pool.users.results];
            stateObj.unshift(payload.user);
            state.pool.users.results = stateObj;
        },
    }
});
export { reducer, actions };