import React, { useEffect, useState } from "react";
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../Typography/Typography.js";
import Button from "../Button/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//UI Components
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
//Assets
import CloseIcon from '../../assets/icons/CloseIcon.svg';

const AreYouSureModal = ({ dialogOpened, titleCopy, confirmCopy, onConfirm, onChange }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(window.innerWidth <= 575)
    }, [])

    return (
        <Dialog
            fullScreen={isMobile}
            onClose={onChange}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialog, paperScrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    onChange(event, reason);
                }
            }}
            open={dialogOpened}
        >
            <IconButton className={classes.closeButton} onClick={onChange}>
                <img src={CloseIcon} alt={t('scope.step2')} className={classes.stepIcon} />
            </IconButton>
            <MuiDialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant="subtitle1">{titleCopy}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <Typography className={classes.dialogValue} color="secondaryDark" align="center" variant="body" component="div">{t('common.irreversibleAction')}</Typography>
            </MuiDialogContent>
            <Box display="flex" alignItems="center" justifyContent="space-evenly" className={classes.dialodCtas}>
                <Button variant="error" onClick={onConfirm}>{confirmCopy}</Button>
                <Button className={classes.deleteButton} variant="secondary" onClick={onChange}>{t('common.noTakeMeBack')}</Button>
            </Box>
        </Dialog >
    );
};
export default AreYouSureModal;
