export const Shared = ({ radar, getUser, radars }) => {
    const calculateCompanyScore = company => {
        let sum =
            company.success_potential_score +
            company.growth_score +
            company.maturity_score +
            company.innovation_score +
            company.sustainability_score;
        return (sum / 5) * 100;
    };
    const calculateAverageScore = companies => {
        let index = 0;
        let tempRadar = {
            ...radar
        };
        tempRadar.average_score = 0;
        tempRadar.sectors = [];
        if (companies && companies.length > 0) {
            companies.forEach((company) => {
                index++;
                tempRadar.average_score += calculateCompanyScore(company);
                tempRadar.sectors.push(...company.sectors);

            });
            tempRadar.sectors = [...new Set(tempRadar.sectors)];
            tempRadar.average_score = (tempRadar.average_score / index).toFixed();
        }
        return tempRadar.average_score;
    };
    const getRadarAverageScore = () => {
        let score = 0;
        radar.companies && radar.companies.forEach((company) => {
            score += calculateCompanyScore(company);
        });
        return (score / (radar.companies && radar.companies.length) || 0).toFixed();
    };

    const keyCodes = {
        comma: 188,
        enter: [10, 13],
    };
    const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

    const formatNumber = value => {
        return value ? Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
    };
    const isRadarsLimitReached = () => {
        if (getUser && getUser.subscription) {
            const { radars_available } = getUser.subscription;
            return radars && radars.length >= radars_available;
        }
        return false;
    };
    const checkUserSubscription = subscription => {
        return getUser && getUser.subscription && getUser.subscription.name === subscription;
    };

    function hasOnlySearchValue(obj) {
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "" && key !== "search")
                return false;
        }
        return true;
    }
    return {
        calculateAverageScore,
        calculateCompanyScore,
        getRadarAverageScore,
        keyCodes,
        emailReg,
        formatNumber,
        isRadarsLimitReached,
        checkUserSubscription,
        hasOnlySearchValue
    }
}