import React, { useEffect, useState } from "react";
// UI components
import Box from '@material-ui/core/Box';
import Typography from "../../../../components/Typography/Typography";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import Button from "../../../../components/Button/Button";
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
//Assets 
import GreenCheckMark from "../../../../assets/icons/GreenCheckMark.png"
import OrangeCheckMark from "../../../../assets/icons/OrangeCheckMark.png"
import CloseIcon from '../../../../assets/icons/CloseIcon.svg';

const WarningModal = ({ onChange, dialogOpened, type, data, onConfirmAction, onCancelAction }) => {
    const { t } = useTranslation();
    const [colorSwitch, setColorSwitch] = useState(false);
    const classes = useStyles({ colorSwitch });
    const [description, setDescription] = useState("");
    const [icon, setIcon] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const [showUnsaveButtons, setShowUnsaveButtons] = useState(false);
    const [confirmButtonText, setConfirmButtonText] = useState("");
    const [cancelButtonText, setCancelButtonText] = useState("");


    const confirmAction = () => {
        onConfirmAction(type);
    };
    const cancelAction = () => {
        onCancelAction()
    };
    const goBackAction = () => {
        onCancelAction();
    };
    const dontSaveAction = () => {
        onChange();
    };
    const closeWarningModal = () => {
        onChange(type);
    };
    useEffect(() => {
        type === "edit_success" && setShowUnsaveButtons(false);
    }, [type])
    useEffect(() => {
        switch (type) {
            case "create_success":
                setDescription(t("warningModal.successWhenCreated", { name: data.name }));
                setIcon(GreenCheckMark);
                setColorSwitch(false);
                setShowButtons(false);
                break;
            case "edit_success":
                setDescription(t("warningModal.successWhenEdit"));
                setIcon(GreenCheckMark);
                setColorSwitch(false);
                setShowButtons(false)
                break;
            case "unsaved":
                setDescription(t("warningModal.unsavedChangesText"));
                setColorSwitch(true);
                setIcon(null);
                setShowButtons(false);
                setShowUnsaveButtons(true);
                setConfirmButtonText(t("warningModal.saveChanges"));
                setCancelButtonText(t("warningModal.dontSave"));
                break;
            case "publish":
                setDescription(t("warningModal.publish", { name: data.name }));
                setColorSwitch(null);
                setIcon(null);
                setShowButtons(true);
                setConfirmButtonText(t("warningModal.yesPublish"));
                setCancelButtonText(t("warningModal.noCancel"));
                break;
            case "unpublish":
                setDescription(t("warningModal.unpublish", { name: data.name }));
                setIcon(null);
                setColorSwitch(true);
                setShowButtons(true);
                setConfirmButtonText(t("warningModal.yesUnpublish"));
                setCancelButtonText(t("warningModal.noCancel"));
                break;
            case "publish_success":
                setDescription(t("warningModal.publishSuccessText", { name: data.name }));
                setIcon(GreenCheckMark);
                setColorSwitch(false);
                setShowButtons(false);
                break;
            case "unpublish_success":
                setDescription(t("warningModal.unpublishSuccessText", { name: data.name }));
                setIcon(OrangeCheckMark);
                setShowButtons(false);
                setColorSwitch(true);
                break;
            case "delete":
                setDescription(t("warningModal.deleteText", { name: data.name }));
                setIcon(null);
                setColorSwitch(true);
                setShowButtons(true);
                setConfirmButtonText(t("warningModal.yesDelete"));
                setCancelButtonText(t("warningModal.noCancel"));
                break;
            case "delete_success":
                setDescription(t("warningModal.deleteSuccessText"));
                setIcon(OrangeCheckMark);
                setColorSwitch(true);
                setShowButtons(false);
                break;
        }
    }, [type])
    return (
        <Dialog
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialogFilter, scrollPaper: classes.dialogScrollPaper }}
            onClose={closeWarningModal}
            open={dialogOpened}>
            {type !== "unsaved" && <IconButton className={classes.closeButton} onClick={closeWarningModal}>
                <img src={CloseIcon} alt={t('warningModal.closeIcon')} className={classes.stepIcon} />
            </IconButton>}
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.content}>
                    <Typography variant="subtitle1" color="secondaryDark">{description}</Typography>
                    {icon && <img className={classes.checkIcon} src={icon} alt={t("warningModal.checkIcon")} />}
                </div>
                {showButtons && <div className={classes.buttons}>
                    <Button onClick={confirmAction} className={classes.confirmButton}>{confirmButtonText}</Button>
                    <Button onClick={cancelAction} variant="secondary">{cancelButtonText}</Button>
                </div>}
                {
                    showUnsaveButtons && <div className={classes.buttons}>
                        <Button onClick={goBackAction} className={classes.goBack} variant="tertiary">{t("warningModal.goBack")}</Button>
                        <Button onClick={dontSaveAction} className={classes.dontSave} variant="tertiary">{cancelButtonText}</Button>
                        <Button onClick={confirmAction} variant="secondary" className={classes.saveButton}>{confirmButtonText}</Button>

                    </div>
                }

            </MuiDialogContent>
        </Dialog>
    );

}
export default WarningModal;