import React from "react";
// UI components
import Typography from "../../../../../components/Typography/Typography";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import Button from "../../../../../components/Button/Button";
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";

const WarningModal = ({ onClose, dialogOpened, onYes, warningTitle, warningSubtitle }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const handleYes = () => {
        onYes()
    }

    return (
        <Dialog
            onClose={onClose}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialogFilter, scrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    onClose(event, reason);
                }
              }}
            open={dialogOpened}>
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" className={classes.dialogTitle} align="center" component="div"><strong>{warningTitle}</strong></Typography>
                <Typography variant="body" className={classes.dialogSubtitle} align="center" component="div">{warningSubtitle}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.ctas}>
                    <Button onClick={handleYes} className={classes.confirmBtn}>{t("industryProfile.yesSure")}</Button>
                    <Button onClick={onClose} variant="secondary">{t("industryProfile.noBack")}</Button>
                </div>
            </MuiDialogContent>
        </Dialog>
    );

}
export default WarningModal;