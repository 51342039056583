import React from "react";
// UI components
import Typography from "../../../../../components/Typography/Typography";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import Button from "../../../../../components/Button/Button";
// Hooks
import { useTranslation } from 'react-i18next';
import { useStyles } from "./style";
import { useRouterHook } from "../../../../../common/useRouterHook";
// Assets
import { ReactComponent as Success } from "../../../../../assets/icons/Success.svg";

const SuccessModal = ({ onClose, dialogOpened, backToSearchPage }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openPage } = useRouterHook();
    const handleGoBack = () => {
        if (!!backToSearchPage) {
            openPage('/results')
        } else {
            window.location.href = "https://www.valuer.ai";
        }
    };
    return (
        <Dialog
            onClose={onClose}
            maxWidth="lg"
            TransitionComponent={Grow}
            classes={{ root: classes.dialogRoot, paper: classes.dialogFilter, scrollPaper: classes.dialogScrollPaper }}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    onClose(event, reason);
                }
              }}
            open={dialogOpened}>
            <MuiDialogTitle disableTypography className={classes.dialogTitleWrapper}>
                <Typography variant="subtitle1" className={classes.dialogTitle} align="center" component="div"><strong>{t('industryProfile.successTitle')}</strong></Typography>
                <Typography variant="body" className={classes.dialogSubtitle} align="center" component="div">{t('industryProfile.successSubtitle')}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent className={classes.dialogContent}>
                <div className={classes.ctas}>
                    <Success />
                </div>
            </MuiDialogContent>
        </Dialog>
    );

}
export default SuccessModal;