import { createContext, useEffect, useState, useRef } from "react";
// Hooks
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { getMeRequest } from "../../store/auth/requests";
import {
    getLogoutStatusSelector,
    getLoginStatusSelector,
    getUserSelector
} from "../../store/auth/selectors";
import {
    REQUEST_SUCCESS,
    REQUEST_FAILURE,
    REQUEST_PENDING,
    REQUEST_UNDEFINED
} from "../../constants/statuses";
export const UserContext = createContext();

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  }

export const UserProvider = ({ children }) => {
    const dispatch = useDispatch();
    const logoutStatus = useSelector(getLogoutStatusSelector);
    const userState = useSelector(getUserSelector);
    const loginStatus = useSelector(getLoginStatusSelector);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const prevLogoutStatus = usePrevious(logoutStatus);
    const prevLoginStatus = usePrevious(loginStatus);
    const [user, setUser] = useState({});

    useEffect(() => {
        if (loginStatus !== REQUEST_PENDING && logoutStatus !== REQUEST_PENDING && logoutStatus !== REQUEST_SUCCESS) {
          dispatch(getMeRequest());
        }
      }, [dispatch, loginStatus, logoutStatus])
    // Login updates the user data with a name parameter
    const login = us => {
        setIsAuthenticated(true);
        setUser(us);
    };

    // Logout updates the user data to default
    const logout = () => {
        setUser(null);
        setIsAuthenticated(false);
    };

    return (
        <UserContext.Provider value={{ user, isAuthenticated, login, logout }}>
            {children}
        </UserContext.Provider>
    );
}
