import React, { useEffect, useState } from 'react';
// Hooks
import useStyles from './style';
import { useTranslation } from 'react-i18next';
import { useRouterHook } from '../../common/useRouterHook';
import { useAuthHook } from '../../common/useAuthHook';
//Components
import Header from './components/Header/Header';
import TechnologyCard from './components/TechnologyCard/TechnologyCard';
import WarningModal from './components/WarningModal/WarningModal';
import AddNewTechnologyModal from './components/AddNewTechnologyModal/AddNewTechnologyModal';
import ContentLoader from '../../components/ContentLoader/ContentLoader';
//Redux
import {
    deleteTechnologyRequest,
    fetchTechnologiesRequest,
    publishTechnologyRequest,
    unPublishTechnologyRequest
} from '../../store/technologies/requests';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllTechnologiesStatusSelector,
    getAlltechnologiesStateSelector,
    publishStatusSelector,
    unPublishStatusSelector,
    deleteTechnologyStatusSelector
} from '../../store/technologies/selectors';
import { REQUEST_PENDING, REQUEST_SUCCESS } from '../../constants/statuses';
import {
    updateTechnologyRequest,
    createTechnologyRequest
} from '../../store/technologies/requests';

const Technologies = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();
    const dispatch = useDispatch();
    const { user } = useAuthHook();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [warningModalType, setWarningModalType] = useState('');
    const [editData, setEditData] = useState(null);
    const [warningModalData, setWarningModalData] = useState(null);
    const technologiesSelector = useSelector(getAlltechnologiesStateSelector);
    const [allTechnologies, setAllTechnologies] = useState(technologiesSelector);
    const getAllTechnologiesStatus = useSelector(getAllTechnologiesStatusSelector);

    const publishStatus = useSelector(publishStatusSelector);
    const unPublishStatus = useSelector(unPublishStatusSelector);
    const deleteStatus = useSelector(deleteTechnologyStatusSelector);
    const [unsavedData, setUnsavedData] = useState(null);

    useEffect(() => {
        dispatch(fetchTechnologiesRequest());
    }, [dispatch]);
    useEffect(() => {
        setAllTechnologies(technologiesSelector);
    }, [technologiesSelector]);

    const handleClosingWarningModal = () => {
        setShowWarningModal(false);
        window.location.reload();
    };
    const handleOnDataEdited = () => {
        setShowAddNewModal(false);
        setShowWarningModal(true);
        setWarningModalType('edit_success');
    };
    const handleOnDataCreated = (isMLSave, data) => {
        setShowAddNewModal(false);
        if (!isMLSave) {
            setShowWarningModal(true);
            setWarningModalData(data);
            setWarningModalType('create_success');
        } else {
            setShowWarningModal(false);
            openPage(`/technologies-board/${data.id}`);
        }
    };
    const handleClosingDialog = () => {
        setShowAddNewModal(false);
        setEditData(null);
    };
    const handleOnAddTechnology = () => {
        setShowAddNewModal(true);
    };
    const handleOnSaveML = () => {
        setShowAddNewModal(false);
        setShowWarningModal(true);
        setWarningModalType('create_success');
    };
    const handleToggleAction = (toggle, data) => {
        setWarningModalData(data);
        if (toggle) {
            setShowWarningModal(true);
            setWarningModalType('publish');
        } else {
            setShowWarningModal(true);
            setWarningModalType('unpublish');
        }
    };
    const handleConfirmAction = (val) => {
        let tech = {};
        switch (val) {
            case 'unsaved':
                tech = {
                    id: unsavedData.id,
                    name: unsavedData.title,
                    description: unsavedData.description
                };
                unsavedData && unsavedData.id
                    ? dispatch(updateTechnologyRequest(tech))
                    : dispatch(
                          createTechnologyRequest({
                              title: unsavedData.title,
                              description: unsavedData.description
                          })
                      );
                setShowWarningModal(true);
                setWarningModalType('edit_success');
                break;
            case 'publish':
                tech = { id: warningModalData.id, published: !warningModalData.published };
                dispatch(publishTechnologyRequest(tech));
                setShowWarningModal(true);
                setWarningModalType('publish_success');
                break;
            case 'unpublish':
                tech = { id: warningModalData.id, published: !warningModalData.published };
                dispatch(unPublishTechnologyRequest(tech));
                setShowWarningModal(true);
                setWarningModalType('unpublish_success');
                break;
            case 'delete':
                tech = { id: warningModalData.id };
                dispatch(deleteTechnologyRequest(tech));
                setShowWarningModal(true);
                setWarningModalType('delete_success');
                break;
        }
    };
    useEffect(() => {
        publishStatus === REQUEST_SUCCESS &&
            setShowWarningModal(true) &&
            setWarningModalType('publish_success');
        unPublishStatus === REQUEST_SUCCESS &&
            setShowWarningModal(true) &&
            setWarningModalType('unpublish_success');
    }, [publishStatus, unPublishStatus]);
    const handleDeleteAction = (technology) => {
        setWarningModalData(technology);
        setShowWarningModal(true);
        setWarningModalType('delete');
    };
    const handleEditTechnology = (val) => {
        setEditData(val);
        setShowAddNewModal(true);
    };
    const handleCancelAction = () => {
        setWarningModalData(null);
        setWarningModalType('');
        setShowWarningModal(false);
    };
    const handleDataChanged = (data) => {
        setUnsavedData(data);
        setShowWarningModal(true);
        setWarningModalType('unsaved');
    };
    const handleOnSearch = (searchValue) => {
        setTimeout(() => {
            if (searchValue) {
                let techs =
                    technologiesSelector &&
                    technologiesSelector.filter((f) => checkSearchCriteria(searchValue, f));
                setAllTechnologies(techs);
            } else {
                setAllTechnologies(technologiesSelector);
            }
        }, 500);
    };
    const checkSearchCriteria = (criteria, technology) => {
        let nameFound = false;
        let descriptionFound = false;

        nameFound = technology.name.toLowerCase().includes(criteria.toLowerCase());
        descriptionFound = technology.description.toLowerCase().includes(criteria.toLowerCase());

        return nameFound || descriptionFound;
    };

    return (
        <div className={classes.root}>
            <Header
                onAddTechnology={handleOnAddTechnology}
                onSearch={handleOnSearch}
                user={user}></Header>
            {getAllTechnologiesStatus !== REQUEST_PENDING ? (
                <>
                    <div className={classes.technologiesBlocks}>
                        {allTechnologies &&
                            allTechnologies.map((technology, i) => (
                                <TechnologyCard
                                    testId={i}
                                    user={user}
                                    key={`technology-${technology.id}`}
                                    technology={technology}
                                    onSwitchToggleAction={handleToggleAction}
                                    onDeleteTechnology={handleDeleteAction}
                                    onEditTechnology={handleEditTechnology}
                                />
                            ))}
                    </div>
                </>
            ) : (
                <>
                    <ContentLoader />
                </>
            )}
            <WarningModal
                type={warningModalType}
                dialogOpened={showWarningModal}
                onChange={handleClosingWarningModal}
                data={warningModalData}
                onConfirmAction={handleConfirmAction}
                onCancelAction={handleCancelAction}></WarningModal>
            <AddNewTechnologyModal
                data={editData}
                dialogOpened={showAddNewModal}
                onChange={handleClosingDialog}
                onDataEdited={handleOnDataEdited}
                onDataCreated={handleOnDataCreated}
                onSaveML={handleOnSaveML}
                dataChanged={handleDataChanged}></AddNewTechnologyModal>
        </div>
    );
};
export default Technologies;
