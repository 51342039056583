import React from "react";
import classnames from 'classnames';
import useStyles from "./style";

const Card =
    React.forwardRef(({
        className,
        children,
        active,
        variant,
        onClick,
        testId,
        ...props
    }, ref) => {
        const classes = useStyles();
        const cardClasses = classnames(
            className,
            classes.card,
            classes[variant]
        );

        return (
            <div
                className={cardClasses}
                ref={ref}
                onClick={onClick}
                test-id={testId}
                {...props}
            >
                {children}
            </div>
        )
    })

export default Card;
