import React, { useState, useEffect } from "react";
//Hooks
import { useAuthHook } from "../../../common/useAuthHook";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { getCountriesRequest } from "../../../store/countries/requests";
import { getCountriesSelector } from "../../../store/countries/selectors";

// import { getCompanySelector, getCompanyStatusSelector } from "../../../store/results/selectors";
// import { getPostSearchResponseSelector } from "../../../store/search/selectors";
//Shared
import { Shared } from "../../../utils/shared";
//Constants
import { stageOptions, teamSizeOptions } from "../../../constants/filters";
//Redux
import { updateCompanyKanbanRequest } from "../../../store/technologies/requests";
import { updateCompanyIndustryKanbanRequest } from "../../../store/industries/requests";

export const useEditCompanyHook = ({ handleClose, dialogOpened, companyData, isIndustry }) => {
    const { t } = useTranslation();
    let { slug } = useParams();
    let { search } = useLocation();
    const dispatch = useDispatch();
    const { isAuthenticated, getMeStatus, user } = useAuthHook();
    // const postSearchResponse = useSelector(getPostSearchResponseSelector);
    // const getCompanyStatus = useSelector(getCompanyStatusSelector);
    const { formatNumber } = Shared({ getUser: user });
    const [breakDownModal, setShowBreakdownModal] = useState(false);

    const [companyResult, setCompanyResult] = useState({
        deck: "",
        name: "",
        degree_of_fit: "",
        website: "",
        number_of_employees: "",
        total_funding_amount_currency: "",
        total_funding_amount_dollars: "",
        hq_city: "",
        hq_country: "",
        investment_stage: "",
        description: "",
        sectors: "",
        completeness_score: "",
        vote_user: ""
    });
    const {
        name,
        degree_of_fit,
        website,
        number_of_employees,
        total_funding_amount_currency,
        total_funding_amount_dollars,
        last_funding_amount_dollars,
        hq_country,
        investment_stage,
        description,
        sectors,
        subsectors,
        founded_date,
    } = companyResult;

    const isIndustryDataFunding = () => {
        return isIndustry ? last_funding_amount_dollars : total_funding_amount_dollars;
    }
    const [newDescriptionText, setNewDescriptionText] = useState(description);
    const handleNewDescriptionText = event => {
        setNewDescriptionText(event.target.value);
    };
    const [newDateText, setNewDateText] = useState(founded_date);
    const handleFoundedDateChange = event => {
        setNewDateText(event.target.value);
    };
    const [newWebsiteText, setNewWebsiteText] = useState(website);
    const handleWebsiteTextChange = event => {
        setNewWebsiteText(event.target.value);
    };
    const [newFundingAmountDollarsText, setNewFundingAmountDollarsText] = useState(isIndustryDataFunding());

    const handleFundingTextChange = event => {
        setNewFundingAmountDollarsText(event.target.value);
    };
    const [newTitleText, setNewTitleText] = useState(name);
    const handleTitleChange = event => {
        setNewTitleText(event.target.value);
    };
    const [newFundingAmountCurrencyText, setNewFundingAmountCurrencyText] = useState(total_funding_amount_currency ? total_funding_amount_currency : "");
    const handleCurrencyTextChange = event => {
        setNewFundingAmountCurrencyText(event.target.value);
    };
    const [newCountryText, setNewCountryText] = useState(hq_country);
    const handleCountryChange = event => {
        setNewCountryText(event.target.value);
    };
    const [investmentStageText, setInvestmentStageText] = useState(investment_stage);
    const handleInvestmentStageChange = event => {
        setInvestmentStageText(event.target.value);
    };
    const [allNumEmployeesText, setAllNumEmployeesText] = useState(number_of_employees);
    const handleNumEmployeeTextChange = event => {
        setAllNumEmployeesText(event.target.value);
    };
    const [allCountries, setAllCountries] = useState([]);
    const [allStageOptions, setAllStageOptions] = useState(stageOptions);
    const [allNumEmployeesOptions, setAllNumEmployeesOptions] = useState(teamSizeOptions);
    const allCountriesSelector = useSelector(getCountriesSelector);


    const handleSavingCompany = () => {

        isIndustry ?
            dispatch(updateCompanyIndustryKanbanRequest({ companies: [{ id: companyData.id, name: newTitleText, description: newDescriptionText, founded_date: newDateText, website: newWebsiteText, last_funding_amount_dollars: newFundingAmountDollarsText ? newFundingAmountDollarsText : 0, country: newCountryText, investment_stage: investmentStageText, number_of_employees: allNumEmployeesText, kanban_status: companyData.kanban_status }] })) :
            dispatch(updateCompanyKanbanRequest({ companies: [{ id: companyData.id, name: newTitleText, description: newDescriptionText, founded_date: newDateText, website: newWebsiteText, total_funding_amount_dollars: newFundingAmountDollarsText ? newFundingAmountDollarsText : 0, total_funding_amount_currency: newFundingAmountCurrencyText, hq_country: newCountryText, investment_stage: investmentStageText, number_of_employees: allNumEmployeesText, kanban_status: companyData.kanban_status }] }));
        handleClose();
    };
    const closeModal = () => {
        setNewDescriptionText(description);
        setNewDateText(founded_date);
        setNewWebsiteText(website);
        setNewFundingAmountDollarsText(isIndustryDataFunding);
        setNewFundingAmountCurrencyText(total_funding_amount_currency);
        setNewCountryText(hq_country);
        setAllStageOptions(stageOptions);
        setInvestmentStageText(investment_stage);
        setAllNumEmployeesOptions(teamSizeOptions);
        setAllNumEmployeesText(number_of_employees);

        handleClose();
    }



    useEffect(() => {
        setCompanyResult(companyData)
    }, []);
    useEffect(() => {
        dialogOpened && dispatch(getCountriesRequest());
    }, [dispatch, dialogOpened]);
    useEffect(() => {
        setNewDescriptionText(description);
        setNewDateText(founded_date);
        setNewWebsiteText(website);
        setNewFundingAmountDollarsText(isIndustryDataFunding());
        setNewFundingAmountCurrencyText(total_funding_amount_currency);
        setNewCountryText(hq_country);
        setAllCountries(allCountriesSelector);
        setAllStageOptions(stageOptions);
        setInvestmentStageText(investment_stage);
        setAllNumEmployeesOptions(teamSizeOptions);
        setAllNumEmployeesText(number_of_employees);
        setNewTitleText(name);

    }, [companyResult, stageOptions, allCountriesSelector, name]);

    return {
        t,
        slug,
        search,
        dispatch,
        setCompanyResult,
        name,
        degree_of_fit,
        website,
        number_of_employees,
        total_funding_amount_currency,
        total_funding_amount_dollars,
        hq_country,
        investment_stage,
        description,
        sectors,
        founded_date,
        formatNumber,
        breakDownModal,
        setShowBreakdownModal,
        companyResult,
        handleNewDescriptionText,
        newDescriptionText,
        subsectors,
        handleFoundedDateChange,
        newDateText,
        newWebsiteText,
        handleWebsiteTextChange,
        newFundingAmountDollarsText,
        newFundingAmountCurrencyText,
        handleFundingTextChange,
        handleCurrencyTextChange,
        handleCountryChange,
        newCountryText,
        allCountries,
        investmentStageText,
        handleInvestmentStageChange,
        allStageOptions,
        allNumEmployeesOptions,
        handleNumEmployeeTextChange,
        allNumEmployeesText,
        handleSavingCompany,
        closeModal,
        handleTitleChange,
        newTitleText
    }
};