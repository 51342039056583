import { createSlice } from "@reduxjs/toolkit";
import {
    REQUEST_UNDEFINED,
    REQUEST_PENDING,
    REQUEST_SUCCESS,
    REQUEST_FAILURE
} from "../../constants/statuses";

export const INITIAL_STATE = {
    pool: [],
    totalCount: null,
    getFilteringStatus: REQUEST_UNDEFINED,
    filterResultsCount: null,
    getFilterResultsCountStatus: REQUEST_UNDEFINED
};
export const REDUCER_PREFIX = "filtering";

const { reducer, actions } = createSlice({
    name: REDUCER_PREFIX,
    initialState: INITIAL_STATE,
    reducers: {
        getFilteringPending: state => {
            state.getFilteringStatus = REQUEST_PENDING;
        },
        getFilteringSuccess: state => {
            state.getFilteringStatus = REQUEST_SUCCESS;
        },
        getFilteringFailure: state => {
            state.getFilteringStatus = REQUEST_FAILURE;
        },
        getFilterResultsCountPending: state => {
            state.getFilterResultsCountStatus = REQUEST_PENDING;
        },
        getFilterResultsCountSuccess: state => {
            state.getFilterResultsCountStatus = REQUEST_SUCCESS;
        },
        getFilterResultsCountFailure: state => {
            state.getFilterResultsCountStatus = REQUEST_FAILURE;
        },
        setFilterResultsCount: (state, { payload }) => {
            state.filterResultsCount = payload.data.pagination.total;
        },
        setFilteredResults: (state, { payload }) => {
            const statePool = state.pool;
            state.pool = !payload.page || (!!payload.page && payload.page < 2) ? [] : statePool;
            payload.data.results.forEach(result => {
                const {
                    name,
                    degree_of_fit,
                    deck,
                    sectors,
                    subsectors,
                    description,
                    founded_date,
                    hq_city,
                    hq_country,
                    investment_stage,
                    number_of_employees,
                    total_funding_amount_dollars,
                    id,
                    slug,
                    success_potential_score,
                    growth_score,
                    maturity_score,
                    innovation_score,
                    completeness_score,
                    website,
                    sustainability_score,
                    enrichment_request,
                    ready_for_release,
                    priority,
                    search_id,
                    valuer_score
                } = result;
                const uniqueResult = {
                    id,
                    name,
                    degree_of_fit,
                    deck,
                    sectors,
                    subsectors,
                    description,
                    founded_date,
                    hq_city,
                    hq_country,
                    investment_stage,
                    number_of_employees,
                    total_funding_amount_dollars: total_funding_amount_dollars ? Math.round(parseInt(total_funding_amount_dollars)) : "",
                    success_potential_score,
                    growth_score,
                    maturity_score,
                    innovation_score,
                    slug,
                    website,
                    sustainability_score,
                    completeness_score,
                    enrichment_request,
                    ready_for_release,
                    priority,
                    search_id,
                    valuer_score
                };
                state.pool.push(uniqueResult);
            });
            state.totalCount = payload.data.pagination.total;
            state.sid = payload.data.results[0] && payload.data.results[0].search_id;
            state.totalPages = payload.data.pagination.total_pages;
        },
        clearResults: state => {
            state.pool = [];
            state.totalCount = null;
        }
    }
});
export { reducer, actions };