import React, { useState, useEffect } from "react";
import classnames from "classnames";
// Hooks
import useStyles from "./style";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import { useRouterHook } from "../../common/useRouterHook";
// Components
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import Link from '../../components/Link/Link';
import IndustryCard from "../Industries/IndustryCard/IndustryCard";
// Assets
import { ReactComponent as ArrowRight } from '../../assets/icons/ArrowRight.svg';

const data = {
    title: "Accomodation and Food",
    slug: "accomodation-and-food",
    icon: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis imperdiet accumsan. Morbi sollicitudin velit id lacinia sollicitudin. Nullam erat enim, tincidunt nec urna eget, consequat mattis felis. Morbi sollicitudin velit id lacinia sollicitudin. Nullam erat enim, tincidunt nec urna eget.",
    subsectors: [
        {
            name: "Agriculture",
            slug: "agriculture",
            description: "agriculture",
            lastUpdated: "January 20, 2021",
            userUpdated: "gka@valuer.ai",
            inProgress: false,
            ready: true
        },
        {
            name: "Aviation",
            slug: "aviation",
            description: "aviation",
            lastUpdated: null,
            userUpdated: null,
            inProgress: true,
            ready: false
        },
        {
            name: "AI",
            slug: "ai",
            description: "ai",
            lastUpdated: null,
            userUpdated: null,
            inProgress: false,
            ready: false
        },
    ]
}

const AddIndustry = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openPage } = useRouterHook();

    const handleGoBack = () => {
        openPage("/industries");
    };

    return (
        <div className={classes.root}>
            <Typography onClick={handleGoBack} variant="bodyButton" rotateIcon color="secondaryDark" component="span" className={classes.goBack}>
                <ArrowRight className={classes.goBackIcon} />
                {t('common.goBack')}
            </Typography>
            <IndustryCard className={classes.card} profile add />
        </div>
    )
};
export default AddIndustry;